import { createFeatureSelector, createSelector } from '@ngrx/store';

import { MarketingPermissionState } from './marketing-permission.state';

export const marketingPermissionFeatureStateSelector =
  createFeatureSelector<MarketingPermissionState>('marketingPermission');

export const getClaasMarketingPermission = createSelector(
  marketingPermissionFeatureStateSelector,
  state => state.claasMarketingPermission
);

export const getDealerMarketingPermissions = createSelector(
  marketingPermissionFeatureStateSelector,
  state => state.dealerMarketingPermissions
);

export const getMarketingPermissionGetPending = createSelector(
  marketingPermissionFeatureStateSelector,
  state => state.getPending
);

export const getMarketingPermissionCreatePending = createSelector(
  marketingPermissionFeatureStateSelector,
  state => state.createPending
);
