import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { AuthService } from '../shared/services/auth.service';
import { Store } from '@ngrx/store';
import { selectUserCoordinates } from '../shared/state/user-coordinates/user-coordinates.selectors';
import { loadUserCoordinates } from '../shared/state/user-coordinates/user-coordinates.actions';

@Component({
  templateUrl: 'terms.component.html',
  styleUrls: ['terms.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TermsComponent {
  pdfSrc = '';
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  private browserSet = this.getBrowserSet();
  public country = this.getCountry(this.browserSet);

  constructor(
    public translate: TranslateService,
    public store: Store,
    private authService: AuthService
  ) {
    this.pdfSrc = '/assets/legal/' + this.country + '/terms.pdf';
  }

  // returns an array with browser language [0] and country [1]
  getBrowserSet() {
    const browserSetString = window.navigator.language;
    const browserSet = ['', '']; // array for language and country
    if (browserSetString.indexOf('-') !== -1) {
      browserSet[0] = browserSetString.split('-')[0].toLowerCase(); // language
      browserSet[1] = browserSetString.split('-')[1].toLowerCase(); // country
    }
    if (browserSetString.indexOf('_') !== -1) {
      browserSet[0] = browserSetString.split('_')[0].toLowerCase();
      browserSet[1] = browserSetString.split('_')[1].toLowerCase();
    }
    if (browserSetString.length <= 2) {
      browserSet[0] = browserSetString;
      browserSet[1] = browserSetString;
    }
    return browserSet;
  }

  getCountry(browserSet: any[]) {
    let country;
    // set country
    if (environment.general.countries.indexOf(browserSet[1]) >= 0) {
      country = browserSet[1];
    } else {
      country = 'de';
    }
    if (this.authService.loggedIn) {
      this.store.select(selectUserCoordinates).subscribe({
        next: (coordinates: any) => {
          if (!coordinates) {
            this.store.dispatch(loadUserCoordinates());
          } else {
            if (coordinates['country']) {
              country = coordinates['country'].toLowerCase();
            }
          }
        }
      });
    }
    return country;
  }
}
