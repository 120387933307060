<div class="intl-phone-container">
  <form *ngIf="myForm" [formGroup]="myForm">
    <mat-form-field class="intl-phone-container__field">
      <ngx-mat-intl-tel-input
        name="phone"
        cssClass="country-phone-input"
        formControlName="phone"
        [preferredCountries]="preferredCountries ? preferredCountries : []"
        [onlyCountries]="onlyCountries"
        (countryChanged)="updateCountryCode($event)">
      </ngx-mat-intl-tel-input>
    </mat-form-field>

    <div *ngIf="isEditMode || isAddingMode">
      <div *ngIf="numberFieldTouched">
        <div
          *ngIf="!myForm.get('phone')?.value || (alreadyExistsSubject | async)"
          class="intl-phone-container__error-message">
          <span>
            {{ hintMessage }}
          </span>
        </div>
      </div>
    </div>
  </form>
</div>
