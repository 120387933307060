import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GlobalState } from './global.state';

export const selectGlobalState = createFeatureSelector<GlobalState>('global');

export const selectRegistrationDataPending = createSelector(
  selectGlobalState,
  state => state.registrationDataPending
);

export const selectRegistrationPersonalData = createSelector(
  selectGlobalState,
  state => state.registrationPersonalData
);

export const selectRegistrationPersonalDataPending = createSelector(
  selectGlobalState,
  state => state.registrationPersonalDataPending
);

export const selectRegistrationDealerData = createSelector(
  selectGlobalState,
  state => state.registrationDealerData
);

export const selectAddressAutocompleteSuggestionsData = createSelector(
  selectGlobalState,
  state => state.addressAutocompleteSuggestions
);

export const selectRegistrationShopData = createSelector(
  selectGlobalState,
  state => state.registrationShopData
);

export const selectShopDataLoadErrorData = createSelector(
  selectGlobalState,
  state => state.shopDataLoadError
);

export const selectRegistrationErrorData = createSelector(
  selectGlobalState,
  state => state.registrationError
);
