import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: 'about.component.html',
  styleUrls: ['about.component.scss']
})
export class AboutComponent {
  constructor(public translate: TranslateService) {}
}
