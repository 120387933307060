export class OrgAddress {
  type: string;
  legalName: string;
  country: string;
  state: string;
  suburb: string;
  territory: string;
  street: string;
  houseNumber: string;
  postalCode: string;
  city: string;
  postbox: string;
  addressAddition: string;
  id: string;
  latitude: number;
  longitude: number;

  new = false;
  edit_visible = false;

  constructor(addressValue?: any) {
    this.type =
      addressValue?.type || addressValue?.type === ''
        ? addressValue.type
        : undefined;

    this.legalName =
      addressValue?.legalName || addressValue?.legalName === ''
        ? addressValue.legalName
        : '';

    this.country =
      addressValue?.country || addressValue?.country === ''
        ? addressValue.country
        : 'de';

    this.state =
      addressValue?.state || addressValue?.state === ''
        ? addressValue.state
        : '';

    this.suburb =
      addressValue?.suburb || addressValue?.suburb === ''
        ? addressValue.suburb
        : '';

    if (this.country.toLowerCase() === 'ro') {
      this.territory =
        addressValue?.state || addressValue?.state === ''
          ? addressValue.state
          : '';
    } else {
      this.territory =
        addressValue?.territory || addressValue?.territory === ''
          ? addressValue.territory
          : '';
    }

    this.street =
      addressValue?.street || addressValue?.street === ''
        ? addressValue.street
        : '';

    this.houseNumber =
      addressValue?.houseNumber || addressValue?.houseNumber === ''
        ? addressValue.houseNumber
        : '';

    // in case we are copying the address from search selection
    if (addressValue?.house_number) {
      this.houseNumber = addressValue.house_number;
    }

    this.postalCode =
      addressValue?.postalCode || addressValue?.postalCode === ''
        ? addressValue.postalCode
        : '';

    // in case we are copying the address from search selection
    if (addressValue?.postal_code) {
      this.postalCode = addressValue.postal_code;
    }

    this.city =
      addressValue?.city || addressValue?.city === '' ? addressValue.city : '';

    this.postbox =
      addressValue?.postbox || addressValue?.postbox === ''
        ? addressValue.postbox
        : '';

    this.id =
      addressValue?.id || addressValue?.id === '' ? addressValue.id : undefined;

    this.addressAddition =
      addressValue?.addressAddition || addressValue?.addressAddition === ''
        ? addressValue.addressAddition
        : '';

    this.latitude = addressValue?.latitude ? addressValue.latitude : 0;
    this.longitude = addressValue?.longitude ? addressValue.longitude : 0;
  }

  getOrgAddress() {
    return {
      type: this.type,
      legalName: this.legalName,
      country: this.country,
      street: this.street,
      houseNumber: this.houseNumber,
      postalCode: this.postalCode,
      city: this.city,
      state: this.state,
      postbox: this.postbox
    };
  }
}
