<div class="language__wrapper--line">
  <p class="language__title subtitle-2">
    {{ 'account_settings.language.edit_header' | translate }}
  </p>
  <claas-circular-progress
    *ngIf="showSpinner$ | async"
    class="spinner"
    [color]="color"
    [diameter]="size"
  ></claas-circular-progress>

  <p class="language__subheading">
    {{ 'account_settings.language.edit_text' | translate }}
  </p>

  <claas-dropdown-key-value
    class="language__dropdown"
    [label]="'account_settings.language.label' | translate"
    [options]="options"
    [multiSelect]="false"
    [defaultOption]="defaultOption"
    (onChange)="onChangeLanguage($event)" />

  <div class="dialog-actions">
    <div class="left-side">
      <div class="btn-left">
      </div>
    </div>
    <div class="right-side">
      <claas-button
        *ngIf="data?.buttonCancel"
        class="btn-left"
        [buttonClass]="secondaryTextButtonClass"
        [text]="'generic.cancel' | translate"
        [disabled]="(showSpinner$ | async) === true"
        (onClick)="onCancelClicked()">
      </claas-button>

      <claas-button
        *ngIf="data?.buttonSave"
        class="btn-right"
        [text]="'generic.save' | translate"
        [disabled]="(showSpinner$ | async) === true"
        (onClick)="onSaveClicked()">
      </claas-button>
    </div>
  </div>
</div>
