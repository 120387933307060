import { Pipe, PipeTransform } from '@angular/core';
import { UserOrgaAddress } from '../../models/user-orga-address.model';
import { countriesArr } from '../../components/language-view/countries-source';
import { Observable, of, switchMap } from 'rxjs';
import { FieldDetails } from '../../components/address-view/address-view.component';

@Pipe({
  name: 'address'
})
export class AddressPipe implements PipeTransform {
  withName = false;

  transform(
    value: UserOrgaAddress | UserOrgaAddress[],
    type?: 'INVOICE' | 'DELIVERY',
    withName?: boolean
  ): string {
    this.withName = !!withName;
    if (Array.isArray(value)) {
      value = value.filter(el => el.type === type)[0];
    }

    return this.getDisplayedAddress(value);
  }

  getDisplayedAddress(value: UserOrgaAddress): string {
    let displayedAddress = '';

    if (value.street) {
      displayedAddress = displayedAddress.concat(value.street);
    }

    if (value.houseNumber) {
      displayedAddress = displayedAddress.concat(' ', value.houseNumber);
    }

    if (value.house_number) {
      displayedAddress = displayedAddress.concat(' ', value.house_number);
    }

    displayedAddress = displayedAddress.concat('\n');

    if (value.addressAddition) {
      displayedAddress = displayedAddress.concat(value.addressAddition);
      displayedAddress = displayedAddress.concat('\n');
    }

    if (value.postalCode) {
      displayedAddress = displayedAddress.concat(' ', value.postalCode);
    }
    if (value.postal_code) {
      displayedAddress = displayedAddress.concat(' ', value.postal_code);
    }

    if (value.city) {
      displayedAddress = displayedAddress.concat(' ', value.city, ' ');
    }

    displayedAddress = displayedAddress.concat('\n');

    if (value.suburb) {
      displayedAddress = displayedAddress.concat(' ', value.suburb, ' ');
      displayedAddress = displayedAddress.concat('\n');
    }

    if (value.territory) {
      displayedAddress = displayedAddress.concat(' ', value.territory, ' ');

      displayedAddress = displayedAddress.concat('\n');
    }

    if (value.state) {
      displayedAddress = displayedAddress.concat(' ', value.state, ' ');

      displayedAddress = displayedAddress.concat('\n');
    }

    if (value.country) {
      displayedAddress = displayedAddress.concat(' ', value.country);
    }

    return displayedAddress;
  }
}
