<div class="email-view">
  <div *ngIf="email_pending" class="email-view__body">
    <div class="email-view__body-content">
      <div class="email-view__body-content--icon">
        <lib-icon [iconId]="'001419'" [size]="80"></lib-icon>
      </div>
      <div class="email-view__body-content--header">
        <span class="header-3-bold">{{'mail.info_headline' | translate}}</span>
      </div>
      <div class="email-view__body-content--message">
        <span>{{'mail.info_sub_headline' | translate}}</span>
      </div>
      <div class="email-view__body-content--actions">
        <claas-button
          *ngIf="!isLoading else loadingSpinner"
          class="email-view__body-content--actions_resend-btn"
          [text]="'mail.resend_link' | translate"
          [buttonClass]="primaryButtonClass"
          [size]="mediumButtonSize"
          (click)="resentConfirmationEmail()"></claas-button>
      </div>
    </div>

    <ng-template #loadingSpinner>
      <span class="email-view__body-content--actions_loader">
        <claas-circular-progress
          [color]="secondaryColor"
          [diameter]="largeSize"></claas-circular-progress>
      </span>
    </ng-template>
  </div>

  <div *ngIf="email_missing" class="email-view__body">
    <div class="email-view__body-content">
      <div class="email-view__body-content--icon mail-missing">
        <img class='mail-icon' src='assets/images/mail_missing.jpg' />
      </div>
      <div class="email-view__body-content--header">
        <span class="header-3-bold">{{'mail.headline_email_missing' | translate}}</span>
      </div>
      <div class="email-view__body-content--message">
        <span>{{'mail.subline_email_missing_1' | translate}}</span>
      </div>
      <div class="email-view__body-content--message">
        <span [innerHTML]="'mail.subline_email_missing_2' | translate"></span>
      </div>

      <div class="email-view__body-content--actions">
        <claas-button
          class="email-view__body-content--actions_resend-btn"
          [text]="'mail.to_facebook_button' | translate"
          [buttonClass]="primaryButtonClass"
          [size]="mediumButtonSize"
          (click)="routeToFacebook()"></claas-button>
      </div>
    </div>
  </div>
</div>
