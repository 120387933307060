import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {
  ResponseDataFromDialog,
  passedDataToDialog
} from '../dialog-window/dialog-window.component';
import { ButtonClass } from '@claas/claas-form-components';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subscription, finalize, switchMap } from 'rxjs';
import { errorStatusObject } from '../address-view/address-view.component';
import { ApiService } from '../../services/api.service';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { Organization } from '../../models/organization.model';
import { timeZonesNames } from '@vvo/tzdb';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-organisation-timezone-view',
  templateUrl: './organisation-timezone-view.component.html',
  styleUrls: ['./organisation-timezone-view.component.scss']
})
export class OrganisationTimezoneViewComponent implements OnInit, OnDestroy {
  @Input() data: passedDataToDialog = {};
  @Input() secondaryTextButtonClass?: ButtonClass;

  @Output() displayPopup = new EventEmitter();
  @Output() closeDialogWindow = new EventEmitter();

  tempTimezone = '';
  organisationTimezoneOptions = timeZonesNames;
  myForm: FormGroup | undefined;
  subscriptions = new Subscription();
  invalidSaveButtonState$ = new BehaviorSubject(true);
  errorsStatusObject$: errorStatusObject = {};

  constructor(
    private apiService: ApiService,
    private auth: AuthService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.tempTimezone = this.data.organisationData?.timeZone
      ? this.data.organisationData?.timeZone
      : '';

    this.myForm = this.setForm();
    this.trackForm(this.myForm);
  }

  setForm(): FormGroup {
    return new FormGroup({
      timezone: new FormControl(this.tempTimezone, [Validators.required])
    });
  }

  trackForm(myForm: FormGroup): void {
    // @ts-ignore
    this.errorsStatusObject$['timezone'] = this.myForm
      .get('timezone')
      .valueChanges.pipe(
        map(value => {
          return this.myForm?.get('timezone')?.status === 'INVALID'
            ? 'registration.validationMsgs.required'
            : undefined;
        })
      );

    this.subscriptions.add(
      myForm.valueChanges.subscribe(() => {
        // update the save button status
        this.invalidSaveButtonState$.next(!!this.myForm?.invalid);
      })
    );
  }

  onCancelClicked(): any {
    const passedObject = {
      action: 'cancel'
    };

    this.closeDialogWindow.emit(passedObject);
  }

  onSaveClicked(): any {
    const passedObject: ResponseDataFromDialog = {
      context: this.data.context,
      action: 'save'
    };

    const organisationUpdate = this.preUpdateOrganisation();

    this.saveNewTimezoneDetails(passedObject, organisationUpdate);
    this.invalidSaveButtonState$.next(true);
  }

  saveNewTimezoneDetails(
    passedObject: ResponseDataFromDialog,
    organisationUpdate: Organization
  ): void {
    this.subscriptions.add(
      this.auth
        .getTokenSilently$()
        .pipe(
          switchMap(token =>
            this.apiService.updateOrganisation(
              this.data.organisationData.auth0Id,
              organisationUpdate,
              token
            )
          ),
          finalize(() => this.closeDialogWindow.emit(passedObject))
        )
        .subscribe({
          next: value => {
            // Update the organisation data
            this.translateService
              .get('organization.edit_timezone.success')
              .subscribe(text => {
                passedObject.refreshIsRequired = true;
                this.displayPopup.emit({ text, className: '' });
              });
          },
          error: err => {
            this.translateService
              .get('organization.edit_timezone.fail')
              .subscribe(text => {
                this.displayPopup.emit({ text, className: 'error' });
              });
          }
        })
    );
  }

  preUpdateOrganisation(): Organization {
    const organisationUpdate = new Organization();

    switch (this.data.context) {
      case 'organisation-timezone':
        organisationUpdate.copyOrganisation(this.data.organisationData);
        organisationUpdate.timeZone = this.myForm?.get('timezone')?.value
          ? this.myForm?.get('timezone')?.value
          : '';
        break;
    }

    return organisationUpdate;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
