<div [ngSwitch]="type">
  <ng-container *ngSwitchCase="'outdated'">
    <mat-chip>
      <lib-icon [iconId]="'001339'" [size]="26"></lib-icon>
      <span>{{ label }}</span>
    </mat-chip>
  </ng-container>

  <ng-container *ngSwitchCase="'accepted'">
    <mat-chip class="status green"
      ><lib-icon [iconId]="'648503'" [size]="21"></lib-icon
      ><span>{{ label }}</span></mat-chip
    >
  </ng-container>

  <ng-container *ngSwitchCase="'pending'">
    <mat-chip class="status blue"
      ><lib-icon [iconId]="'001110'" [size]="25"></lib-icon
      ><span>{{ label }}</span></mat-chip
    >
  </ng-container>
</div>
