import { User } from '../../models/auth.model';

export interface UserState {
  userDetails: User | null;
  isLoggedIn: boolean;
  token: string | null;
  pending: boolean;
  userAvatarUrl: string | null;
}

export const initialState: UserState = {
  userDetails: null,
  isLoggedIn: false,
  token: null,
  pending: false,
  userAvatarUrl: null
};
