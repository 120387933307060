import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {
  ResponseDataFromDialog,
  passedDataToDialog
} from '../dialog-window/dialog-window.component';
import {
  ButtonClass,
  DropdownOptionObject
} from '@claas/claas-form-components';
import { BehaviorSubject, Subscription, finalize } from 'rxjs';
import { ApiService } from '../../services/api.service';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { countriesArr } from '../language-view/countries-source';
import { PhoneNumber } from '../../models/phone-number.model';
import { CircularProgressDiameter, ProgressColor } from '@claas/claas-layout-components';

@Component({
  selector: 'app-phonenumber-view',
  templateUrl: './phonenumber-view.component.html',
  styleUrls: ['./phonenumber-view.component.scss']
})
export class PhonenumberViewComponent implements OnInit, OnDestroy {
  countriesIds = countriesArr.map(el => el.id);
  currentPhoneIndex = 0;
  phoneNumbers: PhoneNumber[] = [];
  subscriptions = new Subscription();
  invalidNumberState = true;
  selectedPhoneNumberType = '';
  selectedPhoneNumberValue: string | undefined = '';
  selectedPhoneNumberCountry = '';
  phoneNumberTypeOptions: string[] = [];
  myForm: FormGroup | undefined;
  touchedPhoneField = false;
  preferredCountries: string[] | undefined = [];
  confirmationDialogIsActive$ = new BehaviorSubject(false);
  invalidSaveButtonState$ = new BehaviorSubject(true);
  actionInProgress$ = new BehaviorSubject(false);
  myForm$: BehaviorSubject<any> = new BehaviorSubject(undefined);
  color = ProgressColor.PRIMARY;
  size = CircularProgressDiameter.SMALL;
  isSaving = false;

  typeOptions = [
    {
      text: 'label',
      key: 'key'
    }
  ];

  @Input() data: passedDataToDialog = {};
  @Input() errorButtonClass?: ButtonClass;
  @Input() secondaryTextButtonClass?: ButtonClass;

  @Output() displayPopup = new EventEmitter();
  @Output() closeDialogWindow = new EventEmitter();

  constructor(
    private apiService: ApiService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    if (this.data.phonenumberDetails?.phoneNumbers) {
      this.phoneNumbers = this.data.phonenumberDetails.phoneNumbers;

      if (
        this.data.subContext === 'edit' &&
        (this.data.phonenumberDetails.currentIndex ||
          this.data.phonenumberDetails.currentIndex === 0)
      ) {
        this.currentPhoneIndex = this.data.phonenumberDetails.currentIndex;
      }

      if (this.data.phonenumberDetails.phoneNumbers.length === 2) {
        // edit one of the phoneNumbers

        const onlyPossibleType =
          this.data.phonenumberDetails.phoneNumbers[this.currentPhoneIndex]
            .type;
        this.phoneNumberTypeOptions = [];
        this.phoneNumberTypeOptions.push(onlyPossibleType);

        this.myForm = new FormGroup({
          type: new FormControl(onlyPossibleType)
        });
      } else {
        if (this.data.subContext === 'add' && this.phoneNumbers[0]) {
          // add a second phoneNumber

          this.phoneNumberTypeOptions =
            this.phoneNumbers[0].type === 'phone' ? ['mobile'] : ['phone'];

          this.selectedPhoneNumberType = this.phoneNumberTypeOptions[0];

          this.myForm = new FormGroup({
            type: new FormControl(this.selectedPhoneNumberType)
          });
        } else {
          this.phoneNumberTypeOptions = ['mobile', 'phone'];

          if (this.data.subContext === 'edit') {
            // edit the first phoneNumber

            this.myForm = new FormGroup({
              type: new FormControl(
                this.data.phonenumberDetails.phoneNumbers[
                  this.currentPhoneIndex
                ].type
              )
            });
          } else {
            // add the first phoneNumber

            this.selectedPhoneNumberType = 'mobile';

            this.myForm = new FormGroup({
              type: new FormControl(this.selectedPhoneNumberType)
            });
          }
        }
      }

      this.typeOptions = this.getPhoneNumberTypeOptions();

      this.myForm
        .get('type')
        ?.valueChanges.subscribe(value => this.updatedPhonenumberType(value));

      this.myForm$.next(this.myForm);
    }

    this.preferredCountries = this.data.countriesForPhone;
  }

  getPhoneNumberTypeOptions() {
    const options: any[] = [];
    for (const p of this.phoneNumberTypeOptions) {
      const o = { text: this.getPhoneNumberType(p), key: p };
      options.push(o);
    }
    return options;
  }

  getPhoneNumberType(p: string) {
    let label = '';
    this.translateService
      .get('personal_data.phonenumber.types.' + p)
      .subscribe((data: string) => (label = data));
    return label;
  }

  showConfirmationDialog(): void {
    this.confirmationDialogIsActive$.next(true);
  }

  onCancelClicked(): any {
    const passedObject = {
      action: 'cancel'
    };

    this.closeDialogWindow.emit(passedObject);
  }

  onSaveClicked(): void {
    this.isSaving = true;
    const passedObject: ResponseDataFromDialog = {
      context: this.data.context,
      action: 'save'
    };

    const passedNumber = new PhoneNumber();

    if (this.data.phonenumberDetails && !this.selectedPhoneNumberType) {
      this.selectedPhoneNumberType =
        this.data.phonenumberDetails.phoneNumbers[this.currentPhoneIndex].type;
    }

    passedNumber.type = this.selectedPhoneNumberType;
    passedNumber.country = this.selectedPhoneNumberCountry;
    passedNumber.phone_number_value = this.selectedPhoneNumberValue;

    this.disableAllActions();

    if (this.data.subContext === 'add') {
      this.createUserPhonenumber(passedNumber, passedObject);
    } else {
      this.updateUserPhonenumber(passedNumber, passedObject);
    }
  }

  createUserPhonenumber(
    passedNumber: PhoneNumber,
    passedObject: ResponseDataFromDialog
  ) {
    this.subscriptions.add(
      this.apiService
        .createUserPhoneNumber(passedNumber)
        .pipe(finalize(() => this.closeDialogWindow.emit(passedObject)))
        .subscribe({
          next: value => {
            // Set new phone Number
            this.translateService
              .get('personal_data.phonenumber.add.success')
              .subscribe((text: string) => {
                passedObject.refreshIsRequired = true;
                this.displayPopup.emit({ text, className: '' });
              });
          },
          error: err => {
            this.translateService
              .get('personal_data.phonenumber.add.fail')
              .subscribe((text: string) => {
                this.displayPopup.emit({ text, className: 'error' });
              });
          }
        })
    );
  }

  updateUserPhonenumber(
    passedNumber: PhoneNumber,
    passedObject: ResponseDataFromDialog
  ) {
    this.subscriptions.add(
      this.apiService
        .updateUserPhoneNumber(passedNumber)
        .pipe(finalize(() => this.closeDialogWindow.emit(passedObject)))
        .subscribe({
          next: value => {
            // Update phone Number
            this.translateService
              .get('personal_data.phonenumber.update.success')
              .subscribe((text: string) => {
                passedObject.refreshIsRequired = true;
                this.displayPopup.emit({ text, className: '' });
              });
          },
          error: err => {
            this.translateService
              .get('personal_data.phonenumber.update.fail')
              .subscribe((text: string) => {
                passedObject.refreshIsRequired = true;
                this.displayPopup.emit({ text, className: 'error' });
              });
          }
        })
    );
  }

  deletionIsCanceled(): void {
    this.confirmationDialogIsActive$.next(false);
  }

  deletionIsConfirmed(): void {
    this.confirmationDialogIsActive$.next(false);
    this.onDeleteClicked();
  }

  onDeleteClicked(): void {
    this.isSaving = true;
    const type = this.data?.phonenumberDetails
      ? this.data?.phonenumberDetails.phoneNumbers[this.currentPhoneIndex].type
      : '';

    const passedObject: ResponseDataFromDialog = {
      context: this.data.context,
      action: 'delete'
    };

    this.disableAllActions();

    this.deletePhoneNumber(passedObject, type);
  }

  deletePhoneNumber(passedObject: ResponseDataFromDialog, type: string): void {
    if (this.data.phonenumberDetails) {
      this.subscriptions.add(
        this.apiService
          .deleteUserPhoneNumber(type)
          .pipe(finalize(() => this.closeDialogWindow.emit(passedObject)))
          .subscribe({
            next: value => {
              // Delete the phone Number
              this.translateService
                .get('personal_data.phonenumber.delete.success')
                .subscribe((text: string) => {
                  passedObject.refreshIsRequired = true;
                  this.displayPopup.emit({ text, className: '' });
                });
            },
            error: err => {
              this.translateService
                .get('personal_data.phonenumber.delete.fail')
                .subscribe((text: string) => {
                  passedObject.refreshIsRequired = true;
                  this.displayPopup.emit({ text, className: 'error' });
                });
            }
          })
      );
    }
  }

  updatePhonenumber(value: any): void {
    this.selectedPhoneNumberValue = value.phone_number_value;
    this.selectedPhoneNumberCountry = value.country;
  }

  updateNumberInvalidState(isInvalid: boolean): void {
    this.touchedPhoneField = true;
    this.invalidNumberState = isInvalid;
    this.updateSaveButtonState();
  }

  updatedPhonenumberType(value: DropdownOptionObject): void {
    if (
      this.data.phonenumberDetails?.currentIndex ||
      this.data.phonenumberDetails?.currentIndex === 0
    ) {
      const currentIndex: number = this.data.phonenumberDetails?.currentIndex;

      this.selectedPhoneNumberValue =
        this.data.phonenumberDetails?.phoneNumbers[
          currentIndex
        ].phone_number_value;
    }
    this.selectedPhoneNumberType = value as unknown as string;
    this.updateSaveButtonState();
  }

  updateSaveButtonState(): void {
    let fieldsState;

    if (this.data.subContext === 'add') {
      fieldsState =
        this.selectedPhoneNumberType === '' || this.invalidNumberState;
    } else {
      if (this.touchedPhoneField) {
        fieldsState =
          this.selectedPhoneNumberType ===
            this.data.phonenumberDetails?.phoneNumbers[this.currentPhoneIndex]
              .type || this.invalidNumberState;
      } else {
        fieldsState =
          this.selectedPhoneNumberType ===
          this.data.phonenumberDetails?.phoneNumbers[this.currentPhoneIndex]
            .type;
      }
    }
    this.invalidSaveButtonState$.next(fieldsState);
  }

  disableAllActions(): void {
    this.invalidSaveButtonState$.next(true);
    this.actionInProgress$.next(true);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
