import { createAction, props } from '@ngrx/store';
import { MarketingPermission } from '../../models/marketing-permission.model';
import { StateError } from '../index';

export const loadUserCoordinates = createAction(
  '[UserCoordinates] Load User Coordinates'
);
export const userCoordinatesLoaded = createAction(
  '[UserCoordinates] User Coordinates Loaded',
  props<{ userDetails: any }>()
);

export const loadMarketingPermissions = createAction(
  'UserCoordinates] Load User Marketing Permissions',
  props<{ claasId: string }>()
);

export const marketingPermissionsLoaded = createAction(
  'UserCoordinates] User Marketing Permissions Loaded',
  props<{ permissions: MarketingPermission[] }>()
);

export const marketingPermissionsNotLoaded = createAction(
  'UserCoordinates] User Marketing Permissions Not Loaded',
  props<{ error: StateError }>()
);
