<div class="address-view">
  <div class="address-view__header">
    <div
      *ngIf="(confirmationDialogIsActive$ | async) === false"
      class="address-view__header-main">
      <h2
        *ngIf="data && data.subContext && data.subContext === 'edit'"
        class="address-view__header-title edit subtitle-2">
        {{ 'personal_data.address.edit_header' | translate }}
      </h2>
      <h2
        *ngIf="data && data.subContext && data.subContext === 'add'"
        class="address-view__header-title add subtitle-2">
        {{ 'personal_data.address.add_header' | translate }}
      </h2>
      <claas-circular-progress
        *ngIf="isSaving"
        class="spinner"
        [color]="color"
        [diameter]="size"
      ></claas-circular-progress>
    </div>

    <div
      *ngIf="confirmationDialogIsActive$ | async"
      class="address-view__header-confirmation-dialog">
      <div class="address-view__header-confirmation-dialog--header">
        <h2 class="subtitle-2">
          {{ 'personal_data.address.delete_header' | translate }}
        </h2>
      </div>
      <span class="dialog-window__confirmation-dialog--text">{{
        'personal_data.address.delete_text' | translate
      }}</span>
    </div>
  </div>

  <section *ngIf="(confirmationDialogIsActive$ | async) === false">
    <div class="address-view__body">
      <div class="address-view__body-content">
        <div class="address-view__body-content--line" *ngIf="currentAddressFormatObject['autocomplete']">
          <form class="search-form" >
            <mat-form-field class="full-width">
              <mat-label>{{ 'personal_data.address.search_label' | translate }}</mat-label>
              <input
                type="text"
                [placeholder]="'personal_data.address.search_placeholder' | translate"
                matInput
                [formControl]="mySearchFieldControl"
                [matAutocomplete]="auto" />
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option
                  *ngFor="let option of getAutoCompleteOptions$ | async"
                  [value]="option.description"
                  id="{{ option.id ? option.id : '' }}"
                  (onSelectionChange)="
                    getSelectedAddressDetails(option)
                  ">
                  {{ option.description }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </form>
        </div>

        <div
          *ngIf="(displayAddressFields$ | async) === false"
          class="address-view__body-content--line">
          <claas-button
            class="toggle-btn address"
            [text]="'personal_data.address.add_address_manually' | translate"
            [buttonClass]="primaryTextButtonClass"
            [size]="mediumButtonSize"
            (onClick)="displayAddressFields$.next(true)"></claas-button>
        </div>
      </div>

      <div
        *ngIf="displayAddressFields$ | async"
        class="address-view__body-content form-container">
        <div
          *ngIf="myForm$ | async; else formNotLoaded"
          class="address-form-container">
          <form [formGroup]="myForm$ | async">
            <div
              *ngIf="data?.target === 'organisation'"
              class="address-view__body-content--line">
              <mat-form-field class="full-width">
                <mat-label>{{
                  'registration.formfield.addressType' | translate
                }}</mat-label>

                <input
                  ngDefaultControl
                  matInput
                  disabled
                  [value]="
                    data?.elementType === invoice
                      ? ('registration.formfield.select.INVOICE' | translate)
                      : ('registration.formfield.select.DELIVERY' | translate)
                  " />
              </mat-form-field>
            </div>

            <div
              *ngIf="data?.subContext === 'edit'"
              class="address-view__body-content--line">
              <mat-form-field class="full-width">
                <mat-label>{{ 'registration.formfield.country' | translate }}</mat-label>
                <input ngDefaultControl matInput formControlName="country" />
              </mat-form-field>
            </div>

            <div
              *ngIf="data?.subContext !== 'edit'"
              class="address-view__body-content--line">
              <claas-dropdown-key-value
                ngDefaultControl
                class="full-width"
                formControlName="country"
                [label]="('registration.formfield.country' | translate) + '*'"
                [options]="countryOptions"
                [error]="errorsStatusObject$['country'] | async | translate"
                (onChange)="onCountryChange($event)"></claas-dropdown-key-value>
            </div>

            <div class="address-view__body-content--line">
              <claas-text-field
                *ngIf="currentAddressFormatObject['street']"
                [className]="
                  currentAddressFormatObject['houseNumber']
                    ? 'twice-third-width'
                    : 'full-width'
                "
                ngDefaultControl
                formControlName="street"
                [label]="
                  currentAddressFormatObject['street'].mandatory
                    ? ('registration.formfield.street' | translate) + '*'
                    : ('registration.formfield.street' | translate)
                "
                [placeholder]="('registration.formfield.street' | translate)"
                [error]="
                  errorsStatusObject$['street'] | async | translate
                "
                (input)="updateValidity($event,'street')"
              ></claas-text-field>

              <claas-text-field
                *ngIf="currentAddressFormatObject['houseNumber']"
                ngDefaultControl
                class="third-width"
                [formControlName]="
                  data?.target === 'organisation'
                    ? 'houseNumber'
                    : 'house_number'
                "
                [label]="
                  currentAddressFormatObject['houseNumber'].mandatory
                    ? ('registration.formfield.houseNumber' | translate) + '*'
                    : ('registration.formfield.houseNumber' | translate)
                "
                [placeholder]="('registration.formfield.houseNumber' | translate)"
                [error]="
                  errorsStatusObject$[
                    data?.target === 'organisation'
                      ? 'houseNumber'
                      : 'house_number'
                  ] | async | translate
                "
                (input)="updateValidity($event,
                  data?.target === 'organisation'
                    ? 'houseNumber'
                    : 'house_number')"
              ></claas-text-field>
            </div>

            <div
              *ngIf="currentAddressFormatObject['addressAddition']"
              class="address-view__body-content--line">
              <claas-text-field
                ngDefaultControl
                class="full-width"
                formControlName="addressAddition"
                [label]="
                  currentAddressFormatObject['addressAddition'].mandatory
                    ? ('registration.formfield.addressAddition' | translate) + '*'
                    : ('registration.formfield.addressAddition' | translate)
                "
                [placeholder]="('registration.formfield.addressAddition' | translate)"
                [error]="
                  errorsStatusObject$['addressAddition'] | async | translate
                "
                (input)="updateValidity($event,'addressAddition')"
              ></claas-text-field>
            </div>

            <div class="address-view__body-content--line">
              <div class="address-view__body-content--hint">
                <claas-text-field
                  *ngIf="currentAddressFormatObject['postalCode']"
                  ngDefaultControl
                  class="third-width"
                  [formControlName]="
                  data?.target === 'organisation' ? 'postalCode' : 'postal_code'
                "
                [label]="
                  currentAddressFormatObject['postalCode'].mandatory
                    ? ('registration.formfield.postalCode' | translate) + '*'
                    : ('registration.formfield.postalCode' | translate)
                "
                [placeholder]="('registration.formfield.postalCode' | translate)"
                [error]="
                  errorsStatusObject$[
                    data?.target === 'organisation' ? 'postalCode' : 'postal_code'
                  ] | async | translate
                "
                (input)="updateValidity(
                  $event,
                  data?.target === 'organisation' ? 'postalCode' : 'postal_code'
                )"
              ></claas-text-field>
                <mat-hint *ngIf="!postalCodeValid" style="color: red" >
                  {{ 'overview.postal_code_invalid' | translate }}
                </mat-hint>
              </div>

              <claas-text-field
                *ngIf="currentAddressFormatObject['city']"
                ngDefaultControl
                class="twice-third-width"
                formControlName="city"
                [label]="
                  currentAddressFormatObject['city'].mandatory
                    ? ('registration.formfield.city' | translate) + '*'
                    : ('registration.formfield.city' | translate)
                "
                [placeholder]="('registration.formfield.city' | translate)"
                [error]="
                  errorsStatusObject$['city'] | async | translate
                "
                (input)="updateValidity($event,'city')"
              ></claas-text-field>
            </div>


            <div
              *ngIf="currentAddressFormatObject['suburb']"
              class="address-view__body-content--line">
              <claas-text-field
                ngDefaultControl
                class="full-width"
                formControlName="suburb"
                [label]="
                  currentAddressFormatObject['suburb'].mandatory
                    ? ('registration.formfield.suburb' | translate) + '*'
                    : ('registration.formfield.suburb' | translate)
                "
                [placeholder]="('registration.formfield.suburb' | translate)"
                [error]="
                  errorsStatusObject$['suburb'] | async | translate
                "
                (input)="updateValidity($event,'suburb')"
              ></claas-text-field>
            </div>

            <div
              *ngIf="currentAddressFormatObject['territory']"
              class="address-view__body-content--line">
              <claas-dropdown-key-value
                ngDefaultControl
                class="full-width"
                formControlName="territory"
                [label]="
                  currentAddressFormatObject['territory'].mandatory
                    ? ('registration.formfield.territory' | translate) + '*'
                    : ('registration.formfield.territory' | translate)
                "
                [options]="territoryOptions"
                [error]="
                  errorsStatusObject$['territory'] | async | translate
                "
                (input)="updateValidity($event,'territory')"
              ></claas-dropdown-key-value>
            </div>

            <div
              *ngIf="currentAddressFormatObject['state']"
              class="address-view__body-content--line">
              <claas-dropdown-key-value
                ngDefaultControl
                class="full-width"
                formControlName="state"
                [label]="
                  currentAddressFormatObject['state'].mandatory
                    ? ('registration.formfield.state' | translate) + '*'
                    : ('registration.formfield.state' | translate)
                "
                [options]="stateOptions"
                [error]="
                  errorsStatusObject$['state'] | async | translate
                "
                (input)="updateValidity($event,'state')"
              ></claas-dropdown-key-value>
            </div>
          </form>
        </div>

        <ng-template #formNotLoaded>
          <span class="address-picture__loader">
            <claas-circular-progress
              [color]="secondaryColor"
              [diameter]="largeSize"></claas-circular-progress>
          </span>
        </ng-template>
      </div>
    </div>
  </section>

  <section *ngIf="(confirmationDialogIsActive$ | async) === false">
    <div class="address-view__dialog-actions">
      <div class="left-side">
        <div class="btn-left">
          <app-button
            *ngIf="data && data.buttonDelete"
            class="btn-left"
            type="warn"
            [label]="'generic.delete' | translate"
            [disabled]="!!(savingInProgress$ | async)"
            (click)="showConfirmationDialog()"></app-button>
        </div>
      </div>
      <div class="right-side">
        <claas-button
          *ngIf="data && data.buttonCancel"
          class="btn-left"
          [buttonClass]="secondaryTextButtonClass"
          [text]="'generic.cancel' | translate"
          [disabled]="!!(savingInProgress$ | async)"
          (onClick)="onCancelClicked()">
        </claas-button>

        <claas-button
          *ngIf="data && data.buttonSave"
          class="btn-right save-btn"
          [text]="'generic.save' | translate"
          [disabled]="!!(invalidSaveButtonState$ | async)"
          (onClick)="onSaveClicked()">
        </claas-button>
      </div>
    </div>
  </section>

  <section *ngIf="confirmationDialogIsActive$ | async">
    <div class="address-view__dialog-actions">
      <div class="left-side">
        <div class="btn-left"></div>
      </div>
      <div class="right-side">
        <claas-button
          class="btn-left"
          [buttonClass]="secondaryTextButtonClass"
          [text]="'generic.cancel' | translate"
          (onClick)="deletionIsCanceled()">
        </claas-button>

        <claas-button
          class="btn-left"
          [buttonClass]="errorButtonClass"
          [text]="'generic.delete' | translate"
          (onClick)="deletionIsConfirmed()">
        </claas-button>
      </div>
    </div>
  </section>
</div>
