import { createAction, props } from '@ngrx/store';
import { OrganisationDealerModel } from '../../models/organisation-dealer.model';
import {
  IDealerByNameRequest,
  INearestDealersRequest
} from 'cde-fe-organization-registration-dialog';
import { INearestDealersResponse } from 'cde-fe-organization-registration-dialog/lib/models/organisation.model';
import {
  IDealerData,
  IDealerFilter,
  IGetDealerRequest
} from 'cde-fe-organization-registration-dialog/lib/models/dealer.model';
import { StateError } from '../organisations/organisations.state';

export const loadOrganisationDefaultDealer = createAction(
  '[Dealers] Load Organisation Default Dealer',
  props<{ orgaId: string; dealerId: string }>()
);

export const organisationDefaultDealerLoaded = createAction(
  '[Dealers] Organisation Default Dealer Loaded',
  props<{ defaultDealer: OrganisationDealerModel }>()
);

export const loadNearestDealersSearchData = createAction(
  '[Dealers] Load Nearest Dealers Search Data',
  props<INearestDealersRequest>()
);

export const nearestDealersSearchDataLoaded = createAction(
  '[Dealers] Nearest Dealers Search Data loaded',
  props<{ nearestDealersSearchData: INearestDealersResponse | null }>()
);

export const nearestDealersSearchDataNotLoaded = createAction(
  '[Dealers] Nearest Dealers Search Data Not loaded',
  props<{
    nearestDealersSearchData: INearestDealersResponse;
    organisationError: StateError;
  }>()
);

export const loadDealerByNameSearchData = createAction(
  '[Dealers] Load Dealers By Name Search Data',
  props<IDealerByNameRequest>()
);

export const dealerByNameSearchDataLoaded = createAction(
  '[Dealers] Dealers By Name Search Data Loaded',
  props<{ dealerByNameSearchData: INearestDealersResponse }>()
);

export const dealerByNameSearchDataNotLoaded = createAction(
  '[Dealers] Dealers By Name Search Data Not Loaded',
  props<{ organisationError: StateError }>()
);

export const loadDealerFiltersData = createAction(
  '[Dealers] Load Dealer Filters Data'
);

export const dealerFiltersDataLoaded = createAction(
  '[Dealers] Dealer Filters Data Loaded',
  props<{ dealerFilters: IDealerFilter[] | null }>()
);

export const getDealer = createAction(
  '[Dealers] Get Dealer',
  props<{ request: IGetDealerRequest }>()
);

export const getDealerSuccess = createAction(
  '[Dealers] Get Dealer Success',
  props<{ dealer: IDealerData }>()
);

export const getDealerFailure = createAction(
  '[Dealers] Get Dealer Failure',
  props<{ dealerId: string; error: StateError }>()
);

export const getDealerComplete = createAction('[Dealers] Get Dealer Complete');
