import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IconStyle, Tab } from '@claas/claas-layout-components';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { isHandset, isTablet } from '../app.component';
import { AuthService } from '../shared/services/auth.service';
import { Store } from '@ngrx/store';
import { selectUserCoordinates } from '../shared/state/user-coordinates/user-coordinates.selectors';
import { loadUserCoordinates } from '../shared/state/user-coordinates/user-coordinates.actions';

declare const window: any;

@Component({
  templateUrl: 'privacy.component.html',
  styleUrls: ['privacy.component.scss']
})
export class PrivacyComponent implements OnInit, AfterViewInit {
  // translate strings
  public cookieSettings = 'CookieSettings';

  constructor(
    private translate: TranslateService,
    public changeDetector: ChangeDetectorRef,
    private route: ActivatedRoute,
    private authService: AuthService,
    private store: Store
  ) {
    this.tabs = [
      {
        title: 'Privacy',
        disabled: false,
        content: this.privacy
      },
      {
        title: 'Cookies',
        disabled: false,
        content: this.cookies
      }
    ];
  }

  @ViewChild('privacy') privacy!: TemplateRef<any>;
  @ViewChild('cookies') cookies!: TemplateRef<any>;

  tabs: any[];

  private browserSet = this.getBrowserSet();
  public country = '';
  public pdfSrc = '';

  ngOnInit(): void {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.triggerTranslations();
    });

    this.route.queryParams.subscribe(res => {
      if (res['country'] && res['country'].length > 0) {
        this.country = this.getCountryFromParameter(res['country']);
      } else {
        this.country = this.getCountry(this.browserSet);
      }
      this.pdfSrc = '/assets/legal/' + this.country + '/privacy.pdf';
    });
    this.changeDetector.detectChanges();
    this.updateLanguage();
  }

  ngAfterViewInit(): void {
    this.tabs = [
      {
        title: 'Privacy',
        disabled: false,
        content: this.privacy
      },
      {
        title: 'Cookies',
        disabled: false,
        content: this.cookies
      }
    ];
    this.triggerTranslations();
  }

  private triggerTranslations() {
    this.translate.get('cookies.settings').subscribe(data => {
      this.cookieSettings = data;
    });
    if (this.tabs.length > 0) {
      this.translate.get('footer.privacy').subscribe(data => {
        this.tabs.at(0)!.title = data;
      });
      this.translate.get('cookies.title').subscribe(data => {
        this.tabs.at(1)!.title = data;
      });
    }
  }

  // returns an array with browser language [0] and country [1]
  getBrowserSet() {
    const browserSetString = window.navigator.language;
    const browserSet = ['', '']; // array for language and country
    if (browserSetString.indexOf('-') !== -1) {
      browserSet[0] = browserSetString.split('-')[0].toLowerCase(); // language
      browserSet[1] = browserSetString.split('-')[1].toLowerCase(); // country
    }
    if (browserSetString.indexOf('_') !== -1) {
      browserSet[0] = browserSetString.split('_')[0].toLowerCase();
      browserSet[1] = browserSetString.split('_')[1].toLowerCase();
    }
    if (browserSetString.length <= 2) {
      browserSet[0] = browserSetString;
      browserSet[1] = browserSetString;
    }
    return browserSet;
  }

  getCountry(browserSet: any[]) {
    let country;
    // set country
    if (environment.general.countries.indexOf(browserSet[1]) >= 0) {
      country = browserSet[1];
    } else {
      country = 'de';
    }
    if(this.authService.loggedIn) {
      this.store.select(selectUserCoordinates).subscribe({
        next: (coordinates: any) => {
          if (!coordinates) {
            this.store.dispatch(loadUserCoordinates());
          } else {
            if (coordinates['country']) {
              country = coordinates['country'].toLowerCase();
            }
          }
        }
      });
    }
    return country;
  }

  getCountryFromParameter(country: string) {
    if (environment.general.countries.indexOf(country) >= 0) {
      return country;
    } else {
      return 'de';
    }
  }

  private updateLanguage() {
    if (window.UC_UI == null) {
      return;
    }
    const lang = this.translate.getBrowserLang();
    window.UC_UI.updateLanguage(lang);
    this.changeDetector.detectChanges();
  }

  get isHandset() {
    return isHandset;
  }

  get isTablet() {
    return isTablet;
  }
}
