import { Component, Input } from '@angular/core';

export type ButtonType = 'primary' | 'accent' | 'warn';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  @Input() type: ButtonType | undefined;
  @Input() label: string | undefined;
  @Input() disabled: boolean | undefined;
}
