import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {
  ButtonClass,
  ClaasFormComponentsModule,
  DropdownOptionObject
} from '@claas/claas-form-components';
import {
  ResponseDataFromDialog,
  passedDataToDialog
} from '../dialog-window/dialog-window.component';
import { BehaviorSubject, Subscription, combineLatestWith, filter } from 'rxjs';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ApiService } from '../../services/api.service';
import { User } from '@auth0/auth0-angular';
import { Store } from '@ngrx/store';
import { selectUserCoordinates } from '../../state/user-coordinates/user-coordinates.selectors';
import { MatStep, MatStepper } from '@angular/material/stepper';
import { MatDialogActions, MatDialogContent } from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AsyncPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatFormField } from '@angular/material/form-field';
import { MatOption } from '@angular/material/autocomplete';
import { MatSelect } from '@angular/material/select';
import { MarketingPermission } from '../../models/marketing-permission.model';
import { PhoneNumber } from '../../models/phone-number.model';
import { countriesArr, statesArr } from '../language-view/countries-source';
import { typesArr } from './types-source';
import { environment } from 'src/environments/environment';
import { Address } from '../../models/address.model';
import { MatDivider } from '@angular/material/divider';
import { AddressService } from '../../services/address.service';
import {
  CircularProgressDiameter,
  ClaasLayoutComponentsModule,
  ProgressColor
} from '@claas/claas-layout-components';
import { MarketingUpdatePayload } from '../../models/marketing-update-payload.model';
import { UserAddress } from '../../models/user-address.model';

@Component({
  selector: 'app-marketing-permission-update-view',
  standalone: true,
  imports: [
    MatStepper,
    MatStep,
    MatDialogContent,
    TranslateModule,
    NgForOf,
    FormsModule,
    MatCheckbox,
    NgIf,
    NgClass,
    MatFormField,
    MatOption,
    MatSelect,
    MatDialogActions,
    MatDivider,
    AsyncPipe,
    ClaasFormComponentsModule,
    ClaasLayoutComponentsModule
  ],
  templateUrl: './marketing-permission-update-view.component.html',
  styleUrl: './marketing-permission-update-view.component.scss'
})
export class MarketingPermissionUpdateViewComponent
  implements OnInit, OnDestroy
{
  @Input() secondaryTextButtonClass?: ButtonClass;
  @Input() data: passedDataToDialog = {};
  @Output() closeDialogWindow = new EventEmitter();
  @Output() displayPopup = new EventEmitter();
  country: string = '';
  profileEmail: string = '';
  newPhone: boolean = false;
  newAddress: boolean = false;
  user_id: string = '';
  enableNewPhone: boolean = true;
  phoneNumbers: PhoneNumber[] = [];
  addresses: Address[] = [];
  footnote$: BehaviorSubject<string> = new BehaviorSubject('');
  countriesArr = countriesArr;
  countries = environment.general.countries;
  states = statesArr;
  accepted: boolean = false;
  valid: boolean = false;
  dealerAddress: string = '';
  updateComplete$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  inputError: 'PHONE' | 'ADDRESS' | 'EMAIL' | null = null;

  countryPrefixOptions: string[] = [];
  defaultOptionCountryPrefix: string = '';

  countryOptions = [
    {
      text: 'label',
      key: 'key'
    }
  ];
  defaultOptionCountry = {
    text: 'label',
    key: 'key'
  };
  phoneTypeOptions = [
    {
      text: 'label',
      key: 'key'
    }
  ];
  defaultOptionPhoneType = {
    text: 'label',
    key: 'key'
  };

  showSpinner$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  nextButtonClass: ButtonClass = ButtonClass.PRIMARY_TEXT;
  addButtonClass: ButtonClass = ButtonClass.SECONDARY_OUTLINE;
  secondaryColor: ProgressColor = ProgressColor.SECONDARY;
  largeSize: CircularProgressDiameter = CircularProgressDiameter.LARGE;
  private subscriptions = new Subscription();

  constructor(
    private apiService: ApiService,
    private store: Store,
    public addressService: AddressService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    if (this.data.marketingInvitation) {
      for (const inv of this.data.marketingInvitation) {
        if (inv.type === 'dealer') {
          inv.dealer_name = this.getDealerAddress(inv.sap_reference_id);
        }
      }
    }

    this.getCountryAndEmail();
    this.getPhoneNumbers();

    //   PREPARE DROPDOWNS
    this.initCountryDropdown();
    this.initPhoneTypeDropdown();
    this.initCountryPrefixDropdown();
  }

  getCountryAndEmail() {
    this.subscriptions.add(
      this.store.select(selectUserCoordinates).subscribe({
        next: (coordinates: User | null) => {
          if (coordinates && coordinates['country']) {
            this.country = coordinates['country'].toLowerCase();
            this.getMarketingFootnote(this.country);
          }

          if (coordinates && coordinates['user_id']) {
            this.user_id = coordinates['user_id'];
          }

          if (coordinates && coordinates['email']) {
            this.profileEmail = coordinates['email'];
          }

          if (coordinates && coordinates['addresses']) {
            this.addresses = coordinates['addresses'];

            this.addresses.sort(function (a, b) {
              if (a.is_primary > b.is_primary) {
                return -1;
              }
              if (a.is_primary < b.is_primary) {
                return 1;
              }
              return 0;
            });
          } else {
            this.addresses = [];
            this.newAddress = true;
          }
        }
      })
    );
  }

  getPhoneNumbers() {
    this.subscriptions.add(
      this.apiService.listUserPhoneNumbers().subscribe((result: any) => {
        if (result) {
          if (result['phone_number_value']) {
            const number = new PhoneNumber();
            number.phone_number = result['phone_number_value'];
            number.type = 'phone';
            this.phoneNumbers.push(number);
          }

          if (result['mobile_number_value']) {
            const number = new PhoneNumber();
            number.phone_number = result['mobile_number_value'];
            number.type = 'mobile';
            this.phoneNumbers.push(number);
          }

          // Check if there are already two phone numbers (phone / mobile)
          if (
            this.phoneNumbers.find(number => number.type == 'mobile') &&
            this.phoneNumbers.find(number => number.type == 'phone')
          ) {
            this.enableNewPhone = false;
          }
        } else {
          this.phoneNumbers = [];
          this.newPhone = true;
        }
      })
    );
  }

  overwritePhonenumber(
    inv: MarketingPermission,
    number: PhoneNumber,
    event: MatCheckboxChange
  ) {
    if (event.checked) {
      inv.channels.phone_number.type = number.type;
      // @ts-ignore
      inv.channels.phone_number.number = number.phone_number;
      inv.channels.phone_number.country_prefix = this.getCountryPrefix(
        number.country_prefix!,
        'code'
      );
    } else {
      inv.channels.phone_number.type = '';
      inv.channels.phone_number.number = '';
      inv.channels.phone_number.country_prefix = '';
    }
  }

  getDealerAddress(sapReferenceId: string): string {
    this.subscriptions.add(
      this.apiService.getPreferredDealer(sapReferenceId).subscribe({
        next: (res: any) => {
          if (res) {
            this.dealerAddress = `${res.dealerName}, ${res.streetName} ${res.houseNumber}, ${res.postalCode} ${res.city}`;
          }
        },
        error: err => {
          console.log('ERROR: ', err);
          return '';
        }
      })
    );

    return '';
  }

  toggleNewPhone(inv: MarketingPermission) {
    this.newPhone = !this.newPhone;

    // Clear fields if new phone
    if (this.newPhone) {
      inv.channels.phone_number.country_prefix = '';
      inv.channels.phone_number.number = '';
      inv.channels.phone_number.type = '';
    }
  }

  toggleNewAddress(inv: MarketingPermission) {
    this.newAddress = !this.newAddress;

    // Clear fields if new address
    if (this.newAddress) {
      inv.channels.address.street = '';
      inv.channels.address.house_number = '';
      inv.channels.address.postal_code = '';
      inv.channels.address.city = '';
      inv.channels.address.state = '';
      inv.channels.address.suburb = '';
      inv.channels.address.country = '';
    }
  }

  // INPUT EVENTS FOR PHONE NUMBER
  onChangePhoneType(e: DropdownOptionObject, inv: MarketingPermission) {
    inv.channels.phone_number.type = e as unknown as string;
  }

  onChangePhonePrefix(e: string, inv: MarketingPermission) {
    inv.channels.phone_number.country_prefix = e as unknown as string;
  }

  onChangePhoneNumber(phoneNumber: string, inv: MarketingPermission) {
    inv.channels.phone_number.number = phoneNumber;
  }

  // INPUT EVENTS FOR ADDRESS
  onChangeAddressCountry(e: DropdownOptionObject, inv: MarketingPermission) {
    inv.channels.address.country = e as unknown as string;
  }

  onChangeStreet(street: string, inv: MarketingPermission) {
    inv.channels.address.street = street;
  }

  onChangeHouseNumber(houseNumber: string, inv: MarketingPermission) {
    inv.channels.address.house_number = houseNumber;
  }

  onChangePostalCode(postalCode: string, inv: MarketingPermission) {
    inv.channels.address.postal_code = postalCode;
  }

  onChangeCity(city: string, inv: MarketingPermission) {
    inv.channels.address.city = city;
  }

  onChangeSuburb(suburb: string, inv: MarketingPermission) {
    inv.channels.address.suburb = suburb;
  }

  onChangeState(state: string, inv: MarketingPermission) {
    inv.channels.address.state = state;
  }

  overwriteAddress(
    inv: MarketingPermission,
    address: Address,
    event: MatCheckboxChange
  ) {
    if (event.checked) {
      inv.channels.address.street = address.street;
      inv.channels.address.house_number = address.house_number;
      inv.channels.address.postal_code = address.postal_code;
      inv.channels.address.city = address.city;
      inv.channels.address.state = address.state;
      inv.channels.address.suburb = address.suburb;
      inv.channels.address.country = address.country;
    } else {
      inv.channels.address.street = '';
      inv.channels.address.house_number = '';
      inv.channels.address.postal_code = '';
      inv.channels.address.city = '';
      inv.channels.address.state = '';
      inv.channels.address.suburb = '';
      inv.channels.address.country = '';
    }
  }

  checkMarketingAddress(permission: any, marketingAddress: Address) {
    return (
      permission.address.city === marketingAddress.city &&
      permission.address.country === marketingAddress.country &&
      permission.address.house_number === marketingAddress.house_number &&
      permission.address.postal_code === marketingAddress.postal_code &&
      permission.address.street === marketingAddress.street
    );
  }

  getCountryPrefix(country_prefix: string, prop: string) {
    const c = this.countriesArr.find(function (element) {
      return element.code === country_prefix;
    });
    // @ts-ignore
    return c === undefined ? '' : c[prop];
  }

  getCountry(country: string) {
    const c = this.countriesArr.find(function (element) {
      return element.id === country;
    });

    if (c === undefined) {
      return '';
    } else {
      let r = '';
      this.translate.get(c.label).subscribe((text: string) => (r = text));
      return r;
    }
  }

  checkMarketingPhoneNumber(
    permission: any,
    marketingPhoneNumber: PhoneNumber
  ) {
    return (
      permission.phone_number.type === marketingPhoneNumber.type &&
      permission.phone_number.number === marketingPhoneNumber.phone_number &&
      permission.phone_number.country_prefix ===
        this.getCountryPrefix(marketingPhoneNumber.country_prefix!, 'code')
    );
  }

  getType(type: string) {
    const c = typesArr.find(function (element) {
      return element.id === type;
    });
    // @ts-ignore
    if (c === undefined) {
      return '';
    } else {
      let r = '';
      this.translate.get(c.label).subscribe((text: string) => (r = text));
      return r;
    }
  }

  getMarketingFootnote(country: string) {
    // @ts-ignore
    this.footnote$.next(environment.marketing_footnotes[country]);
  }

  checkMarketingInfos(inv: MarketingPermission) {
    let valid = true;

    if (inv.channels.phone_number.country_prefix == '') {
      inv.channels.phone_number.country_prefix =
        this.defaultOptionCountryPrefix;
    }

    this.valid = valid;
    if (!this.valid) {
      this.showSpinner$.next(false);
    }
  }

  setMarketingInfos(accepted: boolean) {
    if (accepted && this.data.marketingInvitation) {
      for (const inv of this.data.marketingInvitation) {
        this.checkMarketingInfos(inv);

        if (this.valid) {
          // Send HTTPS Request to Backend if an invitation needs to be accepted
          let payload: MarketingUpdatePayload = new MarketingUpdatePayload();
          payload = {
            email: inv.channels.email,
            email_string: '',
            phone: inv.channels.phone,
            phone_number: {
              type: '',
              number: '',
              country_prefix: ''
            },
            push: inv.channels.push,
            post: inv.channels.post,
            address: {
              street: '',
              house_number: '',
              postal_code: '',
              city: '',
              state: '',
              suburb: '',
              country: ''
            }
          };

          payload.email_string = inv.channels.email ? this.profileEmail : '';

          // Accept marketing invitations with selected channels
          this.subscriptions.add(
            this.apiService
              .acceptMarketingInvitation(
                this.user_id,
                inv.invitation_uuid,
                payload
              )
              .subscribe({
                next: () => {
                  this.updateComplete$.next(true);
                  this.showSpinner$.next(false);
                },
                error: () => {
                  this.showSpinner$.next(false);
                }
              })
          );

        }
      }
      this.accepted = accepted;
    }
  }

  next(inv: any, stepper: MatStepper, accept: boolean) {
    this.showSpinner$.next(true);
    for (const invitation of inv) {
      this.checkMarketingInfos(invitation);
    }
    if (this.valid) {
      this.setMarketingInfos(accept);

      this.showSpinner$
        .pipe(
          combineLatestWith(this.updateComplete$),
          filter(([spinner, complete]) => !spinner && complete)
        )
        .subscribe(() => {
          stepper.next();
        });
    }
  }

  close() {
    const passedObject: ResponseDataFromDialog = {
      action: 'save'
    };

    this.closeDialogWindow.emit(passedObject);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private initCountryDropdown() {
    this.defaultOptionCountry = {
      text: this.getCountry(this.country),
      key: this.country
    };
    this.countryOptions.pop();

    for (const c of this.countriesArr) {
      // @ts-ignore
      const o = { text: this.getCountry(c.id), key: c.id };
      // @ts-ignore
      this.countryOptions.push(o);
    }
  }

  private initPhoneTypeDropdown() {
    this.defaultOptionPhoneType = {
      text: this.getType('mobile'),
      key: 'mobile'
    };
    this.phoneTypeOptions.pop();
    for (const c of typesArr) {
      // @ts-ignore
      const o = { text: this.getType(c.id, 'label'), key: c.id };
      // @ts-ignore
      this.phoneTypeOptions.push(o);
    }
  }

  private initCountryPrefixDropdown() {
    for (const c of countriesArr) {
      this.countryPrefixOptions.push(c.code);
    }

    const prefix = countriesArr.find(c => c.id === this.country);

    if (prefix) {
      this.defaultOptionCountryPrefix = prefix.code;
    } else {
      this.defaultOptionCountryPrefix = 'de';
    }
  }
}
