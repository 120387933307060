<div class="two-factor__wrapper">
  <p class="two-factor__title subtitle-2">
    {{ 'account_settings.mfa' | translate }}
  </p>

  <p
    [innerHTML]="'account_settings.mfa_info' | translate"
    class="two-factor__info-text"></p>

  <div class="dialog-actions">
    <div class="left-side">
      <div class="btn-left"></div>
    </div>
    <div class="right-side">
      <claas-button
        (onClick)="onSaveClicked()"
        *ngIf="data?.buttonSave"
        [text]="'generic.confirm' | translate"
        class="btn-right">
      </claas-button>
    </div>
  </div>
</div>
