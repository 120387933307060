import { createFeatureSelector, createSelector } from '@ngrx/store';

import { FeatureToggleState } from './feature-toggle.state';

export const featureToggleFeatureStateSelector =
  createFeatureSelector<FeatureToggleState>('featureToggle');

export const getFeatureToggles = createSelector(
  featureToggleFeatureStateSelector,
  state => state.featureToggles
);
