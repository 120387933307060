import { Component, Input } from '@angular/core';

export type UserStatus = 'outdated' | 'accepted' | 'pending';

@Component({
  selector: 'app-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss']
})
export class ChipComponent {
  @Input() label: string | undefined;
  @Input() type: UserStatus | undefined;
}
