<div class="phonenumber-view">
  <div class="phonenumber-view__header">
    <div
      *ngIf="(confirmationDialogIsActive$ | async) === false"
      class="phonenumber-view__header-main">
      <h2
        *ngIf="data?.subContext === 'add'"
        class="phonenumber-view__header-title subtitle-2">
        {{ 'personal_data.phonenumber.add_header' | translate }}
      </h2>
      <h2
        *ngIf="data?.subContext === 'edit'"
        class="phonenumber-view__header-title subtitle-2">
        {{ 'personal_data.phonenumber.edit_header' | translate }}
      </h2>
      <claas-circular-progress
        *ngIf="isSaving"
        class="spinner"
        [color]="color"
        [diameter]="size"
      ></claas-circular-progress>
    </div>

    <div
      *ngIf="confirmationDialogIsActive$ | async"
      class="phonenumber-view__header-confirmation-dialog">
      <div class="phonenumber-view__header-confirmation-dialog--header">
        <h2 class="subtitle-2">
          {{ 'personal_data.phonenumber.confirmation_header' | translate }}
        </h2>
      </div>
      <span class="dialog-window__confirmation-dialog--text">{{
        'personal_data.phonenumber.confirmation_text' | translate
      }}</span>
    </div>

  </div>

  <section *ngIf="(confirmationDialogIsActive$ | async) === false">
    <div class="phonenumber-view__body">
      <div class="phonenumber-view__body-content">
        <div class="phonenumber-view__body-content--line">
          <ng-container>
            <form [formGroup]="myForm$ | async">
              <claas-dropdown-key-value
                ngDefaultControl
                formControlName="type"
                [label]="('personal_data.phonenumber.types.label' | translate) + '*'"
                [options]="typeOptions"></claas-dropdown-key-value>
            </form>
          </ng-container>

          <app-international-phone
            [preferredCountries]="preferredCountries"
            [hintMessage]="'personal_data.phonenumber.invalid_data' | translate"
            [isAddingMode]="data.subContext === 'add'"
            [isEditMode]="data.subContext === 'edit'"
            [currentPhoneIndex]="currentPhoneIndex"
            [phoneNumbers]="phoneNumbers"
            [onlyCountries]="countriesIds"
            (invalidStateEmitter)="updateNumberInvalidState($event)"
            (currentPhonenumber)="updatePhonenumber($event)">
          </app-international-phone>
        </div>
      </div>
    </div>

    <div class="phonenumber-view__dialog-actions">
      <div class="left-side">
        <div class="btn-left">
          <app-button
            *ngIf="data?.buttonDelete"
            class="btn-left"
            type="warn"
            [label]="'generic.delete' | translate"
            [disabled]="!!(actionInProgress$ | async)"
            (click)="showConfirmationDialog()"></app-button>
        </div>
      </div>
      <div class="right-side">
        <claas-button
          *ngIf="data?.buttonCancel"
          class="btn-left"
          [buttonClass]="secondaryTextButtonClass"
          [text]="'generic.cancel' | translate"
          [disabled]="!!(actionInProgress$ | async)"
          (onClick)="onCancelClicked()">
        </claas-button>

        <claas-button
          *ngIf="data?.buttonSave"
          class="btn-right save-btn"
          [text]="'generic.save' | translate"
          [disabled]="!!(invalidSaveButtonState$ | async)"
          (onClick)="onSaveClicked()">
        </claas-button>
      </div>
    </div>
  </section>

  <section *ngIf="confirmationDialogIsActive$ | async">
    <div class="phonenumber-view__dialog-actions">
      <div class="left-side">
        <div class="btn-left"></div>
      </div>
      <div class="right-side">
        <claas-button
          class="btn-left"
          [buttonClass]="secondaryTextButtonClass"
          [text]="'generic.cancel' | translate"
          (onClick)="deletionIsCanceled()">
        </claas-button>

        <claas-button
          class="btn-left"
          [buttonClass]="errorButtonClass"
          [text]="'generic.delete' | translate"
          (onClick)="deletionIsConfirmed()">
        </claas-button>
      </div>
    </div>
  </section>
</div>
