import { ApiService } from './../../services/api.service';
import { Component, Input, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-dealer-address-footnote',
  templateUrl: './dealer-address.component.html',
  styleUrls: ['./dealer-address.component.scss']
})
export class DealerAddressComponent implements OnInit {
  @Input() dealerId: string = '';

  dealerAddress = '';

  constructor(private apiService: ApiService) {}

  ngOnInit() {
    this.getAddress();
  }

  getAddress() {
    this.getDealer().subscribe(dealer => {
      if (dealer) {
        const dealerName = dealer['dealerName'];
        const street = dealer['streetName'] || '';
        const houseNumber = dealer['houseNumber'] || '';
        const city = dealer['city'] || '';
        const postalCode = dealer['postalCode'] || '';
        this.dealerAddress =
          dealerName +
          ', ' +
          street +
          ' ' +
          houseNumber +
          ', ' +
          postalCode +
          ' ' +
          city;
      }
    });
  }

  getDealer(): Observable<any> {
    return this.apiService.getPreferredDealer(this.dealerId).pipe(
      catchError(err => {
        return of(null);
      })
    );
  }
}
