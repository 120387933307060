<div class="organisation-data-overview">
  <div *ngIf="organisationWithTranslations">
    <div class="line-container">
      <div class="content-line">
        <div class="data">
          <span class="label label-1-style">{{
            'registration.formfield.name' | translate
          }}</span>
          <span class="value">{{ organisationWithTranslations['name'] }}</span>
        </div>

        <div class="action">
          <claas-icon-button
            *ngIf="isAdmin"
            class="edit-btn"
            [icon]="editIcon"
            [iconSize]="mediumIconSize"
            [fontSize]="mediumIconFontSize"
            (click)="openDialog('organisation-name')"></claas-icon-button>
        </div>
      </div>

      <claas-divider
        [dividerOrientation]="horizontalDivider"
        [inset]="false"></claas-divider>
    </div>

    <div class="line-container">
      <div class="content-line">
        <div class="data">
          <span class="label label-1-style">{{
            'registration.formfield.type' | translate
          }}</span>
          <span class="value">{{ 'registration.formfield.select.'+organisationWithTranslations['type'] | translate }}</span>
        </div>

        <div class="action">
          <claas-icon-button
            *ngIf="isAdmin"
            class="edit-btn"
            [icon]="editIcon"
            [iconSize]="mediumIconSize"
            [fontSize]="mediumIconFontSize"
            (click)="openDialog('organisation-type')"></claas-icon-button>
        </div>
      </div>

      <claas-divider
        [dividerOrientation]="horizontalDivider"
        [inset]="false"></claas-divider>
    </div>

    <div class="line-container">
      <div class="content-line">
        <div class="data">
          <span class="label label-1-style">{{
            'organization.fields.customer_number' | translate
          }}</span>
          <span class="value">{{ organisationWithTranslations['debitorId'] }}</span>
        </div>
      </div>

      <claas-divider
        [dividerOrientation]="horizontalDivider"
        [inset]="false"></claas-divider>
    </div>

    <div class="line-container">
      <div class="content-line">
        <div class="data">
          <span class="label label-1-style">{{
            'registration.formfield.country' | translate
          }}</span>
          <span class="value">{{ organisationCountry }}</span>
        </div>
      </div>

      <claas-divider
        [dividerOrientation]="horizontalDivider"
        [inset]="false"></claas-divider>
    </div>

    <div class="line-container">
      <div class="content-line">
        <div class="data">
          <span class="label label-1-style">{{
            'registration.formfield.timeZone' | translate
          }}</span>
          <span class="value">{{ organisationWithTranslations['timeZone'] }}</span>
        </div>

        <div class="action">
          <claas-icon-button
            *ngIf="isAdmin"
            class="edit-btn"
            [icon]="editIcon"
            [iconSize]="mediumIconSize"
            [fontSize]="mediumIconFontSize"
            (click)="openDialog('organisation-timezone')"></claas-icon-button>
        </div>
      </div>

      <claas-divider
        [dividerOrientation]="horizontalDivider"
        [inset]="false"></claas-divider>
    </div>

    <div class="line-container">
      <div class="content-line">
        <div class="data">
          <span class="label label-1-style">{{
            'registration.formfield.unitSystem' | translate
          }}</span>
          <span class="value">{{
            'registration.formfield.select.' + organisationWithTranslations['unitSystem'] | translate
          }}</span>
        </div>

        <div class="action">
          <claas-icon-button
            *ngIf="isAdmin"
            class="edit-btn"
            [icon]="editIcon"
            [iconSize]="mediumIconSize"
            [fontSize]="mediumIconFontSize"
            (click)="openDialog('organisation-unitsystem')"></claas-icon-button>
        </div>
      </div>

      <claas-divider
        [dividerOrientation]="horizontalDivider"
        [inset]="false"></claas-divider>
    </div>

    <div class="line-container">
      <div class="content-line">
        <div
          [ngClass]="
            organisationWithTranslations['phoneNumber']?.phoneNumber
              ? 'data'
              : 'data add-phonenumber'
          ">
          <span class="label label-1-style">{{
            'registration.formfield.phoneNumber' | translate
          }}</span>

          <span
            class="value"
            *ngIf="
              organisationWithTranslations['phoneNumber']?.phoneNumber;
              else addOrgaPhonenumber
            "
            >{{
              organisationWithTranslations['phoneNumber']?.phoneNumber
                ? organisationWithTranslations['phoneNumber']?.phoneNumber
                : ''
            }}</span
          >
          <ng-template #addOrgaPhonenumber>
            <div class="action">
              <claas-button
                *ngIf="isAdmin"
                class="add-btn phonenumber"
                [icon]="addIcon"
                [text]="'personal_data.phonenumber.add_header' | translate"
                [buttonClass]="primaryTextButtonClass"
                [size]="mediumButtonSize"
                (onClick)="
                  openDialog('organisation-phonenumber', 'add')
                "></claas-button>
            </div>
          </ng-template>
        </div>

        <div class="action">
          <claas-icon-button
            *ngIf="isAdmin && organisationWithTranslations['phoneNumber']?.phoneNumber"
            class="edit-btn"
            [icon]="editIcon"
            [iconSize]="mediumIconSize"
            [fontSize]="mediumIconFontSize"
            (click)="
              openDialog('organisation-phonenumber', 'edit')
            "></claas-icon-button>
        </div>
      </div>

      <claas-divider
        [dividerOrientation]="horizontalDivider"
        [inset]="false"></claas-divider>
    </div>

    <div class="line-container">
      <div class="content-line">
        <div class="data address">
          <span class="label label-1-style">{{
            'registration.formfield.select.INVOICE' | translate
          }}</span>
          <span class="value">{{
            organisationWithTranslations['addresses'] | address: 'INVOICE'
          }}</span>
        </div>

        <div class="action">
          <claas-icon-button
            *ngIf="isAdmin"
            class="edit-btn"
            [icon]="editIcon"
            [iconSize]="mediumIconSize"
            [fontSize]="mediumIconFontSize"
            (click)="
              openDialog('organisation-address', 'edit', invoiceValue)
            "></claas-icon-button>
        </div>
      </div>

      <claas-divider
        [dividerOrientation]="horizontalDivider"
        [inset]="false"></claas-divider>
    </div>

    <div class="line-container">
      <div class="content-line">
        <div class="data address">
          <span class="label label-1-style">{{
            'registration.formfield.select.DELIVERY' | translate
          }}</span>
          <span class="value">{{
            organisationWithTranslations['addresses'] | address: 'DELIVERY'
          }}</span>
        </div>

        <div class="action">
          <claas-icon-button
            *ngIf="isAdmin"
            class="edit-btn"
            [icon]="editIcon"
            [iconSize]="mediumIconSize"
            [fontSize]="mediumIconFontSize"
            (click)="
              openDialog('organisation-address', 'edit', deliveryValue)
            "></claas-icon-button>
        </div>
      </div>

      <claas-divider
        [dividerOrientation]="horizontalDivider"
        [inset]="false"></claas-divider>
    </div>

    <div class="line-container btn-container">
      <app-button
        *ngIf="isAdmin"
        type="warn"
        [label]="'organization.delete_organization.label' | translate"
        (click)="showConfirmationDialog('organisation-deletion')"></app-button>
    </div>
  </div>
</div>
