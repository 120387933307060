import * as i0 from '@angular/core';
import { Injectable, Component, ChangeDetectionStrategy, ViewEncapsulation, Input, NgModule } from '@angular/core';
import { BehaviorSubject, retry, Subscription, skipWhile } from 'rxjs';
import * as i1 from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import * as i1$1 from '@angular/material/icon';
import { MatIconModule } from '@angular/material/icon';
import * as i2 from '@angular/platform-browser';
import * as i4 from '@angular/common';
import { CommonModule } from '@angular/common';
const _c0 = a0 => ({
  "defalutColor": a0
});
const _c1 = (a0, a1) => ({
  "width.px": a0,
  "height.px": a1
});
function IconComponent_mat_icon_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "mat-icon", 1);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(5, _c0, !ctx_r0.removeDefaultColor))("ngStyle", i0.ɵɵpureFunction2(7, _c1, ctx_r0.size, ctx_r0.size))("id", ctx_r0.id)("svgIcon", ctx_r0.svgIcon);
    i0.ɵɵattribute("aria-label", ctx_r0.alt);
  }
}
class ClaasIconElementsComponentService {
  http;
  $iconList = new BehaviorSubject(undefined);
  endpointIsCalled = false;
  constructor(http) {
    this.http = http;
  }
  callEndpoint() {
    this.http.get("https://cdn.claas.com/icons/index.json").pipe(retry({
      delay: 1000,
      count: 3
    })).subscribe(result => this.$iconList.next(result));
  }
  getList() {
    if (!this.endpointIsCalled) {
      this.endpointIsCalled = true;
      this.callEndpoint();
    }
    return this.$iconList;
  }
  static ɵfac = function ClaasIconElementsComponentService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ClaasIconElementsComponentService)(i0.ɵɵinject(i1.HttpClient));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: ClaasIconElementsComponentService,
    factory: ClaasIconElementsComponentService.ɵfac,
    providedIn: "root"
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ClaasIconElementsComponentService, [{
    type: Injectable,
    args: [{
      providedIn: "root"
    }]
  }], () => [{
    type: i1.HttpClient
  }], null);
})();
class ClaasIconElementsComponentComponent {
  constructor() {}
  ngOnInit() {}
  static ɵfac = function ClaasIconElementsComponentComponent_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ClaasIconElementsComponentComponent)();
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: ClaasIconElementsComponentComponent,
    selectors: [["lib-claas-icon-elements-component"]],
    decls: 2,
    vars: 0,
    template: function ClaasIconElementsComponentComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵelementStart(0, "p");
        i0.ɵɵtext(1, " claas-icon-elements-component works! ");
        i0.ɵɵelementEnd();
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ClaasIconElementsComponentComponent, [{
    type: Component,
    args: [{
      selector: 'lib-claas-icon-elements-component',
      template: `
    <p>
      claas-icon-elements-component works!
    </p>
  `
    }]
  }], () => [], null);
})();
class IconComponent {
  iconRegistry;
  sanitizer;
  iconService;
  cdRef;
  id = "";
  iconId = "";
  namespace;
  iconName = "";
  iconStyle = "bold";
  size;
  alt;
  removeDefaultColor = false;
  isLoaded = false;
  icon;
  svgIcon = "";
  subscriptions = new Subscription();
  constructor(iconRegistry, sanitizer, iconService, cdRef) {
    this.iconRegistry = iconRegistry;
    this.sanitizer = sanitizer;
    this.iconService = iconService;
    this.cdRef = cdRef;
  }
  ngOnInit() {
    this.svgIcon = !!this.iconId ? `${this.namespace}:${this.iconId}` : `${this.namespace}:${this.iconName}`;
    const observeIcon = !!this.iconId ? this.iconRegistry.getNamedSvgIcon(this.iconId, this.namespace) : this.iconRegistry.getNamedSvgIcon(this.iconName, this.namespace);
    this.subscriptions.add(observeIcon.subscribe({
      next: icon => {
        if (icon) {
          this.isLoaded = true;
          this.cdRef.detectChanges();
        } else {
          this.getIcon();
        }
      },
      error: () => this.getIcon()
    }));
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
  getIcon() {
    if (!!this.iconId) {
      this.extractIcon(`${this.iconId}.svg`, this.iconId);
    } else {
      this.subscriptions.add(this.iconService.getList().pipe(skipWhile(list => list === undefined)).subscribe(result => {
        if (result) {
          const style = this.iconStyle === "bold" ? "linear" : "bold";
          const icon = result[this.iconStyle][this.iconName] ?? result[style][this.iconName];
          this.extractIcon(icon, this.iconName);
        }
      }));
    }
  }
  extractIcon(icon, name) {
    this.iconRegistry.addSvgIconInNamespace(this.namespace, name, this.sanitizer.bypassSecurityTrustResourceUrl(`https://cdn.claas.com/icons/${icon}`));
    this.isLoaded = true;
    this.cdRef.detectChanges();
  }
  static ɵfac = function IconComponent_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || IconComponent)(i0.ɵɵdirectiveInject(i1$1.MatIconRegistry), i0.ɵɵdirectiveInject(i2.DomSanitizer), i0.ɵɵdirectiveInject(ClaasIconElementsComponentService), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: IconComponent,
    selectors: [["lib-icon"]],
    hostVars: 2,
    hostBindings: function IconComponent_HostBindings(rf, ctx) {
      if (rf & 2) {
        i0.ɵɵclassMap("inline-flex");
      }
    },
    inputs: {
      id: "id",
      iconId: "iconId",
      namespace: "namespace",
      iconName: "iconName",
      iconStyle: "iconStyle",
      size: "size",
      alt: "alt",
      removeDefaultColor: "removeDefaultColor"
    },
    decls: 1,
    vars: 1,
    consts: [["class", "iconCenter", 3, "ngClass", "ngStyle", "id", "svgIcon", 4, "ngIf"], [1, "iconCenter", 3, "ngClass", "ngStyle", "id", "svgIcon"]],
    template: function IconComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵtemplate(0, IconComponent_mat_icon_0_Template, 1, 10, "mat-icon", 0);
      }
      if (rf & 2) {
        i0.ɵɵproperty("ngIf", ctx.isLoaded);
      }
    },
    dependencies: [i4.NgClass, i4.NgIf, i4.NgStyle, i1$1.MatIcon],
    styles: ["mat-icon.iconCenter{display:flex;align-items:center;justify-content:center;margin:0 auto}mat-icon.defalutColor{color:#000000a3}\n"],
    encapsulation: 2,
    changeDetection: 0
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IconComponent, [{
    type: Component,
    args: [{
      selector: "lib-icon",
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        "[class]": '"inline-flex"'
      },
      encapsulation: ViewEncapsulation.None,
      template: "<mat-icon\n  class=\"iconCenter\"\n  [ngClass]=\"{'defalutColor': !removeDefaultColor}\"\n  *ngIf=\"isLoaded\"\n  [ngStyle]=\"{ 'width.px': size, 'height.px': size }\"\n  [attr.aria-label]=\"alt\"\n  [id]=\"id\"\n  [svgIcon]=\"svgIcon\"\n></mat-icon>\n",
      styles: ["mat-icon.iconCenter{display:flex;align-items:center;justify-content:center;margin:0 auto}mat-icon.defalutColor{color:#000000a3}\n"]
    }]
  }], () => [{
    type: i1$1.MatIconRegistry
  }, {
    type: i2.DomSanitizer
  }, {
    type: ClaasIconElementsComponentService
  }, {
    type: i0.ChangeDetectorRef
  }], {
    id: [{
      type: Input
    }],
    iconId: [{
      type: Input
    }],
    namespace: [{
      type: Input
    }],
    iconName: [{
      type: Input
    }],
    iconStyle: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    alt: [{
      type: Input
    }],
    removeDefaultColor: [{
      type: Input
    }]
  });
})();
class ClaasIconElementsComponentModule {
  static ɵfac = function ClaasIconElementsComponentModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ClaasIconElementsComponentModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: ClaasIconElementsComponentModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [HttpClientModule, CommonModule, MatIconModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ClaasIconElementsComponentModule, [{
    type: NgModule,
    args: [{
      declarations: [ClaasIconElementsComponentComponent, IconComponent],
      imports: [HttpClientModule, CommonModule, MatIconModule],
      exports: [ClaasIconElementsComponentComponent, IconComponent]
    }]
  }], null, null);
})();

/*
 * Public API Surface of claas-icon-elements-component
 */

/**
 * Generated bundle index. Do not edit.
 */

export { ClaasIconElementsComponentComponent, ClaasIconElementsComponentModule, ClaasIconElementsComponentService, IconComponent };
