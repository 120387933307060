import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {
  ButtonClass,
  ButtonSize,
  DropdownOptionObject,
  Icon
} from '@claas/claas-form-components';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  CircularProgressDiameter,
  ProgressColor
} from '@claas/claas-layout-components';
import { MatDialog } from '@angular/material/dialog';
import {
  DialogWindowComponent,
  ResponseDataFromDialog,
  passedDataToDialog
} from '../shared/components/dialog-window/dialog-window.component';
import { environment } from '../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-organisation-data-users',
  templateUrl: './organisation-data-users.component.html',
  styleUrls: ['./organisation-data-users.component.scss']
})
export class OrganisationDataUsersComponent implements OnInit, OnDestroy {
  @Input() organisationUsers: any;
  @Input() isAdmin = false;
  @Input() isUniqueAdmin = false;
  @Input() roleOptions: any = [];
  @Input() requestInProgress: any = false;
  @Input() myUserId: string | undefined;
  @Input() startInvite: undefined | boolean;

  @Output() invitationIsConsumedOutput = new EventEmitter<any>();
  @Output() selectNewRoleOutput = new EventEmitter<any>();
  @Output() leaveOrganisationOutput = new EventEmitter<any>();
  @Output() RemoveUserFromOrganisationOutput = new EventEmitter<any>();
  @Output() inviteUserToOrganisationOutput = new EventEmitter<any>();

  private subscriptions = new Subscription();

  largeSize = CircularProgressDiameter.LARGE;
  primaryButtonClass = ButtonClass.PRIMARY;
  mediumButtonSize = ButtonSize.DEFAULT_MEDIUM;
  secondaryTextButtonClass = ButtonClass.SECONDARY_TEXT;
  secondaryColor = ProgressColor.SECONDARY;
  extendIcon: Icon = {
    iconId: '659469',
    iconStyle: 'bold',
    namespace: 'claas-id',
    size: 21
  };
  addIcon: Icon = {
    iconId: '000973',
    iconStyle: 'bold',
    namespace: 'claas-id',
    size: 19
  };
  deleteIcon: Icon = {
    iconId: '648544',
    iconStyle: 'bold',
    namespace: 'claas-id'
  };
  invitationIcon: Icon = {
    iconId: '006218',
    iconStyle: 'bold',
    namespace: 'claas-id'
  };
  leaveIcon: Icon = {
    iconId: '001308',
    iconStyle: 'bold',
    namespace: 'claas-id'
  };
  selectedElement: string | undefined;
  selectedUserEmail = '';
  previousInvitationEmail: string | undefined;
  selectedUserRole: string | undefined;
  isSmallScreen = false;
  isHandset = false;
  targetIsMember: boolean | undefined;
  displayedColumns: string[] = ['username', 'role', 'status', 'actions'];

  constructor(
    private breakpointObserver: BreakpointObserver,
    public dialog: MatDialog,
    private translateService: TranslateService
  ) {
    this.subscriptions.add(this.isSmallScreen$().subscribe());
    this.subscriptions.add(this.isHandset$().subscribe());
  }
  ngOnInit() {
    if (this.startInvite === true) {
      this.inviteUserToOrganisation();
      this.invitationIsConsumedOutput.emit();
    }
  }

  isHandset$(): Observable<any> {
    return this.breakpointObserver
      .observe(Breakpoints.Handset)
      .pipe(map((state: any) => (this.isHandset = state.matches)));
  }

  isSmallScreen$(): Observable<any> {
    return this.breakpointObserver
      .observe([Breakpoints.TabletPortrait, Breakpoints.Handset])
      .pipe(map((state: any) => (this.isSmallScreen = state.matches)));
  }

  selectRole(selectedRole: DropdownOptionObject, idpUser: string): void {
    this.selectNewRoleOutput.emit({
      role: selectedRole as unknown as string,
      idpUser
    });
  }

  getDefaultRoleOption(role: string) {
    return { text: this.getRole(role), key: role };
  }

  getRole(r: string) {
    var label = '';
    this.translateService
      .get('organization.roles.' + r.toLowerCase())
      .subscribe((data: string) => (label = data));
    return label;
  }

  removeUserFromOrganisation(targetId: string, isMember: boolean): void {
    this.selectedElement = targetId;
    this.targetIsMember = isMember;

    this.openDialog('organisation-remove-user');
  }

  inviteUserToOrganisation(email?: string, role?: string): void {
    this.selectedUserRole = role;

    if (email) {
      this.previousInvitationEmail = email;
    }

    this.openDialog('organisation-invitation');
  }

  leaveOrganisation(userId: string): void {
    this.selectedElement = userId;
    this.openDialog('organisation-leave');
  }

  openDialog(subContext: string): void {
    const data: passedDataToDialog = {
      context: 'organisation-users',
      subContext,
      buttonCancel: 'Cancel',
      buttonSave: 'Save'
    };

    if (subContext === 'organisation-invitation') {
      data.profileEmail = this.selectedUserEmail;
      data.profileRole = this.selectedUserRole;
      data.roleOptions = this.roleOptions;
      data.initialEmail = this.previousInvitationEmail;
    }

    if (subContext === 'organisation-leave') {
      data.isUniqueAdmin = this.isUniqueAdmin;
    }

    const dialogRef = this.dialog.open(DialogWindowComponent, {
      maxWidth: this.isHandset ? '100vw' : '80vw',
      data
    });

    dialogRef
      .afterClosed()
      .pipe(
        map((answer: ResponseDataFromDialog) => {
          if (answer) {
            switch (answer.action) {
              case 'organisation-leave':
                this.leaveOrganisationOutput.emit(this.selectedElement);
                break;

              case 'organisation-invitation':
                if (answer?.targetEmails) {
                  const emails = answer?.targetEmails;
                  const role = answer?.role;

                  for (const email of emails) {
                    this.inviteUserToOrganisationOutput.emit({
                      email,
                      role
                    });
                  }
                }
                break;

              case 'organisation-remove-user':
                this.RemoveUserFromOrganisationOutput.emit({
                  target: this.selectedElement,
                  targetIsMember: this.targetIsMember
                });
                break;
            }
          }
        })
      )
      .subscribe();
  }

  redirectToInfo(): void {
    if (environment.info_url) {
      var lang = 'en';
      lang = this.translateService.currentLang;
      if(lang == 'en-gb' || lang == 'en-us') {
        lang = 'en';
      }
      window.open(
        environment.info_url +
          'page/'+lang+'/web/id/claas-id#claas-id-collection-organisation-title',
        '_blank'
      );
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
