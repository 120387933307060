import { IUserMetadata, User } from './auth.model';

export class OrganisationRegistrationDialogUserModel implements User {
  sub: string;
  email: string;
  given_name: string;
  family_name: string;
  name: string;
  first_name: string;
  last_name: string;
  uuid: string;
  custom_image: string;
  user_metadata: IUserMetadata;
  app_metadata: {
    [key: string]: any;
  };
  fullRegistrationFormCompleted: boolean;
  isFullyRegistered: boolean;
  serialize: () => string;
  [key: string]: any;

  constructor() {
    this.sub = '';
    this.email = '';
    this.given_name = '';
    this.family_name = '';
    this.name = '';
    this.first_name = '';
    this.last_name = '';
    this.uuid = '';
    this.custom_image = '';
    this.user_metadata = {
      family_name: '',
      given_name: '',
      country: '',
      language: '',
      addresses: []
    };
    this.app_metadata = {
      status: undefined
    };

    this.fullRegistrationFormCompleted = false;
    this.isFullyRegistered = false;
    this.serialize = () => '';
  }
}
