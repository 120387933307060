import { createAction, props } from '@ngrx/store';
import { StateError } from '../index';
import { IFeatureToggle } from '../../models/feature-toggle.model';

export const getFeatureToggles = createAction(
  '[Feature Toggle] Get Feature Toggle'
);

export const getFeatureTogglesSuccess = createAction(
  '[Feature Toggle] Get Feature Toggle Success',
  props<{ featureToggles: IFeatureToggle[] }>()
);

export const getFeatureTogglesFailure = createAction(
  '[Feature Toggle] Get Feature Toggle Failure',
  props<{ error: StateError }>()
);

export const getFeatureTogglesComplete = createAction(
  '[Feature Toggle] Get Feature Toggle Complete'
);
