import { Injectable } from '@angular/core';
import { IMarketingPermission } from 'cde-fe-organization-registration-dialog/lib/models/marketing-permission.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MarketingPermissionService {
  private marketingBaseUrl = `${environment.base_urls.api_base_url}/v2/marketing-permission`;
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private http: HttpClient) {}

  public getClaasMarketingPermission() {
    return this.http.get<IMarketingPermission>(
      encodeURI(`${this.marketingBaseUrl}/claas`),
      this.httpOptions
    );
  }

  public getDealerMarketingPermission(dealerId: string, language: string) {
    return this.http.get<IMarketingPermission>(
      encodeURI(`${this.marketingBaseUrl}/dealer/${dealerId}`),
      { ...this.httpOptions, params: { language } }
    );
  }

  public createClaasMarketingPermission(
    marketingPermission: IMarketingPermission
  ) {
    return this.http.post<void>(
      encodeURI(`${this.marketingBaseUrl}/claas`),
      marketingPermission,
      this.httpOptions
    );
  }

  public createDealerMarketingPermission(
    marketingPermission: IMarketingPermission
  ) {
    return this.http.post<void>(
      encodeURI(`${this.marketingBaseUrl}/dealer`),
      marketingPermission,
      this.httpOptions
    );
  }
}
