<div class="password-view">
  <div class="password-view__header">
    <h2 class="password-view__header-title subtitle-2">
      {{ 'account_settings.password.change.label' | translate }}
    </h2>
    <claas-circular-progress
      *ngIf="showSpinner$ | async"
      class="spinner"
      [color]="color"
      [diameter]="size"
    ></claas-circular-progress>
    <p class="password-view__header-subheading">
      {{ 'account_settings.password.info1' | translate }}
    </p>
  </div>

  <div *ngIf="myForm$ | async" class="password-view__content">
    <form [formGroup]="myForm$ | async" class="password-view__content-form">
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <input
          [errorStateMatcher]="
            errorsStatusObject$['oldPassword'] | async | translate
          "
          formControlName="oldPassword"
          matInput
          placeholder="{{
            'account_settings.password.current_password' | translate
          }}"
          type="{{ showPassword ? 'text' : 'password' }}" />
        <mat-icon
          (click)="showPassword = !showPassword; $event.preventDefault()"
          matSuffix>
          <claas-icon-button
            [iconSize]="smallIconSize"
            [icon]="showPasswordIcon" />
        </mat-icon>
        <mat-hint *ngIf="oldPasswordWrong" style="color: red">{{
          'account_settings.password.change.password_wrong' | translate
        }}</mat-hint>
      </mat-form-field>

      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <input
          (focus)="showPasswordPolicy = true"
          [errorStateMatcher]="
            errorsStatusObject$['newPassword'] | async | translate
          "
          formControlName="newPassword"
          matInput
          placeholder="{{
            'account_settings.password.new_password' | translate
          }}"
          type="{{ showNewPassword ? 'text' : 'password' }}" />
        <mat-icon
          (click)="showNewPassword = !showNewPassword; $event.preventDefault()"
          matSuffix>
          <claas-icon-button
            [iconSize]="smallIconSize"
            [icon]="showPasswordIcon" />
        </mat-icon>
      </mat-form-field>

      <ul *ngIf="showPasswordPolicy">
        <mat-hint style="color: black">
          {{ 'password.passwordStrength.shouldContain' | translate }}</mat-hint
        >
        <li>
          <mat-hint
            [class.password-view__input-valid]="pwLengthPolicy"
            style="color: red">
            {{ 'password.passwordStrength.lengthAtLeast' | translate }}
          </mat-hint>
        </li>
        <li>
          <mat-hint
            [class.password-view__input-valid]="pwCharsPolicy"
            style="color: red">
            {{ 'password.passwordStrength.lowerCase' | translate }},
            {{ 'password.passwordStrength.upperCase' | translate }},
            {{ 'password.passwordStrength.numbers' | translate }},
            {{ 'password.passwordStrength.specialCharacters' | translate }}
          </mat-hint>
        </li>
      </ul>

      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <input
          (focus)="showPasswordPolicy = true"
          formControlName="confirmPassword"
          matInput
          placeholder="{{
            'account_settings.password.new_password_confirm' | translate
          }}"
          type="{{ showConfirmPassword ? 'text' : 'password' }}" />
        <mat-icon
          (click)="
            showConfirmPassword = !showConfirmPassword; $event.preventDefault()
          "
          matSuffix>
          <claas-icon-button
            [iconSize]="smallIconSize"
            [icon]="showPasswordIcon" />
        </mat-icon>
      </mat-form-field>
    </form>

    <p class="password-view__footnote">
      {{ 'account_settings.password.info2' | translate }}
    </p>
  </div>

  <div class="password-view__dialog-actions">
    <div class="left-side">
      <div class="btn-left">
      </div>
    </div>
    <div class="right-side">
      <claas-button
        (onClick)="onCancelClicked()"
        *ngIf="data.buttonCancel"
        [buttonClass]="secondaryTextButtonClass"
        [disabled]="(showSpinner$ | async) === true"
        [text]="'generic.cancel' | translate"
        class="btn-left">
      </claas-button>

      <claas-button
        (onClick)="onSaveClicked()"
        *ngIf="data?.buttonSave"
        [disabled]="
          !!(invalidSaveButtonState$ | async) || (showSpinner$ | async) === true
        "
        [text]="'account_settings.password.change.label' | translate"
        class="btn-right">
      </claas-button>
    </div>
  </div>
</div>
