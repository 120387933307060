import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { OrgAddress } from '../models/org-address.model';
import { UserAddress } from '../models/user-address.model';
import { Observable, of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { countriesArr } from '../components/language-view/countries-source';
import { FieldDetails } from '../components/address-view/address-view.component';
import { OrgAddressPatch } from '../models/org-address-patch.model';

@Injectable({
  providedIn: 'root'
})
export class AddressService {
  constructor(private apiService: ApiService) {}

  existingAddressesFormatsObject: any = {};
  currentAddressFormatObject: FieldDetails = {};

  getAddressesFormats(country: string): Observable<any> {
    return this.getSelectedAddressFormat$(country).pipe(
      map(singleFormat => {
        const multipleFormFields: any = {};
        multipleFormFields[country] = singleFormat.allAddressDataRows.filter(
          (row: any[]) => row.length
        );
        this.existingAddressesFormatsObject = multipleFormFields;
      })
    );
  }

  setCurrentAddressFormatObject(currentCountry: string): void {
    const currentAddressFormatArray = this.existingAddressesFormatsObject[
      currentCountry
    ].flatMap((subArray: any) => [...subArray]);
    this.currentAddressFormatObject = {};
    currentAddressFormatArray.map(
      (details: any) => (this.currentAddressFormatObject[details.id] = details)
    );
  }

  getSelectedAddressFormat$(country: string): Observable<any> {
    return this.apiService.getFormFieldsForCountry(country);
  }

  validateUserAddress(address: UserAddress): Observable<UserAddress[]> {
    return this.getAddressesFormats(address.country).pipe(
      switchMap(() => {
        this.setCurrentAddressFormatObject(address.country);
        let payload = {
          city: address.city,
          country: address.country,
          county: '',
          streetNumber: address.house_number,
          postalCode: address.postal_code,
          state: address.state,
          street: address.street,
          territory: address.territory,
          village: address.suburb
        };
        const payloadString: string = JSON.stringify(payload);

        return this.apiService.validateAddress(payloadString).pipe(
          // @ts-ignore
          map((res: any[]) => {
            let addresses: UserAddress[] = [];
            let proposedAddresses: UserAddress[] = [];

            for (let proposedAddress of res) {
              let address = new UserAddress();
              address.street = proposedAddress['street'];
              address.house_number = proposedAddress['streetNumber'];
              address.postal_code = proposedAddress['postalCode'];
              address.city = proposedAddress['city'];
              address.country = proposedAddress['country']?.toLowerCase();
              address.suburb = proposedAddress['village'];
              address.state = proposedAddress['state']?.toLowerCase();
              // only use first 3 address proposals
              if (addresses.length < 3) {
                addresses.push(address);
              }
            }

            if (!this.compareUserAddresses(address, addresses)) {
              proposedAddresses = addresses;
            }

            return proposedAddresses;
          }),
          catchError(err => {
            console.log(err);
            return of([]); // Use 'of' to return an observable of an empty array
          })
        );
      })
    );
  }

  validateOrgAddress(address: OrgAddressPatch): Observable<OrgAddressPatch[]> {
    return this.getAddressesFormats(address.country).pipe(
      switchMap(() => {
        this.setCurrentAddressFormatObject(address.country);
        let payload = {
          city: address.city,
          country: address.country,
          county: '',
          streetNumber: address.houseNumber,
          postalCode: address.postalCode,
          state: address.state,
          street: address.street,
          territory: address.territory,
          village: address.suburb
        };
        const payloadString: string = JSON.stringify(payload);

        return this.apiService.validateAddress(payloadString).pipe(
          // @ts-ignore
          map((res: any[]) => {
            let addresses: OrgAddressPatch[] = [];
            let proposedAddresses: OrgAddressPatch[] = [];

            for (let proposedAddress of res) {
              let address = new OrgAddressPatch();
              address.street = proposedAddress['street'];
              address.houseNumber = proposedAddress['streetNumber'];
              address.postalCode = proposedAddress['postalCode'];
              address.city = proposedAddress['city'];
              address.country = proposedAddress['country']?.toLowerCase();
              address.suburb = proposedAddress['village'];
              address.state = proposedAddress['state']?.toLowerCase();

              // only use first 3 address proposals
              if (addresses.length < 3) {
                addresses.push(address);
              }
            }

            if (!this.compareOrgAddresses(address, addresses)) {
              proposedAddresses = addresses;
            }

            return proposedAddresses;
          }),
          catchError(err => {
            console.log(err);
            return of([]); // Use 'of' to return an observable of an empty array
          })
        );
      })
    );
  }

  compareUserAddresses(
    usersAddress: UserAddress,
    proposedAddresses: UserAddress[]
  ) {
    // handle array instead of object
    let same = true;
    for (let proposedAddress of proposedAddresses) {
      while (same) {
        if (
          this.currentAddressFormatObject['city'] &&
          usersAddress.city?.toString().toLowerCase() !==
            proposedAddress.city?.toString().toLowerCase()
        ) {
          same = false;
        }

        if (
          this.currentAddressFormatObject['street'] &&
          usersAddress.street?.toString().toLowerCase() !==
            proposedAddress.street?.toString().toLowerCase()
        ) {
          same = false;
        }

        if (
          this.currentAddressFormatObject['houseNumber'] &&
          usersAddress.house_number != null &&
          usersAddress.house_number?.toString() !==
            proposedAddress.house_number?.toString()
        ) {
          same = false;
        }

        if (
          this.currentAddressFormatObject['postalCode'] &&
          usersAddress.postal_code?.toString() !==
            proposedAddress.postal_code?.toString()
        ) {
          same = false;
        }

        if (
          this.currentAddressFormatObject['state'] &&
          usersAddress.state?.toString().toLowerCase() !==
            proposedAddress.state?.toString().toLowerCase()
        ) {
          same = false;
        }

        if (this.currentAddressFormatObject['suburb']) {
          if (
            (usersAddress.suburb && !proposedAddress.suburb) ||
            (!usersAddress.suburb && proposedAddress.suburb) ||
            usersAddress.suburb?.toString().toLowerCase() !==
              proposedAddress.suburb?.toString().toLowerCase()
          ) {
            same = false;
          }
        }

        break;
      }
    }
    // sometimes the address validation service is returning the same address
    return same;
  }

  compareOrgAddresses(
    orgAddress: OrgAddressPatch,
    proposedAddresses: OrgAddressPatch[]
  ) {
    // handle array instead of object
    let same = true;
    for (let proposedAddress of proposedAddresses) {
      while (same) {
        if (
          this.currentAddressFormatObject['city'] &&
          orgAddress.city?.toString().toLowerCase() !==
            proposedAddress.city?.toString().toLowerCase()
        ) {
          same = false;
        }

        if (
          this.currentAddressFormatObject['street'] &&
          orgAddress.street?.toString().toLowerCase() !==
            proposedAddress.street?.toString().toLowerCase()
        ) {
          same = false;
        }

        if (
          this.currentAddressFormatObject['houseNumber'] &&
          orgAddress.houseNumber != null &&
          orgAddress.houseNumber?.toString() !==
            proposedAddress.houseNumber?.toString()
        ) {
          same = false;
        }

        if (
          this.currentAddressFormatObject['postalCode'] &&
          orgAddress.postalCode?.toString() !==
            proposedAddress.postalCode?.toString()
        ) {
          same = false;
        }

        if (
          this.currentAddressFormatObject['state'] &&
          orgAddress.state?.toString().toLowerCase() !==
            proposedAddress.state?.toString().toLowerCase()
        ) {
          same = false;
        }

        if (this.currentAddressFormatObject['suburb']) {
          if (
            orgAddress.suburb?.toString().toLowerCase() !==
            proposedAddress.suburb?.toString().toLowerCase()
          ) {
            same = false;
          }
        }

        break;
      }
    }
    // sometimes the address validation service is returning the same address
    return same;
  }
}
