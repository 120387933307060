// important: The application ID needs to be equal to key definition in the i18n files
import { environment } from '../../environments/environment';

export const consentsArr = [
  { id: 'CLAAS_connect_3',
    label: 'applications.CLAAS_connect_3',
    client_id: environment.client_ids.auth_connect_3_id,
    img: '/assets/images/017904.svg',
    url: environment.base_urls.cc_3_base_url
  },
  { id: 'CLAAS_connect_3_app',
    label: 'applications.CLAAS_connect_3_app',
    client_id: environment.client_ids.auth_connect_3_app_id,
    img: '/assets/images/CLAAS connect_App.svg',
    url: environment.base_urls.cc_3_app_base_url
  },
  {
    id: 'CLAAS_connect_farm',
    label: 'applications.CLAAS_connect_farm',
    client_id: environment.client_ids.claas_connect_farm,
    img: '/assets/images/750146.svg',
    url: environment.base_urls.claas_connect_farm
  },
  {
    id: 'CLAAS_connect_farm_app',
    label: 'applications.CLAAS_connect_farm_app',
    client_id: environment.client_ids.claas_connect_farm_app,
    img: '/assets/images/CLAAS_connect_farm_App.svg',
    url: environment.base_urls.claas_connect_farm_app
  }
];
