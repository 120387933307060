<div class="unavailable-app-view">
  <div class="unavailable-app-view__body">
    <div class="unavailable-app-view__body-content">
      <div class="unavailable-app-view__body-content--header">
        <span class="header-3-bold">{{ 'access_control.app_not_available' | translate }}</span>
      </div>
      <div class="unavailable-app-view__body-content--message">
        <span [innerHtml]="'access_control.check_available_apps' | translate"></span>
      </div>

      <div class="unavailable-app-view__body-content--actions">
        <claas-button
          class="button redirect"
          [buttonClass]="secondaryOutlineButtonClass"
          [text]="'access_control.redirect' | translate"
          (click)="redirectToRoutePage()">
        </claas-button>
      </div>
    </div>
  </div>
</div>
