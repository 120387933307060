export class MarketingPermission {
  status: string;
  subscribed?: boolean;
  invitation_uuid: string;
  permission_uuid: string;
  claas_id: string;
  sap_reference_id: string;
  dealer_name: string;
  type: string;
  channels: {
    email: boolean;
    email_string: string;
    phone: boolean;
    phone_number: {
      type: string;
      number: string;
      country_prefix: string;
    };
    push: boolean;
    post: boolean;
    address: {
      street: string;
      house_number: string;
      postal_code: string;
      city: string;
      state: string;
      suburb: string;
      country: string;
    };
  };

  constructor() {
    this.status = '';
    this.subscribed = false;
    this.invitation_uuid = '';
    this.permission_uuid = '';
    this.claas_id = '';
    this.sap_reference_id = '';
    this.dealer_name = '';
    this.type = '';
    this.channels = {
      email: false,
      email_string: '',
      post: false,
      push: false,
      phone: false,
      phone_number: {
        type: '',
        number: '',
        country_prefix: ''
      },
      address: {
        street: '',
        house_number: '',
        postal_code: '',
        city: '',
        state: '',
        suburb: '',
        country: ''
      }
    };
  }
}
