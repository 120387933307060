import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserState } from './user.state';
import { User } from '@auth0/auth0-angular';

export const selectUser = createFeatureSelector<UserState>('user');

export const selectUserDetails = createSelector(
  selectUser,
  (state: UserState) => state.userDetails
);

export const selectIsLoggedIn = createSelector(
  selectUser,
  (state: UserState) => state.isLoggedIn
);

export const selectUserAvatarUrl = createSelector(
  selectUser,
  (state: UserState) => state.userAvatarUrl
);

export const selectIsSocialConnection = createSelector(
  selectUserDetails,
  (state: User | null) => {
    if (state) {
      return !!state['https://claas.com/isSocialConnection'];
    }
    return false;
  }
);
