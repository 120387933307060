import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, finalize, map, mergeMap } from 'rxjs/operators';

import { IMarketingPermission } from 'cde-fe-organization-registration-dialog';
import { MarketingPermissionService } from '../../services/marketing-permission.service';
import {
  createClaasMarketingPermission,
  createClaasMarketingPermissionComplete,
  createClaasMarketingPermissionFailure,
  createClaasMarketingPermissionSuccess,
  createDealerMarketingPermission,
  createDealerMarketingPermissionComplete,
  createDealerMarketingPermissionFailure,
  createDealerMarketingPermissionSuccess,
  getClaasMarketingPermission,
  getClaasMarketingPermissionComplete,
  getClaasMarketingPermissionFailure,
  getClaasMarketingPermissionSuccess,
  getDealerMarketingPermission,
  getDealerMarketingPermissionComplete,
  getDealerMarketingPermissionFailure,
  getDealerMarketingPermissionSuccess
} from './marketing-permission.actions';
import { State } from '..';

@Injectable()
export class MarketingPermissionEffects {
  constructor(
    private store: Store<State>,
    private actions$: Actions,
    private marketingPermissionService: MarketingPermissionService
  ) {}

  getClaasMarketingPermission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getClaasMarketingPermission),
      mergeMap(() =>
        this.marketingPermissionService.getClaasMarketingPermission().pipe(
          map((marketingPermission: IMarketingPermission) =>
            getClaasMarketingPermissionSuccess({ marketingPermission })
          ),
          catchError(error =>
            of(getClaasMarketingPermissionFailure({ error }))
          ),
          finalize(() => {
            this.store.dispatch(getClaasMarketingPermissionComplete());
          })
        )
      )
    )
  );

  getDealerMarketingPermission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getDealerMarketingPermission),
      map(action => ({ dealerId: action.dealerId, language: action.language })),
      mergeMap(({ dealerId, language }) =>
        this.marketingPermissionService
          .getDealerMarketingPermission(dealerId, language)
          .pipe(
            map((marketingPermission: IMarketingPermission) =>
              getDealerMarketingPermissionSuccess({ marketingPermission })
            ),
            catchError(error =>
              of(
                getDealerMarketingPermissionFailure({
                  error,
                  dealerId
                })
              )
            ),
            finalize(() => {
              this.store.dispatch(getDealerMarketingPermissionComplete());
            })
          )
      )
    )
  );

  createClaasMarketingPermission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createClaasMarketingPermission),
      map(action => action.request),
      mergeMap((request: IMarketingPermission) =>
        this.marketingPermissionService
          .createClaasMarketingPermission(request)
          .pipe(
            map(() => createClaasMarketingPermissionSuccess({ request })),
            catchError(error =>
              of(createClaasMarketingPermissionFailure({ error }))
            ),
            finalize(() => {
              this.store.dispatch(createClaasMarketingPermissionComplete());
            })
          )
      )
    )
  );

  createDealerMarketingPermission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createDealerMarketingPermission),
      map(action => action.request),
      mergeMap(request =>
        this.marketingPermissionService
          .createDealerMarketingPermission(request)
          .pipe(
            map(() => createDealerMarketingPermissionSuccess({ request })),
            catchError(error =>
              of(createDealerMarketingPermissionFailure({ error }))
            ),
            finalize(() => {
              this.store.dispatch(createDealerMarketingPermissionComplete());
            })
          )
      )
    )
  );
}
