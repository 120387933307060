<div class="layout-wrapper">
  <div class="header flex flex-row items-center">
    <claas-icon-button
      *ngIf="isLoggedIn && menuExpanded"
      [icon]="menuIconOpen"
      [iconSize]="smallIconSize"
      class="menu-button"
      (onClick)="toggleMenu()"
      [fontSize]="smallIconFontSize"></claas-icon-button>
    <claas-icon-button
      *ngIf="isLoggedIn && !menuExpanded"
      [icon]="menuIconClosed"
      [iconSize]="smallIconSize"
      class="menu-button"
      (onClick)="toggleMenu()"
      [fontSize]="smallIconFontSize"></claas-icon-button>
    <a routerLink="/" class="flex">
      <span
        *ngIf="isTablet === false && isHandset === false"
        class="logo-icon"></span>
      <div
        class="icon-text"
        [ngStyle]="{ 'margin-left': isTablet || isHandset ? '0px' : '20px' }">
        CLAAS ID
      </div>
    </a>
    <div class="header-right flex items-center">
      <claas-icon-button
        *ngIf="isHandset === false"
        class="icon-button"
        [icon]="helpIcon"
        [iconSize]="smallIconSize"
        [fontSize]="smallIconFontSize"
        (click)="redirectToInfo()"></claas-icon-button>
<!--
      <claas-icon-button
        *ngIf="isHandset === false"
        class="icon-button"
        [icon]="notificationIcon"
        [iconSize]="smallIconSize"
        [fontSize]="smallIconFontSize"
        (click)="printStuff()"></claas-icon-button>
      <claas-app-launcher *ngIf="isHandset === false"></claas-app-launcher>
-->
      <button class="usermenu avatar-container" (click)="toggleAvatarMenu()">
        <img class="usermenu avatar-menu-element" [src]="avatarUrl" />
      </button>

    </div>
  </div>
  <claas-navigation
    *ngIf="isLoggedIn && isHandset === false"
    [menuItems]="navigationItems"
    [expanded]="menuExpanded"
    [applicationHasRouterOutlet]="true"
    [disableHover]="true"></claas-navigation>
  <claas-navigation
    *ngIf="isLoggedIn && isHandset && menuExpanded"
    [menuItems]="navigationItems"
    [expanded]="menuExpanded"
    [applicationHasRouterOutlet]="true"
    [bottomMenuItems]="bottomMenuItems"
    [disableHover]="true"></claas-navigation>
</div>

<div
  *ngIf="avatarMenu"
  class="avatarmenu avatar-menu-element"
  [ngStyle]="{ 'width': isHandset ? 'calc(100% - 32px)' : '320px' }"
>
  <ng-container *ngIf="auth0.isAuthenticated$ | async; else loggedOut">
    <button class="avatar-container avatar-menu-element" disabled>
      <img class="image avatar-menu-element" [src]="avatarUrl" />
    </button>
    <h1 class="name avatar-menu-element">
      <span *ngIf="(salutation$ | async)">
        {{ 'registration.formfield.select.'+((salutation$ | async) | uppercase) | translate }}
      </span>
      <span *ngIf="(title$ | async)">
        {{ (title$ | async) }}
      </span>

      {{ givenName$ | async }} {{ familyName$ | async}}
    </h1>
    <p class="email avatar-menu-element"> {{ email$ | async }} </p>

    <claas-button
      class="button avatar-menu-element"
      [buttonClass]="secondaryOutlineButtonClass"
      [text]="logout"
      [icon]="logoutIcon"
      [stretch]="true"
      (onClick)="logoutUser()">
    </claas-button>

    <div class="legal-footer avatar-menu-element">
      <a routerLink="/privacy">{{ 'footer.privacy' | translate }}</a>
      <a routerLink="/terms">{{ 'footer.terms' | translate }}</a>
      <a routerLink="/about">{{ 'footer.about' | translate }}</a>
      <a onclick="UC_UI.showSecondLayer();"> {{ 'cookies.settings' | translate }}</a>
    </div>
  </ng-container>

  <ng-template #loggedOut>
    <claas-button
      class="button avatar-menu-element"
      [text]="'Login'"
      [stretch]="true"
      (onClick)="auth0.loginWithRedirect()"></claas-button>
    <div class="legal-footer avatar-menu-element">
      <a routerLink="/privacy">{{ 'footer.privacy' | translate }}</a>
      <a routerLink="/terms">{{ 'footer.terms' | translate }}</a>
      <a routerLink="/about">{{ 'footer.about' | translate }}</a>
      <a onclick="UC_UI.showSecondLayer();"> {{ 'cookies.settings' | translate }}</a>
    </div>
  </ng-template>
</div>

<div
  *ngIf="isTablet === false && isHandset === false"
  class="content"
  [style.margin-left]="isLoggedIn && menuExpanded ? '305px' : '57px'">
  <router-outlet></router-outlet>
</div>

<div
  *ngIf="isTablet"
  class="content"
  [style.margin-left]="'57px'"
  [style.z-index]="menuExpanded ? '0' : '2'">
  <router-outlet></router-outlet>
</div>

<div
  *ngIf="isHandset"
  class="content"
  [style.z-index]="menuExpanded ? '0' : '2'">
  <router-outlet></router-outlet>
</div>

<div *ngIf="!isLoggedIn" class="loader-container">
  <claas-circular-progress
    [color]="secondaryColor"
    [diameter]="largeSize"></claas-circular-progress>
</div>
