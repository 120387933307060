import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, finalize, map, mergeMap } from 'rxjs/operators';

import {
  getFeatureToggles,
  getFeatureTogglesComplete,
  getFeatureTogglesFailure,
  getFeatureTogglesSuccess
} from './feature-toggle.actions';
import { State } from '..';
import { FeatureToggleService } from '../../services/feature-toggle.service';
import { IFeatureToggle } from '../../models/feature-toggle.model';

@Injectable()
export class FeatureToggleEffects {
  constructor(
    private store: Store<State>,
    private actions$: Actions,
    private featureToggleService: FeatureToggleService
  ) {}

  geFeatureToggles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getFeatureToggles),
      mergeMap(() =>
        this.featureToggleService.getFeatureToggles().pipe(
          map((featureToggles: IFeatureToggle[]) =>
            getFeatureTogglesSuccess({ featureToggles })
          ),
          catchError(error => of(getFeatureTogglesFailure({ error }))),
          finalize(() => {
            this.store.dispatch(getFeatureTogglesComplete());
          })
        )
      )
    )
  );
}
