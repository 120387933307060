<div class='mail'>
  <div class='mail-panel'>
    <img class='mail-icon' src='assets/images/mail_ok.jpg' />
    <h1 class='mail-headline'>
      {{'mail.verified_headline' | translate}}
    </h1>    
    <h3 class='mail-text'>
      {{'mail.verified_sub_headline' | translate}} {{app_name}}.
    </h3>   
  </div>
</div>
