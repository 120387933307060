import { createAction, props } from '@ngrx/store';
import { StateError } from '../index';

import { IMarketingPermission } from 'cde-fe-organization-registration-dialog/lib/models/marketing-permission.model';

export const getClaasMarketingPermission = createAction(
  '[Marketing Permission] Get Claas Marketing Permission'
);

export const getClaasMarketingPermissionSuccess = createAction(
  '[Marketing Permission] Get Claas Marketing Permission Success',
  props<{ marketingPermission: IMarketingPermission }>()
);

export const getClaasMarketingPermissionFailure = createAction(
  '[Marketing Permission] Get Claas Marketing Permission Failure',
  props<{ error: StateError }>()
);

export const getClaasMarketingPermissionComplete = createAction(
  '[Marketing Permission] Get Claas Marketing Permission Complete'
);

export const getDealerMarketingPermission = createAction(
  '[Marketing Permission] Get Dealer Marketing Permission',
  props<{ dealerId: string; language: string }>()
);

export const getDealerMarketingPermissionSuccess = createAction(
  '[Marketing Permission] Get Dealer Marketing Permission Success',
  props<{ marketingPermission: IMarketingPermission }>()
);

export const getDealerMarketingPermissionFailure = createAction(
  '[Marketing Permission] Get Dealer Marketing Permission Failure',
  props<{ error: StateError; dealerId: string }>()
);

export const getDealerMarketingPermissionComplete = createAction(
  '[Marketing Permission] Get Dealer Marketing Permission Complete'
);

export const createClaasMarketingPermission = createAction(
  '[Marketing Permission] Create Claas Marketing Permission',
  props<{ request: IMarketingPermission }>()
);

export const createClaasMarketingPermissionSuccess = createAction(
  '[Marketing Permission] Create Claas Marketing Permission Success',
  props<{ request: IMarketingPermission }>()
);

export const createClaasMarketingPermissionFailure = createAction(
  '[Marketing Permission] Create Claas Marketing Permission Failure',
  props<{ error: StateError }>()
);

export const createClaasMarketingPermissionComplete = createAction(
  '[Marketing Permission] Create Claas Marketing Permission Complete'
);

export const createDealerMarketingPermission = createAction(
  '[Marketing Permission] Create Dealer Marketing Permission',
  props<{ request: IMarketingPermission }>()
);

export const createDealerMarketingPermissionSuccess = createAction(
  '[Marketing Permission] Create Dealer Marketing Permission Success',
  props<{ request: IMarketingPermission }>()
);

export const createDealerMarketingPermissionFailure = createAction(
  '[Marketing Permission] Create Dealer Marketing Permission Failure',
  props<{ error: StateError }>()
);

export const createDealerMarketingPermissionComplete = createAction(
  '[Marketing Permission] Create Dealer Marketing Permission Complete'
);
