<div class="confirmation-dialog">
  <div class="phonenumber-view__header-confirmation-dialog">
    <div class="confirmation-dialog__header">
      <h2 class="subtitle-2">
        {{
          data.confirmationHeader
            ? (data.confirmationHeader | translate)
            : ('confirmation.header' | translate)
        }}
      </h2>
    </div>

    <span class="confirmation-dialog__text">{{
      data.confirmationText
        ? (data.confirmationText | translate)
        : ('confirmation.text' | translate)
    }}</span>
  </div>
</div>

<div class="confirmation-dialog__dialog-actions">
  <div class="left-side">
    <div class="btn-left"></div>
  </div>
  <div class="right-side">
    <claas-button
      class="btn-left"
      [buttonClass]="secondaryTextButtonClass"
      [text]="'generic.cancel' | translate"
      (onClick)="deletionIsCanceled()">
    </claas-button>

    <claas-button
      class="btn-left"
      [disabled]="!!(requestInProgress$ | async)"
      [buttonClass]="errorButtonClass"
      [text]="'generic.delete' | translate"
      (onClick)="deletionIsConfirmed()">
    </claas-button>
  </div>
</div>
