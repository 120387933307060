import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output
} from '@angular/core';
import { IChangePasswordModel } from '../../models/change-password.model';
import { passedDataToDialog } from '../dialog-window/dialog-window.component';
import { ApiService } from '../../services/api.service';
import { ButtonClass, Icon, IconSize } from '@claas/claas-form-components';
import { BehaviorSubject, Subscription, debounceTime, tap } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { combineLatestWith, distinctUntilChanged, map } from 'rxjs/operators';
import { errorStatusObject } from '../address-view/address-view.component';
import { TranslateService } from '@ngx-translate/core';
import {
  CircularProgressDiameter,
  ProgressColor
} from '@claas/claas-layout-components';

@Component({
  selector: 'app-password-view',
  templateUrl: './password-view.component.html',
  styleUrls: ['./password-view.component.scss']
})
export class PasswordViewComponent implements OnDestroy {
  subscriptions = new Subscription();
  myForm: FormGroup | undefined = undefined;
  myForm$: BehaviorSubject<any> = new BehaviorSubject(this.myForm);
  invalidSaveButtonState$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  errorsStatusObject$: errorStatusObject = {};

  showPassword: boolean = false;
  showNewPassword: boolean = false;
  showConfirmPassword: boolean = false;
  oldPasswordWrong: string = '';

  @Input() data: passedDataToDialog = {};
  @Input() secondaryTextButtonClass?: ButtonClass;

  @Output() closeDialogWindow = new EventEmitter();
  @Output() displayPopup = new EventEmitter();

  showPasswordIcon: Icon = {
    iconId: '001003',
    iconStyle: 'bold',
    namespace: 'claas-id',
    size: 24
  };
  size = CircularProgressDiameter.SMALL;
  smallIconSize = IconSize.SMALL;
  color = ProgressColor.PRIMARY;

  showPasswordPolicy = false;
  pwLengthPolicy = false;
  pwCharsPolicy = false;

  showSpinner$ = new BehaviorSubject(false);

  constructor(
    private authService: AuthService,
    private apiService: ApiService,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef
  ) {
    this.myForm = this.setForm();
    this.preTrackFormChanges(this.myForm);

    this.myForm$.next(this.myForm);
  }

  setForm(): FormGroup {
    return new FormGroup({
      oldPassword: new FormControl('', [Validators.required]),
      newPassword: new FormControl('', [Validators.required]),
      confirmPassword: new FormControl('', [Validators.required])
    });
  }

  preTrackFormChanges(myForm: FormGroup): void {
    this.invalidSaveButtonState$.next(true);

    this.trackFormChanges(myForm);
  }

  trackFormChanges(myForm: FormGroup): void {
    for (const formControlKey in this.myForm?.controls) {
      if (this.myForm?.get(formControlKey)) {
        // @ts-ignore
        this.errorsStatusObject$[formControlKey] = this.myForm
          .get(formControlKey)
          .valueChanges.pipe(
            debounceTime(200),
            map(() => {
              switch (formControlKey) {
                case 'newPassword':
                  // for the newPassword field we have a third possible state
                  if (this.myForm?.get(formControlKey)?.status === 'INVALID') {
                    return 'registration.validationMsgs.required';
                  }

                  return;
                case 'confirmPassword':
                  // for the confirmPassword field we have a third possible state
                  if (this.myForm?.get(formControlKey)?.status === 'INVALID') {
                    return 'registration.validationMsgs.required';
                  } else {
                    const confirmPasswordCorrect =
                      this.myForm?.get('newPassword')?.value ===
                      this.myForm?.get('confirmPassword')?.value;

                    return confirmPasswordCorrect
                      ? undefined
                      : 'password.error.confirm';
                  }
                default:
                  // currently only in currentPassword use case this part will be used
                  return this.myForm?.get(formControlKey)?.status === 'INVALID'
                    ? 'registration.validationMsgs.required'
                    : undefined;
              }
            })
          );
      }
    }
    this.subscriptions.add(
      myForm
        .get('newPassword')!
        .valueChanges.pipe(
          debounceTime(150),
          distinctUntilChanged(),
          map(() => {
            this.checkPwdPolicy();
          })
        )
        .subscribe(() => this.cdr.detectChanges())
    );

    this.subscriptions.add(
      myForm.valueChanges.subscribe(() => {
        // update the save button status
        this.invalidSaveButtonState$.next(
          !!this.myForm?.invalid ||
            this.myForm?.get('newPassword')?.value !==
              this.myForm?.get('confirmPassword')?.value
        );
      })
    );
  }

  onSaveClicked(): void {
    const passedObject = {
      context: this.data.context,
      action: 'save'
    };

    this.showSpinner$.next(true);

    this.changePassword(passedObject);
  }

  onCancelClicked(): any {
    const passedObject = {
      action: 'cancel'
    };

    this.closeDialogWindow.emit(passedObject);
  }

  checkPwdPolicy(): void {
    let specials = 0;

    if (this.myForm?.get('newPassword')?.value.length < 10) {
      this.pwLengthPolicy = false;
    } else {
      this.pwLengthPolicy = true;
    }

    if (/[A-Z]/.test(this.myForm?.get('newPassword')?.value)) {
      specials++;
    }

    if (/[a-z]/.test(this.myForm?.get('newPassword')?.value)) {
      specials++;
    }

    if (/[0-9]/.test(this.myForm?.get('newPassword')?.value)) {
      specials++;
    }

    if (/[^a-zA-Z0-9 ]/.test(this.myForm?.get('newPassword')?.value)) {
      specials++;
    }

    this.pwCharsPolicy = specials >= 3;
  }

  changePassword(passedObject: any) {
    const payload: IChangePasswordModel = {
      email: this.data?.profileEmail ? this.data?.profileEmail : '',
      password: this.myForm?.get('newPassword')?.value,
      oldPassword: this.myForm?.get('oldPassword')?.value
    };

    this.subscriptions.add(
      this.apiService.changePassword(payload).subscribe({
        next: () => {
          this.translateService
            .get('account_settings.password.change.success')
            .subscribe((text: string) => {
              this.displayPopup.emit({ text, className: 'success' });
            });

          this.showSpinner$.next(false);
          this.closeDialogWindow.emit(passedObject);
          this.authService.login('/');
        },
        error: err => {
          // THE ERROR IS NOT RETURNING THE CORRECT MESSAGE OR STATUS CODE
          // FILTERING FOR THE CORRECT ERROR MESSAGE IS NOT WORKING
          const errorString = JSON.stringify(err);

          if (errorString.indexOf('PasswordStrengthError') !== -1) {
            this.translateService
              .get('account_settings.password.change.too_weak')
              .subscribe((text: string) => {
                this.displayPopup.emit({ text, className: 'error' });
              });
          }

          if (err.statusCode === 403) {
            this.oldPasswordWrong = 'password.invalid_grant';
            this.translateService
              .get('account_settings.password.change.password_wrong')
              .subscribe((text: string) => {
                this.displayPopup.emit({ text, className: 'error' });
              });
          }

          this.showSpinner$.next(false);
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
