<!-- German -->
<div
  *ngIf="this.translate.currentLang === 'de'"
  class="flex flex-row justify-center">
  <div class="content">
    <div class="align-center">
      <h1><strong>Impressum</strong></h1>
      <br />
      <p>
        Verantwortliche Gesellschaft im Sinne des §5 Telemediengesetz (TMG):<br />
        CLAAS KGaA mbH Mühlenwinkel 1 33428 Harsewinkel
      </p>
      <p>Telefonzentrale: +49 (0)52 47-12 0</p>
      <p>
        E-Mail: <a href="mailto:infoclaas@claas.com">infoclaas&#64;claas.com</a>
      </p>
      <p>
        CLAAS Kommanditgesellschaft auf Aktien mbH<br />
        Handelsregister: Amtsgericht Gütersloh HRB Nr. 3027<br /><br />
        Persönlich haftende Gesellschafterin: Helmut Claas GmbH<br />
        Handelsregister: Amtsgericht Gütersloh HRB Nr. 2980<br /><br />
        Konzernleitung:<br />
        Jan-Hendrik Mohr (Vorsitzender)*<br />
        Dr. Martin von Hoyningen-Huene*<br />
        Henner Böttcher*<br />
        Christian Radons*<br />
        Thomas Spiering*<br /><br />
        * Geschäftsführer der Helmut Claas GmbH<br /><br />
        Vorsitzende des Aufsichtsrates und des Gesellschafterausschusses:
        Cathrina Claas-Mühlhäuser<br /><br />
        UST-Id. Nr.: DE 160 410 788
      </p>
    </div>
  </div>
</div>

<!-- Other languages -->
<div
  *ngIf="this.translate.currentLang !== 'de'"
  class="flex flex-row justify-center">
  <div class="content">
    <div class="align-center">
      <h1><strong>Legal notice</strong></h1>
      <br />
      <p><strong>Contact</strong></p>
      <p>
        CLAAS KGaA mbH<br />
        Mühlenwinkel 1<br />
        33428 Harsewinkel, Germany
      </p>
      <p>Tel.: +49 (0)52 47-12 0</p>
      <p>
        E-Mail: <a href="mailto:infoclaas@claas.com">infoclaas&#64;claas.com</a>
      </p>
      <p>CLAAS Group Legal Notice</p>
      <p>
        CLAAS Kommanditgesellschaft auf Aktien mbH<br />
        Register of Companies: Gütersloh District Court,<br />
        Register of Companies (HRB) No. 3027<br /><br />
        Individually liable shareholder: Helmut Claas GmbH<br />
        Register of Companies: Gütersloh District Court,<br />
        Register of Companies (HRB) No. 2980<br /><br />
        Executive Board:<br />
        Jan-Hendrik Mohr (Chairman of the Group Executive Board)*<br />
        Henner Böttcher*<br />
        Dr. Martin von Hoyningen-Huene*<br />
        Christian Radons*<br />
        Thomas Spiering*<br /><br />
        *General Managers of Helmut Claas GmbH<br /><br />
        Chair of the Supervisory Board and of the Shareholders' Committee:
        Cathrina Claas-Mühlhäuser<br /><br />
        VAT ID No.: DE 160 410 788
      </p>
    </div>
  </div>
</div>
