import { Component, OnDestroy } from '@angular/core';
import {
  ButtonClass,
  ButtonSize,
  Icon,
  IconFontSize,
  IconSize
} from '@claas/claas-form-components';
import { ApiService } from '../shared/services/api.service';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  forkJoin,
  take,
  tap,
  switchMap
} from 'rxjs';
import { Store } from '@ngrx/store';
import { selectUserDetails } from '../shared/state/user';
import { filter, map } from 'rxjs/operators';
import {
  CircularProgressDiameter,
  ProgressColor
} from '@claas/claas-layout-components';
import { Router } from '@angular/router';
import {
  addressDetailsLoaded,
  currentOrganisationLoaded,
  loadAddressDetails,
  loadOrganisationRegistrationSteps,
  loadRegistrationDefaultOrganisation,
  loadUserDefaultOrganisation,
  loadUserOrganisations,
  organisationMembersNotLoaded,
  registerOrganisation
} from '../shared/state/organisations';
import {
  selectAddressDetails,
  selectCurrentOrganisation,
  selectDefaultOrganisation,
  selectOrganisationError,
  selectOrganisations,
  selectRegisteredOrganisation,
  selectRegistrationDefaultOrganisation,
  selectRegistrationSteps
} from '../shared/state/organisations/organisations.selectors';
import { UserOrganisations } from '../shared/state/organisations/organisations.state';
import { TranslateService } from '@ngx-translate/core';
import {
  IOrganisationRegistrationDialogInput,
  OrganisationRegistrationDialogComponent
} from 'cde-fe-organization-registration-dialog';
import { environment } from '../../environments/environment';
import {
  selectAddressAutocompleteSuggestionsData,
  selectRegistrationDataPending,
  selectRegistrationDealerData,
  selectRegistrationErrorData,
  selectRegistrationPersonalData,
  selectRegistrationPersonalDataPending,
  selectRegistrationShopData
} from '../shared/state/global/global.selectors';
import {
  selectDealerByNameSearchData,
  selectDealerFiltersData,
  selectNearestDealerSearchData,
  selectNearestDealerSearchDataPending
} from '../shared/state/dealers/dealers.selectors';
import { User } from 'cde-fe-organization-registration-dialog/lib/models/auth.model';
import { AuthService } from '../shared/services/auth.service';
import {
  IAddressAutocompleteRequest,
  IAddressDetailsRequest,
  IGetDealerRequest,
  IRegistrationDialogStep
} from 'cde-fe-organization-registration-dialog/lib/models/organisation.model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  loadDealerByNameSearchData,
  loadDealerFiltersData,
  loadNearestDealersSearchData,
  nearestDealersSearchDataLoaded
} from '../shared/state/dealers';
import {
  clearRegistrationData,
  loadAddressAutocompleteSuggestionsData,
  loadRegistrationDealerData,
  loadRegistrationPersonalData,
  loadRegistrationShopData,
  updateRegistrationPersonalData
} from '../shared/state/global';
import { OrganisationUpdateRequest } from '../shared/models/organisation-update-request.model';
import {
  DialogWindowComponent,
  ResponseDataFromDialog,
  passedDataToDialog
} from '../shared/components/dialog-window/dialog-window.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { UserAddress } from '../shared/models/user-address.model';
import { AddressHelperService } from '../shared/helpers/address.helper.ts.service';
import {
  getClaasMarketingPermission,
  getDealerMarketingPermissions
} from '../shared/state/marketing-permission/marketing-permission.selectors';
import {
  createClaasMarketingPermission,
  createDealerMarketingPermission,
  getClaasMarketingPermission as getClaasMarketingPermissionFromBackend,
  getDealerMarketingPermission as getDealerMarketingPermissionFromBackend
} from '../shared/state/marketing-permission/marketing-permission.actions';
import { getFeatureToggles } from '../shared/state/feature-toggle/feature-toggle.selectors';
import { Features } from '../shared/models/feature-toggle.model';

declare const window: any;

@Component({
  selector: 'app-organisation',
  templateUrl: './organisations.component.html',
  styleUrls: ['./organisations.component.scss']
})
export class OrganisationsComponent implements OnDestroy {
  largeSize = CircularProgressDiameter.LARGE;
  primaryButtonClass = ButtonClass.PRIMARY;
  mediumButtonSize = ButtonSize.DEFAULT_MEDIUM;
  isHandset = false;
  addIcon: Icon = {
    iconId: '000973',
    iconStyle: 'bold',
    namespace: 'claas-id',
    size: 19
  };
  organisationUpdateRequest: OrganisationUpdateRequest | undefined;
  currentUser: User | undefined = undefined;
  accessToken = '';
  registrationSteps: IRegistrationDialogStep[] | undefined = [];
  mediumIconSize = IconSize.MEDIUM;
  mediumIconFontSize = IconFontSize.MEDIUM;
  secondaryColor = ProgressColor.SECONDARY;

  organisations$: BehaviorSubject<any> = new BehaviorSubject(undefined);
  isLoadingOrganisations$: BehaviorSubject<any> = new BehaviorSubject(true);
  private subscriptions = new Subscription();
  private marketingPermissionFeatureToggle = false;

  constructor(
    private store: Store,
    private apiService: ApiService,
    private router: Router,
    private translateService: TranslateService,
    private authService: AuthService,
    private dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
    private addressHelper: AddressHelperService
  ) {
    this.store.dispatch(loadOrganisationRegistrationSteps());
    this.resetPropsFromState();
    this.getOrganisations();

    this.organisations$.subscribe(orgs => {
      if (orgs) {
        for (const org of orgs) {
          if (org.myRole === 'organisation.role.admin') {
            this.apiService
              .getOrganisationUpdateRequest(org.auth0Id)
              .subscribe((res: any) => {
                if (res) {
                  this.organisationUpdateRequest = res;

                  if (this.dialog.openDialogs.length === 0) {
                    this.openDialog('org-update');
                  }
                }
              });
          }
        }
      }
    });

    this.subscriptions.add(
      this.authService
        .getTokenSilently$()
        .subscribe(token => (this.accessToken = token))
    );

    this.subscriptions.add(
      this.store
        .select(selectRegistrationSteps)
        .subscribe(steps => (this.registrationSteps = steps))
    );

    this.subscriptions.add(
      this.store
        .select(getFeatureToggles)
        .subscribe(
          featureToggles =>
            (this.marketingPermissionFeatureToggle =
              featureToggles?.some(
                toggle => toggle.id === Features.MARKETING_PERMISSION
              ) ?? false)
        )
    );
  }

  isHandset$(): Observable<any> {
    return this.breakpointObserver
      .observe(Breakpoints.Handset)
      .pipe(map((state: any) => (this.isHandset = state.matches)));
  }

  resetPropsFromState(): void {
    this.store.dispatch(addressDetailsLoaded({ addressDetails: null }));
    this.store.dispatch(
      nearestDealersSearchDataLoaded({
        nearestDealersSearchData: null
      })
    );
    this.store.dispatch(
      currentOrganisationLoaded({ currentOrganisation: null })
    );
  }

  getRole(role: string) {
    let r = '';
    const split = role.split('.');
    this.translateService
      .get('organization.roles.' + split.pop())
      .subscribe((text: string) => (r = text));
    return r;
  }

  getOrganisations() {
    this.subscriptions.add(
      this.store
        .select(selectUserDetails)
        .pipe(
          filter((user: any) => !!user),
          take(1),
          tap((user: any) => {
            this.currentUser = user;

            // in case the token is required please use "user.sub" for it
            this.store.dispatch(loadUserOrganisations({ userId: user.uuid }));

            this.store.dispatch(
              loadUserDefaultOrganisation({ userId: user.uuid })
            );

            this.subscriptions.add(
              this.store
                .select(selectOrganisations)
                .pipe(
                  filter((organisations: any) => !!organisations),
                  map((organisations: UserOrganisations) => {
                    if (Object.keys(organisations).length === 0) {
                      // the user has no registered organisation
                      this.isLoadingOrganisations$.next(false);
                    } else {
                      this.subscriptions.add(
                        this.getOrganisationsMembers$(organisations).subscribe({
                          next: (orgaMembersDetails: any[]) => {
                            {
                              organisations = this.addAdminNameProperty(
                                organisations,
                                orgaMembersDetails
                              );

                              organisations = this.addMyRoleProperty(
                                organisations,
                                orgaMembersDetails,
                                user.sub
                              );

                              organisations =
                                this.translatePropertiesValues(organisations);

                              this.markDefaultOrganisation(organisations);
                            }
                          },
                          error: error =>
                            this.store.dispatch(
                              organisationMembersNotLoaded({
                                organisationError: error.payload
                              })
                            )
                        })
                      );
                    }
                  })
                )
                .subscribe()
            );
          })
        )
        .subscribe()
    );
  }

  translatePropertiesValues(
    organisationsDict: UserOrganisations
  ): UserOrganisations {
    const organisationsWithTranslations: UserOrganisations = {};

    for (const orgId in organisationsDict) {
      const orgAddress: UserAddress[] = organisationsDict[
        orgId
      ].addresses.slice(0, 1);

      const translatedCountryName = this.addressHelper.translateCountryValue(
        orgAddress[0].country
      );
      const translatedStateName = orgAddress[0].state
        ? this.addressHelper.translateStateValue(
            orgAddress[0].state,
            orgAddress[0].country
          )
        : '';

      const translatedTerritoryName = orgAddress[0].territory
        ? this.addressHelper.translateTerritoryValue(
            orgAddress[0].territory,
            orgAddress[0].country
          )
        : '';

      orgAddress[0] = new UserAddress({
        ...orgAddress[0],
        state: translatedStateName,
        territory: translatedTerritoryName,
        country: translatedCountryName
      });

      organisationsWithTranslations[orgId] = organisationsDict[orgId];
      organisationsWithTranslations[orgId].addresses = orgAddress;
    }
    return organisationsWithTranslations;
  }

  markDefaultOrganisation(organisationsDict: UserOrganisations): void {
    this.subscriptions.add(
      this.store.select(selectDefaultOrganisation).subscribe((orgaId: any) => {
        if (organisationsDict[orgaId]) {
          organisationsDict[orgaId]['isDefault'] = true;
        }

        // convert the dictionary into an array
        const organisationsArray = Object.values(organisationsDict);
        const targetIndex = organisationsArray.findIndex(
          element => element.isDefault
        );
        const currentDefaultOrganisation = organisationsArray.splice(
          targetIndex,
          1
        )[0];

        organisationsArray.splice(0, 0, currentDefaultOrganisation);

        this.isLoadingOrganisations$.next(false);
        this.organisations$.next(organisationsArray);
      })
    );
  }

  getOrganisationsMembers$(filteredOrgs: any): Observable<any> {
    const orgaMembersSubs: any = {};

    for (const orgId in filteredOrgs) {
      orgaMembersSubs[orgId] = this.authService
        .getTokenSilently$()
        .pipe(
          switchMap(token =>
            this.apiService.getOrganisationMembers(orgId, token)
          )
        );
    }

    return forkJoin(orgaMembersSubs);
  }

  addAdminNameProperty(organisations: any, orgaMembersDetails: any) {
    const output: any = {};
    let adminName;

    for (const organisationId in organisations) {
      output[organisationId] = organisations[organisationId];

      const adminMember = orgaMembersDetails[organisationId].content.find(
        (member: any) => member.role === 'ADMIN'
      );

      if (adminMember) {
        adminName = adminMember
          ? adminMember?.givenName + ' ' + adminMember?.familyName
          : 'organisation.unknown';
      }

      output[organisationId] = {
        ...output[organisationId],
        adminName
      };
    }

    return output;
  }

  addMyRoleProperty(
    organisations: any,
    orgaMembersDetails: any,
    myIdpID: string
  ): any {
    const output: any = {};
    let myRole;

    for (const organisationId in organisations) {
      output[organisationId] = organisations[organisationId];

      const member = orgaMembersDetails[organisationId].content.find(
        (member: any) => member.idpUser === myIdpID
      );

      if (member) {
        myRole = 'organisation.role.' + member.role.toLowerCase();
      } else {
        myRole = null;
      }

      output[organisationId] = {
        ...output[organisationId],
        myRole
      };
    }

    return output;
  }

  openDetailsPage(orgaDetails: any): void {
    this.router.navigate(['/organisation-data'], {
      queryParams: { orgId: orgaDetails.auth0Id }
    });
  }

  openRegistrationDialog(): void {
    const data = this.createDialogInput();

    const dialogRef = this.dialog.open(
      OrganisationRegistrationDialogComponent,
      {
        data: data,
        disableClose: true
      }
    );

    this.processWithDialogRef(dialogRef);

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((response: any) => {
        if (response && response !== '') {
          this.isLoadingOrganisations$.next(true);

          this.store
            .select(selectRegisteredOrganisation)
            .pipe(filter((result: any) => !!result))
            .subscribe(result => this.getOrganisations());
        }
      });
  }

  openDialog(context: string): void {
    const data: passedDataToDialog = {
      context,
      buttonCancel: 'Cancel',
      buttonSave: 'Save',
      orgUpdateRequest: this.organisationUpdateRequest
    };

    const dialogRef = this.dialog.open(DialogWindowComponent, {
      maxWidth: this.isHandset ? '100vw' : '80vw',
      data
    });

    this.subscriptions.add(
      dialogRef
        .afterClosed()
        .pipe(
          tap((answer: ResponseDataFromDialog) => {
            this.getOrganisations();
          })
        )
        .subscribe()
    );
  }

  processWithDialogRef(
    dialogRef: MatDialogRef<OrganisationRegistrationDialogComponent>
  ): void {
    this.subscriptions.add(
      dialogRef.componentInstance.getDealerFilter.subscribe(() =>
        this.store.dispatch(loadDealerFiltersData())
      )
    );

    this.subscriptions.add(
      dialogRef.componentInstance.getNearestDealers.subscribe(
        (request: any) => {
          this.store.dispatch(loadNearestDealersSearchData(request));
        }
      )
    );

    this.subscriptions.add(
      dialogRef.componentInstance.getAddressAutocomplete.subscribe(
        (request: IAddressAutocompleteRequest) =>
          this.store.dispatch(
            loadAddressAutocompleteSuggestionsData({
              addressDetailsRequest: request
            })
          )
      )
    );

    this.subscriptions.add(
      dialogRef.componentInstance.getDealersByName.subscribe((request: any) => {
        this.store.dispatch(loadDealerByNameSearchData(request));
      })
    );

    this.subscriptions.add(
      dialogRef.componentInstance.clearRegistrationData.subscribe(() =>
        this.store.dispatch(clearRegistrationData())
      )
    );

    this.subscriptions.add(
      dialogRef.componentInstance.getRegistrationDefaultOrganisation.subscribe(
        (country: string) =>
          this.store.dispatch(loadRegistrationDefaultOrganisation({ country }))
      )
    );

    this.subscriptions.add(
      dialogRef.componentInstance.getRegistrationShopData.subscribe(
        (request: any) =>
          this.store.dispatch(loadRegistrationShopData({ country: request }))
      )
    );

    this.subscriptions.add(
      dialogRef.componentInstance.getRegistrationPersonalData.subscribe(
        (request: any) => {
          this.store.dispatch(
            loadRegistrationPersonalData({
              country: request.country,
              language: request.language
            })
          );
        }
      )
    );

    this.subscriptions.add(
      dialogRef.componentInstance.getRegistrationDealerData.subscribe(
        (request: any) => {
          this.store.dispatch(
            loadRegistrationDealerData({
              language: request.language
            })
          );
        }
      )
    );

    this.subscriptions.add(
      dialogRef.componentInstance.maintainPersonalData.subscribe(
        (request: any) =>
          this.store.dispatch(
            updateRegistrationPersonalData({
              request: request.request,
              language: request.language
            })
          )
      )
    );

    this.subscriptions.add(
      dialogRef.componentInstance.registerOrganisationWithDealerAndShops.subscribe(
        (request: any) => this.store.dispatch(registerOrganisation(request))
      )
    );

    this.subscriptions.add(
      dialogRef.componentInstance.stepperError.subscribe((message: any) => {
        console.log(message);
      })
    );

    this.subscriptions.add(
      dialogRef.componentInstance.getAddressDetails
        .pipe(take(1))
        .subscribe((request: IAddressDetailsRequest) =>
          this.store.dispatch(
            loadAddressDetails({ addressDetailsRequest: request })
          )
        )
    );

    // Marketing permissions
    this.subscriptions.add(
      dialogRef.componentInstance.getClaasMarketingPermission.subscribe(() =>
        this.store.dispatch(getClaasMarketingPermissionFromBackend())
      )
    );
    this.subscriptions.add(
      dialogRef.componentInstance.getDealerMarketingPermission.subscribe(
        (request: IGetDealerRequest) =>
          this.store.dispatch(
            getDealerMarketingPermissionFromBackend({
              dealerId: request.dealerId,
              language: request.language
            })
          )
      )
    );
    this.subscriptions.add(
      dialogRef.componentInstance.createClaasMarketingPermission.subscribe(
        request =>
          this.store.dispatch(createClaasMarketingPermission({ request }))
      )
    );
    this.subscriptions.add(
      dialogRef.componentInstance.createDealerMarketingPermission.subscribe(
        request =>
          this.store.dispatch(createDealerMarketingPermission({ request }))
      )
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  // do not use extra class anymore to avoid inconsistencies between interface
  // and class when the version of the dialog changes
  private createDialogInput(
    input?: Partial<IOrganisationRegistrationDialogInput>
  ): Partial<IOrganisationRegistrationDialogInput> {
    return {
      user: this.currentUser,
      dialogSteps: this.registrationSteps ? this.registrationSteps : [],
      disableAutostart: true,
      registrationDataPending$: this.store.select(
        selectRegistrationDataPending
      ),
      searchNearestDealerPending$: this.store.select(
        selectNearestDealerSearchDataPending
      ),
      currentDefaultOrganisation$: this.store.select(
        selectRegistrationDefaultOrganisation
      ),
      currentOrganisation$: this.store.select(selectCurrentOrganisation),
      registrationDealerData$: this.store.select(selectRegistrationDealerData),
      searchNearestDealers$: this.store.select(selectNearestDealerSearchData),
      dealerFilters$: this.store.select(selectDealerFiltersData),
      addressAutocompleteSuggestions$: this.store.select(
        selectAddressAutocompleteSuggestionsData
      ),
      addressDetails$: this.store.select(selectAddressDetails),
      searchDealersByName$: this.store.select(selectDealerByNameSearchData),
      registrationPersonalData$: this.store.select(
        selectRegistrationPersonalData
      ),
      registrationError$: this.store.select(selectRegistrationErrorData),
      organisationsError$: this.store.select(selectOrganisationError),
      getRegisteredOrganisation$: this.store.select(
        selectRegisteredOrganisation
      ),
      registrationDefaultOrganisation$: this.store.select(
        selectRegistrationDefaultOrganisation
      ),
      registrationPersonalDataPending$: this.store.select(
        selectRegistrationPersonalDataPending
      ),
      registrationShopData$: this.store.select(selectRegistrationShopData),
      bearerToken: this.accessToken,
      apiBaseUrl: environment.base_urls.cc_3_api_base_url,
      clientId: environment.auth.azure_maps_client_id,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      UC_UI: window.UC_UI,
      claasMarketingPermission$: this.store.select(getClaasMarketingPermission),
      dealerMarketingPermissions$: this.store.select(
        getDealerMarketingPermissions
      ),
      marketingPermissionFeatureToggle: this.marketingPermissionFeatureToggle,

      ...(input ?? {})
    };
  }
}
