import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {
  ResponseDataFromDialog,
  passedDataToDialog
} from '../dialog-window/dialog-window.component';
import {
  ButtonClass,
  DropdownOptionObject
} from '@claas/claas-form-components';
import { UserBasicInformation } from '../../../personal-data/personal-data.component';
import { ApiService } from '../../services/api.service';
import { BehaviorSubject, Subscription, finalize } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { UserInformationModel } from '../../models/user-information.model';
import {
  CircularProgressDiameter,
  ProgressColor
} from '@claas/claas-layout-components';

@Component({
  selector: 'app-name-view',
  templateUrl: './name-view.component.html',
  styleUrls: ['./name-view.component.scss']
})
export class NameViewComponent implements OnInit, OnDestroy {
  @Input() data: passedDataToDialog = {};
  @Input() secondaryTextButtonClass?: ButtonClass;

  @Output() displayPopup = new EventEmitter();
  @Output() closeDialogWindow = new EventEmitter();

  private subscriptions = new Subscription();
  tempNameDetails: any;
  salutationOptions = environment.general.salutations;
  error_text = 'registration.validationMsgs.required';
  fieldsAreTouched = false;
  invalidStateGivenName$ = new BehaviorSubject(false);
  invalidStateFamilyName$ = new BehaviorSubject(false);
  invalidState$ = new BehaviorSubject(false);
  inProgress$ = new BehaviorSubject(false);
  size = CircularProgressDiameter.SMALL;
  color = ProgressColor.PRIMARY;
  isSaving = false;
  maxLengthGivenName = 50;
  maxLengthFamilyName = 80;

  optionsSalutation = [
    {
      text: 'label',
      key: 'key'
    }
  ];

  constructor(
    private apiService: ApiService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.tempNameDetails = this.data?.nameDetails
      ? this.getNameDetails(this.data.nameDetails)
      : {};

    this.optionsSalutation = this.getSalutationOptions();
  }

  getSalutationOptions() {
    var options: any[] = [];
    for (var s of this.salutationOptions) {
      var o = { text: this.getSalutation(s), key: s };
      options.push(o);
    }
    return options;
  }

  getSalutation(s: string) {
    var label = '';
    this.translateService
      .get('registration.formfield.select.' + s.toUpperCase())
      .subscribe((data: string) => (label = data));
    return label;
  }

  getNameDetails(input: UserInformationModel): UserBasicInformation {
    return {
      given_name: input.first_name,
      family_name: input.last_name,
      title: input.title ? input.title : '',
      salutation: input.salutation ? input.salutation : '',
      country: input.country,
      language: input.language
    };
  }

  updatedSalutation(newValue: DropdownOptionObject): void {
    this.tempNameDetails.salutation = newValue as unknown as string;
    this.updateSaveButtonStatus();
  }

  updatedTitle(newValue: any): void {
    this.fieldsAreTouched = true;
    this.tempNameDetails.title = newValue.trim();
    this.updateSaveButtonStatus();
  }

  updatedGivenName(newValue: any): void {
    this.fieldsAreTouched = true;
    this.tempNameDetails.given_name = newValue.target.value.trim();
    newValue.target.value = newValue.target.value.slice(
      0,
      this.maxLengthGivenName
    );

    this.updateSaveButtonStatus();
  }

  updatedFamilyName(newValue: any): void {
    this.fieldsAreTouched = true;
    this.tempNameDetails.family_name = newValue.target.value.trim();
    newValue.target.value = newValue.target.value.slice(
      0,
      this.maxLengthFamilyName
    );

    this.updateSaveButtonStatus();
  }

  updateSaveButtonStatus(): void {
    this.invalidStateGivenName$.next(
      /^\s*$/.test(this.tempNameDetails.given_name)
    );

    this.invalidStateFamilyName$.next(
      /^\s*$/.test(this.tempNameDetails.family_name)
    );

    this.invalidState$.next(
      /^\s*$/.test(this.tempNameDetails.given_name) ||
        /^\s*$/.test(this.tempNameDetails.family_name)
    );
  }
  onCancelClicked(): any {
    const passedObject = {
      action: 'cancel'
    };

    this.closeDialogWindow.emit(passedObject);
  }

  onSaveClicked(): any {
    this.isSaving = true;
    const passedObject = {
      context: this.data.context,
      action: 'save',
      refreshIsRequired: true
    };

    this.inProgress$.next(true);
    this.saveNewNameDetails(this.tempNameDetails, passedObject);
  }

  saveNewNameDetails(
    tempNameDetails: UserInformationModel | object,
    passedObject: ResponseDataFromDialog
  ): void {
    this.subscriptions.add(
      this.apiService
        .setUserProfile(tempNameDetails)
        .pipe(finalize(() => this.closeDialogWindow.emit(passedObject)))
        .subscribe({
          next: () => {
            this.translateService
              .get('personal_data.name.update.success')
              .subscribe(text =>
                this.displayPopup.emit({ text, className: '' })
              );
          },
          error: err => {
            this.translateService
              .get('personal_data.name.update.fail')
              .subscribe(text =>
                this.displayPopup.emit({ text, className: 'error' })
              );
          }
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
