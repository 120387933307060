import { Action, createReducer, on } from '@ngrx/store';
import { OrganisationDealersState, initialState } from './dealers.state';
import {
  dealerByNameSearchDataLoaded,
  dealerByNameSearchDataNotLoaded,
  dealerFiltersDataLoaded,
  getDealer,
  getDealerComplete,
  getDealerFailure,
  getDealerSuccess,
  loadDealerByNameSearchData,
  loadDealerFiltersData,
  loadNearestDealersSearchData,
  loadOrganisationDefaultDealer,
  nearestDealersSearchDataLoaded,
  nearestDealersSearchDataNotLoaded,
  organisationDefaultDealerLoaded
} from '../dealers';
import { removeLeadingZeros } from '../../utils/format-dealer-id';
import { HttpErrorResponse } from '@angular/common/http';

export const dealersReducer = createReducer(
  initialState,
  on(loadOrganisationDefaultDealer, state => ({
    ...state
  })),
  on(organisationDefaultDealerLoaded, (state, { defaultDealer }) => ({
    ...state,
    defaultDealer
  })),
  on(loadNearestDealersSearchData, state => ({
    ...state,
    nearestDealersSearchDataPending: true
  })),
  on(nearestDealersSearchDataLoaded, (state, { nearestDealersSearchData }) => ({
    ...state,
    nearestDealersSearchData,
    nearestDealersSearchDataPending: false
  })),
  on(
    nearestDealersSearchDataNotLoaded,
    (state, { nearestDealersSearchData, organisationError }) => ({
      ...state,
      nearestDealersSearchData,
      nearestDealersSearchDataPending: false
    })
  ),
  on(loadDealerByNameSearchData, state => ({
    ...state
  })),
  on(dealerByNameSearchDataLoaded, (state, { dealerByNameSearchData }) => ({
    ...state,
    dealerByNameSearchData
  })),
  on(dealerByNameSearchDataNotLoaded, state => ({
    ...state
  })),
  on(loadDealerFiltersData, state => ({
    ...state
  })),
  on(dealerFiltersDataLoaded, (state, { dealerFilters }) => ({
    ...state,
    dealerFilters
  })),
  on(getDealer, state => ({
    ...state
  })),
  on(getDealerSuccess, (state, { dealer }) => {
    const dealerIdWithoutLeadingZeros = removeLeadingZeros(dealer.dealerId);
    const dealers = {
      ...state.dealers,
      [dealerIdWithoutLeadingZeros]: dealer
    };

    return {
      ...state,
      dealers
    };
  }),
  on(getDealerFailure, (state, { error, dealerId }) => {
    const errorData = error as HttpErrorResponse;
    const dealers = {
      ...state.dealers
    };

    // if dealer was not found we add null intentionally so that subscribers
    // can react to not found dealer data
    if (errorData.status === 404) {
      const dealerIdWithoutLeadingZeros = removeLeadingZeros(dealerId);
      dealers[dealerIdWithoutLeadingZeros] = null;
    }

    return {
      ...state,
      error: error,
      dealers
    };
  }),
  on(getDealerComplete, state => ({
    ...state
  }))
);

export function reducer(
  state: OrganisationDealersState | undefined,
  action: Action
) {
  return dealersReducer(state, action);
}
