import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorService } from './shared/services/interceptor.service';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsComponent } from './terms/terms.component';
import { AboutComponent } from './about/about.component';
import { PersonalDataComponent } from './personal-data/personal-data.component';
import { UserSettingsComponent } from './user-settings/user-settings.component';
import { OrganisationsComponent } from './organisations/organisations.component';
import { OrganisationDataComponent } from './organisation-data/organisation-data.component';
import { AuthGuard } from './auth/auth.guard';
import { ApplicationsComponent } from './applications/applications.component';
import { PreferenceCenterComponent } from './preference-center/preference-center.component';
import { EmailViewComponent } from './shared/components/email-view/email-view.component';
import { UnavailableAppViewComponent } from './shared/components/unavailable-app-view/unavailable-app-view.component';
import { OrgSelectionComponent } from './org-selection/org-selection.component';
import { CompleteViewComponent } from './shared/components/complete-view/complete-view.component';
import { VerifiedComponent } from './verified/verified.component';
import { MarketingPermissionSubmitViewComponent } from './shared/components/marketing-permission-submit-view/marketing-permission-submit-view.component';

const routes: Routes = [
  {
    path: 'verified',
    component: VerifiedComponent
  },
  {
    path: 'complete',
    component: CompleteViewComponent
  },
  {
    path: 'info-page',
    component: UnavailableAppViewComponent
  },
  {
    path: 'info',
    component: EmailViewComponent
  },
  {
    path: 'privacy',
    component: PrivacyComponent
  },
  {
    path: 'terms',
    component: TermsComponent
  },
  {
    path: 'about',
    component: AboutComponent
  },
  {
    path: 'org-selection',
    component: OrgSelectionComponent
  },
  {
    path: 'personal-data',
    component: PersonalDataComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'user-settings',
    component: UserSettingsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'organisations',
    component: OrganisationsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'organisation-data',
    component: OrganisationDataComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'applications',
    component: ApplicationsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'preference-center',
    component: PreferenceCenterComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'submit',
    component: MarketingPermissionSubmitViewComponent
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'personal-data'
  },
  {
    path: 'overview',
    pathMatch: 'full',
    redirectTo: 'personal-data'
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    },
    AuthGuard
  ]
})
export class AppRoutingModule {}
