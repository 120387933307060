import { createAction, props } from '@ngrx/store';
import { StateError, UserOrganisations } from './organisations.state';
import {
  IAddressAutocompleteRequest,
  IAddressDetailsRequest,
  IAddressDetailsResponse,
  IRegisterOrganisationRequestWithMoreParams,
  IRegisterOrganisationResult,
  IRegistrationCurrentDefaultOrganisation,
  IRegistrationDialogStep
} from 'cde-fe-organization-registration-dialog/lib/models/organisation.model';
import { Organization } from '../../models/organization.model';
import { RegistrationDialogError } from '../global/global.state';
import { IAddressSearchResponse } from 'cde-fe-organization-registration-dialog';

export const setCurrentOrganisation = createAction(
  '[Organisation] Set Current Organisation',
  props<{ auth0Id: string }>()
);

export const loadUserOrganisations = createAction(
  '[Organisation] Load User Organisations',
  props<{ userId: string }>()
);

export const userOrganisationsLoaded = createAction(
  '[Organisation] User Organisations loaded',
  props<{ userOrganisations: UserOrganisations }>()
);

export const loadUserDefaultOrganisation = createAction(
  '[Organisation] Load User Default Organisation',
  props<{ userId: string }>()
);

export const userDefaultOrganisationLoaded = createAction(
  '[Organisation] User Default Organisation loaded',
  props<{ defaultOrganisationId: string | null }>()
);

export const setUserDefaultOrganisation = createAction(
  '[Organisation] Set User Default Organisation',
  props<{ userId: string; orgId: string }>()
);

export const loadOrganisationRegistrationSteps = createAction(
  '[Organisation] Load Organisation Registration Steps'
);

export const organisationRegistrationStepsLoaded = createAction(
  '[Organisation] Organisation Registration Steps loaded',
  props<{ registrationSteps: IRegistrationDialogStep[] }>()
);

export const organisationRegistrationStepsNotLoaded = createAction(
  '[Organisation] Organisation Registration Steps Not loaded',
  props<{ organisationError: StateError }>()
);

export const loadRegistrationDefaultOrganisation = createAction(
  '[Organisation] Load Registration Default Organisation',
  props<{ country: string }>()
);

export const registrationDefaultOrganisationLoaded = createAction(
  '[Organisation] Registration Default Organisation loaded',
  props<{
    registrationDefaultOrganisation: IRegistrationCurrentDefaultOrganisation;
  }>()
);

export const registrationDefaultOrganisationNotLoaded = createAction(
  '[Organisation] Registration Default Organisation Not loaded',
  props<{
    organisationError: StateError;
  }>()
);

export const registerOrganisation = createAction(
  '[Organisation] Register Organisation',
  props<IRegisterOrganisationRequestWithMoreParams>()
);

export const registeredOrganisationLoaded = createAction(
  '[Organisation] Registered Organisation loaded',
  props<{ registeredOrganisation: IRegisterOrganisationResult }>()
);

export const registeredOrganisationNotLoaded = createAction(
  '[Organisation] Registered Organisation Not loaded',
  props<{
    registrationError: RegistrationDialogError;
    organisationError: StateError;
  }>()
);

export const currentOrganisationLoaded = createAction(
  '[Organisation] Current Organisation loaded',
  props<{ currentOrganisation: Partial<Organization> | null }>()
);

export const loadAddressDetails = createAction(
  '[Organisation] Load Address Details',
  props<{ addressDetailsRequest: IAddressDetailsRequest }>()
);

export const addressDetailsLoaded = createAction(
  '[Organisation] Address Details loaded',
  props<{ addressDetails: IAddressDetailsResponse | null }>()
);

export const addressDetailsNotLoaded = createAction(
  '[Organisation] Address Details Not loaded',
  props<{ organisationError: any }>()
);

export const organisationMembersNotLoaded = createAction(
  '[Organisation] Organisation Members Not loaded',
  props<{
    organisationError: StateError;
  }>()
);

export const organisationDataNotLoaded = createAction(
  '[Organisation] Organisation Data Not loaded',
  props<{
    organisationError: StateError;
  }>()
);

export const searchAddress = createAction(
  '[Organisation] Search Address',
  props<{ request: IAddressAutocompleteRequest }>()
);

export const searchAddressSuccess = createAction(
  '[Organisation] Search Address Success',
  props<{ addressSearchResponse: IAddressSearchResponse }>()
);

export const searchAddressFailure = createAction(
  '[Organisation] Search Address Failure',
  props<{ error: StateError }>()
);

export const searchAddressComplete = createAction(
  '[Organisation] Search Address Complete'
);
