import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  ButtonClass,
  DropdownOptionObject,
  Icon,
  IconSize
} from '@claas/claas-form-components';
import {
  CircularProgressDiameter,
  ProgressColor
} from '@claas/claas-layout-components';
import { passedDataToDialog } from '../../dialog-window/dialog-window.component';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ApiService } from '../../../services/api.service';
import { OrganisationRole } from '../../../models/organisation-role.model';
import { OrganizationInvitation } from '../../../models/organisation-invitation.model';

@Component({
  selector: 'app-admin-invitation-view',
  templateUrl: './admin-invitation-view.component.html',
  styleUrl: './admin-invitation-view.component.scss'
})
export class AdminInvitationViewComponent implements OnInit, OnDestroy {
  @Input() data: passedDataToDialog = {};
  @Input() secondaryTextButtonClass?: ButtonClass;
  @Output() closeDialogWindow = new EventEmitter();
  @Output() displayPopup = new EventEmitter();
  organisationInvitation: OrganizationInvitation | undefined;
  orgName: string = '';
  auth0Id: string = '';
  largeSize: CircularProgressDiameter = CircularProgressDiameter.LARGE;
  mediumIconSize: IconSize = IconSize.MEDIUM;
  secondaryColor: ProgressColor = ProgressColor.SECONDARY;
  selectedRole: string = '';
  showSpinner$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  roles = [
    {
      text: 'label',
      key: 'key'
    }
  ];
  infoIcon: Icon = {
    iconId: '001111',
    iconStyle: 'bold',
    namespace: 'claas-id',
    size: 48
  };
  private subscriptions = new Subscription();

  constructor(
    private apiService: ApiService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    if (this.data.organisationInvitation?.auth0Id) {
      this.auth0Id = this.data.organisationInvitation.auth0Id;

      this.subscriptions.add(
        this.apiService.getOrganisationName(this.auth0Id).subscribe({
          next: (result: any) => {
            this.orgName = result.name;
          }
        })
      );
    }

    if (this.data.organisationInvitation) {
      this.organisationInvitation = this.data.organisationInvitation;
    }

    this.getRoles();
  }

  getRoles() {
    this.subscriptions.add(
      this.apiService.getRolesInOrganisation().subscribe({
        next: (result: any) => {
          const transformedRoles = result['content'].map(
            (role: OrganisationRole) => ({
              text: this.getRole(role.translationKey),
              key: role.role
            })
          );

          this.roles = transformedRoles.filter(
            (r: { text: string; key: string }) => r.key !== 'DEMO_ADMIN'
          );

          this.selectedRole = 'ADMIN';
        }
      })
    );
  }

  getRole(r: string) {
    let label = '';
    this.translateService.get(r).subscribe((data: string) => (label = data));
    return label;
  }

  onChangeRole(e: DropdownOptionObject) {
    this.selectedRole = e as unknown as string;
    console.log(this.selectedRole);
  }

  onClose(joinOrg: boolean): void {
    if (joinOrg) {
      // Create member in organization
      const payload = {
        idpUser: this.organisationInvitation!.userId,
        role: this.selectedRole
      };

      this.apiService
        .createOrganisationMember(payload, this.organisationInvitation!.auth0Id)
        .subscribe();
    }

    this.organisationInvitation!.status = joinOrg ? 'accepted' : 'denied';
    this.apiService
      .setOrganisationAdminInvitationStatus(
        this.organisationInvitation!,
        this.organisationInvitation!.auth0Id
      )
      //TODO: MAYBE IMPLEMENT AN ERROR STATE?
      .subscribe();

    const passedObject = {
      context: this.data.context,
      action: 'save'
    };

    this.closeDialogWindow.emit(passedObject);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
