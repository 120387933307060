<div class="organisation-timezone-view">
  <div class="organisation-timezone-view__header">
    <div class="organisation-timezone-view__header-main">
      <h2 class="organisation-timezone-view__header-title subtitle-2">
        {{ 'organization.edit_timezone.header' | translate }}
      </h2>
    </div>
  </div>

  <div class="organisation-timezone-view__body">
    <div class="organisation-timezone-view__body-content">
      <form *ngIf="myForm" [formGroup]="myForm">
        <div class="organisation-timezone-view__body-content--line">
          <claas-dropdown-simple
            ngDefaultControl
            formControlName="timezone"
            class="full-width"
            [label]="('registration.formfield.timeZone' | translate) + '*'"
            [options]="organisationTimezoneOptions"
            [error]="
              errorsStatusObject$['timezone'] | async | translate
            "></claas-dropdown-simple>
        </div>
      </form>
    </div>
  </div>

  <div class="organisation-timezone-view__dialog-actions">
    <div class="left-side"></div>
    <div class="right-side">
      <claas-button
        *ngIf="data && data.buttonCancel"
        class="btn-left"
        [buttonClass]="secondaryTextButtonClass"
        [text]="'generic.cancel' | translate"
        (onClick)="onCancelClicked()">
      </claas-button>

      <claas-button
        *ngIf="data && data.buttonSave"
        class="btn-right save-btn"
        [text]="'generic.save' | translate"
        [disabled]="!!(invalidSaveButtonState$ | async)"
        (onClick)="onSaveClicked()">
      </claas-button>
    </div>
  </div>
</div>
