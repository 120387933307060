import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonClass } from '@claas/claas-form-components';
import { passedDataToDialog } from '../dialog-window/dialog-window.component';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {
  requestInProgress$ = new BehaviorSubject<boolean>(false);

  @Input() data: passedDataToDialog = {};
  @Input() errorButtonClass?: ButtonClass;
  @Input() secondaryTextButtonClass?: ButtonClass;

  @Output() closeDialogWindow = new EventEmitter();

  deletionIsCanceled(): void {
    const passedObject = {
      action: 'cancel',
      context: 'organisation-deletion'
    };

    this.closeDialogWindow.emit(passedObject);
  }

  deletionIsConfirmed(): void {
    this.requestInProgress$.next(true);

    const passedObject = {
      action: 'delete',
      context: 'organisation-deletion'
    };

    this.closeDialogWindow.emit(passedObject);
  }
}
