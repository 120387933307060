export class PhoneNumber {
  type: string;
  country_prefix?: string;
  country: string;
  phone_number?: string;
  phone_number_value?: string;

  constructor() {
    this.type = '';
    this.country = '';
    this.phone_number_value = '';
  }

  getNumber() {
    return {
      type: this.type,
      country_prefix: this.country_prefix,
      country: this.country,
      phone_number: this.phone_number,
      phone_number_value: this.phone_number_value
    };
  }
}
