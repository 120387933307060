import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  currentOrganisationLoaded,
  loadRegistrationDefaultOrganisation,
  registrationDefaultOrganisationLoaded,
  setCurrentOrganisation
} from '../organisations';
import { catchError, map } from 'rxjs/operators';
import {
  addressAutocompleteSuggestionsDataLoaded,
  addressAutocompleteSuggestionsDataNotLoaded,
  loadAddressAutocompleteSuggestionsData,
  loadRegistrationDealerData,
  loadRegistrationPersonalData,
  loadRegistrationShopData,
  registrationDataPendingUpdated,
  registrationDealerDataLoaded,
  registrationDealerDataNotLoaded,
  registrationPersonalDataLoaded,
  registrationPersonalDataNotLoaded,
  registrationPersonalDataNotUpdated,
  registrationPersonalDataPendingUpdated,
  registrationPersonalDataUpdated,
  registrationShopDataLoaded,
  registrationShopDataNotLoaded,
  updateRegistrationPersonalData
} from './global.actions';
import { mergeMap, of } from 'rxjs';
import { ApiService } from '../../services/api.service';
import { Store } from '@ngrx/store';

@Injectable()
export class GlobalEffects {
  constructor(
    private store: Store,
    private actions$: Actions,
    private apiService: ApiService
  ) {}

  setRegistrationDataPendingTrueFromOrganisation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadRegistrationDefaultOrganisation, setCurrentOrganisation),
      map(() =>
        registrationDataPendingUpdated({ registrationDataPending: true })
      )
    );
  });

  setRegistrationDataPendingFalseFromOrganisation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(registrationDefaultOrganisationLoaded, currentOrganisationLoaded),
      map(() =>
        registrationDataPendingUpdated({ registrationDataPending: false })
      )
    );
  });

  loadRegistrationPersonalData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadRegistrationPersonalData),
      mergeMap((action: any) => {
        this.store.dispatch(
          registrationPersonalDataPendingUpdated({
            registrationPersonalDataPending: true
          })
        );

        return this.apiService
          .getRegistrationPersonalData(action.country, action.language)
          .pipe(
            map(result =>
              registrationPersonalDataLoaded({
                registrationPersonalData: result
              })
            ),
            catchError(error =>
              of(
                registrationPersonalDataNotLoaded({
                  organisationError: error.payload
                })
              )
            )
          );
      })
    );
  });

  setRegistrationPersonalData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(),
      map((action: any) =>
        registrationPersonalDataPendingUpdated({
          registrationPersonalDataPending:
            action.registrationPersonalDataPending
        })
      )
    );
  });

  loadRegistrationDealerData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadRegistrationDealerData),
      mergeMap((action: any) => {
        return this.apiService.getRegistrationDealerData(action.language).pipe(
          map(result =>
            registrationDealerDataLoaded({ registrationDealerData: result })
          ),
          catchError(error =>
            of(
              registrationDealerDataNotLoaded({
                registrationDealerData: 'NOT_FOUND',
                organisationError: error.payload
              })
            )
          )
        );
      })
    );
  });

  loadAddressAutocompleteSuggestionsData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadAddressAutocompleteSuggestionsData),
      mergeMap((action: any) => {
        return this.apiService
          .getAddressAutocomplete(action.addressDetailsRequest)
          .pipe(
            map((result: any) => {
              return addressAutocompleteSuggestionsDataLoaded({
                addressAutocompleteSuggestions: result.content
              });
            }),
            catchError(error =>
              of(
                addressAutocompleteSuggestionsDataNotLoaded({
                  organisationError: error.payload
                })
              )
            )
          );
      })
    );
  });

  loadRegistrationShopData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadRegistrationShopData),
      mergeMap((action: any) => {
        return this.apiService
          .getRegistrationShopData(action.country, 'newOrg')
          .pipe(
            map((result: any) => {
              return registrationShopDataLoaded({
                registrationShopData: result
              });
            }),
            catchError(err =>
              of(
                registrationShopDataNotLoaded({
                  shopDataLoadError: err.payload
                })
              )
            )
          );
      })
    );
  });

  updateRegistrationPersonalData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateRegistrationPersonalData),
      mergeMap((action: any) => {
        return this.apiService
          .updateRegistrationPersonalData(action.request, action.language)
          .pipe(
            map((result: any) => {
              return registrationPersonalDataUpdated();
            }),
            catchError(error =>
              of(
                registrationPersonalDataNotUpdated({
                  organisationError: error.payload
                })
              )
            )
          );
      })
    );
  });
}
