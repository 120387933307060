import { Component, EventEmitter, Input, Output } from '@angular/core';
import { passedDataToDialog } from '../dialog-window/dialog-window.component';
import { ButtonClass } from '@claas/claas-form-components';

@Component({
  selector: 'app-two-factor-view',
  templateUrl: './two-factor-view.component.html',
  styleUrls: ['./two-factor-view.component.scss']
})
export class TwoFactorViewComponent {
  @Input() data: passedDataToDialog | undefined;
  @Input() secondaryTextButtonClass?: ButtonClass;

  @Output() closeDialogWindow = new EventEmitter();
  @Output() displayPopup = new EventEmitter();

  onSaveClicked(): void {
    this.closeDialogWindow.emit();
  }
}
