<div>
  <mat-horizontal-stepper #stepper [linear]="true">
    <mat-step label="{{ 'overview.marketing.preferences' | translate }}">
      <h1 class="subtitle-2 mp-update__title">
        {{ 'overview.marketing.confirm' | translate }}
      </h1>
      <div class="mp-update__content-wrapper">
        <p class="mp-update__text">
          {{ 'overview.marketing.confirm_info' | translate }}
        </p>

        <div
          *ngFor="let inv of this.data.marketingInvitation"
          class="mp-update__checkbox-wrapper mp-update__text-label">
          <!--CLAAS ONLY TEXTS-->
          <div *ngIf="inv.type === 'claas'">
            <p>
              {{ 'overview.marketing.claas_group_claas' | translate }}
            </p>
            <p>
              {{
                'overview.central_marketing_permission.consent.acceptance.claas_only'
                  | translate
              }}
            </p>
          </div>
          <!--DEALER ONLY TEXTS-->
          <div *ngIf="inv.type === 'dealer'">
            <p class="mp-update__text">
              {{ 'overview.marketing.dealers' | translate }}
            </p>
            <p>
              {{
                'overview.central_marketing_permission.consent.acceptance.dealer_only'
                  | translate
              }}
            </p>
          </div>
          <!--EMAIL-->
          <div>
            <mat-checkbox
              [(ngModel)]="inv.channels.email"
              labelPosition="after"
              name="mpEmail">
              <span class="mp-update__text">
                {{
                  'overview.central_marketing_permission.channels.email'
                    | translate
                }}
              </span>
            </mat-checkbox>
            <div *ngIf="inv.channels.email">
              <span class="mp-update__text-sub">
                {{ 'overview.marketing.used_email' | translate }}:
                {{ this.profileEmail }}
              </span>
            </div>
          </div>
          <!--PHONE-->
          <div>
            <mat-checkbox
              [(ngModel)]="inv.channels.phone"
              labelPosition="after"
              name="mpPhone">
              <span class="mp-update__text">
                {{
                  'overview.central_marketing_permission.channels.phone'
                    | translate
                }}
              </span>
            </mat-checkbox>
          </div>
          <!--PUSH-->
          <div>
            <mat-checkbox
              [(ngModel)]="inv.channels.push"
              labelPosition="after"
              name="mpPush">
              <span class="mp-update__text">
                {{
                  'overview.central_marketing_permission.channels.push'
                    | translate
                }}
              </span>
            </mat-checkbox>
          </div>
          <!--POST-->
          <div>
            <mat-checkbox
              [(ngModel)]="inv.channels.post"
              labelPosition="after"
              name="mpPost">
              <span class="mp-update__text">
                {{
                  'overview.central_marketing_permission.channels.post'
                    | translate
                }}
              </span>
            </mat-checkbox>
          </div>
          <!--SUBTEXTS-->
          <div class="mp-update__content-wrapper">
            <!--DEALER ONLY-->
            <p *ngIf="inv.type === 'dealer'">
              {{
                'overview.central_marketing_permission.consent.subtext.claas_and_dealer'
                  | translate
              }}
            </p>
            <!--CLAAS ONLY-->
            <p *ngIf="inv.type === 'claas'">
              {{
                'overview.central_marketing_permission.consent.subtext.claas_only'
                  | translate
              }}
            </p>
            <!--GENERAL SUBTEXT 2-->
            <p
              [innerHTML]="
                'overview.central_marketing_permission.consent.subtext_2'
                  | translate
              "></p>

            <!--FOOTNOTE CLAAS-->
            <div *ngIf="inv.type === 'claas'">
              <span class="mp-update__footnote">
                <sup>1</sup>{{ footnote$ | async }}
              </span>
            </div>
            <!--FOOTNOTE DEALER-->
            <div *ngIf="inv.type === 'dealer'">
              <span class="mp-update__footnote"
                ><sup>1</sup
                >{{
                  'overview.central_marketing_permission.consent.footnote.dealer'
                    | translate: { dealer_address: dealerAddress }
                }}</span
              >
            </div>
          </div>
        </div>
        <claas-divider />

        <p *ngIf="inputError" class="mp-update__error">
          {{ 'overview.marketing.invalid_data' | translate }}
        </p>
      </div>

      <!--DIALOG ACTIONS-->
      <div class="mp-update__dialog-actions">
        <div class="left-side">
          <div class="btn-left">
            <div *ngIf="showSpinner$ | async" class="flex flex-end w-full">
              <span class="flex">
                <claas-circular-progress
                  [color]="secondaryColor"
                  [diameter]="largeSize">
                </claas-circular-progress>
              </span>
            </div>
          </div>
        </div>
        <div class="right-side">
          <claas-button
            (onClick)="next(this.data.marketingInvitation, stepper, true)"
            *ngIf="data?.buttonSave"
            [buttonClass]="nextButtonClass"
            [disabled]="(showSpinner$ | async) === true"
            [text]="'generic.accept' | translate"
            class="btn-right" />
        </div>
      </div>
    </mat-step>
    <mat-step label="{{ 'overview.marketing.feedback' | translate }}">
      <span *ngIf="this.accepted">{{
        'overview.central_marketing_permission.submit_page.consent_updated'
          | translate
      }}</span>
      <span *ngIf="!this.accepted">{{
        'overview.central_marketing_permission.submit_page.consent_unchanged'
          | translate
      }}</span>
      <div class="mp-update__dialog-actions">
        <div class="left-side">
          <div class="btn-left"></div>
        </div>
        <div class="right-side">
          <claas-button
            (onClick)="close()"
            *ngIf="data?.buttonSave"
            [buttonClass]="nextButtonClass"
            [disabled]="(showSpinner$ | async) === true"
            [text]="'generic.close' | translate"
            class="btn-right" />
        </div>
      </div> </mat-step
  ></mat-horizontal-stepper>
</div>
