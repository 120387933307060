<div [ngSwitch]="type">
  <ng-container *ngSwitchCase="'primary'">
    <button class="primary" mat-button color="primary" [disabled]="disabled">
      {{ label }}
    </button>
  </ng-container>

  <ng-container *ngSwitchCase="'accent'">
    <button class="accent" mat-button color="accent" [disabled]="disabled">
      {{ label }}
    </button>
  </ng-container>

  <ng-container *ngSwitchCase="'warn'">
    <button class="warn" mat-button color="warn" [disabled]="disabled">
      {{ label }}
    </button>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <button class="primary" mat-button color="primary" [disabled]="disabled">
      {{ label ? label : 'Primary' }}
    </button>
  </ng-container>
</div>
