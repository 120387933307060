<div class="organisation-connected-dealers-view">
  <div class="organisation-connected-dealers-view__header">
    <h2 class="organisation-connected-dealers-view__header-title subtitle-2">
      {{ 'organization.edit_connected.header' | translate }}
    </h2>
  </div>

  <div class="organisation-connected-dealers-view__body">
    <div class="organisation-connected-dealers-view__body-content">
      <div *ngIf="(confirmationDialogIsActive$ | async) === false">
        <span class="organisation-connected-dealers-view__body-content--text">{{
          'organization.edit_connected.info' | translate
        }}</span>

        <div class="organisation-connected-dealers-view__body-content--listing">
          <div
            *ngIf="data.connectedDealers && data.connectedDealers.length"
            class="organisation-connected-dealers-view__others-content">
            <div
              *ngFor="let element of data.connectedDealers; index as i"
              class="element-info">
              <div class="element-info-top">
                <div class="logo-container">
                  <span class="logo-icon"></span>
                </div>
              </div>

              <div class="element-info-sub">
                <div class="element-info-sub--checkbox">
                  <claas-checkbox
                    [checked]="true"
                    (onChange)="
                      selectionHasChanged($event, i)
                    "></claas-checkbox>
                </div>

                <div class="element-info-sub--left">
                  <div class="logo-container">
                    <span class="logo-icon"></span>
                  </div>
                </div>

                <div class="element-info-sub--right">
                  <div class="element-info--right_title">
                    <h3 class="header-3-bold">{{ element.name }}</h3>
                  </div>

                  <div class="element-info--right_content">
                    <span
                      >{{ element.street }}, {{ element.postalCode }}
                      {{ element.state }}
                      {{ element.country | translate }}</span
                    ><br />
                    <span>{{ 'organization.dealer_phone' | translate }} {{ element.phone }}</span
                    ><br />
                    <span>{{ element.email }}</span
                    ><br />
                    <span>{{ element.url }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            *ngIf="data.connectedDealers && !data.connectedDealers.length"
            class="organisation-connected-dealers-view__others-content emtpy">
            <span class="no-connected-dealers">{{
              'organization.no_connected_dealers' | translate
            }}</span>
          </div>
        </div>
      </div>

      <div *ngIf="confirmationDialogIsActive$ | async">
        <span
          class="organisation-connected-dealers-view__confirmation-dialog--text"
          >{{ 'generic.delete_text' | translate }}</span
        >
      </div>
    </div>
  </div>

  <div class="organisation-connected-dealers-view__dialog-actions">
    <div
      *ngIf="(confirmationDialogIsActive$ | async) === false"
      class="connected-dealers-selection">
      <claas-button
        class="btn-right save-btn"
        [text]="'generic.confirm' | translate"
        [disabled]="!saveIsPossible"
        (onClick)="saveSelection()">
      </claas-button>

      <claas-button
        class="btn-left"
        [buttonClass]="secondaryTextButtonClass"
        [text]="'generic.cancel' | translate"
        (onClick)="cancelSelection()">
      </claas-button>
    </div>
  </div>
</div>
