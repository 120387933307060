export class MarketingUpdatePayload {
  email: boolean;
  email_string: string;
  phone: boolean;
  phone_number: {
    type: string;
    number: string;
    country_prefix: string;
  };
  push: boolean;
  post: boolean;
  address: {
    street: string;
    house_number: string;
    postal_code: string;
    city: string;
    state: string;
    suburb: string;
    country: string;
  };

  constructor(data: any = {}) {
    this.email = data.email || false;
    this.email_string = data.email_string || '';
    this.phone = data.phone || false;
    this.phone_number = {
      type: data.phone_number?.type || '',
      number: data.phone_number?.number || '',
      country_prefix: data.phone_number?.country_prefix || ''
    };
    this.push = data.push || false;
    this.post = data.post || false;
    this.address = {
      street: data.address?.street || '',
      house_number: data.address?.house_number || '',
      postal_code: data.address?.postal_code || '',
      city: data.address?.city || '',
      state: data.address?.state || '',
      suburb: data.address?.suburb || '',
      country: data.address?.country || ''
    };
  }
}
