import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { AuthService as LocalAuthService } from '../../services/auth.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  tap
} from 'rxjs/operators';
import * as UserActions from './user.actions';
import { combineLatest, of } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '../../models/auth.model';
import { Store } from '@ngrx/store';
import * as allOrganisationActions from '../organisations/organisations.actions';
import { environment } from '../../../../environments/environment';
@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions<any>,
    private authService: AuthService,
    private router: Router,
    private store: Store,
    private activeRoute: ActivatedRoute,
    private localAuthService: LocalAuthService
  ) {}

  userChanged$ = createEffect(() => {
    return this.authService.user$.pipe(
      filter(userDetails => userDetails !== undefined && userDetails !== null),
      map(userDetails => new User(userDetails)),
      map(userDetails => {
        // Set current organisation to org of token
        if (userDetails['org_id'])
          this.store.dispatch(
            allOrganisationActions.setCurrentOrganisation({
              auth0Id: userDetails['org_id']
            })
          );
        return UserActions.userChangedFromAuth0SDK({
          userDetails: userDetails
        });
      })
    );
  });
  login$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(UserActions.allAuthActions.loginFlowInitiated.type),
        tap(() => this.authService.loginWithRedirect())
      );
    },
    { dispatch: false }
  );

  logout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.allAuthActions.logoutFlowInitiated.type),
      tap(() => this.authService.logout()),

      switchMap(() => of(UserActions.allAuthActions.logoutComplete()))
    );
  });

  checkLogin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.allAuthActions.checkLogin.type),
      switchMap(() =>
        combineLatest([
          this.authService.isAuthenticated$,
          this.authService.user$
        ])
      ),
      switchMap(([isLoggedIn, profile]) => {
        if (isLoggedIn) {
          return of(
            UserActions.allAuthActions.loginComplete({ profile, isLoggedIn })
          );
        }

        return of(UserActions.allAuthActions.logoutComplete());
      })
    );
  });

  logoutComplete$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(UserActions.allAuthActions.logoutComplete.type),
        //tap(() => this.router.navigate(["/"])),
        tap(() => {
          // Skip redirect for unauthed routes or if invitation & organisation parameter are set
          const urlParams = new URLSearchParams(window.location.search);
          const invitationParam = urlParams.get('invitation');
          if (
            !environment.auth.unauthed_routes.includes(
              window.location.pathname
            ) &&
            !invitationParam
          ) {
            this.router.navigate(['/']);
          }
        })
      );
    },
    { dispatch: false }
  );

  setOrganisation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.allAuthActions.setOrganisation.type),
      tap(() => console.log('get access token in setOrganisation')),
      mergeMap(orgId =>
        this.authService
          .getAccessTokenSilently({
            cacheMode: 'off',
            authorizationParams: {
              audience: environment.base_urls.user_api_base_url,
              organisation: orgId.orgId
            }
          })
          .pipe(
            map(token =>
              this.store.dispatch(
                UserActions.allAuthActions.setOrganisationSuccess({ token })
              )
            ),
            catchError(error =>
              of(UserActions.allAuthActions.setOrganisationFailure(error))
            )
          )
      )
    );
  });

  loadUserAvatarUrl$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.allAuthActions.loadUserAvatarUrl),
      switchMap(() =>
        this.localAuthService.getUserNoCache$().pipe(
          map(profile => {
            const profileJson = JSON.parse(JSON.stringify(profile, null, 2));

            if (profileJson['picture']) {
              const avatarUrl =
                profileJson.picture + '?' + new Date().getTime();

              return UserActions.allAuthActions.userAvatarUrlLoaded({
                url: avatarUrl
              });
            } else {
              return UserActions.allAuthActions.userAvatarUrlLoaded({
                url: null
              });
            }
          })
        )
      )
    );
  });
}
