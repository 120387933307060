import * as i0 from '@angular/core';
import { InjectionToken, EventEmitter, Injectable, Pipe, Inject, NgModule } from '@angular/core';
import { com } from 'cde-unit-converter';
import { getLocaleByAlpha2 } from 'country-locale-map';
import moment from 'moment';
import * as i2 from '@ngx-translate/core';
import moment$1 from 'moment-timezone';
import 'moment/min/locales';
var UnitSystem;
(function (UnitSystem) {
  UnitSystem["SI"] = "SI";
  UnitSystem["EU28"] = "EU28";
  UnitSystem["UK"] = "UK";
  UnitSystem["US"] = "US";
  UnitSystem["METRIC"] = "METRIC";
  UnitSystem["IMPERIAL"] = "IMPERIAL";
  UnitSystem["CUSTOMARY"] = "CUSTOMARY";
})(UnitSystem || (UnitSystem = {}));
var MeasurementsScale;
(function (MeasurementsScale) {
  MeasurementsScale["DEFAULT"] = "DEFAULT";
  MeasurementsScale["SMALL"] = "SMALL";
})(MeasurementsScale || (MeasurementsScale = {}));
const TRANSLATE_SERVICE = new InjectionToken('TranslateService');
class ConvertPipeStore {
  constructor() {
    this.unitSystem = UnitSystem.METRIC;
    this.country = 'de';
    this.language = 'de';
    this.timeZone = 'Europe/Berlin';
    this.onUnitSystemChange = new EventEmitter();
    this.onCountryChange = new EventEmitter();
    this.onLanguageChange = new EventEmitter();
    this.onTimeZoneChange = new EventEmitter();
  }
}
class ConvertPipeService {
  get onUnitSystemChange() {
    return this.store.onUnitSystemChange;
  }
  get onCountryChange() {
    return this.store.onCountryChange;
  }
  get onLanguageChange() {
    return this.store.onLanguageChange;
  }
  get onTimeZoneChange() {
    return this.store.onTimeZoneChange;
  }
  get unitSystem() {
    return this.store.unitSystem;
  }
  set unitSystem(unitSystem) {
    this.store.unitSystem = unitSystem;
  }
  get country() {
    return this.store.country;
  }
  set country(country) {
    this.store.country = country;
  }
  get language() {
    return this.store.language;
  }
  set language(language) {
    this.store.language = language;
  }
  get timeZone() {
    return this.store.timeZone;
  }
  set timeZone(timeZone) {
    this.store.timeZone = timeZone;
  }
  constructor(store) {
    this.store = store;
  }
  setUnitSystem(unitSystem) {
    if (unitSystem === this.unitSystem) return;
    this.unitSystem = unitSystem;
    this.onUnitSystemChange.emit(unitSystem);
  }
  setCountry(country) {
    if (country === this.country) return;
    const locale = getLocaleByAlpha2(country.toUpperCase());
    moment.locale(locale);
    this.country = country;
    this.onCountryChange.emit(country);
  }
  setLanguage(language) {
    if (language === this.language) return;
    this.language = language;
    this.onLanguageChange.emit(language);
  }
  setTimeZone(timeZone) {
    if (timeZone === this.timeZone) return;
    this.timeZone = timeZone;
    this.onTimeZoneChange.emit(timeZone);
  }
  static {
    this.ɵfac = function ConvertPipeService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ConvertPipeService)(i0.ɵɵinject(ConvertPipeStore));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ConvertPipeService,
      factory: ConvertPipeService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ConvertPipeService, [{
    type: Injectable
  }], () => [{
    type: ConvertPipeStore
  }], null);
})();
const convert = com.claas.cde.unitsandmeasurement.essentials.CDEUnits.convert;
class ConvertPipe {
  constructor(translator, convertPipeService) {
    this.translator = translator;
    this.convertPipeService = convertPipeService;
    this.converted = '';
  }
  transform(value, fromUnit, options) {
    if (isNaN(Number(value))) {
      return this.localizationValueCallback(value.toString(), options?.measurementKey);
    }
    if (typeof value !== 'number' || !fromUnit) {
      return '--';
    }
    this.convert(value, fromUnit, options);
    this._dispose();
    if (!this.onUnitSystemChange) {
      this.onUnitSystemChange = this.convertPipeService.onUnitSystemChange.subscribe(event => {
        if (event === this.convertPipeService.unitSystem) {
          this.convert(value, fromUnit, options);
        }
      });
    }
    if (!this.onCountryChange) {
      this.onCountryChange = this.convertPipeService.onCountryChange.subscribe(event => {
        if (event === this.convertPipeService.country) {
          this.convert(value, fromUnit, options);
        }
      });
    }
    if (!this.onLanguageChange) {
      this.onLanguageChange = this.convertPipeService.onLanguageChange.subscribe(event => {
        if (event === this.convertPipeService.language) {
          this.convert(value, fromUnit, options);
        }
      });
    }
    return this.converted;
  }
  convert(value, fromUnit, options) {
    let convertedMeasurement = com.claas.cde.unitsandmeasurement.essentials.CDEUnits.convert(value, fromUnit, this.convertPipeService.unitSystem, options?.unitsScale);
    this.converted = convertedMeasurement.formatted(this.convertPipeService.country, options?.maxFractionDigits, options?.withMinutes, options?.withSeconds, this.localizationUnitCallback.bind(this));
  }
  localizationValueCallback(value, measurementKey) {
    const resource = measurementKey ? `measurement.${measurementKey}.schema_values` : 'measurements.default_schema_values';
    const translatedValue = this.translator.instant(`${resource}.${value.toLowerCase()}`);
    return translatedValue === `${resource}.${value.toLowerCase()}` ? value : translatedValue;
  }
  localizationUnitCallback(value) {
    const translatedUnit = this.translator.instant(`unit.${value.toLowerCase()}`);
    return translatedUnit === `unit.${value.toLowerCase()}` ? value : translatedUnit;
  }
  /**
   * Clean any existing subscription to change events
   */
  _dispose() {
    if (typeof this.onUnitSystemChange !== 'undefined') {
      this.onUnitSystemChange.unsubscribe();
      this.onUnitSystemChange = undefined;
    }
    if (typeof this.onCountryChange !== 'undefined') {
      this.onCountryChange.unsubscribe();
      this.onCountryChange = undefined;
    }
    if (typeof this.onLanguageChange !== 'undefined') {
      this.onLanguageChange.unsubscribe();
      this.onLanguageChange = undefined;
    }
  }
  ngOnDestroy() {
    this._dispose();
  }
  static {
    this.ɵfac = function ConvertPipe_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ConvertPipe)(i0.ɵɵdirectiveInject(TRANSLATE_SERVICE, 16), i0.ɵɵdirectiveInject(ConvertPipeService, 16));
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "convert",
      type: ConvertPipe,
      pure: false
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ConvertPipe, [{
    type: Pipe,
    args: [{
      name: 'convert',
      pure: false
    }]
  }], () => [{
    type: i2.TranslateService,
    decorators: [{
      type: Inject,
      args: [TRANSLATE_SERVICE]
    }]
  }, {
    type: ConvertPipeService
  }], null);
})();
class DateTimePipe {
  constructor(convertPipeService) {
    this.convertPipeService = convertPipeService;
    this.converted = '';
  }
  transform(value, showTime) {
    if (!value) return this.converted;
    this.convert(value, showTime);
    this._dispose();
    if (!this.onCountryChange) {
      this.onCountryChange = this.convertPipeService.onCountryChange.subscribe(event => {
        if (event === this.convertPipeService.country) {
          this.convert(value, showTime);
        }
      });
    }
    if (!this.onTimeZoneChange) {
      this.onTimeZoneChange = this.convertPipeService.onTimeZoneChange.subscribe(event => {
        if (event === this.convertPipeService.timeZone) {
          this.convert(value, showTime);
        }
      });
    }
    return this.converted;
  }
  convert(value, showTime) {
    const locale = getLocaleByAlpha2(this.convertPipeService.country.toUpperCase());
    moment$1.locale(locale);
    this.converted = showTime ? moment$1.tz(value, this.convertPipeService.timeZone).format('L LT').toString() : moment$1.tz(value, this.convertPipeService.timeZone).format('L').toString();
  }
  _dispose() {
    if (typeof this.onCountryChange !== 'undefined') {
      this.onCountryChange.unsubscribe();
      this.onCountryChange = undefined;
    }
    if (typeof this.onTimeZoneChange !== 'undefined') {
      this.onTimeZoneChange.unsubscribe();
      this.onTimeZoneChange = undefined;
    }
  }
  ngOnDestroy() {
    this._dispose();
  }
  static {
    this.ɵfac = function DateTimePipe_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DateTimePipe)(i0.ɵɵdirectiveInject(ConvertPipeService, 16));
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "dateTime",
      type: DateTimePipe,
      pure: false
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DateTimePipe, [{
    type: Pipe,
    args: [{
      name: 'dateTime',
      pure: false
    }]
  }], () => [{
    type: ConvertPipeService
  }], null);
})();
class CdeFeConvertPipeModule {
  static forRoot(config = {}) {
    return {
      ngModule: CdeFeConvertPipeModule,
      providers: [ConvertPipeStore, ConvertPipeService]
    };
  }
  static forChild(config = {}) {
    return {
      ngModule: CdeFeConvertPipeModule,
      providers: [ConvertPipeService]
    };
  }
  static {
    this.ɵfac = function CdeFeConvertPipeModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CdeFeConvertPipeModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CdeFeConvertPipeModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [ConvertPipeService]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CdeFeConvertPipeModule, [{
    type: NgModule,
    args: [{
      declarations: [ConvertPipe, DateTimePipe],
      imports: [],
      exports: [ConvertPipe, DateTimePipe],
      providers: [ConvertPipeService]
    }]
  }], null, null);
})();

/*
 * Public API Surface of cde-fe-convert-pipe
 */

/**
 * Generated bundle index. Do not edit.
 */

export { CdeFeConvertPipeModule, ConvertPipe, ConvertPipeService, ConvertPipeStore, DateTimePipe, MeasurementsScale, TRANSLATE_SERVICE, UnitSystem };
