import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output
} from '@angular/core';
import {
  ResponseDataFromDialog,
  passedDataToDialog
} from '../dialog-window/dialog-window.component';
import { ButtonClass } from '@claas/claas-form-components';
import { BehaviorSubject, Subscription, finalize } from 'rxjs';
import { ApiService } from '../../services/api.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-application-dialog',
  templateUrl: './application-dialog.component.html',
  styleUrl: './application-dialog.component.scss'
})
export class ApplicationDialogComponent implements OnDestroy {
  @Input() data: passedDataToDialog = {};
  @Input() errorButtonClass?: ButtonClass;
  @Input() primaryTextButtonClass?: ButtonClass;
  @Input() secondaryTextButtonClass?: ButtonClass;

  @Output() displayPopup = new EventEmitter();
  @Output() closeDialogWindow = new EventEmitter();

  private subscriptions = new Subscription();

  checkBoxIsChecked$ = new BehaviorSubject(false);
  actionInProgress$ = new BehaviorSubject(false);

  constructor(
    private apiService: ApiService,
    private translateService: TranslateService
  ) {}

  disconnectApplication(): void {
    const passedObject: ResponseDataFromDialog = {
      action: 'delete'
    };

    if (this.data.applicationId) {
      this.subscriptions.add(
        this.apiService
          .revokeConsent(this.data.applicationId)
          .pipe(finalize(() => this.closeDialogWindow.emit(passedObject)))
          .subscribe({
            next: () => {
              this.subscriptions.add(
                this.translateService
                  .get('connected_apps.disconnect.success')
                  .subscribe((text: string) => {
                    // Revoke consent for the application
                    this.displayPopup.emit({ text, className: '' });
                  })
              );
            },
            error: err => {
              this.translateService
              .get('connected_apps.disconnect.fail')
              .subscribe((text: string) => {
                this.displayPopup.emit({ text, className: 'error' });
              });
            }
          })
      );
    }
  }

  onCancelClicked(): void {
    const passedObject = {
      action: 'cancel'
    };

    this.closeDialogWindow.emit(passedObject);
  }

  connectApplication(): void {
    const passedObject: ResponseDataFromDialog = {
      action: 'save'
    };

    if (this.data.applicationId) {
      this.subscriptions.add(
        this.apiService
          .grantConsent(this.data.applicationId)
          .pipe(finalize(() => this.closeDialogWindow.emit(passedObject)))
          .subscribe({
            next: () => {
              this.subscriptions.add(
                this.translateService
                  .get('connected_apps.connect.success')
                  .subscribe((text: string) => {
                    // Grant consent for the application
                    this.displayPopup.emit({ text, className: '' });
                  })
              );
            },
            error: err => {
              this.translateService
              .get('connected_apps.connect.fail')
              .subscribe((text: string) => {
                this.displayPopup.emit({ text, className: 'error' });
              });
            }
          })
      );
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
