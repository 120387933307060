<div class="organisations">
  <div>
    <section class="organisations__header">
      <h1 class="organisations__header-title">
        {{ 'organization.header' | translate }}
      </h1>

      <claas-button
        *ngIf="organisations$ | async"
        class="add-btn"
        [icon]="addIcon"
        [text]="'organization.add' | translate"
        [buttonClass]="primaryButtonClass"
        [size]="mediumButtonSize"
        [disabled]="!registrationSteps || !registrationSteps.length"
        (click)="openRegistrationDialog()"></claas-button>
    </section>

    <div *ngIf="(isLoadingOrganisations$ | async) === false">
      <div *ngIf="organisations$ | async as organisations; else NoOrganisation">
        <section class="organisations__content">
          <section class="organisations__content-list">
            <div
              *ngFor="let organisation of organisations"
              class="organisations__content-list--item"
              (click)="openDetailsPage(organisation)">
              <div class="item-container">
                <h3 class="item-header header-2-light">
                  {{ organisation?.name }}
                </h3>

                <div class="item-data">
                  <span class="label label-1-style">{{
                    'organization.fields.customer_number' | translate
                  }}</span>

                  <div class="content">
                    <div class="content-line">
                      <div class="organisations-admin">
                        <p>{{ organisation?.debitorId }}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div *ngIf="organisation?.myRole != null" class="item-data">
                  <span class="label label-1-style">{{
                    'organization.fields.my_role' | translate
                  }}</span>
                  <div class="content">
                    <div class="content-line">
                      <div class="organisations-role">
                        <p>{{ getRole(organisation?.myRole) | translate }}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="item-data address">
                  <span class="label label-1-style">{{
                    'organization.fields.address' | translate
                  }}</span>
                  <div class="content">
                    <div
                      *ngIf="
                        organisation?.addresses &&
                        organisation?.addresses[0] as currentAddress
                      "
                      class="content-line">
                      <div class="organisation-address">
                        <p>{{ currentAddress | address }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="item-icon-container">
                <lib-icon
                  class="arrow-icon"
                  [iconId]="'006107'"
                  [size]="24"></lib-icon>

                <mat-icon
                  *ngIf="organisation?.isDefault"
                  fontIcon="star"></mat-icon>
              </div>
            </div>
          </section>
        </section>
      </div>
    </div>

    <div *ngIf="isLoadingOrganisations$ | async">
      <span class="organisations__loader">
        <claas-circular-progress
          [color]="secondaryColor"
          [diameter]="largeSize"></claas-circular-progress>
      </span>
    </div>
  </div>

  <ng-template #NoOrganisation>
    <section class="organisations__content-no-entities">
      <div class="organisations__content-no-entities--icon-container"></div>

      <div class="organisations__content-no-entities--header">
        <span class="subtitle-1">{{
          'organization.header' | translate
        }}</span>
      </div>

      <div class="organisations__content-no-entities--message">
        <span>{{ 'organization.no-organization_message' | translate }}</span>
      </div>

      <claas-button
        class="add-btn"
        [icon]="addIcon"
        [text]="'organization.add' | translate"
        [buttonClass]="primaryButtonClass"
        [size]="mediumButtonSize"
        [disabled]="!registrationSteps || !registrationSteps?.length"
        (click)="openRegistrationDialog()"
      ></claas-button>
    </section>
  </ng-template>
</div>
