export class UserInformationModel {
  salutation?: string;
  title?: string;
  first_name: string;
  last_name: string;
  language: string;
  email: string;
  country: string;
  addresses: any[];
  phone_number_value?: string;
  mobile_number_value?: string;

  constructor() {
    this.first_name = '';
    this.last_name = '';
    this.language = '';
    this.email = '';
    this.country = 'DE';
    this.addresses = [];
  }
}
