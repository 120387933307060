import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output
} from '@angular/core';
import {
  ResponseDataFromDialog,
  passedDataToDialog
} from '../dialog-window/dialog-window.component';
import { ButtonClass } from '@claas/claas-form-components';
import { BehaviorSubject, Subscription, finalize } from 'rxjs';
import { ApiService } from '../../services/api.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-marketing-permission-delete',
  templateUrl: './marketing-permission-delete-view.component.html',
  styleUrl: './marketing-permission-delete-view.component.scss'
})
export class MarketingPermissionDeleteViewComponent implements OnDestroy {
  @Input() data: passedDataToDialog = {};
  @Input() errorButtonClass?: ButtonClass;
  @Input() primaryTextButtonClass?: ButtonClass;
  @Input() secondaryTextButtonClass?: ButtonClass;

  @Output() displayPopup = new EventEmitter();
  @Output() closeDialogWindow = new EventEmitter();
  actionInProgress$ = new BehaviorSubject(false);
  private subscriptions = new Subscription();

  constructor(
    private apiService: ApiService,
    private translateService: TranslateService
  ) {}

  onCancelClicked(): void {
    const passedObject = {
      action: 'cancel'
    };

    this.closeDialogWindow.emit(passedObject);
  }

  onDeleteClicked() {
    this.deleteAllMarketingPermissions();
    this.disableAllActions();
  }

  deleteAllMarketingPermissions(): void {
    const passedObject: ResponseDataFromDialog = {
      action: 'delete'
    };

    if (this.data?.userId) {
      this.subscriptions.add(
        this.apiService
          .deleteAllMarketingPermissions(this.data.userId)
          .pipe(finalize(() => this.closeDialogWindow.emit(passedObject)))
          .subscribe({
            next: () => {
              this.subscriptions.add(
                this.translateService
                  .get('preference_center.delete.success')
                  .subscribe((text: string) => {
                    this.displayPopup.emit({ text, className: '' });
                  })
              );
            },
            error: err => {
              this.subscriptions.add(
                this.translateService
                  .get('preference_center.delete.fail')
                  .subscribe((text: string) => {
                    this.displayPopup.emit({ text, className: '' });
                  })
              );
            }
          })
      );
    }
  }

  disableAllActions(): void {
    this.actionInProgress$.next(true);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
