import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {
  DialogWindowComponent,
  ResponseDataFromDialog,
  passedDataToDialog
} from '../dialog-window/dialog-window.component';
import { ButtonClass } from '@claas/claas-form-components';
import { BehaviorSubject, Observable, Subscription, finalize, tap } from 'rxjs';
import { ApiService } from '../../services/api.service';
import { TranslateService } from '@ngx-translate/core';
import { MarketingPermissionPatch } from '../../models/marketing-permission-patch.model';
import { MarketingPermission } from '../../models/marketing-permission.model';
import { Address } from '../../models/address.model';
import { MatDialog } from '@angular/material/dialog';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-marketing-permission',
  templateUrl: './marketing-permission-view.component.html',
  styleUrl: './marketing-permission-view.component.scss'
})
export class MarketingPermissionViewComponent
  implements OnInit, OnDestroy, OnDestroy
{
  @Input() data: passedDataToDialog = {};
  @Input() errorButtonClass?: ButtonClass;
  @Input() primaryTextButtonClass?: ButtonClass;
  @Input() secondaryTextButtonClass?: ButtonClass;

  @Output() displayPopup = new EventEmitter();
  @Output() closeDialogWindow = new EventEmitter();

  isHandset = false;
  footnote = '';

  currentMarketingPermission = new MarketingPermissionPatch();
  actionInProgress$ = new BehaviorSubject(false);
  private subscriptions = new Subscription();

  constructor(
    private apiService: ApiService,
    private translateService: TranslateService,
    private dialog: MatDialog,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit() {
    if (this.data.marketingPermission) {
      this.currentMarketingPermission =
        MarketingPermissionPatch.fromMarketingPermission(
          this.data.marketingPermission
        );
    }
    if (this.data.userCountry) {
      this.getMarketingFootnote();
    }
  }

  isHandset$(): Observable<any> {
    return this.breakpointObserver
      .observe(Breakpoints.Handset)
      .pipe(map((state: any) => (this.isHandset = state.matches)));
  }

  onCancelClicked(): void {
    const passedObject = {
      action: 'cancel'
    };

    this.closeDialogWindow.emit(passedObject);
  }

  onSaveClicked() {
    this.setMarketingPermission();
    this.disableAllActions();
  }

  updateChannel(channel: string): any {
    // Update the MarketingPermission channels
    switch (channel) {
      case 'email':
        this.currentMarketingPermission.email =
          !this.currentMarketingPermission.email;
        break;

      case 'post':
        this.currentMarketingPermission.post =
          !this.currentMarketingPermission.post;
        break;

      case 'push':
        this.currentMarketingPermission.push =
          !this.currentMarketingPermission.push;
        break;

      case 'phone':
        this.currentMarketingPermission.phone =
          !this.currentMarketingPermission.phone;
        break;
    }
  }

  setMarketingPermission(): void {
    const passedObject: ResponseDataFromDialog = {
      action: 'save'
    };

    if (this.data?.userId && this.data?.marketingPermission) {
      this.subscriptions.add(
        this.apiService
          .updateMarketingPermission(
            this.data.userId,
            this.data.marketingPermission.permission_uuid,
            this.currentMarketingPermission
          )
          .pipe(finalize(() => this.closeDialogWindow.emit(passedObject)))
          .subscribe({
            next: () => {
              this.subscriptions.add(
                this.translateService
                  .get('preference_center.edit.success')
                  .subscribe((text: string) => {
                    this.displayPopup.emit({ text, className: '' });
                  })
              );
            },
            error: err => {
              this.subscriptions.add(
                this.translateService
                  .get('preference_center.edit.fail')
                  .subscribe((text: string) => {
                    this.displayPopup.emit({ text, className: '' });
                  })
              );
            }
          })
      );
    }
  }

  getMarketingFootnote() {
    // @ts-ignore
    this.footnote = environment.marketing_footnotes[this.data?.userCountry];
  }

  disableAllActions(): void {
    this.actionInProgress$.next(true);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
