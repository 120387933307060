<div class="flex flex-col">
  <h1 class="subtitle-2 user-update__header">
    {{ 'updates.user_update' | translate }}
  </h1>

  <p class="user-update__text">
    {{ 'updates.update_request_user' | translate }}
  </p>

  <div class="user-update__grid-container">
    <!--    HEADER-->
    <div class="user-update__grid-header">
      <p class="user-update__grid-cell"></p>
      <p class="user-update__grid-cell">
        {{ 'updates.existing_data' | translate }}
      </p>
      <p class="user-update__grid-cell">{{ 'updates.change' | translate }}</p>
    </div>

    <claas-divider class="user-update__grid-divider" />

    <div *ngFor="let field of userData" class="user-update__grid-row">
      <p class="user-update__grid-cell">{{ field.label | translate }}</p>
      <!--      OLD VALUES-->
      <p
        *ngIf="field.key === 'address.country' && this.userAddress"
        class="user-update__grid-cell-old">
        {{ getCountry(this.userAddress.country) | translate }}
      </p>
      <p *ngIf="field.key === 'salutation'" class="user-update__grid-cell-old">
        {{ getSalutation(this.userSalutation) | translate }}
      </p>
      <p *ngIf="field.key === 'title'" class="user-update__grid-cell-old">
        {{ this.userTitle }}
      </p>
      <p
        *ngIf="
          field.key !== 'address.country' &&
          field.key !== 'salutation' &&
          field.key !== 'title'
        "
        class="user-update__grid-cell-old">
        {{ field.existingValue | translate }}
      </p>

      <!--      NEW VALUES-->
      <p
        *ngIf="
          field.key !== 'address.country' &&
          field.key !== 'salutation' &&
          field.key !== 'title'
        "
        class="user-update__grid-cell-new subtitle-2">
        {{ field.updatedValue | translate }}
      </p>
      <p
        *ngIf="field.key === 'address.country'"
        class="user-update__grid-cell-new subtitle-2">
        {{ getCountry(field.updatedValue) | translate }}
      </p>
      <p
        *ngIf="field.key === 'salutation'"
        class="user-update__grid-cell-new subtitle-2">
        {{ getSalutation(field.updatedValue) | translate }}
      </p>
      <p
        *ngIf="field.key === 'title'"
        class="user-update__grid-cell-new subtitle-2">
        {{ field.updatedValue }}
      </p>
      <claas-divider class="user-update__grid-divider" />
    </div>
  </div>
  <div class="user-update__dialog-actions">
    <div class="left-side">
      <div class="btn-left">
        <div *ngIf="showSpinner$ | async" class="flex flex-end w-full">
          <span class="flex">
            <claas-circular-progress
              [color]="secondaryColor"
              [diameter]="largeSize">
            </claas-circular-progress>
          </span>
        </div>
      </div>
    </div>
    <div class="right-side">
      <claas-button
        (onClick)="onClose(false)"
        *ngIf="data.buttonCancel"
        [buttonClass]="secondaryTextButtonClass"
        [disabled]="(showSpinner$ | async) === true"
        [text]="'generic.deny' | translate"
        class="btn-left">
      </claas-button>

      <claas-button
        (onClick)="onClose(true)"
        *ngIf="data?.buttonSave"
        [buttonClass]="secondaryOutlineButtonClass"
        [disabled]="(showSpinner$ | async) === true"
        [text]="'generic.confirm' | translate"
        class="btn-right" />
    </div>
  </div>
</div>
