import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserCoordinatesState } from './user-coordinates.state';

export const selectUserCoordinatesState =
  createFeatureSelector<UserCoordinatesState>('userCoordinates');

export const selectUserCoordinates = createSelector(
  selectUserCoordinatesState,
  state => state.userDetails
);

export const selectMarketingPermissions = createSelector(
  selectUserCoordinatesState,
  state => state.marketingPermissions
);
