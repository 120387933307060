import { User } from './user.model';
import { Dealer } from './dealer.model';
import { OrgPhoneNumber } from './org-phone-number.model';
import { OrgAddress } from './org-address.model';
import { Invitation } from './invitation.model';

export class Organization {
  name: string;
  type: string;
  number: number;
  id: string;
  validated: boolean;
  unitSystem: string;
  timeZone: string;
  country: string;
  debitorId: string;
  dealer: Dealer;

  phoneNumber: OrgPhoneNumber;
  addresses: OrgAddress[];

  admins: User[];
  users: User[];
  pendingInvitations: Invitation[];

  user_role: string;
  spinner_visible: boolean;
  leave_visible: boolean;

  auth0Id?: string;
  createdAt?: string;
  createdBy?: string;
  createdByMigration?: string;
  currency?: string;
  initialFallbackLanguage?: string;
  registered?: boolean;
  registeredByDealer?: boolean;
  registrationStatus?: string;

  constructor() {
    this.admins = [];
    this.users = [];
    this.addresses = [];
    this.pendingInvitations = [];
    this.spinner_visible = false;
    this.leave_visible = false;
    this.registered = false;
    this.name = '';
    this.type = '';
    this.number = 0;
    this.id = '';
    this.validated = false;
    this.unitSystem = '';
    this.timeZone = '';
    this.country = '';
    this.debitorId = '';
    this.user_role = '';
    this.dealer = new Dealer();
    this.phoneNumber = new OrgPhoneNumber();
  }

  getOrganisation() {
    return {
      name: this.name,
      type: this.type,
      unitSystem: this.unitSystem,
      timeZone: this.timeZone,
      country: this.country,
      debitorId: this.debitorId
    };
  }

  copyOrganisation(source: any): void {
    this.name = source?.name ? source?.name : '';
    this.type = source?.type ? source?.type : '';
    this.number = source?.number ? source?.number : 0;
    this.country = source?.country ? source?.country : '';
    this.timeZone = source?.timeZone ? source?.timeZone : '';
    this.unitSystem = source?.unitSystem ? source?.unitSystem : '';
    this.phoneNumber = source?.phoneNumber
      ? source?.phoneNumber
      : new OrgPhoneNumber();
    this.addresses = source?.addresses ? source?.addresses : [];

    this.auth0Id = source?.auth0Id ? source?.auth0Id : '';
    this.createdAt = source?.createdAt ? source?.createdAt : '';
    this.createdBy = source?.createdBy ? source?.createdBy : '';
    this.createdByMigration = source?.createdByMigration
      ? source?.createdByMigration
      : null;
    this.currency = source?.currency ? source?.currency : '';
    this.dealer = source?.dealer ? source?.dealer : null;
    this.debitorId = source?.debitorId ? source?.debitorId : '';
    this.id = source?.id ? source?.id : '';
    this.initialFallbackLanguage = source?.initialFallbackLanguage
      ? source?.initialFallbackLanguage
      : '';
    this.registered = source?.registered ? source?.registered : false;
    this.registeredByDealer = source?.registeredByDealer
      ? source?.registeredByDealer
      : null;
    this.registrationStatus = source?.registrationStatus
      ? source?.registrationStatus
      : '';
    this.validated = source?.validated ? source?.validated : null;
  }
}
