import { Injectable } from '@angular/core';
import {
  GetTokenSilentlyOptions,
  Auth0ClientOptions,
  createAuth0Client,
  AuthorizationParams
} from '@auth0/auth0-spa-js';
import {
  Auth0Client,
  User as Auth0User
} from '@auth0/auth0-spa-js/dist/typings';
import { BehaviorSubject, Observable, from, of, throwError } from 'rxjs';
import {
  catchError,
  concatMap,
  map,
  shareReplay,
  switchMap,
  tap
} from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { User } from 'cde-fe-organization-registration-dialog';

@Injectable()
export class AuthService {
  defaultAuth0ClientOptions: Auth0ClientOptions = {
    domain: environment.auth.auth_domain, // Test Tenant
    clientId: environment.auth.auth_client_id, // Test Tenant
    authorizationParams: {
      redirect_uri: `${window.location.origin}`,
      audience: environment.base_urls.user_api_base_url,
      scope: 'openid email profile'
    }
  };

  // Create an observable of Auth0 instance of client
  auth0Client$ = (
    from(
      createAuth0Client(this.defaultAuth0ClientOptions)
    ) as Observable<Auth0Client>
  ).pipe(
    shareReplay(1), // Every subscription receives the same shared value
    catchError(err => throwError(err))
  );
  // Define observables for SDK methods that return promises by default
  // For each Auth0 SDK method, first ensure the client instance is ready
  // concatMap: Using the client instance, call SDK method; SDK returns a promise
  // from: Convert that resulting promise into an observable
  isAuthenticated$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.isAuthenticated())),
    tap(res => (this.loggedIn = res))
  );

  // Create subject and public observable of user profile data
  private userProfileSubject$ = new BehaviorSubject<any>(null);
  userProfile$ = this.userProfileSubject$.asObservable();
  // Create a local property for login status
  loggedIn = false;

  constructor(private router: Router) {
    // On initial load, check authentication state with authorization server
    // Set up local auth streams if user is already authenticated
    this.localAuthSetup();
    // Handle redirect from Auth0 login
  }

  // When calling, options can be passed if desired
  // https://auth0.github.io/auth0-spa-js/classes/auth0client.html#getuser
  getUser$(): Observable<any> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getUser())),
      map((user: Auth0User | undefined) => new User(user)),
      tap((user: User) => this.userProfileSubject$.next(user))
    );
  }

  getTokenSilently$(options?: any): Observable<any> {
    if (window.localStorage.getItem('currentOrganisationId')) {
      if (options) {
        options['organization'] = window.localStorage.getItem(
          'currentOrganisationId'
        );
      } else {
        options = {};
        options['organization'] = window.localStorage.getItem(
          'currentOrganisationId'
        );
      }
    } else {
      // If no orgId is present, skip org selection here
      if (options) options['skipOrgSelection'] = true;
      else {
        options = {};
        options['skipOrgSelection'] = true;
      }
    }
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getTokenSilently(options)))
    );
  }

  getUserNoCache$(): Observable<any> {
    // TODO: Dynamic
    return this.getTokenSilently$({
      ignoreCache: true,
      audience: environment.base_urls.user_api_base_url
    }).pipe(
      switchMap(() => {
        return this.auth0Client$.pipe(
          concatMap((client: Auth0Client) => from(client.getUser()))
        );
      })
    );
  }

  private localAuthSetup() {
    // This should only be called on app initialization
    // Set up local authentication streams
    const checkAuth$ = this.isAuthenticated$.pipe(
      concatMap((loggedIn: boolean) => {
        if (loggedIn) {
          // If authenticated, get user and set in app
          // NOTE: you could pass options here if needed
          return this.getUser$();
        }
        // If not authenticated, return stream that emits 'false'
        return of(loggedIn);
      })
    );
    checkAuth$.subscribe();
  }

  login(redirectPath = '/') {
    const authParams: AuthorizationParams = {
      redirect_uri: `${window.location.origin}`
    };
    // A desired redirect path can be passed to login method
    // (e.g., from a route guard)
    // Ensure Auth0 client instance exists
    this.auth0Client$.subscribe((client: Auth0Client) => {
      // Call method to log in
      client.loginWithRedirect({
        authorizationParams: authParams,
        appState: { target: redirectPath }
      });
    });
  }

  logout() {
    // Ensure Auth0 client instance exists
    this.auth0Client$.subscribe((client: Auth0Client) => {
      this.loggedIn = false;

      // Call method to log out
      client.logout({
        // client_id: 'CGFun7eCXf5GB0xwyyG7VkImwZwgGdZt', // Test Tenant
        clientId: environment.auth.auth_client_id, // Dev Tenant
        logoutParams: {
          returnTo: `${window.location.origin}`
        }
      });

      window.localStorage.removeItem('currentOrganisationId');
    });
  }
}
