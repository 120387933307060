import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  CircularProgressDiameter,
  ProgressColor
} from '@claas/claas-layout-components';
import { BehaviorSubject, Observable, Subscription, take } from 'rxjs';
import { Icon, IconFontSize, IconSize } from '@claas/claas-form-components';
import { MatDialog } from '@angular/material/dialog';
import {
  DialogWindowComponent,
  ResponseDataFromDialog,
  passedDataToDialog
} from '../shared/components/dialog-window/dialog-window.component';
import { filter, map } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ApiService } from '../shared/services/api.service';
import { consentsArr } from './consent-source';
import { Application } from '../shared/models/application.model';
import { TranslateService } from '@ngx-translate/core';
import { Consent } from '../shared/models/consent.model';
import { Store } from '@ngrx/store';
import { selectUserDetails } from '../shared/state/user';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-applications',
  templateUrl: './applications.component.html',
  styleUrl: './applications.component.scss'
})
export class ApplicationsComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();

  largeSize = CircularProgressDiameter.LARGE;
  secondaryColor = ProgressColor.SECONDARY;
  applicationsList: Application[] = [];

  connectIcon: Icon = {
    iconId: '648585',
    iconStyle: 'bold',
    namespace: 'claas-id',
    size: 24
  };

  expandIcon: Icon = {
    iconId: '659469',
    iconStyle: 'bold',
    namespace: 'claas-id',
    size: 21
  };

  disconnectIcon: Icon = {
    iconId: '648584',
    iconStyle: 'bold',
    namespace: 'claas-id',
    size: 24
  };
  mediumIconSize = IconSize.MEDIUM;
  mediumIconFontSize = IconFontSize.MEDIUM;
  isHandset = false;
  userConsentsArray: Consent[] = [];
  shop_relations: any;

  applications$: BehaviorSubject<any> = new BehaviorSubject([]);
  isLoadingApplications$: BehaviorSubject<any> = new BehaviorSubject(true);

  constructor(
    private translateService: TranslateService,
    private apiService: ApiService,
    private breakpointObserver: BreakpointObserver,
    private dialog: MatDialog,
    private store: Store
  ) {
    this.subscriptions.add(this.isHandset$().subscribe());

    this.subscriptions.add(
      this.store
        .select(selectUserDetails)
        .pipe(filter(element => !!element))
        .subscribe({
          next: (userDetails: any) =>
            (this.shop_relations = userDetails?.app_metadata?.shop_relations)
        })
    );
  }

  ngOnInit() {
    this.preGetApplications();
  }

  preGetApplications(): void {
    this.subscriptions.add(
      this.getUserConsents$()
        .pipe(
          map(userConsentsArray => {
            this.userConsentsArray = userConsentsArray;

            this.getApplications();
          })
        )
        .subscribe()
    );
  }

  getUserConsents$(): Observable<Consent[]> {
    return this.apiService.getConsent();
  }

  getApplications(): void {
    this.subscriptions.add(
      this.apiService
        .getApplications()
        .pipe(take(1))
        .subscribe(result => {
          this.applicationsList = this.filterApplications(result);
          this.setApplicationsState();

          this.applications$.next(this.applicationsList);
          this.isLoadingApplications$.next(false);
        })
    );
  }

  setApplicationsState(): void {
    this.userConsentsArray.forEach(consent => {
      const targetIndex = this.applicationsList.findIndex(
        app => app.application_id === consent.client_id && consent.consent_given
      );

      if (targetIndex >= 0) {
        this.applicationsList[targetIndex].connected = true;
      }
    });
  }

  filterApplications(initialList: Application[]): Application[] {
    const filteredList: Application[] = [];

    initialList.forEach(app => {
      app.label = app.id.startsWith('ET_')
        ? 'applications.ET'
        : 'applications.'.concat(app.id);

      const availableApp = consentsArr.find(target => target.id === app.id);

      if (availableApp) {
        app.img = availableApp.img;
        app.url = availableApp.url;
        filteredList.push(app);
      }
    });

    return filteredList;
  }

  isHandset$(): Observable<any> {
    return this.breakpointObserver
      .observe(Breakpoints.Handset)
      .pipe(map((state: any) => (this.isHandset = state.matches)));
  }

  openDialog(subContext: string, applicationId: string | undefined, id: string | undefined): void {
    const data: passedDataToDialog = {
      context: 'application-dialog',
      subContext,
      applicationId,
      id,
      buttonCancel: 'generic.cancel',
      buttonSave: 'connected_apps.connect.header',
      buttonDelete: 'connected_apps.disconnect.header'
    };

    const dialogRef = this.dialog.open(DialogWindowComponent, {
      maxWidth: this.isHandset ? '100vw' : '80vw',
      data
    });

    this.subscriptions.add(
      dialogRef
        .afterClosed()
        .pipe(
          map((answer: ResponseDataFromDialog) => {
            if (answer) {
              if (answer.action === 'save' || answer.action === 'delete') {
                this.applications$.next([]);
                this.isLoadingApplications$.next(true);

                this.preGetApplications();
              }
            }
          })
        )
        .subscribe()
    );
  }

  navigateToApp(applicationUrl: string) {
    if (environment.shops_url) {
      window.location.href = applicationUrl;
    } else {
      console.log('Error: missing link');
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
