import { NgModule } from '@angular/core';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ClaasFormComponentsModule } from '@claas/claas-form-components';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CoreModule } from './shared/core.module';
import { AuthModule } from '@auth0/auth0-angular';
import { CommonModule } from '@angular/common';
import { ClaasLayoutComponentsModule } from '@claas/claas-layout-components';
import { ClaasNavigationComponentsModule } from '@claas/claas-navigation-components';
import { PrivacyComponent } from './privacy/privacy.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { TermsComponent } from './terms/terms.component';
import { AboutComponent } from './about/about.component';
import { ClaasIconElementsComponentModule } from '@claas/claas-icon-elements-component';
import { PersonalDataComponent } from './personal-data/personal-data.component';
import { MatDialogModule } from '@angular/material/dialog';
import { UserSettingsComponent } from './user-settings/user-settings.component';
import { OrganisationsComponent } from './organisations/organisations.component';
import { OrganisationDataComponent } from './organisation-data/organisation-data.component';
import { OrganisationDataOverviewComponent } from './organisation-data-overview/organisation-data-overview.component';
import { OrganisationDataUsersComponent } from './organisation-data-users/organisation-data-users.component';
import { ClaasFilterComponentsModule } from '@claas/claas-filter-components';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { OrganisationDataDealersComponent } from './organisation-data-dealers/organisation-data-dealers.component';
import { AsStringPipe } from './shared/pipes/asString/asString.pipe';
import { ApplicationsComponent } from './applications/applications.component';
import { PreferenceCenterComponent } from './preference-center/preference-center.component';
import { CdeFeOrganizationRegistrationDialogModule } from 'cde-fe-organization-registration-dialog';
import { CdeFeConvertPipeModule, ConvertPipeStore } from 'cde-fe-convert-pipe';
import { MapModule as AzureMapModule } from 'cde-fe-components-azure-map';
import { GoogleMapModule } from 'cde-fe-components-google-map';
import { VerifiedComponent } from './verified/verified.component';

@NgModule({
  declarations: [
    AppComponent,
    PrivacyComponent,
    TermsComponent,
    AboutComponent,
    PersonalDataComponent,
    UserSettingsComponent,
    OrganisationsComponent,
    AsStringPipe,
    OrganisationDataComponent,
    OrganisationDataOverviewComponent,
    OrganisationDataUsersComponent,
    OrganisationDataDealersComponent,
    ApplicationsComponent,
    PreferenceCenterComponent,
    VerifiedComponent
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    ClaasFormComponentsModule,
    ClaasLayoutComponentsModule,
    ClaasNavigationComponentsModule,
    ClaasIconElementsComponentModule,
    PdfViewerModule,
    CoreModule,
    MatTableModule,
    MatIconModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    AuthModule.forRoot({
      domain: environment.auth.auth_domain,
      clientId: environment.auth.auth_client_id,

      authorizationParams: {
        redirect_uri: window.location.origin,
        audience: environment.auth.auth_audience
      }
    }),
    MatDialogModule,
    ClaasFilterComponentsModule,
    CdeFeOrganizationRegistrationDialogModule.forRoot(
      environment.base_urls.user_api_base_url,
      environment.base_urls.api_base_url,
      environment.base_urls.cc_3_api_base_url,
      environment.general.languages
    ),
    AzureMapModule,
    GoogleMapModule,
    CdeFeConvertPipeModule.forRoot({})
  ],
  providers: [ConvertPipeStore],
  bootstrap: [AppComponent]
})
export class AppModule {}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    environment.base_urls.storage_base_url,
    '.json?ignoreCache=1'
  );
}
