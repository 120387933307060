<div class="preference-center">
  <section class="preference-center__header">
    <h1 class="preference-center__header-title">
      {{ 'navigation.preference_center' | translate }}
    </h1>
  </section>
  <section class="preference-center__content">
    <div class="preference-center__content-upper-message">
      <span>{{ 'preference_center.info' | translate }}</span>
    </div>

    <section *ngIf="(isLoadingMarketingPermissions$ | async) === false">
      <div *ngIf="marketingPermissions$ | async as marketingPermissions">
        <!-- CLAAS -->
        <section class="preference-center__content-list">
          <div class="preference-center__content-list--header label-1-style">
            <span>{{ 'preference_center.claas' | translate }} ({{claasCount}})</span>
          </div>
          <ng-container *ngFor="let permission of marketingPermissions">
            <div *ngIf="permission.type === 'claas'" class="preference-center__content-list--item">
              <div class="container">
                <div class="first-content">
                  <span class="label-1-style">{{ 'preference_center.mp_settings' | translate }}</span>
                </div>
                <div class="second-content">
                  <div class="second-content--status">
                    <app-chip
                      *ngIf="permission?.subscribed"
                      [label]="'preference_center.status.subscribed' | translate"
                      [type]="'accepted'"></app-chip>

                    <app-chip
                      *ngIf="!permission?.subscribed"
                      [label]="'preference_center.status.unsubscribed' | translate"
                      [type]="'outdated'"></app-chip>
                  </div>

                  <div class="second-content--actions">
                    <claas-icon-button
                      class="edit-btn name"
                      [icon]="editIcon"
                      [iconSize]="mediumIconSize"
                      [fontSize]="mediumIconFontSize"
                      (click)="openDialog('marketing-permission', permission)"></claas-icon-button>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </section>

        <!-- DEALER -->
        <section class="preference-center__content-list">
          <div class="preference-center__content-list--header label-1-style">
            <span>{{ 'preference_center.dealer' | translate }} ({{dealerCount}})</span>
          </div>

          <ng-container *ngFor="let permission of (marketingPermissions$ | async)">
            <div *ngIf="permission.type === 'dealer'" class="preference-center__content-list--item">
              <div class="container">
                <div class="first-content">
                  <span class="label-1-style">{{ permission.dealer_name }}</span>
                </div>
                <div class="second-content">
                  <div class="second-content--status">
                    <app-chip
                      *ngIf="permission?.subscribed"
                      [label]="'preference_center.status.subscribed' | translate"
                      [type]="'accepted'"></app-chip>

                    <app-chip
                      *ngIf="!permission?.subscribed"
                      [label]="'preference_center.status.unsubscribed' | translate"
                      [type]="'outdated'"></app-chip>
                  </div>

                  <div class="second-content--actions">
                    <claas-icon-button
                      class="edit-btn name"
                      [icon]="editIcon"
                      [iconSize]="mediumIconSize"
                      [fontSize]="mediumIconFontSize"
                      (click)="openDialog('marketing-permission', permission)"></claas-icon-button>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </section>
        <section class="preference-center__content-delete-permissions-container">
          <app-button
            type="warn"
            [label]="'generic.delete_all' | translate"
            (click)="openDialog('marketing-permission-delete', marketingPermissions)"></app-button>
        </section>
      </div>
    </section>
  </section>
  <section *ngIf="isLoadingMarketingPermissions$ | async">
      <span class="preference-center__loader">
        <claas-circular-progress
          [color]="secondaryColor"
          [value]="40"
          [diameter]="largeSize"></claas-circular-progress>
      </span>
  </section>
</div>
