import { Action, createReducer, on } from '@ngrx/store';
import { UserCoordinatesState, initialState } from './user-coordinates.state';
import {
  loadMarketingPermissions,
  loadUserCoordinates,
  marketingPermissionsLoaded,
  marketingPermissionsNotLoaded,
  userCoordinatesLoaded
} from './user-coordinates.actions';

export const userCoordinatesReducer = createReducer(
  initialState,
  on(loadUserCoordinates, state => ({
    ...state
  })),
  on(userCoordinatesLoaded, (state, { userDetails }) => ({
    ...state,
    userDetails
  })),
  on(loadMarketingPermissions, state => ({ ...state })),
  on(marketingPermissionsLoaded, (state, { permissions }) => ({
    ...state,
    marketingPermissions: permissions
  })),
  on(marketingPermissionsNotLoaded, state => ({
    ...state
  }))
);

export function reducer(
  state: UserCoordinatesState | undefined,
  action: Action
) {
  return userCoordinatesReducer(state, action);
}
