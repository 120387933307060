<div class="application-dialog-view">
  <div class="application-dialog-view__header">
    <div class="application-dialog-view__header-title">
      <h2 *ngIf="data.subContext === 'disconnect' && data.buttonDelete" class="header-3-bold">
        {{ data.buttonDelete | translate }}
      </h2>

      <h2 *ngIf="data.subContext === 'connect' && data.buttonSave" class="header-3-bold">
        {{  data.buttonSave | translate }}
      </h2>
    </div>
  </div>

  <div class="application-dialog-view__body">
    <div class="application-dialog-view__body-text">
      @if (data.subContext === 'connect') {
        {{ 'connected_apps.connect.info' | translate }}
      }
      @else if(data.subContext === 'disconnect') {
        {{ 'connected_apps.disconnect.info' | translate }}
      }
    </div>

    <div class="application-dialog-view__body-list">
      <ul>
        <li>{{ 'connected_apps.permissions.name' | translate }}</li>
        <li>{{ 'connected_apps.permissions.email' | translate }}</li>
        <li>{{ 'connected_apps.permissions.country' | translate }}</li>
        <li>{{ 'connected_apps.permissions.addresses' | translate }}</li>
        <li>{{ 'connected_apps.permissions.phones' | translate }}</li>
      </ul>
    </div>

    <div
      *ngIf="data.subContext === 'connect'"
      class="application-dialog-view__body-checkbox">
      <claas-checkbox
        [checked]="!!(checkBoxIsChecked$ | async)"
        (onChange)="
          checkBoxIsChecked$.next(!checkBoxIsChecked$.value)
        ">
      </claas-checkbox>
      <span *ngIf="data.id!.startsWith('ET_')" class="inner-hyperlink" [innerHTML]="'applications.ET.accept_terms' | translate"></span>
      <span *ngIf="!data.id!.startsWith('ET_')" class="inner-hyperlink" [innerHTML]="'applications.'+data.id+'.accept_terms' | translate"></span>
        
    </div>
  </div>

  <div class="application-dialog-view__actions">
    <claas-button
      *ngIf="data.buttonDelete && data.subContext === 'disconnect'"
      [disabled]="!!(actionInProgress$ | async)"
      [buttonClass]="errorButtonClass"
      [text]="data.buttonDelete | translate"
      (click)="disconnectApplication()">
    </claas-button>

    <claas-button
      *ngIf="data.buttonSave && data.subContext === 'connect'"
      [text]="data.buttonSave | translate"
      [disabled]="
        (checkBoxIsChecked$ | async) === false || !!(actionInProgress$ | async)
      "
      (click)="connectApplication()">
    </claas-button>

    <claas-button
      *ngIf="data?.buttonCancel"
      [buttonClass]="secondaryTextButtonClass"
      [text]="'generic.cancel' | translate"
      [disabled]="!!(actionInProgress$ | async)"
      (click)="onCancelClicked()">
    </claas-button>
  </div>
</div>
