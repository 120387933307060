import {
  IDealerMarketingPermissions,
  IMarketingPermission
} from 'cde-fe-organization-registration-dialog';
import { StateError } from '..';

export interface MarketingPermissionState {
  getPending: boolean;
  createPending: boolean;
  claasMarketingPermission: IMarketingPermission | 'NOT_FOUND' | null;
  dealerMarketingPermissions: IDealerMarketingPermissions | null;
  error: StateError;
}

export const initialState: MarketingPermissionState = {
  getPending: true,
  createPending: true,
  claasMarketingPermission: null,
  dealerMarketingPermissions: null,
  error: null
};
