import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IFeatureToggle } from '../models/feature-toggle.model';

@Injectable({
  providedIn: 'root'
})
export class FeatureToggleService {
  private featureToggleBaseUrl = `${environment.base_urls.api_base_url}/v1/feature-toggles`;
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private http: HttpClient) {}

  public getFeatureToggles() {
    return this.http.get<IFeatureToggle[]>(
      encodeURI(this.featureToggleBaseUrl),
      this.httpOptions
    );
  }
}
