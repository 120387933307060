import { MarketingPermission } from './marketing-permission.model';

export class MarketingPermissionPatch {
  address: null;
  email: boolean;
  email_string: string;
  phone: boolean;
  phone_number: null;
  post: boolean;
  push: boolean;

  constructor() {
    this.address = null;
    this.email = false;
    this.email_string = '';
    this.phone = false;
    this.phone_number = null;
    this.post = false;
    this.push = false;
  }

  static fromMarketingPermission(
    marketingPermission: MarketingPermission
  ): MarketingPermissionPatch {
    const patchMarketingPermission = new MarketingPermissionPatch();

    patchMarketingPermission.email = marketingPermission.channels.email;
    patchMarketingPermission.email_string =
      marketingPermission.channels.email_string || '';
    patchMarketingPermission.phone = marketingPermission.channels.phone;
    patchMarketingPermission.phone_number = null;
    patchMarketingPermission.post = marketingPermission.channels.post;
    patchMarketingPermission.push = marketingPermission.channels.push;

    return patchMarketingPermission;
  }
}
