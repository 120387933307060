export class Serializable {
  constructor(params = {}) {
    Object.assign(this, params);
  }

  static copyProperties(
    target: { [key: string]: any },
    src: { [key: string]: any }
  ) {
    // tslint:disable-line:no-any
    for (const propName in src) {
      if (src[propName]) {
        target[propName] = src[propName];
      }
    }

    return target;
  }

  serialize(): string {
    const targetObj = Serializable.copyProperties({}, this);
    return JSON.stringify(targetObj);
  }
}
