export class OrganisationDealerModel {
  dealerId: string;
  name: string;
  street: string;
  postalCode: string;
  city: string;
  state: string;
  territory: string;
  country: string;
  phone: string;
  fax: string;
  email: string;
  url: string;
  deliveryProgramIcons: string[];

  constructor(value?: any) {
    this.dealerId = value?.dealerId ? value.dealerId : '';
    this.name = value?.name ? value.name : '';
    this.street = value?.street ? value.street : '';
    this.postalCode = value?.postalCode ? value.postalCode : '';
    this.city = value?.city ? value.city : '';
    this.state = value?.state ? value.state : '';
    this.territory = value?.territory ? value.territory : '';
    this.country = value?.country ? value.country : '';
    this.phone = value?.phone ? value.phone : '';
    this.fax = value?.fax ? value.fax : '';
    this.email = value?.email ? value.email : '';
    this.url = value?.url ? value.url : '';
    this.deliveryProgramIcons = [];
  }
}
