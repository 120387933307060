import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { countriesArr } from '../components/language-view/countries-source';

@Injectable({
  providedIn: 'root'
})
export class AddressHelperService {
  countriesArray = countriesArr;
  constructor(private translateService: TranslateService) {}

  translateCountryValue(countryId: string): string {
    const target: any = this.countriesArray.find(el => el.id === countryId);

    return this.translateService.instant(
      target && target.label ? target.label : ''
    );
  }

  translateStateValue(stateId: string, countryId: string): string {
    return this.translateService.instant(
      'registration.formfield.select.states.' +
        countryId.toLowerCase() +
        '.' +
        stateId.toLowerCase()
    );
  }

  translateTerritoryValue(territoryId: string, countryId: string): string {
    return this.translateService.instant(
      'registration.formfield.select.territories.' +
        countryId.toLowerCase() +
        '.' +
        territoryId.toLowerCase()
    );
  }
}
