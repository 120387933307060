//region block: polyfills
if (typeof ArrayBuffer.isView === 'undefined') {
  ArrayBuffer.isView = function (a) {
    return a != null && a.__proto__ != null && a.__proto__.__proto__ === Int8Array.prototype.__proto__;
  };
}
if (typeof Array.prototype.fill === 'undefined') {
  // Polyfill from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/fill#Polyfill
  Object.defineProperty(Array.prototype, 'fill', {
    value: function (value) {
      // Steps 1-2.
      if (this == null) {
        throw new TypeError('this is null or not defined');
      }
      var O = Object(this); // Steps 3-5.
      var len = O.length >>> 0; // Steps 6-7.
      var start = arguments[1];
      var relativeStart = start >> 0; // Step 8.
      var k = relativeStart < 0 ? Math.max(len + relativeStart, 0) : Math.min(relativeStart, len); // Steps 9-10.
      var end = arguments[2];
      var relativeEnd = end === undefined ? len : end >> 0; // Step 11.
      var finalValue = relativeEnd < 0 ? Math.max(len + relativeEnd, 0) : Math.min(relativeEnd, len); // Step 12.
      while (k < finalValue) {
        O[k] = value;
        k++;
      }
      ; // Step 13.
      return O;
    }
  });
}
[Int8Array, Int16Array, Uint16Array, Int32Array, Float32Array, Float64Array].forEach(function (TypedArray) {
  if (typeof TypedArray.prototype.fill === 'undefined') {
    Object.defineProperty(TypedArray.prototype, 'fill', {
      value: Array.prototype.fill
    });
  }
});
if (typeof Math.clz32 === 'undefined') {
  Math.clz32 = function (log, LN2) {
    return function (x) {
      var asUint = x >>> 0;
      if (asUint === 0) {
        return 32;
      }
      return 31 - (log(asUint) / LN2 | 0) | 0; // the "| 0" acts like math.floor
    };
  }(Math.log, Math.LN2);
}
if (typeof Math.imul === 'undefined') {
  Math.imul = function imul(a, b) {
    return (a & 4.29490176E9) * (b & 65535) + (a & 65535) * (b | 0) | 0;
  };
}
//endregion
(function (root, factory) {
  if (typeof define === 'function' && define.amd) define(['exports'], factory);else if (typeof exports === 'object') factory(module.exports);else root['kotlin-kotlin-stdlib'] = factory(typeof this['kotlin-kotlin-stdlib'] === 'undefined' ? {} : this['kotlin-kotlin-stdlib']);
})(this, function (_) {
  'use strict';

  //region block: imports
  var clz32 = Math.clz32;
  var imul = Math.imul;
  var isView = ArrayBuffer.isView;
  //endregion
  //region block: pre-declaration
  setMetadataFor(CharSequence, 'CharSequence', interfaceMeta);
  setMetadataFor(Number_0, 'Number', classMeta);
  setMetadataFor(Unit, 'Unit', objectMeta);
  setMetadataFor(IntCompanionObject, 'IntCompanionObject', objectMeta);
  setMetadataFor(DoubleCompanionObject, 'DoubleCompanionObject', objectMeta);
  setMetadataFor(Collection, 'Collection', interfaceMeta);
  setMetadataFor(AbstractCollection, 'AbstractCollection', classMeta, VOID, [Collection]);
  setMetadataFor(AbstractMutableCollection, 'AbstractMutableCollection', classMeta, AbstractCollection, [AbstractCollection, Collection]);
  setMetadataFor(Map_0, 'Map', interfaceMeta);
  setMetadataFor(AbstractMap, 'AbstractMap', classMeta, VOID, [Map_0]);
  setMetadataFor(AbstractMutableMap, 'AbstractMutableMap', classMeta, AbstractMap, [AbstractMap, Map_0]);
  setMetadataFor(Set, 'Set', interfaceMeta, VOID, [Collection]);
  setMetadataFor(AbstractMutableSet, 'AbstractMutableSet', classMeta, AbstractMutableCollection, [AbstractMutableCollection, Set, Collection]);
  setMetadataFor(HashMap, 'HashMap', classMeta, AbstractMutableMap, [AbstractMutableMap, Map_0], HashMap_init_$Create$);
  setMetadataFor(HashMapEntrySetBase, 'HashMapEntrySetBase', classMeta, AbstractMutableSet, [Set, Collection, AbstractMutableSet]);
  setMetadataFor(HashMapEntrySet, 'HashMapEntrySet', classMeta, HashMapEntrySetBase);
  setMetadataFor(Companion, 'Companion', objectMeta);
  setMetadataFor(Itr, 'Itr', classMeta);
  setMetadataFor(EntriesItr, 'EntriesItr', classMeta, Itr);
  setMetadataFor(Entry, 'Entry', interfaceMeta);
  setMetadataFor(EntryRef, 'EntryRef', classMeta, VOID, [Entry]);
  function containsAllEntries(m) {
    var tmp$ret$0;
    $l$block_0: {
      // Inline function 'kotlin.collections.all' call
      var tmp;
      if (isInterface(m, Collection)) {
        tmp = m.m();
      } else {
        tmp = false;
      }
      if (tmp) {
        tmp$ret$0 = true;
        break $l$block_0;
      }
      var tmp0_iterator = m.j();
      while (tmp0_iterator.j2()) {
        var element = tmp0_iterator.p2();
        // Inline function 'kotlin.collections.InternalMap.containsAllEntries.<anonymous>' call
        // Inline function 'kotlin.js.unsafeCast' call
        // Inline function 'kotlin.js.asDynamic' call
        var entry = element;
        var tmp_0;
        if (!(entry == null) ? isInterface(entry, Entry) : false) {
          tmp_0 = this.a3(entry);
        } else {
          tmp_0 = false;
        }
        if (!tmp_0) {
          tmp$ret$0 = false;
          break $l$block_0;
        }
      }
      tmp$ret$0 = true;
    }
    return tmp$ret$0;
  }
  setMetadataFor(InternalMap, 'InternalMap', interfaceMeta);
  setMetadataFor(InternalHashMap, 'InternalHashMap', classMeta, VOID, [InternalMap], InternalHashMap_init_$Create$);
  setMetadataFor(LinkedHashMap, 'LinkedHashMap', classMeta, HashMap, [HashMap, Map_0], LinkedHashMap_init_$Create$);
  setMetadataFor(StringBuilder, 'StringBuilder', classMeta, VOID, [CharSequence], StringBuilder_init_$Create$_0);
  setMetadataFor(Enum, 'Enum', classMeta);
  setMetadataFor(DurationUnit, 'DurationUnit', classMeta, Enum);
  setMetadataFor(Char, 'Char', classMeta);
  setMetadataFor(Companion_0, 'Companion', objectMeta);
  setMetadataFor(Companion_1, 'Companion', objectMeta);
  setMetadataFor(Long, 'Long', classMeta, Number_0);
  setMetadataFor(Exception, 'Exception', classMeta, Error, VOID, Exception_init_$Create$);
  setMetadataFor(RuntimeException, 'RuntimeException', classMeta, Exception, VOID, RuntimeException_init_$Create$);
  setMetadataFor(IllegalArgumentException, 'IllegalArgumentException', classMeta, RuntimeException, VOID, IllegalArgumentException_init_$Create$);
  setMetadataFor(IndexOutOfBoundsException, 'IndexOutOfBoundsException', classMeta, RuntimeException, VOID, IndexOutOfBoundsException_init_$Create$);
  setMetadataFor(IllegalStateException, 'IllegalStateException', classMeta, RuntimeException, VOID, IllegalStateException_init_$Create$);
  setMetadataFor(UnsupportedOperationException, 'UnsupportedOperationException', classMeta, RuntimeException, VOID, UnsupportedOperationException_init_$Create$);
  setMetadataFor(NoSuchElementException, 'NoSuchElementException', classMeta, RuntimeException, VOID, NoSuchElementException_init_$Create$);
  setMetadataFor(Error_0, 'Error', classMeta, Error, VOID, Error_init_$Create$);
  setMetadataFor(AssertionError, 'AssertionError', classMeta, Error_0, VOID, AssertionError_init_$Create$);
  setMetadataFor(ConcurrentModificationException, 'ConcurrentModificationException', classMeta, RuntimeException, VOID, ConcurrentModificationException_init_$Create$);
  setMetadataFor(NullPointerException, 'NullPointerException', classMeta, RuntimeException, VOID, NullPointerException_init_$Create$);
  setMetadataFor(NoWhenBranchMatchedException, 'NoWhenBranchMatchedException', classMeta, RuntimeException, VOID, NoWhenBranchMatchedException_init_$Create$);
  setMetadataFor(ClassCastException, 'ClassCastException', classMeta, RuntimeException, VOID, ClassCastException_init_$Create$);
  setMetadataFor(Companion_2, 'Companion', objectMeta);
  setMetadataFor(Companion_3, 'Companion', objectMeta);
  setMetadataFor(Companion_4, 'Companion', objectMeta);
  setMetadataFor(Companion_5, 'Companion', objectMeta);
  //endregion
  function CharSequence() {}
  function Number_0() {}
  function Unit() {}
  protoOf(Unit).toString = function () {
    return 'kotlin.Unit';
  };
  var Unit_instance;
  function Unit_getInstance() {
    return Unit_instance;
  }
  function IntCompanionObject() {
    this.MIN_VALUE = -2147483648;
    this.MAX_VALUE = 2147483647;
    this.SIZE_BYTES = 4;
    this.SIZE_BITS = 32;
  }
  protoOf(IntCompanionObject).b = function () {
    return this.MIN_VALUE;
  };
  protoOf(IntCompanionObject).c = function () {
    return this.MAX_VALUE;
  };
  protoOf(IntCompanionObject).d = function () {
    return this.SIZE_BYTES;
  };
  protoOf(IntCompanionObject).e = function () {
    return this.SIZE_BITS;
  };
  var IntCompanionObject_instance;
  function IntCompanionObject_getInstance() {
    return IntCompanionObject_instance;
  }
  function DoubleCompanionObject() {
    this.MIN_VALUE = 4.9E-324;
    this.MAX_VALUE = 1.7976931348623157E308;
    this.POSITIVE_INFINITY = Infinity;
    this.NEGATIVE_INFINITY = -Infinity;
    this.NaN = NaN;
    this.SIZE_BYTES = 8;
    this.SIZE_BITS = 64;
  }
  protoOf(DoubleCompanionObject).b = function () {
    return this.MIN_VALUE;
  };
  protoOf(DoubleCompanionObject).c = function () {
    return this.MAX_VALUE;
  };
  protoOf(DoubleCompanionObject).f = function () {
    return this.POSITIVE_INFINITY;
  };
  protoOf(DoubleCompanionObject).g = function () {
    return this.NEGATIVE_INFINITY;
  };
  protoOf(DoubleCompanionObject).h = function () {
    return this.NaN;
  };
  protoOf(DoubleCompanionObject).d = function () {
    return this.SIZE_BYTES;
  };
  protoOf(DoubleCompanionObject).e = function () {
    return this.SIZE_BITS;
  };
  var DoubleCompanionObject_instance;
  function DoubleCompanionObject_getInstance() {
    return DoubleCompanionObject_instance;
  }
  function isNaN_0(_this__u8e3s4) {
    return !(_this__u8e3s4 === _this__u8e3s4);
  }
  function isFinite(_this__u8e3s4) {
    return !isInfinite(_this__u8e3s4) ? !isNaN_0(_this__u8e3s4) : false;
  }
  function isInfinite(_this__u8e3s4) {
    var tmp;
    if (_this__u8e3s4 === Infinity) {
      tmp = true;
    } else {
      tmp = _this__u8e3s4 === -Infinity;
    }
    return tmp;
  }
  function takeHighestOneBit(_this__u8e3s4) {
    var tmp;
    if (_this__u8e3s4 === 0) {
      tmp = 0;
    } else {
      var tmp_0 = 32 - 1 | 0;
      // Inline function 'kotlin.countLeadingZeroBits' call
      tmp = 1 << (tmp_0 - clz32(_this__u8e3s4) | 0);
    }
    return tmp;
  }
  function collectionToArray(collection) {
    return collectionToArrayCommonImpl(collection);
  }
  function AbstractMutableCollection() {
    AbstractCollection.call(this);
  }
  protoOf(AbstractMutableCollection).toJSON = function () {
    return this.toArray();
  };
  function AbstractMutableMap() {
    AbstractMap.call(this);
    this.p_1 = null;
    this.q_1 = null;
  }
  function AbstractMutableSet() {
    AbstractMutableCollection.call(this);
  }
  protoOf(AbstractMutableSet).equals = function (other) {
    if (other === this) return true;
    if (!(!(other == null) ? isInterface(other, Set) : false)) return false;
    return Companion_instance_4.y(this, other);
  };
  protoOf(AbstractMutableSet).hashCode = function () {
    return Companion_instance_4.z(this);
  };
  function arrayOfUninitializedElements(capacity) {
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!(capacity >= 0)) {
      // Inline function 'kotlin.collections.arrayOfUninitializedElements.<anonymous>' call
      var message = 'capacity must be non-negative.';
      throw IllegalArgumentException_init_$Create$_0(toString_1(message));
    }
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.arrayOfNulls' call
    // Inline function 'kotlin.js.asDynamic' call
    return fillArrayVal(Array(capacity), null);
  }
  function resetRange(_this__u8e3s4, fromIndex, toIndex) {
    // Inline function 'kotlin.js.nativeFill' call
    // Inline function 'kotlin.js.asDynamic' call
    _this__u8e3s4.fill(null, fromIndex, toIndex);
  }
  function copyOfUninitializedElements(_this__u8e3s4, newSize) {
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return copyOf_0(_this__u8e3s4, newSize);
  }
  function HashMap_init_$Init$(internalMap, $this) {
    AbstractMutableMap.call($this);
    HashMap.call($this);
    $this.e1_1 = internalMap;
    return $this;
  }
  function HashMap_init_$Init$_0($this) {
    HashMap_init_$Init$(InternalHashMap_init_$Create$(), $this);
    return $this;
  }
  function HashMap_init_$Create$() {
    return HashMap_init_$Init$_0(objectCreate(protoOf(HashMap)));
  }
  protoOf(HashMap).u = function (key) {
    return this.e1_1.g1(key);
  };
  protoOf(HashMap).x = function () {
    var tmp0_elvis_lhs = this.f1_1;
    var tmp;
    if (tmp0_elvis_lhs == null) {
      // Inline function 'kotlin.also' call
      var this_0 = new HashMapEntrySet(this.e1_1);
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'kotlin.collections.HashMap.<get-entries>.<anonymous>' call
      this.f1_1 = this_0;
      tmp = this_0;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    return tmp;
  };
  protoOf(HashMap).w = function (key) {
    return this.e1_1.w(key);
  };
  protoOf(HashMap).r = function (key, value) {
    return this.e1_1.r(key, value);
  };
  protoOf(HashMap).i = function () {
    return this.e1_1.i();
  };
  function HashMap() {
    this.f1_1 = null;
  }
  function HashMapEntrySet(backing) {
    HashMapEntrySetBase.call(this, backing);
  }
  protoOf(HashMapEntrySet).j = function () {
    return this.i1_1.j1();
  };
  function HashMapEntrySetBase(backing) {
    AbstractMutableSet.call(this);
    this.i1_1 = backing;
  }
  protoOf(HashMapEntrySetBase).i = function () {
    return this.i1_1.i();
  };
  protoOf(HashMapEntrySetBase).m = function () {
    return this.i1_1.i() === 0;
  };
  protoOf(HashMapEntrySetBase).k1 = function (element) {
    return this.i1_1.l1(element);
  };
  protoOf(HashMapEntrySetBase).k = function (element) {
    if (!(!(element == null) ? isInterface(element, Entry) : false)) return false;
    return this.k1((!(element == null) ? isInterface(element, Entry) : false) ? element : THROW_CCE());
  };
  protoOf(HashMapEntrySetBase).l = function (elements) {
    return this.i1_1.m1(elements);
  };
  function computeHashSize($this, capacity) {
    return takeHighestOneBit(imul(coerceAtLeast(capacity, 1), 3));
  }
  function computeShift($this, hashSize) {
    // Inline function 'kotlin.countLeadingZeroBits' call
    return clz32(hashSize) + 1 | 0;
  }
  function InternalHashMap_init_$Init$($this) {
    InternalHashMap_init_$Init$_0(8, $this);
    return $this;
  }
  function InternalHashMap_init_$Create$() {
    return InternalHashMap_init_$Init$(objectCreate(protoOf(InternalHashMap)));
  }
  function InternalHashMap_init_$Init$_0(initialCapacity, $this) {
    InternalHashMap.call($this, arrayOfUninitializedElements(initialCapacity), null, new Int32Array(initialCapacity), new Int32Array(computeHashSize(Companion_instance, initialCapacity)), 2, 0);
    return $this;
  }
  function _get_capacity__a9k9f3($this) {
    return $this.n1_1.length;
  }
  function _get_hashSize__tftcho($this) {
    return $this.q1_1.length;
  }
  function registerModification($this) {
    $this.u1_1 = $this.u1_1 + 1 | 0;
  }
  function ensureExtraCapacity($this, n) {
    if (shouldCompact($this, n)) {
      rehash($this, _get_hashSize__tftcho($this));
    } else {
      ensureCapacity($this, $this.s1_1 + n | 0);
    }
  }
  function shouldCompact($this, extraCapacity) {
    var spareCapacity = _get_capacity__a9k9f3($this) - $this.s1_1 | 0;
    var gaps = $this.s1_1 - $this.i() | 0;
    return (spareCapacity < extraCapacity ? (gaps + spareCapacity | 0) >= extraCapacity : false) ? gaps >= (_get_capacity__a9k9f3($this) / 4 | 0) : false;
  }
  function ensureCapacity($this, minCapacity) {
    if (minCapacity < 0) throw RuntimeException_init_$Create$_0('too many elements');
    if (minCapacity > _get_capacity__a9k9f3($this)) {
      var newSize = Companion_instance_2.y1(_get_capacity__a9k9f3($this), minCapacity);
      $this.n1_1 = copyOfUninitializedElements($this.n1_1, newSize);
      var tmp = $this;
      var tmp0_safe_receiver = $this.o1_1;
      tmp.o1_1 = tmp0_safe_receiver == null ? null : copyOfUninitializedElements(tmp0_safe_receiver, newSize);
      $this.p1_1 = copyOf($this.p1_1, newSize);
      var newHashSize = computeHashSize(Companion_instance, newSize);
      if (newHashSize > _get_hashSize__tftcho($this)) {
        rehash($this, newHashSize);
      }
    }
  }
  function allocateValuesArray($this) {
    var curValuesArray = $this.o1_1;
    if (!(curValuesArray == null)) return curValuesArray;
    var newValuesArray = arrayOfUninitializedElements(_get_capacity__a9k9f3($this));
    $this.o1_1 = newValuesArray;
    return newValuesArray;
  }
  function hash($this, key) {
    return key == null ? 0 : imul(hashCode(key), -1640531527) >>> $this.t1_1 | 0;
  }
  function compact($this) {
    var i = 0;
    var j = 0;
    var valuesArray = $this.o1_1;
    while (i < $this.s1_1) {
      if ($this.p1_1[i] >= 0) {
        $this.n1_1[j] = $this.n1_1[i];
        if (!(valuesArray == null)) {
          valuesArray[j] = valuesArray[i];
        }
        j = j + 1 | 0;
      }
      i = i + 1 | 0;
    }
    resetRange($this.n1_1, j, $this.s1_1);
    if (valuesArray == null) null;else {
      resetRange(valuesArray, j, $this.s1_1);
    }
    $this.s1_1 = j;
  }
  function rehash($this, newHashSize) {
    registerModification($this);
    if ($this.s1_1 > $this.v1_1) {
      compact($this);
    }
    if (!(newHashSize === _get_hashSize__tftcho($this))) {
      $this.q1_1 = new Int32Array(newHashSize);
      $this.t1_1 = computeShift(Companion_instance, newHashSize);
    } else {
      fill($this.q1_1, 0, 0, _get_hashSize__tftcho($this));
    }
    var i = 0;
    while (i < $this.s1_1) {
      var tmp0 = i;
      i = tmp0 + 1 | 0;
      if (!putRehash($this, tmp0)) {
        throw IllegalStateException_init_$Create$_0('This cannot happen with fixed magic multiplier and grow-only hash array. Have object hashCodes changed?');
      }
    }
  }
  function putRehash($this, i) {
    var hash_0 = hash($this, $this.n1_1[i]);
    var probesLeft = $this.r1_1;
    while (true) {
      var index = $this.q1_1[hash_0];
      if (index === 0) {
        $this.q1_1[hash_0] = i + 1 | 0;
        $this.p1_1[i] = hash_0;
        return true;
      }
      probesLeft = probesLeft - 1 | 0;
      if (probesLeft < 0) return false;
      var tmp0 = hash_0;
      hash_0 = tmp0 - 1 | 0;
      if (tmp0 === 0) hash_0 = _get_hashSize__tftcho($this) - 1 | 0;
    }
  }
  function findKey($this, key) {
    var hash_0 = hash($this, key);
    var probesLeft = $this.r1_1;
    while (true) {
      var index = $this.q1_1[hash_0];
      if (index === 0) return -1;
      if (index > 0 ? equals($this.n1_1[index - 1 | 0], key) : false) return index - 1 | 0;
      probesLeft = probesLeft - 1 | 0;
      if (probesLeft < 0) return -1;
      var tmp0 = hash_0;
      hash_0 = tmp0 - 1 | 0;
      if (tmp0 === 0) hash_0 = _get_hashSize__tftcho($this) - 1 | 0;
    }
  }
  function addKey($this, key) {
    $this.z1();
    retry: while (true) {
      var hash_0 = hash($this, key);
      var tentativeMaxProbeDistance = coerceAtMost(imul($this.r1_1, 2), _get_hashSize__tftcho($this) / 2 | 0);
      var probeDistance = 0;
      while (true) {
        var index = $this.q1_1[hash_0];
        if (index <= 0) {
          if ($this.s1_1 >= _get_capacity__a9k9f3($this)) {
            ensureExtraCapacity($this, 1);
            continue retry;
          }
          var tmp1 = $this.s1_1;
          $this.s1_1 = tmp1 + 1 | 0;
          var putIndex = tmp1;
          $this.n1_1[putIndex] = key;
          $this.p1_1[putIndex] = hash_0;
          $this.q1_1[hash_0] = putIndex + 1 | 0;
          $this.v1_1 = $this.v1_1 + 1 | 0;
          registerModification($this);
          if (probeDistance > $this.r1_1) $this.r1_1 = probeDistance;
          return putIndex;
        }
        if (equals($this.n1_1[index - 1 | 0], key)) {
          return -index | 0;
        }
        probeDistance = probeDistance + 1 | 0;
        if (probeDistance > tentativeMaxProbeDistance) {
          rehash($this, imul(_get_hashSize__tftcho($this), 2));
          continue retry;
        }
        var tmp4 = hash_0;
        hash_0 = tmp4 - 1 | 0;
        if (tmp4 === 0) hash_0 = _get_hashSize__tftcho($this) - 1 | 0;
      }
    }
  }
  function contentEquals($this, other) {
    return $this.v1_1 === other.i() ? $this.m1(other.x()) : false;
  }
  function Companion() {
    this.a2_1 = -1640531527;
    this.b2_1 = 8;
    this.c2_1 = 2;
    this.d2_1 = -1;
  }
  var Companion_instance;
  function Companion_getInstance() {
    return Companion_instance;
  }
  function Itr(map) {
    this.e2_1 = map;
    this.f2_1 = 0;
    this.g2_1 = -1;
    this.h2_1 = this.e2_1.u1_1;
    this.i2();
  }
  protoOf(Itr).i2 = function () {
    while (this.f2_1 < this.e2_1.s1_1 ? this.e2_1.p1_1[this.f2_1] < 0 : false) {
      this.f2_1 = this.f2_1 + 1 | 0;
    }
  };
  protoOf(Itr).j2 = function () {
    return this.f2_1 < this.e2_1.s1_1;
  };
  protoOf(Itr).k2 = function () {
    if (!(this.e2_1.u1_1 === this.h2_1)) throw ConcurrentModificationException_init_$Create$();
  };
  function EntriesItr(map) {
    Itr.call(this, map);
  }
  protoOf(EntriesItr).p2 = function () {
    this.k2();
    if (this.f2_1 >= this.e2_1.s1_1) throw NoSuchElementException_init_$Create$();
    var tmp = this;
    var tmp1 = this.f2_1;
    this.f2_1 = tmp1 + 1 | 0;
    tmp.g2_1 = tmp1;
    var result = new EntryRef(this.e2_1, this.g2_1);
    this.i2();
    return result;
  };
  protoOf(EntriesItr).q2 = function () {
    if (this.f2_1 >= this.e2_1.s1_1) throw NoSuchElementException_init_$Create$();
    var tmp = this;
    var tmp1 = this.f2_1;
    this.f2_1 = tmp1 + 1 | 0;
    tmp.g2_1 = tmp1;
    // Inline function 'kotlin.hashCode' call
    var tmp0_safe_receiver = this.e2_1.n1_1[this.g2_1];
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : hashCode(tmp0_safe_receiver);
    var tmp_0 = tmp1_elvis_lhs == null ? 0 : tmp1_elvis_lhs;
    // Inline function 'kotlin.hashCode' call
    var tmp0_safe_receiver_0 = ensureNotNull(this.e2_1.o1_1)[this.g2_1];
    var tmp1_elvis_lhs_0 = tmp0_safe_receiver_0 == null ? null : hashCode(tmp0_safe_receiver_0);
    var result = tmp_0 ^ (tmp1_elvis_lhs_0 == null ? 0 : tmp1_elvis_lhs_0);
    this.i2();
    return result;
  };
  protoOf(EntriesItr).r2 = function (sb) {
    if (this.f2_1 >= this.e2_1.s1_1) throw NoSuchElementException_init_$Create$();
    var tmp = this;
    var tmp1 = this.f2_1;
    this.f2_1 = tmp1 + 1 | 0;
    tmp.g2_1 = tmp1;
    var key = this.e2_1.n1_1[this.g2_1];
    if (equals(key, this.e2_1)) {
      sb.u2('(this Map)');
    } else {
      sb.t2(key);
    }
    sb.v2(_Char___init__impl__6a9atx(61));
    var value = ensureNotNull(this.e2_1.o1_1)[this.g2_1];
    if (equals(value, this.e2_1)) {
      sb.u2('(this Map)');
    } else {
      sb.t2(value);
    }
    this.i2();
  };
  function EntryRef(map, index) {
    this.w2_1 = map;
    this.x2_1 = index;
  }
  protoOf(EntryRef).y2 = function () {
    return this.w2_1.n1_1[this.x2_1];
  };
  protoOf(EntryRef).z2 = function () {
    return ensureNotNull(this.w2_1.o1_1)[this.x2_1];
  };
  protoOf(EntryRef).equals = function (other) {
    var tmp;
    var tmp_0;
    if (!(other == null) ? isInterface(other, Entry) : false) {
      tmp_0 = equals(other.y2(), this.y2());
    } else {
      tmp_0 = false;
    }
    if (tmp_0) {
      tmp = equals(other.z2(), this.z2());
    } else {
      tmp = false;
    }
    return tmp;
  };
  protoOf(EntryRef).hashCode = function () {
    // Inline function 'kotlin.hashCode' call
    var tmp0_safe_receiver = this.y2();
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : hashCode(tmp0_safe_receiver);
    var tmp = tmp1_elvis_lhs == null ? 0 : tmp1_elvis_lhs;
    // Inline function 'kotlin.hashCode' call
    var tmp0_safe_receiver_0 = this.z2();
    var tmp1_elvis_lhs_0 = tmp0_safe_receiver_0 == null ? null : hashCode(tmp0_safe_receiver_0);
    return tmp ^ (tmp1_elvis_lhs_0 == null ? 0 : tmp1_elvis_lhs_0);
  };
  protoOf(EntryRef).toString = function () {
    return '' + this.y2() + '=' + this.z2();
  };
  function InternalHashMap(keysArray, valuesArray, presenceArray, hashArray, maxProbeDistance, length) {
    this.n1_1 = keysArray;
    this.o1_1 = valuesArray;
    this.p1_1 = presenceArray;
    this.q1_1 = hashArray;
    this.r1_1 = maxProbeDistance;
    this.s1_1 = length;
    this.t1_1 = computeShift(Companion_instance, _get_hashSize__tftcho(this));
    this.u1_1 = 0;
    this.v1_1 = 0;
    this.w1_1 = false;
  }
  protoOf(InternalHashMap).i = function () {
    return this.v1_1;
  };
  protoOf(InternalHashMap).w = function (key) {
    var index = findKey(this, key);
    if (index < 0) return null;
    return ensureNotNull(this.o1_1)[index];
  };
  protoOf(InternalHashMap).g1 = function (key) {
    return findKey(this, key) >= 0;
  };
  protoOf(InternalHashMap).r = function (key, value) {
    var index = addKey(this, key);
    var valuesArray = allocateValuesArray(this);
    if (index < 0) {
      var oldValue = valuesArray[(-index | 0) - 1 | 0];
      valuesArray[(-index | 0) - 1 | 0] = value;
      return oldValue;
    } else {
      valuesArray[index] = value;
      return null;
    }
  };
  protoOf(InternalHashMap).equals = function (other) {
    var tmp;
    if (other === this) {
      tmp = true;
    } else {
      var tmp_0;
      if (!(other == null) ? isInterface(other, Map_0) : false) {
        tmp_0 = contentEquals(this, other);
      } else {
        tmp_0 = false;
      }
      tmp = tmp_0;
    }
    return tmp;
  };
  protoOf(InternalHashMap).hashCode = function () {
    var result = 0;
    var it = this.j1();
    while (it.j2()) {
      result = result + it.q2() | 0;
    }
    return result;
  };
  protoOf(InternalHashMap).toString = function () {
    var sb = StringBuilder_init_$Create$(2 + imul(this.v1_1, 3) | 0);
    sb.u2('{');
    var i = 0;
    var it = this.j1();
    while (it.j2()) {
      if (i > 0) {
        sb.u2(', ');
      }
      it.r2(sb);
      i = i + 1 | 0;
    }
    sb.u2('}');
    return sb.toString();
  };
  protoOf(InternalHashMap).z1 = function () {
    if (this.w1_1) throw UnsupportedOperationException_init_$Create$();
  };
  protoOf(InternalHashMap).l1 = function (entry) {
    var index = findKey(this, entry.y2());
    if (index < 0) return false;
    return equals(ensureNotNull(this.o1_1)[index], entry.z2());
  };
  protoOf(InternalHashMap).a3 = function (entry) {
    return this.l1(isInterface(entry, Entry) ? entry : THROW_CCE());
  };
  protoOf(InternalHashMap).j1 = function () {
    return new EntriesItr(this);
  };
  function InternalMap() {}
  function LinkedHashMap_init_$Init$($this) {
    HashMap_init_$Init$_0($this);
    LinkedHashMap.call($this);
    return $this;
  }
  function LinkedHashMap_init_$Create$() {
    return LinkedHashMap_init_$Init$(objectCreate(protoOf(LinkedHashMap)));
  }
  function LinkedHashMap() {}
  function roundToLong(_this__u8e3s4) {
    var tmp;
    if (isNaN_0(_this__u8e3s4)) {
      throw IllegalArgumentException_init_$Create$_0('Cannot round NaN value.');
    } else {
      Companion_getInstance_1();
      if (_this__u8e3s4 > new Long(-1, 2147483647).d3()) {
        Companion_getInstance_1();
        tmp = new Long(-1, 2147483647);
      } else {
        Companion_getInstance_1();
        if (_this__u8e3s4 < new Long(0, -2147483648).d3()) {
          Companion_getInstance_1();
          tmp = new Long(0, -2147483648);
        } else {
          tmp = numberToLong(Math.round(_this__u8e3s4));
        }
      }
    }
    return tmp;
  }
  function StringBuilder_init_$Init$(capacity, $this) {
    StringBuilder_init_$Init$_0($this);
    return $this;
  }
  function StringBuilder_init_$Create$(capacity) {
    return StringBuilder_init_$Init$(capacity, objectCreate(protoOf(StringBuilder)));
  }
  function StringBuilder_init_$Init$_0($this) {
    StringBuilder.call($this, '');
    return $this;
  }
  function StringBuilder_init_$Create$_0() {
    return StringBuilder_init_$Init$_0(objectCreate(protoOf(StringBuilder)));
  }
  function StringBuilder(content) {
    this.s2_1 = !(content === undefined) ? content : '';
  }
  protoOf(StringBuilder).a = function () {
    // Inline function 'kotlin.js.asDynamic' call
    return this.s2_1.length;
  };
  protoOf(StringBuilder).v2 = function (value) {
    this.s2_1 = this.s2_1 + toString(value);
    return this;
  };
  protoOf(StringBuilder).e3 = function (value) {
    this.s2_1 = this.s2_1 + toString_0(value);
    return this;
  };
  protoOf(StringBuilder).t2 = function (value) {
    this.s2_1 = this.s2_1 + toString_0(value);
    return this;
  };
  protoOf(StringBuilder).u2 = function (value) {
    var tmp = this;
    var tmp_0 = this.s2_1;
    tmp.s2_1 = tmp_0 + (value == null ? 'null' : value);
    return this;
  };
  protoOf(StringBuilder).toString = function () {
    return this.s2_1;
  };
  var DurationUnit_NANOSECONDS_instance;
  var DurationUnit_MICROSECONDS_instance;
  var DurationUnit_MILLISECONDS_instance;
  var DurationUnit_SECONDS_instance;
  var DurationUnit_MINUTES_instance;
  var DurationUnit_HOURS_instance;
  var DurationUnit_DAYS_instance;
  var DurationUnit_entriesInitialized;
  function DurationUnit_initEntries() {
    if (DurationUnit_entriesInitialized) return Unit_instance;
    DurationUnit_entriesInitialized = true;
    DurationUnit_NANOSECONDS_instance = new DurationUnit('NANOSECONDS', 0, 1.0);
    DurationUnit_MICROSECONDS_instance = new DurationUnit('MICROSECONDS', 1, 1000.0);
    DurationUnit_MILLISECONDS_instance = new DurationUnit('MILLISECONDS', 2, 1000000.0);
    DurationUnit_SECONDS_instance = new DurationUnit('SECONDS', 3, 1.0E9);
    DurationUnit_MINUTES_instance = new DurationUnit('MINUTES', 4, 6.0E10);
    DurationUnit_HOURS_instance = new DurationUnit('HOURS', 5, 3.6E12);
    DurationUnit_DAYS_instance = new DurationUnit('DAYS', 6, 8.64E13);
  }
  function DurationUnit(name, ordinal, scale) {
    Enum.call(this, name, ordinal);
    this.h3_1 = scale;
  }
  function convertDurationUnit(value, sourceUnit, targetUnit) {
    var sourceCompareTarget = compareTo(sourceUnit.h3_1, targetUnit.h3_1);
    return sourceCompareTarget > 0 ? value * (sourceUnit.h3_1 / targetUnit.h3_1) : sourceCompareTarget < 0 ? value / (targetUnit.h3_1 / sourceUnit.h3_1) : value;
  }
  function convertDurationUnit_0(value, sourceUnit, targetUnit) {
    var sourceCompareTarget = compareTo(sourceUnit.h3_1, targetUnit.h3_1);
    var tmp;
    if (sourceCompareTarget > 0) {
      var scale = numberToLong(sourceUnit.h3_1 / targetUnit.h3_1);
      var result = value.m3(scale);
      var tmp_0;
      if (result.l3(scale).equals(value)) {
        tmp_0 = result;
      } else if (value.n3(new Long(0, 0)) > 0) {
        Companion_getInstance_1();
        tmp_0 = new Long(-1, 2147483647);
      } else {
        Companion_getInstance_1();
        tmp_0 = new Long(0, -2147483648);
      }
      tmp = tmp_0;
    } else if (sourceCompareTarget < 0) {
      tmp = value.l3(numberToLong(targetUnit.h3_1 / sourceUnit.h3_1));
    } else {
      tmp = value;
    }
    return tmp;
  }
  function DurationUnit_NANOSECONDS_getInstance() {
    DurationUnit_initEntries();
    return DurationUnit_NANOSECONDS_instance;
  }
  function DurationUnit_MILLISECONDS_getInstance() {
    DurationUnit_initEntries();
    return DurationUnit_MILLISECONDS_instance;
  }
  function DurationUnit_SECONDS_getInstance() {
    DurationUnit_initEntries();
    return DurationUnit_SECONDS_instance;
  }
  function DurationUnit_MINUTES_getInstance() {
    DurationUnit_initEntries();
    return DurationUnit_MINUTES_instance;
  }
  function DurationUnit_HOURS_getInstance() {
    DurationUnit_initEntries();
    return DurationUnit_HOURS_instance;
  }
  function joinToString(_this__u8e3s4, separator, prefix, postfix, limit, truncated, transform) {
    separator = separator === VOID ? ', ' : separator;
    prefix = prefix === VOID ? '' : prefix;
    postfix = postfix === VOID ? '' : postfix;
    limit = limit === VOID ? -1 : limit;
    truncated = truncated === VOID ? '...' : truncated;
    transform = transform === VOID ? null : transform;
    return joinTo(_this__u8e3s4, StringBuilder_init_$Create$_0(), separator, prefix, postfix, limit, truncated, transform).toString();
  }
  function joinTo(_this__u8e3s4, buffer, separator, prefix, postfix, limit, truncated, transform) {
    separator = separator === VOID ? ', ' : separator;
    prefix = prefix === VOID ? '' : prefix;
    postfix = postfix === VOID ? '' : postfix;
    limit = limit === VOID ? -1 : limit;
    truncated = truncated === VOID ? '...' : truncated;
    transform = transform === VOID ? null : transform;
    buffer.e3(prefix);
    var count = 0;
    var tmp0_iterator = _this__u8e3s4.j();
    $l$loop: while (tmp0_iterator.j2()) {
      var element = tmp0_iterator.p2();
      count = count + 1 | 0;
      if (count > 1) {
        buffer.e3(separator);
      }
      if (limit < 0 ? true : count <= limit) {
        appendElement(buffer, element, transform);
      } else break $l$loop;
    }
    if (limit >= 0 ? count > limit : false) {
      buffer.e3(truncated);
    }
    buffer.e3(postfix);
    return buffer;
  }
  function coerceAtLeast(_this__u8e3s4, minimumValue) {
    return _this__u8e3s4 < minimumValue ? minimumValue : _this__u8e3s4;
  }
  function coerceIn(_this__u8e3s4, minimumValue, maximumValue) {
    if (minimumValue.n3(maximumValue) > 0) throw IllegalArgumentException_init_$Create$_0('Cannot coerce value to an empty range: maximum ' + maximumValue.toString() + ' is less than minimum ' + minimumValue.toString() + '.');
    if (_this__u8e3s4.n3(minimumValue) < 0) return minimumValue;
    if (_this__u8e3s4.n3(maximumValue) > 0) return maximumValue;
    return _this__u8e3s4;
  }
  function coerceAtMost(_this__u8e3s4, maximumValue) {
    return _this__u8e3s4 > maximumValue ? maximumValue : _this__u8e3s4;
  }
  function _Char___init__impl__6a9atx(value) {
    return value;
  }
  function _get_value__a43j40($this) {
    return $this;
  }
  function toString($this) {
    // Inline function 'kotlin.js.unsafeCast' call
    return String.fromCharCode(_get_value__a43j40($this));
  }
  function Char() {}
  function Collection() {}
  function Set() {}
  function Entry() {}
  function Map_0() {}
  function Companion_0() {}
  var Companion_instance_0;
  function Companion_getInstance_0() {
    return Companion_instance_0;
  }
  function Enum(name, ordinal) {
    this.i3_1 = name;
    this.j3_1 = ordinal;
  }
  protoOf(Enum).o3 = function () {
    return this.i3_1;
  };
  protoOf(Enum).p3 = function () {
    return this.j3_1;
  };
  protoOf(Enum).k3 = function (other) {
    return compareTo(this.j3_1, other.j3_1);
  };
  protoOf(Enum).q3 = function (other) {
    return this.k3(other instanceof Enum ? other : THROW_CCE());
  };
  protoOf(Enum).equals = function (other) {
    return this === other;
  };
  protoOf(Enum).hashCode = function () {
    return identityHashCode(this);
  };
  protoOf(Enum).toString = function () {
    return this.i3_1;
  };
  function toString_0(_this__u8e3s4) {
    var tmp1_elvis_lhs = _this__u8e3s4 == null ? null : toString_1(_this__u8e3s4);
    return tmp1_elvis_lhs == null ? 'null' : tmp1_elvis_lhs;
  }
  function implement(interfaces) {
    var maxSize = 1;
    var masks = [];
    var inductionVariable = 0;
    var last = interfaces.length;
    while (inductionVariable < last) {
      var i = interfaces[inductionVariable];
      inductionVariable = inductionVariable + 1 | 0;
      var currentSize = maxSize;
      var tmp1_elvis_lhs = i.prototype.$imask$;
      var imask = tmp1_elvis_lhs == null ? i.$imask$ : tmp1_elvis_lhs;
      if (!(imask == null)) {
        masks.push(imask);
        currentSize = imask.length;
      }
      var iid = i.$metadata$.iid;
      var tmp;
      if (iid == null) {
        tmp = null;
      } else {
        // Inline function 'kotlin.let' call
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'kotlin.js.implement.<anonymous>' call
        tmp = bitMaskWith(iid);
      }
      var iidImask = tmp;
      if (!(iidImask == null)) {
        masks.push(iidImask);
        currentSize = Math.max(currentSize, iidImask.length);
      }
      if (currentSize > maxSize) {
        maxSize = currentSize;
      }
    }
    return compositeBitMask(maxSize, masks);
  }
  function bitMaskWith(activeBit) {
    var numberIndex = activeBit >> 5;
    var intArray = new Int32Array(numberIndex + 1 | 0);
    var positionInNumber = activeBit & 31;
    var numberWithSettledBit = 1 << positionInNumber;
    intArray[numberIndex] = intArray[numberIndex] | numberWithSettledBit;
    return intArray;
  }
  function compositeBitMask(capacity, masks) {
    var tmp = 0;
    var tmp_0 = new Int32Array(capacity);
    while (tmp < capacity) {
      var tmp_1 = tmp;
      var result = 0;
      var inductionVariable = 0;
      var last = masks.length;
      while (inductionVariable < last) {
        var mask = masks[inductionVariable];
        inductionVariable = inductionVariable + 1 | 0;
        if (tmp_1 < mask.length) {
          result = result | mask[tmp_1];
        }
      }
      tmp_0[tmp_1] = result;
      tmp = tmp + 1 | 0;
    }
    return tmp_0;
  }
  function isBitSet(_this__u8e3s4, possibleActiveBit) {
    var numberIndex = possibleActiveBit >> 5;
    if (numberIndex > _this__u8e3s4.length) return false;
    var positionInNumber = possibleActiveBit & 31;
    var numberWithSettledBit = 1 << positionInNumber;
    return !((_this__u8e3s4[numberIndex] & numberWithSettledBit) === 0);
  }
  function fillArrayVal(array, initValue) {
    var inductionVariable = 0;
    var last = array.length - 1 | 0;
    if (inductionVariable <= last) do {
      var i = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      array[i] = initValue;
    } while (!(i === last));
    return array;
  }
  function get_buf() {
    _init_properties_bitUtils_kt__nfcg4k();
    return buf;
  }
  var buf;
  function get_bufFloat64() {
    _init_properties_bitUtils_kt__nfcg4k();
    return bufFloat64;
  }
  var bufFloat64;
  var bufFloat32;
  function get_bufInt32() {
    _init_properties_bitUtils_kt__nfcg4k();
    return bufInt32;
  }
  var bufInt32;
  function get_lowIndex() {
    _init_properties_bitUtils_kt__nfcg4k();
    return lowIndex;
  }
  var lowIndex;
  function get_highIndex() {
    _init_properties_bitUtils_kt__nfcg4k();
    return highIndex;
  }
  var highIndex;
  function getNumberHashCode(obj) {
    _init_properties_bitUtils_kt__nfcg4k();
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.jsBitwiseOr' call
    // Inline function 'kotlin.js.asDynamic' call
    if ((obj | 0) === obj) {
      return numberToInt(obj);
    }
    get_bufFloat64()[0] = obj;
    return imul(get_bufInt32()[get_highIndex()], 31) + get_bufInt32()[get_lowIndex()] | 0;
  }
  var properties_initialized_bitUtils_kt_i2bo3e;
  function _init_properties_bitUtils_kt__nfcg4k() {
    if (!properties_initialized_bitUtils_kt_i2bo3e) {
      properties_initialized_bitUtils_kt_i2bo3e = true;
      buf = new ArrayBuffer(8);
      // Inline function 'kotlin.js.unsafeCast' call
      // Inline function 'kotlin.js.asDynamic' call
      bufFloat64 = new Float64Array(get_buf());
      // Inline function 'kotlin.js.unsafeCast' call
      // Inline function 'kotlin.js.asDynamic' call
      bufFloat32 = new Float32Array(get_buf());
      // Inline function 'kotlin.js.unsafeCast' call
      // Inline function 'kotlin.js.asDynamic' call
      bufInt32 = new Int32Array(get_buf());
      // Inline function 'kotlin.run' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'kotlin.js.lowIndex.<anonymous>' call
      get_bufFloat64()[0] = -1.0;
      lowIndex = !(get_bufInt32()[0] === 0) ? 1 : 0;
      highIndex = 1 - get_lowIndex() | 0;
    }
  }
  function isString(a) {
    return typeof a === 'string';
  }
  function charSequenceLength(a) {
    var tmp;
    if (isString(a)) {
      // Inline function 'kotlin.js.unsafeCast' call
      // Inline function 'kotlin.js.asDynamic' call
      tmp = a.length;
    } else {
      tmp = a.a();
    }
    return tmp;
  }
  function compareTo(a, b) {
    var tmp;
    switch (typeof a) {
      case 'number':
        var tmp_0;
        if (typeof b === 'number') {
          tmp_0 = doubleCompareTo(a, b);
        } else {
          if (b instanceof Long) {
            tmp_0 = doubleCompareTo(a, b.d3());
          } else {
            tmp_0 = primitiveCompareTo(a, b);
          }
        }
        tmp = tmp_0;
        break;
      case 'string':
      case 'boolean':
        tmp = primitiveCompareTo(a, b);
        break;
      default:
        tmp = compareToDoNotIntrinsicify(a, b);
        break;
    }
    return tmp;
  }
  function doubleCompareTo(a, b) {
    var tmp;
    if (a < b) {
      tmp = -1;
    } else if (a > b) {
      tmp = 1;
    } else if (a === b) {
      var tmp_0;
      if (a !== 0) {
        tmp_0 = 0;
      } else {
        // Inline function 'kotlin.js.asDynamic' call
        var ia = 1 / a;
        var tmp_1;
        // Inline function 'kotlin.js.asDynamic' call
        if (ia === 1 / b) {
          tmp_1 = 0;
        } else {
          if (ia < 0) {
            tmp_1 = -1;
          } else {
            tmp_1 = 1;
          }
        }
        tmp_0 = tmp_1;
      }
      tmp = tmp_0;
    } else if (a !== a) {
      tmp = b !== b ? 0 : 1;
    } else {
      tmp = -1;
    }
    return tmp;
  }
  function primitiveCompareTo(a, b) {
    return a < b ? -1 : a > b ? 1 : 0;
  }
  function compareToDoNotIntrinsicify(a, b) {
    return a.q3(b);
  }
  function identityHashCode(obj) {
    return getObjectHashCode(obj);
  }
  function getObjectHashCode(obj) {
    // Inline function 'kotlin.js.jsIn' call
    if (!('kotlinHashCodeValue$' in obj)) {
      var hash = calculateRandomHash();
      var descriptor = new Object();
      descriptor.value = hash;
      descriptor.enumerable = false;
      Object.defineProperty(obj, 'kotlinHashCodeValue$', descriptor);
    }
    // Inline function 'kotlin.js.unsafeCast' call
    return obj['kotlinHashCodeValue$'];
  }
  function calculateRandomHash() {
    // Inline function 'kotlin.js.jsBitwiseOr' call
    return Math.random() * 4.294967296E9 | 0;
  }
  function toString_1(o) {
    var tmp;
    if (o == null) {
      tmp = 'null';
    } else if (isArrayish(o)) {
      tmp = '[...]';
    } else if (!(typeof o.toString === 'function')) {
      tmp = anyToString(o);
    } else {
      // Inline function 'kotlin.js.unsafeCast' call
      tmp = o.toString();
    }
    return tmp;
  }
  function equals(obj1, obj2) {
    if (obj1 == null) {
      return obj2 == null;
    }
    if (obj2 == null) {
      return false;
    }
    if (typeof obj1 === 'object' ? typeof obj1.equals === 'function' : false) {
      return obj1.equals(obj2);
    }
    if (obj1 !== obj1) {
      return obj2 !== obj2;
    }
    if (typeof obj1 === 'number' ? typeof obj2 === 'number' : false) {
      var tmp;
      if (obj1 === obj2) {
        var tmp_0;
        if (obj1 !== 0) {
          tmp_0 = true;
        } else {
          // Inline function 'kotlin.js.asDynamic' call
          var tmp_1 = 1 / obj1;
          // Inline function 'kotlin.js.asDynamic' call
          tmp_0 = tmp_1 === 1 / obj2;
        }
        tmp = tmp_0;
      } else {
        tmp = false;
      }
      return tmp;
    }
    return obj1 === obj2;
  }
  function hashCode(obj) {
    if (obj == null) return 0;
    var typeOf = typeof obj;
    var tmp;
    switch (typeOf) {
      case 'object':
        tmp = 'function' === typeof obj.hashCode ? obj.hashCode() : getObjectHashCode(obj);
        break;
      case 'function':
        tmp = getObjectHashCode(obj);
        break;
      case 'number':
        tmp = getNumberHashCode(obj);
        break;
      case 'boolean':
        // Inline function 'kotlin.js.unsafeCast' call

        tmp = getBooleanHashCode(obj);
        break;
      case 'string':
        tmp = getStringHashCode(String(obj));
        break;
      case 'bigint':
        tmp = getBigIntHashCode(obj);
        break;
      case 'symbol':
        tmp = getSymbolHashCode(obj);
        break;
      default:
        tmp = function () {
          throw new Error('Unexpected typeof `' + typeOf + '`');
        }();
        break;
    }
    return tmp;
  }
  function anyToString(o) {
    return Object.prototype.toString.call(o);
  }
  function getBooleanHashCode(value) {
    return value ? 1231 : 1237;
  }
  function getStringHashCode(str) {
    var hash = 0;
    var length = str.length;
    var inductionVariable = 0;
    var last = length - 1 | 0;
    if (inductionVariable <= last) do {
      var i = inductionVariable;
      inductionVariable = inductionVariable + 1 | 0;
      // Inline function 'kotlin.js.asDynamic' call
      var code = str.charCodeAt(i);
      hash = imul(hash, 31) + code | 0;
    } while (!(i === last));
    return hash;
  }
  function getBigIntHashCode(value) {
    var shiftNumber = BigInt(32);
    var MASK = BigInt(4.294967295E9);
    var bigNumber = value < 0 ? -value : value;
    var hashCode = 0;
    var signum = value < 0 ? -1 : 1;
    while (bigNumber != 0) {
      // Inline function 'kotlin.js.unsafeCast' call
      var chunk = Number(bigNumber & MASK);
      hashCode = imul(31, hashCode) + chunk | 0;
      bigNumber = bigNumber >> shiftNumber;
    }
    return imul(hashCode, signum);
  }
  function getSymbolHashCode(value) {
    var hashCodeMap = symbolIsSharable(value) ? getSymbolMap() : getSymbolWeakMap();
    var cachedHashCode = hashCodeMap.get(value);
    if (cachedHashCode !== VOID) return cachedHashCode;
    var hash = calculateRandomHash();
    hashCodeMap.set(value, hash);
    return hash;
  }
  function symbolIsSharable(symbol) {
    return Symbol.keyFor(symbol) != VOID;
  }
  function getSymbolMap() {
    if (symbolMap === VOID) {
      symbolMap = new Map();
    }
    return symbolMap;
  }
  function getSymbolWeakMap() {
    if (symbolWeakMap === VOID) {
      symbolWeakMap = new WeakMap();
    }
    return symbolWeakMap;
  }
  var symbolMap;
  var symbolWeakMap;
  function unboxIntrinsic(x) {
    var message = 'Should be lowered';
    throw IllegalStateException_init_$Create$_0(toString_1(message));
  }
  function captureStack(instance, constructorFunction) {
    if (Error.captureStackTrace != null) {
      Error.captureStackTrace(instance, constructorFunction);
    } else {
      // Inline function 'kotlin.js.asDynamic' call
      instance.stack = new Error().stack;
    }
  }
  function protoOf(constructor) {
    return constructor.prototype;
  }
  function defineProp(obj, name, getter, setter) {
    return Object.defineProperty(obj, name, {
      configurable: true,
      get: getter,
      set: setter
    });
  }
  function objectCreate(proto) {
    return Object.create(proto);
  }
  function extendThrowable(this_, message, cause) {
    Error.call(this_);
    setPropertiesToThrowableInstance(this_, message, cause);
  }
  function setPropertiesToThrowableInstance(this_, message, cause) {
    var errorInfo = calculateErrorInfo(Object.getPrototypeOf(this_));
    if ((errorInfo & 1) === 0) {
      var tmp;
      if (message == null) {
        var tmp_0;
        if (!(message === null)) {
          var tmp1_elvis_lhs = cause == null ? null : cause.toString();
          tmp_0 = tmp1_elvis_lhs == null ? VOID : tmp1_elvis_lhs;
        } else {
          tmp_0 = VOID;
        }
        tmp = tmp_0;
      } else {
        tmp = message;
      }
      this_.message = tmp;
    }
    if ((errorInfo & 2) === 0) {
      this_.cause = cause;
    }
    this_.name = Object.getPrototypeOf(this_).constructor.name;
  }
  function ensureNotNull(v) {
    var tmp;
    if (v == null) {
      THROW_NPE();
    } else {
      tmp = v;
    }
    return tmp;
  }
  function THROW_NPE() {
    throw NullPointerException_init_$Create$();
  }
  function noWhenBranchMatchedException() {
    throw NoWhenBranchMatchedException_init_$Create$();
  }
  function THROW_CCE() {
    throw ClassCastException_init_$Create$();
  }
  function THROW_IAE(msg) {
    throw IllegalArgumentException_init_$Create$_0(msg);
  }
  function fillFrom(src, dst) {
    var srcLen = src.length;
    var dstLen = dst.length;
    var index = 0;
    // Inline function 'kotlin.js.unsafeCast' call
    var arr = dst;
    while (index < srcLen ? index < dstLen : false) {
      var tmp = index;
      var tmp0 = index;
      index = tmp0 + 1 | 0;
      arr[tmp] = src[tmp0];
    }
    return dst;
  }
  function arrayCopyResize(source, newSize, defaultValue) {
    // Inline function 'kotlin.js.unsafeCast' call
    var result = source.slice(0, newSize);
    // Inline function 'kotlin.copyArrayType' call
    if (source.$type$ !== undefined) {
      result.$type$ = source.$type$;
    }
    var index = source.length;
    if (newSize > index) {
      // Inline function 'kotlin.js.asDynamic' call
      result.length = newSize;
      while (index < newSize) {
        var tmp0 = index;
        index = tmp0 + 1 | 0;
        result[tmp0] = defaultValue;
      }
    }
    return result;
  }
  function Companion_1() {
    Companion_instance_1 = this;
    this.r3_1 = new Long(0, -2147483648);
    this.s3_1 = new Long(-1, 2147483647);
    this.t3_1 = 8;
    this.u3_1 = 64;
  }
  var Companion_instance_1;
  function Companion_getInstance_1() {
    if (Companion_instance_1 == null) new Companion_1();
    return Companion_instance_1;
  }
  function Long(low, high) {
    Companion_getInstance_1();
    Number_0.call(this);
    this.b3_1 = low;
    this.c3_1 = high;
  }
  protoOf(Long).n3 = function (other) {
    return compare(this, other);
  };
  protoOf(Long).q3 = function (other) {
    return this.n3(other instanceof Long ? other : THROW_CCE());
  };
  protoOf(Long).v3 = function (other) {
    return add(this, other);
  };
  protoOf(Long).w3 = function (other) {
    return subtract(this, other);
  };
  protoOf(Long).m3 = function (other) {
    return multiply(this, other);
  };
  protoOf(Long).l3 = function (other) {
    return divide(this, other);
  };
  protoOf(Long).x3 = function () {
    return this.y3().v3(new Long(1, 0));
  };
  protoOf(Long).z3 = function (bitCount) {
    return shiftLeft(this, bitCount);
  };
  protoOf(Long).a4 = function (bitCount) {
    return shiftRight(this, bitCount);
  };
  protoOf(Long).y3 = function () {
    return new Long(~this.b3_1, ~this.c3_1);
  };
  protoOf(Long).b4 = function () {
    return this.b3_1;
  };
  protoOf(Long).d3 = function () {
    return toNumber(this);
  };
  protoOf(Long).valueOf = function () {
    return this.d3();
  };
  protoOf(Long).equals = function (other) {
    var tmp;
    if (other instanceof Long) {
      tmp = equalsLong(this, other);
    } else {
      tmp = false;
    }
    return tmp;
  };
  protoOf(Long).hashCode = function () {
    return hashCode_0(this);
  };
  protoOf(Long).toString = function () {
    return toStringImpl(this, 10);
  };
  function get_ZERO() {
    _init_properties_longjs_kt__tqrzid();
    return ZERO;
  }
  var ZERO;
  function get_ONE() {
    _init_properties_longjs_kt__tqrzid();
    return ONE;
  }
  var ONE;
  function get_NEG_ONE() {
    _init_properties_longjs_kt__tqrzid();
    return NEG_ONE;
  }
  var NEG_ONE;
  function get_MAX_VALUE() {
    _init_properties_longjs_kt__tqrzid();
    return MAX_VALUE;
  }
  var MAX_VALUE;
  function get_MIN_VALUE() {
    _init_properties_longjs_kt__tqrzid();
    return MIN_VALUE;
  }
  var MIN_VALUE;
  function get_TWO_PWR_24_() {
    _init_properties_longjs_kt__tqrzid();
    return TWO_PWR_24_;
  }
  var TWO_PWR_24_;
  function compare(_this__u8e3s4, other) {
    _init_properties_longjs_kt__tqrzid();
    if (equalsLong(_this__u8e3s4, other)) {
      return 0;
    }
    var thisNeg = isNegative(_this__u8e3s4);
    var otherNeg = isNegative(other);
    return (thisNeg ? !otherNeg : false) ? -1 : (!thisNeg ? otherNeg : false) ? 1 : isNegative(subtract(_this__u8e3s4, other)) ? -1 : 1;
  }
  function add(_this__u8e3s4, other) {
    _init_properties_longjs_kt__tqrzid();
    var a48 = _this__u8e3s4.c3_1 >>> 16 | 0;
    var a32 = _this__u8e3s4.c3_1 & 65535;
    var a16 = _this__u8e3s4.b3_1 >>> 16 | 0;
    var a00 = _this__u8e3s4.b3_1 & 65535;
    var b48 = other.c3_1 >>> 16 | 0;
    var b32 = other.c3_1 & 65535;
    var b16 = other.b3_1 >>> 16 | 0;
    var b00 = other.b3_1 & 65535;
    var c48 = 0;
    var c32 = 0;
    var c16 = 0;
    var c00 = 0;
    c00 = c00 + (a00 + b00 | 0) | 0;
    c16 = c16 + (c00 >>> 16 | 0) | 0;
    c00 = c00 & 65535;
    c16 = c16 + (a16 + b16 | 0) | 0;
    c32 = c32 + (c16 >>> 16 | 0) | 0;
    c16 = c16 & 65535;
    c32 = c32 + (a32 + b32 | 0) | 0;
    c48 = c48 + (c32 >>> 16 | 0) | 0;
    c32 = c32 & 65535;
    c48 = c48 + (a48 + b48 | 0) | 0;
    c48 = c48 & 65535;
    return new Long(c16 << 16 | c00, c48 << 16 | c32);
  }
  function subtract(_this__u8e3s4, other) {
    _init_properties_longjs_kt__tqrzid();
    return add(_this__u8e3s4, other.x3());
  }
  function multiply(_this__u8e3s4, other) {
    _init_properties_longjs_kt__tqrzid();
    if (isZero(_this__u8e3s4)) {
      return get_ZERO();
    } else if (isZero(other)) {
      return get_ZERO();
    }
    if (equalsLong(_this__u8e3s4, get_MIN_VALUE())) {
      return isOdd(other) ? get_MIN_VALUE() : get_ZERO();
    } else if (equalsLong(other, get_MIN_VALUE())) {
      return isOdd(_this__u8e3s4) ? get_MIN_VALUE() : get_ZERO();
    }
    if (isNegative(_this__u8e3s4)) {
      var tmp;
      if (isNegative(other)) {
        tmp = multiply(negate(_this__u8e3s4), negate(other));
      } else {
        tmp = negate(multiply(negate(_this__u8e3s4), other));
      }
      return tmp;
    } else if (isNegative(other)) {
      return negate(multiply(_this__u8e3s4, negate(other)));
    }
    if (lessThan(_this__u8e3s4, get_TWO_PWR_24_()) ? lessThan(other, get_TWO_PWR_24_()) : false) {
      return fromNumber(toNumber(_this__u8e3s4) * toNumber(other));
    }
    var a48 = _this__u8e3s4.c3_1 >>> 16 | 0;
    var a32 = _this__u8e3s4.c3_1 & 65535;
    var a16 = _this__u8e3s4.b3_1 >>> 16 | 0;
    var a00 = _this__u8e3s4.b3_1 & 65535;
    var b48 = other.c3_1 >>> 16 | 0;
    var b32 = other.c3_1 & 65535;
    var b16 = other.b3_1 >>> 16 | 0;
    var b00 = other.b3_1 & 65535;
    var c48 = 0;
    var c32 = 0;
    var c16 = 0;
    var c00 = 0;
    c00 = c00 + imul(a00, b00) | 0;
    c16 = c16 + (c00 >>> 16 | 0) | 0;
    c00 = c00 & 65535;
    c16 = c16 + imul(a16, b00) | 0;
    c32 = c32 + (c16 >>> 16 | 0) | 0;
    c16 = c16 & 65535;
    c16 = c16 + imul(a00, b16) | 0;
    c32 = c32 + (c16 >>> 16 | 0) | 0;
    c16 = c16 & 65535;
    c32 = c32 + imul(a32, b00) | 0;
    c48 = c48 + (c32 >>> 16 | 0) | 0;
    c32 = c32 & 65535;
    c32 = c32 + imul(a16, b16) | 0;
    c48 = c48 + (c32 >>> 16 | 0) | 0;
    c32 = c32 & 65535;
    c32 = c32 + imul(a00, b32) | 0;
    c48 = c48 + (c32 >>> 16 | 0) | 0;
    c32 = c32 & 65535;
    c48 = c48 + (((imul(a48, b00) + imul(a32, b16) | 0) + imul(a16, b32) | 0) + imul(a00, b48) | 0) | 0;
    c48 = c48 & 65535;
    return new Long(c16 << 16 | c00, c48 << 16 | c32);
  }
  function divide(_this__u8e3s4, other) {
    _init_properties_longjs_kt__tqrzid();
    if (isZero(other)) {
      throw Exception_init_$Create$_0('division by zero');
    } else if (isZero(_this__u8e3s4)) {
      return get_ZERO();
    }
    if (equalsLong(_this__u8e3s4, get_MIN_VALUE())) {
      if (equalsLong(other, get_ONE()) ? true : equalsLong(other, get_NEG_ONE())) {
        return get_MIN_VALUE();
      } else if (equalsLong(other, get_MIN_VALUE())) {
        return get_ONE();
      } else {
        var halfThis = shiftRight(_this__u8e3s4, 1);
        var approx = shiftLeft(halfThis.l3(other), 1);
        if (equalsLong(approx, get_ZERO())) {
          return isNegative(other) ? get_ONE() : get_NEG_ONE();
        } else {
          var rem = subtract(_this__u8e3s4, multiply(other, approx));
          return add(approx, rem.l3(other));
        }
      }
    } else if (equalsLong(other, get_MIN_VALUE())) {
      return get_ZERO();
    }
    if (isNegative(_this__u8e3s4)) {
      var tmp;
      if (isNegative(other)) {
        tmp = negate(_this__u8e3s4).l3(negate(other));
      } else {
        tmp = negate(negate(_this__u8e3s4).l3(other));
      }
      return tmp;
    } else if (isNegative(other)) {
      return negate(_this__u8e3s4.l3(negate(other)));
    }
    var res = get_ZERO();
    var rem_0 = _this__u8e3s4;
    while (greaterThanOrEqual(rem_0, other)) {
      var approxDouble = toNumber(rem_0) / toNumber(other);
      var approx2 = Math.max(1.0, Math.floor(approxDouble));
      var log2 = Math.ceil(Math.log(approx2) / Math.LN2);
      var delta = log2 <= 48.0 ? 1.0 : Math.pow(2.0, log2 - 48);
      var approxRes = fromNumber(approx2);
      var approxRem = multiply(approxRes, other);
      while (isNegative(approxRem) ? true : greaterThan(approxRem, rem_0)) {
        approx2 = approx2 - delta;
        approxRes = fromNumber(approx2);
        approxRem = multiply(approxRes, other);
      }
      if (isZero(approxRes)) {
        approxRes = get_ONE();
      }
      res = add(res, approxRes);
      rem_0 = subtract(rem_0, approxRem);
    }
    return res;
  }
  function shiftLeft(_this__u8e3s4, numBits) {
    _init_properties_longjs_kt__tqrzid();
    var numBits_0 = numBits & 63;
    if (numBits_0 === 0) {
      return _this__u8e3s4;
    } else {
      if (numBits_0 < 32) {
        return new Long(_this__u8e3s4.b3_1 << numBits_0, _this__u8e3s4.c3_1 << numBits_0 | (_this__u8e3s4.b3_1 >>> (32 - numBits_0 | 0) | 0));
      } else {
        return new Long(0, _this__u8e3s4.b3_1 << (numBits_0 - 32 | 0));
      }
    }
  }
  function shiftRight(_this__u8e3s4, numBits) {
    _init_properties_longjs_kt__tqrzid();
    var numBits_0 = numBits & 63;
    if (numBits_0 === 0) {
      return _this__u8e3s4;
    } else {
      if (numBits_0 < 32) {
        return new Long(_this__u8e3s4.b3_1 >>> numBits_0 | 0 | _this__u8e3s4.c3_1 << (32 - numBits_0 | 0), _this__u8e3s4.c3_1 >> numBits_0);
      } else {
        return new Long(_this__u8e3s4.c3_1 >> (numBits_0 - 32 | 0), _this__u8e3s4.c3_1 >= 0 ? 0 : -1);
      }
    }
  }
  function toNumber(_this__u8e3s4) {
    _init_properties_longjs_kt__tqrzid();
    return _this__u8e3s4.c3_1 * 4.294967296E9 + getLowBitsUnsigned(_this__u8e3s4);
  }
  function equalsLong(_this__u8e3s4, other) {
    _init_properties_longjs_kt__tqrzid();
    return _this__u8e3s4.c3_1 === other.c3_1 ? _this__u8e3s4.b3_1 === other.b3_1 : false;
  }
  function hashCode_0(l) {
    _init_properties_longjs_kt__tqrzid();
    return l.b3_1 ^ l.c3_1;
  }
  function toStringImpl(_this__u8e3s4, radix) {
    _init_properties_longjs_kt__tqrzid();
    if (radix < 2 ? true : 36 < radix) {
      throw Exception_init_$Create$_0('radix out of range: ' + radix);
    }
    if (isZero(_this__u8e3s4)) {
      return '0';
    }
    if (isNegative(_this__u8e3s4)) {
      if (equalsLong(_this__u8e3s4, get_MIN_VALUE())) {
        var radixLong = fromInt(radix);
        var div = _this__u8e3s4.l3(radixLong);
        var rem = subtract(multiply(div, radixLong), _this__u8e3s4).b4();
        var tmp = toStringImpl(div, radix);
        // Inline function 'kotlin.js.unsafeCast' call
        // Inline function 'kotlin.js.asDynamic' call
        return tmp + rem.toString(radix);
      } else {
        return '-' + toStringImpl(negate(_this__u8e3s4), radix);
      }
    }
    var digitsPerTime = radix === 2 ? 31 : radix <= 10 ? 9 : radix <= 21 ? 7 : radix <= 35 ? 6 : 5;
    var radixToPower = fromNumber(Math.pow(radix, digitsPerTime));
    var rem_0 = _this__u8e3s4;
    var result = '';
    while (true) {
      var remDiv = rem_0.l3(radixToPower);
      var intval = subtract(rem_0, multiply(remDiv, radixToPower)).b4();
      // Inline function 'kotlin.js.unsafeCast' call
      // Inline function 'kotlin.js.asDynamic' call
      var digits = intval.toString(radix);
      rem_0 = remDiv;
      if (isZero(rem_0)) {
        return digits + result;
      } else {
        while (digits.length < digitsPerTime) {
          digits = '0' + digits;
        }
        result = digits + result;
      }
    }
  }
  function fromInt(value) {
    _init_properties_longjs_kt__tqrzid();
    return new Long(value, value < 0 ? -1 : 0);
  }
  function isNegative(_this__u8e3s4) {
    _init_properties_longjs_kt__tqrzid();
    return _this__u8e3s4.c3_1 < 0;
  }
  function isZero(_this__u8e3s4) {
    _init_properties_longjs_kt__tqrzid();
    return _this__u8e3s4.c3_1 === 0 ? _this__u8e3s4.b3_1 === 0 : false;
  }
  function isOdd(_this__u8e3s4) {
    _init_properties_longjs_kt__tqrzid();
    return (_this__u8e3s4.b3_1 & 1) === 1;
  }
  function negate(_this__u8e3s4) {
    _init_properties_longjs_kt__tqrzid();
    return _this__u8e3s4.x3();
  }
  function lessThan(_this__u8e3s4, other) {
    _init_properties_longjs_kt__tqrzid();
    return compare(_this__u8e3s4, other) < 0;
  }
  function fromNumber(value) {
    _init_properties_longjs_kt__tqrzid();
    if (isNaN_0(value)) {
      return get_ZERO();
    } else if (value <= -9.223372036854776E18) {
      return get_MIN_VALUE();
    } else if (value + 1 >= 9.223372036854776E18) {
      return get_MAX_VALUE();
    } else if (value < 0.0) {
      return negate(fromNumber(-value));
    } else {
      var twoPwr32 = 4.294967296E9;
      // Inline function 'kotlin.js.jsBitwiseOr' call
      var tmp = value % twoPwr32 | 0;
      // Inline function 'kotlin.js.jsBitwiseOr' call
      var tmp$ret$1 = value / twoPwr32 | 0;
      return new Long(tmp, tmp$ret$1);
    }
  }
  function greaterThan(_this__u8e3s4, other) {
    _init_properties_longjs_kt__tqrzid();
    return compare(_this__u8e3s4, other) > 0;
  }
  function greaterThanOrEqual(_this__u8e3s4, other) {
    _init_properties_longjs_kt__tqrzid();
    return compare(_this__u8e3s4, other) >= 0;
  }
  function getLowBitsUnsigned(_this__u8e3s4) {
    _init_properties_longjs_kt__tqrzid();
    return _this__u8e3s4.b3_1 >= 0 ? _this__u8e3s4.b3_1 : 4.294967296E9 + _this__u8e3s4.b3_1;
  }
  var properties_initialized_longjs_kt_5aju7t;
  function _init_properties_longjs_kt__tqrzid() {
    if (!properties_initialized_longjs_kt_5aju7t) {
      properties_initialized_longjs_kt_5aju7t = true;
      ZERO = fromInt(0);
      ONE = fromInt(1);
      NEG_ONE = fromInt(-1);
      MAX_VALUE = new Long(-1, 2147483647);
      MIN_VALUE = new Long(0, -2147483648);
      TWO_PWR_24_ = fromInt(16777216);
    }
  }
  function classMeta(name, defaultConstructor, associatedObjectKey, associatedObjects, suspendArity) {
    return createMetadata('class', name, defaultConstructor, associatedObjectKey, associatedObjects, suspendArity, null);
  }
  function createMetadata(kind, name, defaultConstructor, associatedObjectKey, associatedObjects, suspendArity, iid) {
    var undef = VOID;
    return {
      kind: kind,
      simpleName: name,
      associatedObjectKey: associatedObjectKey,
      associatedObjects: associatedObjects,
      suspendArity: suspendArity,
      $kClass$: undef,
      defaultConstructor: defaultConstructor,
      iid: iid
    };
  }
  function setMetadataFor(ctor, name, metadataConstructor, parent, interfaces, defaultConstructor, associatedObjectKey, associatedObjects, suspendArity) {
    if (!(parent == null)) {
      ctor.prototype = Object.create(parent.prototype);
      ctor.prototype.constructor = ctor;
    }
    var metadata = metadataConstructor(name, defaultConstructor, associatedObjectKey, associatedObjects, suspendArity == null ? [] : suspendArity);
    ctor.$metadata$ = metadata;
    if (!(interfaces == null)) {
      var receiver = !(metadata.iid == null) ? ctor : ctor.prototype;
      receiver.$imask$ = implement(interfaces);
    }
  }
  function interfaceMeta(name, defaultConstructor, associatedObjectKey, associatedObjects, suspendArity) {
    return createMetadata('interface', name, defaultConstructor, associatedObjectKey, associatedObjects, suspendArity, generateInterfaceId());
  }
  function generateInterfaceId() {
    if (iid === VOID) {
      iid = 0;
    }
    // Inline function 'kotlin.js.unsafeCast' call
    iid = iid + 1 | 0;
    // Inline function 'kotlin.js.unsafeCast' call
    return iid;
  }
  var iid;
  function objectMeta(name, defaultConstructor, associatedObjectKey, associatedObjects, suspendArity) {
    return createMetadata('object', name, defaultConstructor, associatedObjectKey, associatedObjects, suspendArity, null);
  }
  function numberToInt(a) {
    var tmp;
    if (a instanceof Long) {
      tmp = a.b4();
    } else {
      tmp = doubleToInt(a);
    }
    return tmp;
  }
  function doubleToInt(a) {
    var tmp;
    if (a > 2.147483647E9) {
      tmp = 2147483647;
    } else if (a < -2.147483648E9) {
      tmp = -2147483648;
    } else {
      // Inline function 'kotlin.js.jsBitwiseOr' call
      tmp = a | 0;
    }
    return tmp;
  }
  function numberToLong(a) {
    var tmp;
    if (a instanceof Long) {
      tmp = a;
    } else {
      tmp = fromNumber(a);
    }
    return tmp;
  }
  function toLong(a) {
    return fromInt(a);
  }
  function isArrayish(o) {
    return isJsArray(o) ? true : isView(o);
  }
  function isJsArray(obj) {
    // Inline function 'kotlin.js.unsafeCast' call
    return Array.isArray(obj);
  }
  function isInterface(obj, iface) {
    return isInterfaceImpl(obj, iface.$metadata$.iid);
  }
  function isInterfaceImpl(obj, iface) {
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp0_elvis_lhs = obj.$imask$;
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return false;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var mask = tmp;
    return isBitSet(mask, iface);
  }
  function isCharSequence(value) {
    return typeof value === 'string' ? true : isInterface(value, CharSequence);
  }
  function calculateErrorInfo(proto) {
    var tmp0_safe_receiver = proto.constructor;
    var metadata = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.$metadata$;
    var tmp2_safe_receiver = metadata == null ? null : metadata.errorInfo;
    if (tmp2_safe_receiver == null) null;else {
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      return tmp2_safe_receiver;
    }
    var result = 0;
    if (hasProp(proto, 'message')) result = result | 1;
    if (hasProp(proto, 'cause')) result = result | 2;
    if (!(result === 3)) {
      var parentProto = getPrototypeOf(proto);
      if (parentProto != Error.prototype) {
        result = result | calculateErrorInfo(parentProto);
      }
    }
    if (!(metadata == null)) {
      metadata.errorInfo = result;
    }
    return result;
  }
  function hasProp(proto, propName) {
    return proto.hasOwnProperty(propName);
  }
  function getPrototypeOf(obj) {
    return Object.getPrototypeOf(obj);
  }
  function get_VOID() {
    _init_properties_void_kt__3zg9as();
    return VOID;
  }
  var VOID;
  var properties_initialized_void_kt_e4ret2;
  function _init_properties_void_kt__3zg9as() {
    if (!properties_initialized_void_kt_e4ret2) {
      properties_initialized_void_kt_e4ret2 = true;
      VOID = void 0;
    }
  }
  function fill(_this__u8e3s4, element, fromIndex, toIndex) {
    fromIndex = fromIndex === VOID ? 0 : fromIndex;
    toIndex = toIndex === VOID ? _this__u8e3s4.length : toIndex;
    Companion_instance_2.c4(fromIndex, toIndex, _this__u8e3s4.length);
    // Inline function 'kotlin.js.nativeFill' call
    // Inline function 'kotlin.js.asDynamic' call
    _this__u8e3s4.fill(element, fromIndex, toIndex);
  }
  function copyOf(_this__u8e3s4, newSize) {
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!(newSize >= 0)) {
      // Inline function 'kotlin.collections.copyOf.<anonymous>' call
      var message = 'Invalid new array size: ' + newSize + '.';
      throw IllegalArgumentException_init_$Create$_0(toString_1(message));
    }
    return fillFrom(_this__u8e3s4, new Int32Array(newSize));
  }
  function copyOf_0(_this__u8e3s4, newSize) {
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!(newSize >= 0)) {
      // Inline function 'kotlin.collections.copyOf.<anonymous>' call
      var message = 'Invalid new array size: ' + newSize + '.';
      throw IllegalArgumentException_init_$Create$_0(toString_1(message));
    }
    return arrayCopyResize(_this__u8e3s4, newSize, null);
  }
  function Exception_init_$Init$($this) {
    extendThrowable($this);
    Exception.call($this);
    return $this;
  }
  function Exception_init_$Create$() {
    var tmp = Exception_init_$Init$(objectCreate(protoOf(Exception)));
    captureStack(tmp, Exception_init_$Create$);
    return tmp;
  }
  function Exception_init_$Init$_0(message, $this) {
    extendThrowable($this, message);
    Exception.call($this);
    return $this;
  }
  function Exception_init_$Create$_0(message) {
    var tmp = Exception_init_$Init$_0(message, objectCreate(protoOf(Exception)));
    captureStack(tmp, Exception_init_$Create$_0);
    return tmp;
  }
  function Exception() {
    captureStack(this, Exception);
  }
  function IllegalArgumentException_init_$Init$($this) {
    RuntimeException_init_$Init$($this);
    IllegalArgumentException.call($this);
    return $this;
  }
  function IllegalArgumentException_init_$Create$() {
    var tmp = IllegalArgumentException_init_$Init$(objectCreate(protoOf(IllegalArgumentException)));
    captureStack(tmp, IllegalArgumentException_init_$Create$);
    return tmp;
  }
  function IllegalArgumentException_init_$Init$_0(message, $this) {
    RuntimeException_init_$Init$_0(message, $this);
    IllegalArgumentException.call($this);
    return $this;
  }
  function IllegalArgumentException_init_$Create$_0(message) {
    var tmp = IllegalArgumentException_init_$Init$_0(message, objectCreate(protoOf(IllegalArgumentException)));
    captureStack(tmp, IllegalArgumentException_init_$Create$_0);
    return tmp;
  }
  function IllegalArgumentException() {
    captureStack(this, IllegalArgumentException);
  }
  function IndexOutOfBoundsException_init_$Init$($this) {
    RuntimeException_init_$Init$($this);
    IndexOutOfBoundsException.call($this);
    return $this;
  }
  function IndexOutOfBoundsException_init_$Create$() {
    var tmp = IndexOutOfBoundsException_init_$Init$(objectCreate(protoOf(IndexOutOfBoundsException)));
    captureStack(tmp, IndexOutOfBoundsException_init_$Create$);
    return tmp;
  }
  function IndexOutOfBoundsException_init_$Init$_0(message, $this) {
    RuntimeException_init_$Init$_0(message, $this);
    IndexOutOfBoundsException.call($this);
    return $this;
  }
  function IndexOutOfBoundsException_init_$Create$_0(message) {
    var tmp = IndexOutOfBoundsException_init_$Init$_0(message, objectCreate(protoOf(IndexOutOfBoundsException)));
    captureStack(tmp, IndexOutOfBoundsException_init_$Create$_0);
    return tmp;
  }
  function IndexOutOfBoundsException() {
    captureStack(this, IndexOutOfBoundsException);
  }
  function IllegalStateException_init_$Init$($this) {
    RuntimeException_init_$Init$($this);
    IllegalStateException.call($this);
    return $this;
  }
  function IllegalStateException_init_$Create$() {
    var tmp = IllegalStateException_init_$Init$(objectCreate(protoOf(IllegalStateException)));
    captureStack(tmp, IllegalStateException_init_$Create$);
    return tmp;
  }
  function IllegalStateException_init_$Init$_0(message, $this) {
    RuntimeException_init_$Init$_0(message, $this);
    IllegalStateException.call($this);
    return $this;
  }
  function IllegalStateException_init_$Create$_0(message) {
    var tmp = IllegalStateException_init_$Init$_0(message, objectCreate(protoOf(IllegalStateException)));
    captureStack(tmp, IllegalStateException_init_$Create$_0);
    return tmp;
  }
  function IllegalStateException() {
    captureStack(this, IllegalStateException);
  }
  function UnsupportedOperationException_init_$Init$($this) {
    RuntimeException_init_$Init$($this);
    UnsupportedOperationException.call($this);
    return $this;
  }
  function UnsupportedOperationException_init_$Create$() {
    var tmp = UnsupportedOperationException_init_$Init$(objectCreate(protoOf(UnsupportedOperationException)));
    captureStack(tmp, UnsupportedOperationException_init_$Create$);
    return tmp;
  }
  function UnsupportedOperationException() {
    captureStack(this, UnsupportedOperationException);
  }
  function RuntimeException_init_$Init$($this) {
    Exception_init_$Init$($this);
    RuntimeException.call($this);
    return $this;
  }
  function RuntimeException_init_$Create$() {
    var tmp = RuntimeException_init_$Init$(objectCreate(protoOf(RuntimeException)));
    captureStack(tmp, RuntimeException_init_$Create$);
    return tmp;
  }
  function RuntimeException_init_$Init$_0(message, $this) {
    Exception_init_$Init$_0(message, $this);
    RuntimeException.call($this);
    return $this;
  }
  function RuntimeException_init_$Create$_0(message) {
    var tmp = RuntimeException_init_$Init$_0(message, objectCreate(protoOf(RuntimeException)));
    captureStack(tmp, RuntimeException_init_$Create$_0);
    return tmp;
  }
  function RuntimeException() {
    captureStack(this, RuntimeException);
  }
  function NoSuchElementException_init_$Init$($this) {
    RuntimeException_init_$Init$($this);
    NoSuchElementException.call($this);
    return $this;
  }
  function NoSuchElementException_init_$Create$() {
    var tmp = NoSuchElementException_init_$Init$(objectCreate(protoOf(NoSuchElementException)));
    captureStack(tmp, NoSuchElementException_init_$Create$);
    return tmp;
  }
  function NoSuchElementException() {
    captureStack(this, NoSuchElementException);
  }
  function Error_init_$Init$($this) {
    extendThrowable($this);
    Error_0.call($this);
    return $this;
  }
  function Error_init_$Create$() {
    var tmp = Error_init_$Init$(objectCreate(protoOf(Error_0)));
    captureStack(tmp, Error_init_$Create$);
    return tmp;
  }
  function Error_init_$Init$_0(message, $this) {
    extendThrowable($this, message);
    Error_0.call($this);
    return $this;
  }
  function Error_0() {
    captureStack(this, Error_0);
  }
  function AssertionError_init_$Init$($this) {
    Error_init_$Init$($this);
    AssertionError.call($this);
    return $this;
  }
  function AssertionError_init_$Create$() {
    var tmp = AssertionError_init_$Init$(objectCreate(protoOf(AssertionError)));
    captureStack(tmp, AssertionError_init_$Create$);
    return tmp;
  }
  function AssertionError_init_$Init$_0(message, $this) {
    Error_init_$Init$_0(message, $this);
    AssertionError.call($this);
    return $this;
  }
  function AssertionError_init_$Create$_0(message) {
    var tmp = AssertionError_init_$Init$_0(message, objectCreate(protoOf(AssertionError)));
    captureStack(tmp, AssertionError_init_$Create$_0);
    return tmp;
  }
  function AssertionError() {
    captureStack(this, AssertionError);
  }
  function ConcurrentModificationException_init_$Init$($this) {
    RuntimeException_init_$Init$($this);
    ConcurrentModificationException.call($this);
    return $this;
  }
  function ConcurrentModificationException_init_$Create$() {
    var tmp = ConcurrentModificationException_init_$Init$(objectCreate(protoOf(ConcurrentModificationException)));
    captureStack(tmp, ConcurrentModificationException_init_$Create$);
    return tmp;
  }
  function ConcurrentModificationException() {
    captureStack(this, ConcurrentModificationException);
  }
  function NullPointerException_init_$Init$($this) {
    RuntimeException_init_$Init$($this);
    NullPointerException.call($this);
    return $this;
  }
  function NullPointerException_init_$Create$() {
    var tmp = NullPointerException_init_$Init$(objectCreate(protoOf(NullPointerException)));
    captureStack(tmp, NullPointerException_init_$Create$);
    return tmp;
  }
  function NullPointerException() {
    captureStack(this, NullPointerException);
  }
  function NoWhenBranchMatchedException_init_$Init$($this) {
    RuntimeException_init_$Init$($this);
    NoWhenBranchMatchedException.call($this);
    return $this;
  }
  function NoWhenBranchMatchedException_init_$Create$() {
    var tmp = NoWhenBranchMatchedException_init_$Init$(objectCreate(protoOf(NoWhenBranchMatchedException)));
    captureStack(tmp, NoWhenBranchMatchedException_init_$Create$);
    return tmp;
  }
  function NoWhenBranchMatchedException() {
    captureStack(this, NoWhenBranchMatchedException);
  }
  function ClassCastException_init_$Init$($this) {
    RuntimeException_init_$Init$($this);
    ClassCastException.call($this);
    return $this;
  }
  function ClassCastException_init_$Create$() {
    var tmp = ClassCastException_init_$Init$(objectCreate(protoOf(ClassCastException)));
    captureStack(tmp, ClassCastException_init_$Create$);
    return tmp;
  }
  function ClassCastException() {
    captureStack(this, ClassCastException);
  }
  function AbstractCollection$toString$lambda(this$0) {
    return function (it) {
      return it === this$0 ? '(this Collection)' : toString_0(it);
    };
  }
  function AbstractCollection() {}
  protoOf(AbstractCollection).k = function (element) {
    var tmp$ret$0;
    $l$block_0: {
      // Inline function 'kotlin.collections.any' call
      var tmp;
      if (isInterface(this, Collection)) {
        tmp = this.m();
      } else {
        tmp = false;
      }
      if (tmp) {
        tmp$ret$0 = false;
        break $l$block_0;
      }
      var tmp0_iterator = this.j();
      while (tmp0_iterator.j2()) {
        var element_0 = tmp0_iterator.p2();
        // Inline function 'kotlin.collections.AbstractCollection.contains.<anonymous>' call
        if (equals(element_0, element)) {
          tmp$ret$0 = true;
          break $l$block_0;
        }
      }
      tmp$ret$0 = false;
    }
    return tmp$ret$0;
  };
  protoOf(AbstractCollection).l = function (elements) {
    var tmp$ret$0;
    $l$block_0: {
      // Inline function 'kotlin.collections.all' call
      var tmp;
      if (isInterface(elements, Collection)) {
        tmp = elements.m();
      } else {
        tmp = false;
      }
      if (tmp) {
        tmp$ret$0 = true;
        break $l$block_0;
      }
      var tmp0_iterator = elements.j();
      while (tmp0_iterator.j2()) {
        var element = tmp0_iterator.p2();
        // Inline function 'kotlin.collections.AbstractCollection.containsAll.<anonymous>' call
        if (!this.k(element)) {
          tmp$ret$0 = false;
          break $l$block_0;
        }
      }
      tmp$ret$0 = true;
    }
    return tmp$ret$0;
  };
  protoOf(AbstractCollection).m = function () {
    return this.i() === 0;
  };
  protoOf(AbstractCollection).toString = function () {
    return joinToString(this, ', ', '[', ']', VOID, VOID, AbstractCollection$toString$lambda(this));
  };
  protoOf(AbstractCollection).toArray = function () {
    return collectionToArray(this);
  };
  function Companion_2() {
    this.x1_1 = 2147483639;
  }
  protoOf(Companion_2).c4 = function (fromIndex, toIndex, size) {
    if (fromIndex < 0 ? true : toIndex > size) {
      throw IndexOutOfBoundsException_init_$Create$_0('fromIndex: ' + fromIndex + ', toIndex: ' + toIndex + ', size: ' + size);
    }
    if (fromIndex > toIndex) {
      throw IllegalArgumentException_init_$Create$_0('fromIndex: ' + fromIndex + ' > toIndex: ' + toIndex);
    }
  };
  protoOf(Companion_2).y1 = function (oldCapacity, minCapacity) {
    var newCapacity = oldCapacity + (oldCapacity >> 1) | 0;
    if ((newCapacity - minCapacity | 0) < 0) newCapacity = minCapacity;
    if ((newCapacity - 2147483639 | 0) > 0) newCapacity = minCapacity > 2147483639 ? IntCompanionObject_instance.MAX_VALUE : 2147483639;
    return newCapacity;
  };
  var Companion_instance_2;
  function Companion_getInstance_2() {
    return Companion_instance_2;
  }
  function toString_2($this, o) {
    return o === $this ? '(this Map)' : toString_0(o);
  }
  function implFindEntry($this, key) {
    var tmp$ret$1;
    $l$block: {
      // Inline function 'kotlin.collections.firstOrNull' call
      var tmp0_iterator = $this.x().j();
      while (tmp0_iterator.j2()) {
        var element = tmp0_iterator.p2();
        // Inline function 'kotlin.collections.AbstractMap.implFindEntry.<anonymous>' call
        if (equals(element.y2(), key)) {
          tmp$ret$1 = element;
          break $l$block;
        }
      }
      tmp$ret$1 = null;
    }
    return tmp$ret$1;
  }
  function Companion_3() {}
  var Companion_instance_3;
  function Companion_getInstance_3() {
    return Companion_instance_3;
  }
  function AbstractMap$toString$lambda(this$0) {
    return function (it) {
      return this$0.f4(it);
    };
  }
  function AbstractMap() {
    this.s_1 = null;
    this.t_1 = null;
  }
  protoOf(AbstractMap).u = function (key) {
    return !(implFindEntry(this, key) == null);
  };
  protoOf(AbstractMap).v = function (entry) {
    if (!(!(entry == null) ? isInterface(entry, Entry) : false)) return false;
    var key = entry.y2();
    var value = entry.z2();
    // Inline function 'kotlin.collections.get' call
    var ourValue = (isInterface(this, Map_0) ? this : THROW_CCE()).w(key);
    if (!equals(value, ourValue)) {
      return false;
    }
    var tmp;
    if (ourValue == null) {
      // Inline function 'kotlin.collections.containsKey' call
      tmp = !(isInterface(this, Map_0) ? this : THROW_CCE()).u(key);
    } else {
      tmp = false;
    }
    if (tmp) {
      return false;
    }
    return true;
  };
  protoOf(AbstractMap).equals = function (other) {
    if (other === this) return true;
    if (!(!(other == null) ? isInterface(other, Map_0) : false)) return false;
    if (!(this.i() === other.i())) return false;
    var tmp$ret$0;
    $l$block_0: {
      // Inline function 'kotlin.collections.all' call
      var this_0 = other.x();
      var tmp;
      if (isInterface(this_0, Collection)) {
        tmp = this_0.m();
      } else {
        tmp = false;
      }
      if (tmp) {
        tmp$ret$0 = true;
        break $l$block_0;
      }
      var tmp0_iterator = this_0.j();
      while (tmp0_iterator.j2()) {
        var element = tmp0_iterator.p2();
        // Inline function 'kotlin.collections.AbstractMap.equals.<anonymous>' call
        if (!this.v(element)) {
          tmp$ret$0 = false;
          break $l$block_0;
        }
      }
      tmp$ret$0 = true;
    }
    return tmp$ret$0;
  };
  protoOf(AbstractMap).w = function (key) {
    var tmp0_safe_receiver = implFindEntry(this, key);
    return tmp0_safe_receiver == null ? null : tmp0_safe_receiver.z2();
  };
  protoOf(AbstractMap).hashCode = function () {
    return hashCode(this.x());
  };
  protoOf(AbstractMap).i = function () {
    return this.x().i();
  };
  protoOf(AbstractMap).toString = function () {
    var tmp = this.x();
    return joinToString(tmp, ', ', '{', '}', VOID, VOID, AbstractMap$toString$lambda(this));
  };
  protoOf(AbstractMap).f4 = function (entry) {
    return toString_2(this, entry.y2()) + '=' + toString_2(this, entry.z2());
  };
  function Companion_4() {}
  protoOf(Companion_4).z = function (c) {
    var hashCode_0 = 0;
    var tmp0_iterator = c.j();
    while (tmp0_iterator.j2()) {
      var element = tmp0_iterator.p2();
      var tmp = hashCode_0;
      var tmp2_elvis_lhs = element == null ? null : hashCode(element);
      hashCode_0 = tmp + (tmp2_elvis_lhs == null ? 0 : tmp2_elvis_lhs) | 0;
    }
    return hashCode_0;
  };
  protoOf(Companion_4).y = function (c, other) {
    if (!(c.i() === other.i())) return false;
    // Inline function 'kotlin.collections.containsAll' call
    return c.l(other);
  };
  var Companion_instance_4;
  function Companion_getInstance_4() {
    return Companion_instance_4;
  }
  function collectionToArrayCommonImpl(collection) {
    if (collection.m()) {
      // Inline function 'kotlin.emptyArray' call
      return [];
    }
    // Inline function 'kotlin.arrayOfNulls' call
    var size = collection.i();
    var destination = fillArrayVal(Array(size), null);
    var iterator = collection.j();
    var index = 0;
    while (iterator.j2()) {
      var tmp0 = index;
      index = tmp0 + 1 | 0;
      destination[tmp0] = iterator.p2();
    }
    return destination;
  }
  function appendElement(_this__u8e3s4, element, transform) {
    if (!(transform == null)) {
      _this__u8e3s4.e3(transform(element));
    } else {
      if (element == null ? true : isCharSequence(element)) {
        _this__u8e3s4.e3(element);
      } else {
        if (element instanceof Char) {
          _this__u8e3s4.v2(element.g4_1);
        } else {
          _this__u8e3s4.e3(toString_0(element));
        }
      }
    }
  }
  function _Duration___init__impl__kdtzql(rawValue) {
    // Inline function 'kotlin.time.durationAssertionsEnabled' call
    if (true) {
      if (isInNanos(rawValue)) {
        var containsArg = _get_value__a43j40_0(rawValue);
        if (!(new Long(387905, -1073741824).n3(containsArg) <= 0 ? containsArg.n3(new Long(-387905, 1073741823)) <= 0 : false)) throw AssertionError_init_$Create$_0(_get_value__a43j40_0(rawValue).toString() + ' ns is out of nanoseconds range');
      } else {
        var containsArg_0 = _get_value__a43j40_0(rawValue);
        if (!(new Long(1, -1073741824).n3(containsArg_0) <= 0 ? containsArg_0.n3(new Long(-1, 1073741823)) <= 0 : false)) throw AssertionError_init_$Create$_0(_get_value__a43j40_0(rawValue).toString() + ' ms is out of milliseconds range');
        var containsArg_1 = _get_value__a43j40_0(rawValue);
        if (new Long(1108857478, -1074).n3(containsArg_1) <= 0 ? containsArg_1.n3(new Long(-1108857478, 1073)) <= 0 : false) throw AssertionError_init_$Create$_0(_get_value__a43j40_0(rawValue).toString() + ' ms is denormalized');
      }
    }
    return rawValue;
  }
  function _get_rawValue__5zfu4e($this) {
    return $this;
  }
  function _get_value__a43j40_0($this) {
    return _get_rawValue__5zfu4e($this).a4(1);
  }
  function isInNanos($this) {
    // Inline function 'kotlin.time.Duration.unitDiscriminator' call
    return (_get_rawValue__5zfu4e($this).b4() & 1) === 0;
  }
  function _get_storageUnit__szjgha($this) {
    return isInNanos($this) ? DurationUnit_NANOSECONDS_getInstance() : DurationUnit_MILLISECONDS_getInstance();
  }
  function Companion_5() {
    Companion_instance_5 = this;
    this.h4_1 = _Duration___init__impl__kdtzql(new Long(0, 0));
    this.i4_1 = durationOfMillis(new Long(-1, 1073741823));
    this.j4_1 = durationOfMillis(new Long(1, -1073741824));
  }
  var Companion_instance_5;
  function Companion_getInstance_5() {
    if (Companion_instance_5 == null) new Companion_5();
    return Companion_instance_5;
  }
  function Duration__toLong_impl_shr43i($this, unit) {
    var tmp0_subject = _get_rawValue__5zfu4e($this);
    var tmp;
    if (tmp0_subject.equals(_get_rawValue__5zfu4e(Companion_getInstance_5().i4_1))) {
      Companion_getInstance_1();
      tmp = new Long(-1, 2147483647);
    } else if (tmp0_subject.equals(_get_rawValue__5zfu4e(Companion_getInstance_5().j4_1))) {
      Companion_getInstance_1();
      tmp = new Long(0, -2147483648);
    } else {
      tmp = convertDurationUnit_0(_get_value__a43j40_0($this), _get_storageUnit__szjgha($this), unit);
    }
    return tmp;
  }
  function _Duration___get_inWholeHours__impl__kb9f3j($this) {
    return Duration__toLong_impl_shr43i($this, DurationUnit_HOURS_getInstance());
  }
  function _Duration___get_inWholeMinutes__impl__dognoh($this) {
    return Duration__toLong_impl_shr43i($this, DurationUnit_MINUTES_getInstance());
  }
  function _Duration___get_inWholeSeconds__impl__hpy7b3($this) {
    return Duration__toLong_impl_shr43i($this, DurationUnit_SECONDS_getInstance());
  }
  function durationOfMillis(normalMillis) {
    // Inline function 'kotlin.Long.plus' call
    var tmp$ret$0 = normalMillis.z3(1).v3(toLong(1));
    return _Duration___init__impl__kdtzql(tmp$ret$0);
  }
  function toDuration(_this__u8e3s4, unit) {
    var valueInNs = convertDurationUnit(_this__u8e3s4, unit, DurationUnit_NANOSECONDS_getInstance());
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!!isNaN_0(valueInNs)) {
      // Inline function 'kotlin.time.toDuration.<anonymous>' call
      var message = 'Duration value cannot be NaN.';
      throw IllegalArgumentException_init_$Create$_0(toString_1(message));
    }
    var nanos = roundToLong(valueInNs);
    var tmp;
    if (new Long(387905, -1073741824).n3(nanos) <= 0 ? nanos.n3(new Long(-387905, 1073741823)) <= 0 : false) {
      tmp = durationOfNanos(nanos);
    } else {
      var millis = roundToLong(convertDurationUnit(_this__u8e3s4, unit, DurationUnit_MILLISECONDS_getInstance()));
      tmp = durationOfMillisNormalized(millis);
    }
    return tmp;
  }
  function durationOfMillisNormalized(millis) {
    var tmp;
    if (new Long(1108857478, -1074).n3(millis) <= 0 ? millis.n3(new Long(-1108857478, 1073)) <= 0 : false) {
      tmp = durationOfNanos(millisToNanos(millis));
    } else {
      tmp = durationOfMillis(coerceIn(millis, new Long(1, -1073741824), new Long(-1, 1073741823)));
    }
    return tmp;
  }
  function millisToNanos(millis) {
    // Inline function 'kotlin.Long.times' call
    return millis.m3(toLong(1000000));
  }
  function durationOfNanos(normalNanos) {
    return _Duration___init__impl__kdtzql(normalNanos.z3(1));
  }
  //region block: post-declaration
  protoOf(InternalHashMap).m1 = containsAllEntries;
  //endregion
  //region block: init
  Unit_instance = new Unit();
  IntCompanionObject_instance = new IntCompanionObject();
  DoubleCompanionObject_instance = new DoubleCompanionObject();
  Companion_instance = new Companion();
  Companion_instance_0 = new Companion_0();
  Companion_instance_2 = new Companion_2();
  Companion_instance_3 = new Companion_3();
  Companion_instance_4 = new Companion_4();
  //endregion
  //region block: exports
  _.$_$ = _.$_$ || {};
  _.$_$.a = VOID;
  _.$_$.b = DurationUnit_HOURS_getInstance;
  _.$_$.c = DurationUnit_MINUTES_getInstance;
  _.$_$.d = LinkedHashMap_init_$Create$;
  _.$_$.e = Exception_init_$Create$_0;
  _.$_$.f = IllegalArgumentException_init_$Create$_0;
  _.$_$.g = _Duration___get_inWholeHours__impl__kb9f3j;
  _.$_$.h = _Duration___get_inWholeMinutes__impl__dognoh;
  _.$_$.i = _Duration___get_inWholeSeconds__impl__hpy7b3;
  _.$_$.j = Companion_getInstance_5;
  _.$_$.k = Unit_instance;
  _.$_$.l = charSequenceLength;
  _.$_$.m = classMeta;
  _.$_$.n = defineProp;
  _.$_$.o = equals;
  _.$_$.p = getNumberHashCode;
  _.$_$.q = getStringHashCode;
  _.$_$.r = hashCode;
  _.$_$.s = interfaceMeta;
  _.$_$.t = objectMeta;
  _.$_$.u = protoOf;
  _.$_$.v = setMetadataFor;
  _.$_$.w = toLong;
  _.$_$.x = toString_1;
  _.$_$.y = toDuration;
  _.$_$.z = Enum;
  _.$_$.a1 = Long;
  _.$_$.b1 = THROW_CCE;
  _.$_$.c1 = THROW_IAE;
  _.$_$.d1 = isFinite;
  _.$_$.e1 = noWhenBranchMatchedException;
  //endregion
  return _;
});

