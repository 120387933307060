export const languagesArr = [
  { id: 'cs', label: 'language.czech' },
  { id: 'da', label: 'language.danish' },
  { id: 'cs', label: 'language.czech' },
  { id: 'de', label: 'language.german' },
  { id: 'en-gb', label: 'language.english_gb' },
  { id: 'en-us', label: 'language.english_us' },
  { id: 'es', label: 'language.spanish' },
  { id: 'et', label: 'language.estonian' },
  { id: 'fi', label: 'language.finnish' },
  { id: 'fr', label: 'language.french' },
  { id: 'hr', label: 'language.croatian' },
  { id: 'it', label: 'language.italian' },
  { id: 'lv', label: 'language.letvian' },
  { id: 'lt', label: 'language.lithuanian' },
  { id: 'nl', label: 'language.dutch' },
  { id: 'no', label: 'language.norwegian' },
  { id: 'pl', label: 'language.polish' },
  { id: 'ro', label: 'language.romanian' },
  { id: 'pt', label: 'language.portuguese' },
  { id: 'ro', label: 'language.romanian' },
  { id: 'sv', label: 'language.swedish' },
  { id: 'sl', label: 'language.slovenian' },
  { id: 'sk', label: 'language.slovak' },
  { id: 'uk', label: 'language.ukrainian' },
  { id: 'hu', label: 'language.hungarian' },
  { id: 'ru', label: 'language.russian'}
];
