<div class="avatar-view">
  <div class="avatar-view__header">
    <div
      *ngIf="(confirmationDialogIsActive$ | async) === false"
      class="avatar-view__header-main">
      <h2 class="avatar-view__header-title subtitle-2">
        {{ 'personal_data.avatar.edit_header' | translate }}
      </h2>
    </div>

    <div
      *ngIf="confirmationDialogIsActive$ | async"
      class="avatar-view__header-confirmation-dialog">
      <div class="avatar-view__header-confirmation-dialog--header">
        <h2 class="subtitle-2">
          {{ 'personal_data.avatar.confirmation_header' | translate }}
        </h2>
      </div>
      <span class="dialog-window__confirmation-dialog--text">{{
        'personal_data.avatar.confirmation_text' | translate
      }}</span>
    </div>
  </div>

  <section *ngIf="(confirmationDialogIsActive$ | async) === false">
    <div class="avatar-view__body">
      <div class="avatar-view__body-content">
        <div class="avatar-picture">
          <div
            *ngIf="avatarUrl; else avatarNotLoaded"
            class="avatar-picture__image">
            <img [src]="avatarUrl" />
          </div>

          <ng-template #avatarNotLoaded>
            <span class="avatar-picture__loader">
              <claas-circular-progress
                [color]="secondaryColor"
                [diameter]="largeSize"></claas-circular-progress>
            </span>
          </ng-template>
        </div>
        <div class="avatar-action">
          <input
              #hiddenFileInput
              type="file"
              accept="image/*"
              (change)="onFileChanged($event)" />
          <claas-icon-button
            [icon]="editIcon"
            [iconSize]="mediumIconSize"
            [fontSize]="mediumIconFontSize"
            (onClick)="hiddenFileInput.click()"></claas-icon-button>
        </div>
      </div>
    </div>

    <div class="avatar-view__dialog-actions">
      <div class="left-side">
        <div class="btn-left">
          <app-button
            *ngIf="data?.buttonDelete"
            class="btn-left"
            type="warn"
            [label]="'generic.delete' | translate"
            (click)="showConfirmationDialog()"></app-button>
        </div>
      </div>
      <div class="right-side">
        <claas-button
          *ngIf="data?.buttonCancel"
          class="btn-left"
          [buttonClass]="secondaryTextButtonClass"
          [text]="'generic.cancel' | translate"
          (onClick)="onCancelClicked()">
        </claas-button>

        <claas-button
          *ngIf="data?.buttonSave"
          class="btn-right"
          [text]="'generic.save' | translate"
          (onClick)="onSaveClicked()">
        </claas-button>
      </div>
    </div>
  </section>

  <section *ngIf="confirmationDialogIsActive$ | async">
    <div class="avatar-view__dialog-actions confirmation-dialog">
      <div class="left-side">
        <div class="btn-left"></div>
      </div>
      <div class="right-side">
        <claas-button
          class="btn-left"
          [buttonClass]="secondaryTextButtonClass"
          [text]="'generic.cancel' | translate"
          (onClick)="deletionIsCanceled()">
        </claas-button>

        <claas-button
          class="btn-left"
          [buttonClass]="errorButtonClass"
          [text]="'generic.delete' | translate"
          (onClick)="deletionIsConfirmed()">
        </claas-button>
      </div>
    </div>
  </section>
</div>
