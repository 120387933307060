import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ButtonClass } from '@claas/claas-form-components';

@Component({
  selector: 'app-unavailable-app-view',
  templateUrl: './unavailable-app-view.component.html',
  styleUrl: './unavailable-app-view.component.scss'
})
export class UnavailableAppViewComponent {
  secondaryOutlineButtonClass = ButtonClass.SECONDARY_OUTLINE;

  constructor(private router: Router) {}

  redirectToRoutePage(): void {
    this.router.navigate(['/']);
  }
}
