import {
  IAddressDetailsResponse,
  IRegisterOrganisationResult,
  IRegistrationCurrentDefaultOrganisation,
  IRegistrationDialogStep
} from 'cde-fe-organization-registration-dialog/lib/models/organisation.model';
import { Organization } from '../../models/organization.model';
import { IAddressSearchResponse } from 'cde-fe-organization-registration-dialog';

export interface UserOrganisations {
  [key: string]: any;
}

export type StateError = Error | string | null;

export interface UserOrganisationsState {
  userOrganisations: UserOrganisations | null;
  defaultOrganisationId: string | null;
  registrationSteps: IRegistrationDialogStep[] | undefined;
  registrationDefaultOrganisation: IRegistrationCurrentDefaultOrganisation | null;
  registeredOrganisation: IRegisterOrganisationResult | null;
  currentOrganisation: Partial<Organization> | null;
  addressDetails: IAddressDetailsResponse | null;
  organisationError: StateError;
  addressSearchResult: IAddressSearchResponse | null;
}

export const initialState: UserOrganisationsState = {
  userOrganisations: null,
  defaultOrganisationId: null,
  registrationSteps: undefined,
  registrationDefaultOrganisation: null,
  registeredOrganisation: null,
  currentOrganisation: null,
  addressDetails: null,
  organisationError: null,
  addressSearchResult: null
};
