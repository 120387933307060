import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OrganisationDealersState } from './dealers.state';

export const selectDealersState =
  createFeatureSelector<OrganisationDealersState>('dealers');

export const selectDefaultDealer = createSelector(
  selectDealersState,
  state => state.defaultDealer
);

export const selectNearestDealerSearchData = createSelector(
  selectDealersState,
  state => state.nearestDealersSearchData
);

export const selectNearestDealerSearchDataPending = createSelector(
  selectDealersState,
  state => state.nearestDealersSearchDataPending
);

export const selectDealerByNameSearchData = createSelector(
  selectDealersState,
  state => state.dealerByNameSearchData
);

export const selectDealerFiltersData = createSelector(
  selectDealersState,
  state => state.dealerFilters
);

export const getDealers = createSelector(
  selectDealersState,
  state => state.dealers
);
