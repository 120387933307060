import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { passedDataToDialog } from '../dialog-window/dialog-window.component';
import { ButtonClass } from '@claas/claas-form-components';
import { BehaviorSubject } from 'rxjs';
import { OrganisationDealerModel } from '../../models/organisation-dealer.model';

export interface selectionSate {
  selected: boolean;
}

@Component({
  selector: 'app-organisation-connected-dealers-view',
  templateUrl: './organisation-connected-dealers-view.component.html',
  styleUrls: ['./organisation-connected-dealers-view.component.scss']
})
export class OrganisationConnectedDealersViewComponent
  implements AfterViewInit
{
  @Input() data: passedDataToDialog = {};
  @Input() errorButtonClass?: ButtonClass;
  @Input() primaryTextButtonClass?: ButtonClass;
  @Input() secondaryTextButtonClass?: ButtonClass;

  @Output() displayPopup = new EventEmitter();
  @Output() closeDialogWindow = new EventEmitter();

  selectionStateArray: selectionSate[] = [];
  saveIsPossible = false;
  confirmationDialogIsActive$ = new BehaviorSubject(false);

  constructor() {}

  ngAfterViewInit() {
    this.data.connectedDealers?.forEach(dealer =>
      this.selectionStateArray.push({ selected: true })
    );
  }

  saveSelection(): void {
    const disconnectedDealers: OrganisationDealerModel[] | null = [];
    const initialDealers = this.data.connectedDealers
      ? this.data?.connectedDealers
      : [];

    this.selectionStateArray.forEach((element: any, index: number) => {
      if (!element.selected) {
        disconnectedDealers.push(initialDealers[index]);
      }
    });

    if (disconnectedDealers.length) {
      const passedObject = {
        action: 'save',
        context: this.data.context,
        disconnectedDealers: disconnectedDealers,
        refreshIsRequired: true
      };

      this.closeDialogWindow.emit(passedObject);
    } else {
      this.cancelSelection();
    }
  }

  cancelSelection(): void {
    const passedObject = {
      action: 'cancel'
    };

    this.closeDialogWindow.emit(passedObject);
  }

  selectionHasChanged(isSelected: any, index: number): void {
    this.selectionStateArray[index].selected = isSelected;
    this.saveIsPossible = true;
  }
}
