<div class="content">
  <claas-tabs [tabs]="tabs" [selectedIndex]="0"></claas-tabs>

  <ng-template #privacy>
    <pdf-viewer
      [src]="pdfSrc"
      [render-text]="true"
      [original-size]="true"
      style="width: 100%; height: 600px"></pdf-viewer>
  </ng-template>

  <ng-template #cookies>
    <div class="cookie-settings">
      <claas-button [text]="cookieSettings" onclick="UC_UI.showSecondLayer();">
      </claas-button>
    </div>
  </ng-template>
</div>
