<div class="applications">
  <div>
    <section class="applications__header">
      <h1 class="applications__header-title">
        {{ 'navigation.connected_apps' | translate }}
      </h1>
    </section>

    <section
      *ngIf="(isLoadingApplications$ | async) === false"
      class="applications__content">
      <div *ngIf="applications$ | async as applications; else NoApplication">
        <div class="applications__content-upper-message">
          <span>{{ 'connected_apps.info' | translate }}</span>
        </div>

        <section class="applications__content-list">
          <div class="applications__content-list--header label-1-style">
            <span
              >{{ 'connected_apps.apps' | translate }} (
              {{ applications.length }} )</span
            >
          </div>

          <div
            *ngFor="let application of applications"
            class="applications__content-list--item">
            <div class="left-container">
              <img
                [src]="application.img"
                alt="{{ (application.label | translate)?.logo_alt }}" />
            </div>
            <div class="right-container">
              <div class="first-content">
                <span>{{ (application.label | translate)?.name }}</span>
              </div>
              <div class="second-content">
                <div class="second-content--status">
                  <app-chip
                    *ngIf="application?.connected"
                    [label]="'connected_apps.status.connected' | translate"
                    [type]="'accepted'"></app-chip>

                  <app-chip
                    *ngIf="!application?.connected"
                    [label]="'connected_apps.status.not_connected' | translate"
                    [type]="'outdated'"></app-chip>
                </div>

                <div class="second-content--actions">
                  <claas-icon-button
                    *ngIf="!application?.connected"
                    [icon]="connectIcon"
                    [iconSize]="mediumIconSize"
                    [fontSize]="mediumIconFontSize"
                    (click)="
                      openDialog('connect', application?.application_id, application?.id)
                    "></claas-icon-button>

                  <claas-icon-button
                    *ngIf="application?.connected"
                    [icon]="disconnectIcon"
                    [iconSize]="mediumIconSize"
                    [fontSize]="mediumIconFontSize"
                    (click)="
                      openDialog('disconnect', application?.application_id, application?.id)
                    "></claas-icon-button>

                  <claas-icon-button
                    [icon]="expandIcon"
                    [iconSize]="mediumIconSize"
                    [fontSize]="mediumIconFontSize"
                    (onClick)="
                      navigateToApp(application.url)
                    "></claas-icon-button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>

    <section *ngIf="isLoadingApplications$ | async">
      <span class="applications__loader">
        <claas-circular-progress
          [color]="secondaryColor"
          [diameter]="largeSize"></claas-circular-progress>
      </span>
    </section>
  </div>

  <ng-template #NoApplication>
    <section class="applications__content-no-entities">
      <div class="applications__content-no-entities--icon-container"></div>

      <div class="applications__content-no-entities--header">
        <span class="subtitle-1">{{
          'navigation.connected_apps' | translate
        }}</span>
      </div>

      <div class="applications__content-no-entities--message">
        <span>{{ 'account_settings.no_connected_apps' | translate }}</span>
      </div>
    </section>
  </ng-template>
</div>
