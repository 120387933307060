<div class="address-validation-view">
  <div class="address-validation-view__header">
    <div class="address-validation-view__header-title">
      <h2>{{'address_validation.validation_headline'|translate}}</h2>
    </div>
    <div class="address-validation-view__header-subtitle">
      <h5>{{'address_validation.validation_subtext'|translate}}</h5>
    </div>
  </div>
  <div class="address-validation-view__body">
    <div class ="address-validation-view__body-text">
      <p class="address-validation-view__body-subtitle">{{'address_validation.validation_proposal' | translate}}</p>
      <div *ngIf="(proposedAddresses$ | async) as proposedAddresses" class="address-validation-view__body-address-options">
        <ng-container *ngFor="let proposedAddress of proposedAddresses; let i = index">
          <div class="address-validation-view__body-address-option">
            <claas-radio-button
              [id]="'address'"
              [value]="'proposedAddress'"
              [checked]="selectedAddress === proposedAddress"
              (onChange)="changeSelectedAddress(i)">
            </claas-radio-button>
            {{ proposedAddress | address }}
          </div>
        </ng-container>
      </div>
    </div>
    <div class="address-validation-view__body-text">
      <p class="address-validation-view__body-subtitle">{{'address_validation.validation_input' | translate}}</p>
      <div *ngIf="(inputAddress$ | async) as inputAddress" class="address-validation-view__body-address-option">
        <claas-radio-button
          [id]="'address'"
          [value]="'inputAddress'"
          [checked]="selectedAddress === inputAddress"
          (onChange)="changeSelectedAddress(-1)">
        </claas-radio-button>
        <span>{{ inputAddress | address }}</span>
      </div>
    </div>
  </div>
  <br>
</div>
<div class="address-validation-view__actions">
  <claas-button
    *ngIf="data?.buttonSave"
    [text]="'generic.accept' | translate"
    [disabled]="!selectedAddress"
    (click)="onSaveClicked()">
  </claas-button>
</div>
