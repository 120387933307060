export type AddressTypes = 'home' | 'work';

export class UserAddress {
  uuid?: string;
  name: string;
  street: string;
  house_number?: string;
  city: string;
  postal_code?: string;
  is_primary: boolean;
  country: string;
  state?: string;
  type: AddressTypes;
  user_id?: string;
  suburb?: string;
  territory?: string;
  longitude?: number;
  latitude?: number;
  addressAddition?: string;
  postbox?: string;

  constructor(addressValue?: any) {
    this.uuid =
      addressValue?.uuid || addressValue?.uuid === ''
        ? addressValue.uuid
        : this.uuid;

    this.name =
      addressValue?.name || addressValue?.name === '' ? addressValue.name : '';

    this.street =
      addressValue?.street || addressValue?.street === ''
        ? addressValue.street
        : '';

    this.house_number =
      addressValue?.house_number || addressValue?.house_number === ''
        ? addressValue.house_number
        : '';

    this.city =
      addressValue?.city || addressValue?.city === '' ? addressValue.city : '';

    this.postal_code =
      addressValue?.postal_code || addressValue?.postal_code === ''
        ? addressValue.postal_code
        : '';

    this.is_primary =
      addressValue?.is_primary || addressValue?.is_primary === ''
        ? addressValue.is_primary
        : false;

    this.country =
      addressValue?.country || addressValue?.country === ''
        ? addressValue.country
        : 'de';

    this.suburb =
      addressValue?.suburb || addressValue?.suburb === ''
        ? addressValue.suburb
        : '';

    this.addressAddition =
      addressValue?.addressAddition || addressValue?.addressAddition === ''
        ? addressValue.addressAddition
        : '';

    this.state =
      addressValue?.state || addressValue?.state === ''
        ? addressValue.state
        : '';

    this.territory =
      addressValue?.territory || addressValue?.territory === ''
        ? addressValue.territory
        : '';

    this.type =
      addressValue?.type || addressValue?.type === ''
        ? addressValue.type
        : 'home';
  }

  copyAddress(source: UserAddress, exceptCountry?: boolean): void {
    this.uuid = source.uuid || source.uuid === '' ? source.uuid : this.uuid;

    this.name = source.name || source.name === '' ? source.name : this.name;

    this.street =
      source.street || source.street === '' ? source.street : this.street;

    this.house_number =
      source.house_number || source.house_number === ''
        ? source.house_number
        : this.house_number;

    this.city = source.city || source.city === '' ? source.city : this.city;

    this.postal_code =
      source.postal_code || source.postal_code === ''
        ? source.postal_code
        : this.postal_code;

    this.state =
      source.state || source.state === '' ? source.state : this.state;

    this.territory =
      source.territory || source.territory === ''
        ? source.territory
        : this.territory;

    this.suburb =
      source.suburb || source.suburb === '' ? source.suburb : this.suburb;

    this.addressAddition =
      source.addressAddition || source.addressAddition === ''
        ? source.addressAddition
        : this.addressAddition;

    if (exceptCountry) {
      this.country = source.country ? source.country : this.country;
    }
  }
}
