import { Action, createReducer, on } from '@ngrx/store';
import {
  createClaasMarketingPermission,
  createClaasMarketingPermissionComplete,
  createClaasMarketingPermissionFailure,
  createClaasMarketingPermissionSuccess,
  createDealerMarketingPermission,
  createDealerMarketingPermissionComplete,
  createDealerMarketingPermissionFailure,
  createDealerMarketingPermissionSuccess,
  getClaasMarketingPermission,
  getClaasMarketingPermissionComplete,
  getClaasMarketingPermissionFailure,
  getClaasMarketingPermissionSuccess,
  getDealerMarketingPermission,
  getDealerMarketingPermissionComplete,
  getDealerMarketingPermissionFailure,
  getDealerMarketingPermissionSuccess
} from './marketing-permission.actions';
import {
  MarketingPermissionState,
  initialState
} from './marketing-permission.state';
import { removeLeadingZeros } from '../../utils/format-dealer-id';

export const marketingPermissionReducer = createReducer(
  initialState,
  on(getClaasMarketingPermission, state => ({
    ...state,
    getPending: true
  })),
  on(getClaasMarketingPermissionSuccess, (state, { marketingPermission }) => ({
    ...state,
    claasMarketingPermission:
      marketingPermission.status === 'Open'
        ? ('NOT_FOUND' as const)
        : marketingPermission
  })),
  on(getClaasMarketingPermissionFailure, (state, { error }) => {
    return {
      ...state,
      error
    };
  }),
  on(getClaasMarketingPermissionComplete, state => ({
    ...state,
    pending: false
  })),
  on(getDealerMarketingPermission, state => ({
    ...state,
    getPending: true
  })),
  on(getDealerMarketingPermissionSuccess, (state, { marketingPermission }) => {
    const dealerMarketingPermissions = {
      ...(state.dealerMarketingPermissions ?? {})
    };
    dealerMarketingPermissions[
      removeLeadingZeros(marketingPermission.dealerId as string)
    ] = marketingPermission;

    return {
      ...state,
      dealerMarketingPermissions
    };
  }),
  on(getDealerMarketingPermissionFailure, (state, { error }) => {
    return {
      ...state,
      error
    };
  }),
  on(getDealerMarketingPermissionComplete, state => ({
    ...state,
    pending: false
  })),
  on(createClaasMarketingPermission, state => ({
    ...state,
    createPending: true
  })),
  on(createClaasMarketingPermissionSuccess, state => ({
    ...state
  })),
  on(createClaasMarketingPermissionFailure, (state, { error }) => ({
    ...state,
    error
  })),
  on(createClaasMarketingPermissionComplete, state => ({
    ...state,
    createPending: false
  })),

  on(createDealerMarketingPermission, state => ({
    ...state,
    createPending: true
  })),
  on(createDealerMarketingPermissionSuccess, state => ({
    ...state
  })),
  on(createDealerMarketingPermissionFailure, (state, { error }) => ({
    ...state,
    error
  })),
  on(createDealerMarketingPermissionComplete, state => ({
    ...state,
    createPending: false
  }))
);

export function reducer(
  state: MarketingPermissionState | undefined,
  action: Action
) {
  return marketingPermissionReducer(state, action);
}
