import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { passedDataToDialog } from '../dialog-window/dialog-window.component';
import {
  ButtonClass,
  DropdownOptionObject
} from '@claas/claas-form-components';
import { languagesArr } from '../dialog-window/languages-source';
import { ApiService } from '../../services/api.service';
import { TranslateService } from '@ngx-translate/core';
import { countriesArr } from './countries-source';
import { BehaviorSubject } from 'rxjs';
import {
  CircularProgressDiameter,
  ProgressColor
} from '@claas/claas-layout-components';
import { Store } from '@ngrx/store';
import { loadUserCoordinates } from '../../state/user-coordinates/user-coordinates.actions';

@Component({
  selector: 'app-language-view',
  templateUrl: './language-view.component.html',
  styleUrls: ['./language-view.component.scss']
})
export class LanguageViewComponent implements OnInit {
  @Input() data: passedDataToDialog = {};
  @Input() secondaryTextButtonClass?: ButtonClass;

  @Output() closeDialogWindow = new EventEmitter();
  @Output() displayPopup = new EventEmitter();
  public languages = environment.general.languages;
  public languagesArr = languagesArr;
  public countriesArray = countriesArr;
  public countries = environment.general.countries;
  size = CircularProgressDiameter.SMALL;
  selectedLanguage: string = '';
  showSpinner$ = new BehaviorSubject(false);
  color = ProgressColor.PRIMARY;
  options = [
    {
      text: 'label',
      key: 'key'
    }
  ];
  defaultOption = {
    text: 'label',
    key: 'key'
  };
  private FALLBACK_LANGUAGE = 'en-gb';

  constructor(
    private apiService: ApiService,
    private translate: TranslateService,
    private store: Store
  ) {}

  ngOnInit() {
    if (this.data.language) {
      this.selectedLanguage = this.data.language;
      this.defaultOption = {
        text: this.getLanguage(this.selectedLanguage),
        key: this.selectedLanguage
      };
    }
    this.options.pop();
    for (const l of this.languages) {
      const o = { text: this.getLanguage(l), key: l };
      this.options.push(o);
    }
  }

  // 1. UPDATE VARIABLE IN DROPDOWN
  onChangeLanguage(e: DropdownOptionObject) {
    this.selectedLanguage = e as unknown as string;
  }

  setUserLanguage(passedObject: any) {
    const payload = {
      language: passedObject.language
    };

    this.apiService.setUserProfile(payload).subscribe({
      next: () => {
        this.translate
          .get('account_settings.language.update.success')
          .subscribe((text: string) => {
            this.displayPopup.emit({ text: text, className: 'success' });
          });
        this.changeLanguage(payload.language);
        this.closeDialogWindow.emit(passedObject);
      },
      error: (err: Error) => {
        this.translate
          .get('account_settings.language.update.fail')
          .subscribe((text: string) => {
            this.displayPopup.emit({ text: text, className: 'error' });
          });
      }
    });
  }

  // 3. CHANGE LANGUAGE
  changeLanguage(lang: string) {
    if (environment.general.languages.includes(lang.toLowerCase())) {
      this.translate.use(lang);
      this.store.dispatch(loadUserCoordinates());
      this.sortCountries();
    } else {
      this.translate.use(this.FALLBACK_LANGUAGE);
      this.sortCountries();
    }

    this.showSpinner$.next(false);
  }

  getLanguage(lang: string) {
    const c = languagesArr.find(function (element) {
      return element.id === lang;
    });
    if (c === undefined) {
      return '';
    } else {
      let r = '';
      this.translate.get(c.label).subscribe((text: string) => (r = text));
      return r;
    }
  }

  async sortCountries() {
    // dummy call to wait for translation file to be loaded
    await this.translate.get('de').toPromise().then();

    // sort countriesArr by translated values
    this.countriesArray = this.countriesArray.sort((a: any, b: any) => {
      return this.translate
        .instant(a.label)
        .localeCompare(this.translate.instant(b.label));
    });

    // sort countries (which comes from ENV and is used to create the dropdowns) based on countriesArr order
    this.countries = this.countries.sort((a: string, b: string) => {
      if (
        this.countriesArray.findIndex(c => c.id === a) <
        this.countriesArray.findIndex(c => c.id === b)
      ) {
        return -1;
      } else if (
        this.countriesArray.findIndex(c => c.id === a) >
        this.countriesArray.findIndex(c => c.id === b)
      ) {
        return 1;
      } else {
        return 0;
      }
    });

    // sort languagesArr by translated values
    this.languagesArr = this.languagesArr.sort((a: any, b: any) => {
      return this.translate
        .instant(a.label)
        .localeCompare(this.translate.instant(b.label));
    });

    // sort languages (which comes from ENV and is used to create the dropdowns) based on languagesArr order
    this.languages = this.languages.sort((a: string, b: string) => {
      if (
        this.languagesArr.findIndex(c => c.id === a) <
        this.languagesArr.findIndex(c => c.id === b)
      ) {
        return -1;
      } else if (
        this.languagesArr.findIndex(c => c.id === a) >
        this.languagesArr.findIndex(c => c.id === b)
      ) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  onSaveClicked(): void {
    const passedObject = {
      context: this.data.context,
      action: 'save',
      language: this.selectedLanguage
    };

    this.showSpinner$.next(true);

    // If selected language differs from current
    this.setUserLanguage(passedObject);
  }

  onCancelClicked(): any {
    const passedObject = {
      action: 'cancel'
    };

    this.closeDialogWindow.emit(passedObject);
  }
}
