import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { applicationsArr } from '../applications/applications-source';
import { environment } from 'src/environments/environment';

@Component({
  templateUrl: 'verified.component.html',
  styleUrls: ['mail.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class VerifiedComponent {

  public app_name = ''; // application name
  public dl = 4000; // delay before redirect
  public login_url = environment.base_urls.app_base_url;
  public applications = applicationsArr;
  

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {

    // get parameters from url
    this.route.queryParams.subscribe((res) => {

      if (res['client_id']){
        this.login_url = this.applications.find(element=> element.id === res['client_id'])!.login_url;
      }

      if (res['app_name']) {
        this.app_name = res['app_name'];
      }

    });

    if (this.app_name === 'IWC') {
      this.app_name = 'CLAAS connect';
    }
    if (this.app_name === 'All Applications') {
      this.app_name = 'CLAAS connect';
    }

    // Missing field mappings
    if (this.app_name === '') {
      this.app_name = 'CLAAS ID';
    }
    
    // redirect to application with delay
    setTimeout(() => {
      window.location.href = this.login_url;
    }, this.dl);
  }
}
