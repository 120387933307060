<div class="user-settings">
  <!--  Header Section-->
  <section class="user-settings__header">
    <div class="flex flex-row justify-between">
      <h1 class="user-settings__header-title">
        {{ 'navigation.account_settings' | translate }}
      </h1>
    </div>
  </section>

  <!--  Form Section-->
  <section class="user-settings__content">
    <!--    Password-->
    <section class="user-settings__content-password-container">
      <span class="label subtitle-2">
        <p>{{ 'account_settings.password.label' | translate }}</p>
      </span>

      <div class="content">
        <div class="user-password">
          <p>********</p>
        </div>
        <div class="action">
          <claas-icon-button
            (click)="openDialog('profile-password')"
            [fontSize]="mediumIconFontSize"
            [iconSize]="mediumIconSize"
            [icon]="editIcon" />
        </div>
      </div>
    </section>
    <!--    Country-->
    <section class="user-settings__content-country-container">
      <span class="label subtitle-2">
        <p>{{ 'registration.formfield.country' | translate }}</p>
      </span>
      <div
        *ngIf="(isLoadingCountry$ | async) === false; else loader"
        class="content">
        <div class="user-country">
          <p>{{ getCountry(country, 'label') | translate }}</p>
        </div>
      </div>
    </section>
    <!--    Language-->
    <section class="user-settings__content-language-container">
      <span class="label subtitle-2">
        <p>{{ 'account_settings.language.label' | translate }}</p>
      </span>
      <div
        *ngIf="(isLoadingLanguage$ | async) === false; else loader"
        class="content">
        <div class="user-language">
          <p>{{ getLanguage(language) | translate }}</p>
        </div>
        <div class="action">
          <claas-icon-button
            (click)="openDialog('profile-language')"
            [fontSize]="mediumIconFontSize"
            [iconSize]="mediumIconSize"
            [icon]="editIcon" />
        </div>
      </div>
    </section>
    <!--    Two Factor-->
    <section class="user-settings__content-two-factor-container">
      <span class="label subtitle-2">
        <div class="flex flex-grow">
          <p class="section__title">{{ 'account_settings.mfa' | translate }}</p>
          <claas-icon-button
            (click)="openDialog('profile-two-factor')"
            [iconSize]="mediumIconSize"
            [icon]="infoIcon" />
        </div>
      </span>
      <div
        *ngIf="(isLoadingMfa$ | async) === false; else loader"
        class="content">
        <div class="user-two-factor">
          <p *ngIf="mfaChecked">{{ 'generic.activated' | translate }}</p>
          <p *ngIf="!mfaChecked">{{ 'generic.deactivated' | translate }}</p>
        </div>
        <div class="action">
          <div *ngIf="isLoadingMfa$ | async">
            <span class="user-settings__loader">
              <claas-circular-progress
                [color]="secondaryColor"
                [diameter]="largeSize">
              </claas-circular-progress>
            </span>
          </div>
          <!--     Toggle-->
          <claas-toggle
            (onChange)="toggleMfa()"
            *ngIf="(isLoadingMfa$ | async) === false"
            [checked]="mfaChecked"
            [required]="false"
            [size]="mediumToggleSize" />
        </div>
      </div>

      <ng-template #loader>
        <div class="flex flex-end w-full">
          <span class="user-settings__loader">
            <claas-circular-progress
              [color]="secondaryColor"
              [diameter]="largeSize">
            </claas-circular-progress>
          </span>
        </div>
      </ng-template>
    </section>
    <!--    Delete Account-->
    <section class="user-settings__content-delete-account-container">
      <app-button
        (click)="openDialog('profile-deletion')"
        [label]="'account_settings.delete_account' | translate"
        type="warn"></app-button>
    </section>
  </section>
</div>
