import { Action, createReducer, on } from '@ngrx/store';
import {
  initialState,
  GlobalState,
  RegistrationDialogError
} from './global.state';

import {
  addressAutocompleteSuggestionsDataLoaded,
  clearRegistrationData,
  loadAddressAutocompleteSuggestionsData,
  loadRegistrationDealerData,
  loadRegistrationPersonalData,
  loadRegistrationShopData,
  registrationDataPendingUpdated,
  registrationDealerDataLoaded,
  registrationDealerDataNotLoaded,
  registrationPersonalDataLoaded,
  registrationPersonalDataNotLoaded,
  registrationPersonalDataNotUpdated,
  registrationPersonalDataPendingUpdated,
  registrationPersonalDataUpdated,
  registrationShopDataLoaded,
  registrationShopDataNotLoaded
} from './global.actions';
import { IRegistrationShopData } from 'cde-fe-organization-registration-dialog/lib/models/organisation.model';
import {
  registeredOrganisationLoaded,
  registeredOrganisationNotLoaded
} from '../organisations';

export const globalReducer = createReducer(
  initialState,
  on(registrationDataPendingUpdated, (state, { registrationDataPending }) => ({
    ...state,
    registrationDataPending
  })),
  on(loadRegistrationPersonalData, state => ({ ...state })),
  on(registrationPersonalDataLoaded, (state, { registrationPersonalData }) => ({
    ...state,
    registrationPersonalData,
    registrationPersonalDataPending: false
  })),
  on(registrationPersonalDataNotLoaded, state => ({
    ...state,
    registrationPersonalData: null,
    registrationPersonalDataPending: false
  })),
  on(
    registrationPersonalDataPendingUpdated,
    (state, { registrationPersonalDataPending }) => ({
      ...state,
      registrationPersonalDataPending
    })
  ),
  on(loadRegistrationDealerData, state => ({
    ...state,
    registrationDataPending: true
  })),
  on(registrationDealerDataLoaded, (state, { registrationDealerData }) => ({
    ...state,
    registrationDealerData,
    registrationDataPending: false
  })),
  on(registrationDealerDataNotLoaded, (state, { registrationDealerData }) => ({
    ...state,
    registrationDealerData,
    registrationDataPending: false
  })),
  on(
    loadAddressAutocompleteSuggestionsData,
    (state, { addressDetailsRequest }) => ({
      ...state
    })
  ),
  on(
    addressAutocompleteSuggestionsDataLoaded,
    (state, { addressAutocompleteSuggestions }) => ({
      ...state,
      addressAutocompleteSuggestions
    })
  ),
  on(loadRegistrationShopData, state => ({
    ...state,
    registrationDataPending: true
  })),
  on(registrationShopDataLoaded, (state, { registrationShopData }) => ({
    ...state,
    registrationShopData,
    registrationDataPending: false
  })),
  on(registrationShopDataNotLoaded, (state, { shopDataLoadError }) => ({
    ...state,
    registrationShopData: null,
    registrationDataPending: false,
    shopDataLoadError
  })),
  on(registeredOrganisationLoaded, state => ({
    ...state,
    registrationError: RegistrationDialogError.NONE
  })),
  on(
    registeredOrganisationNotLoaded,
    (state, { registrationError, organisationError }) => ({
      ...state,
      registrationError
    })
  ),
  on(registrationPersonalDataUpdated, state => ({
    ...state,
    registrationError: RegistrationDialogError.NONE
  })),
  on(registrationPersonalDataNotUpdated, state => ({
    ...state,
    registrationError: RegistrationDialogError.STEP_2
  })),
  on(clearRegistrationData, state => ({
    ...state,
    registrationPersonalData: null,
    registrationDealerData: null,
    registrationShopData: null,
    registrationDataPending: false,
    registrationError: RegistrationDialogError.NONE
  }))
);

export function reducer(state: GlobalState | undefined, action: Action) {
  return globalReducer(state, action);
}
