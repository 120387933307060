export class Application {
  id: string;
  label: string;
  client_id: string;
  img: string;
  url: string;
  consent_needed: boolean;
  connected?: boolean;
  isShop?: boolean;
  isActivated?: boolean;
  application_id?: string;
  application_name?: string;
  country?: string;

  constructor() {
    this.id = '';
    this.label = '';
    this.client_id = '';
    this.img = '';
    this.url = '';
    this.consent_needed = false;
  }

  getApplication() {
    return {
      id: this.id,
      label: this.label,
      client_id: this.client_id,
      img: this.img,
      url: this.url,
      consent_needed: this.consent_needed
    };
  }
}
