import { ElementTypeOptions } from '../components/dialog-window/dialog-window.component';

export class OrgAddressPatch {
  type?: ElementTypeOptions;
  legalName: string;
  country: string;
  state: string;
  suburb: string;
  territory: string;
  street: string;
  houseNumber: string;
  postalCode: string;
  city: string;
  postbox: string;
  addressAddition: string;
  latitude: string;
  longitude: string;

  constructor(addressValue?: any) {
    this.type =
      addressValue?.type || addressValue?.type === ''
        ? addressValue.type
        : undefined;

    this.legalName =
      addressValue?.legalName || addressValue?.legalName === ''
        ? addressValue.legalName
        : '';

    this.country =
      addressValue?.country || addressValue?.country === ''
        ? addressValue.country
        : 'de';

    this.state =
      addressValue?.state || addressValue?.state === ''
        ? addressValue.state
        : '';

    this.suburb =
      addressValue?.suburb || addressValue?.suburb === ''
        ? addressValue.suburb
        : '';

    this.territory =
      addressValue?.territory || addressValue?.territory === ''
        ? addressValue.territory
        : '';

    this.street =
      addressValue?.street || addressValue?.street === ''
        ? addressValue.street
        : '';

    this.houseNumber =
      addressValue?.houseNumber || addressValue?.houseNumber === ''
        ? addressValue.houseNumber
        : '';

    this.postalCode =
      addressValue?.postalCode || addressValue?.postalCode === ''
        ? addressValue.postalCode
        : '';

    this.city =
      addressValue?.city || addressValue?.city === '' ? addressValue.city : '';

    this.postbox =
      addressValue?.postbox || addressValue?.postbox === ''
        ? addressValue.postbox
        : '';

    this.addressAddition =
      addressValue?.addressAddition || addressValue?.addressAddition === ''
        ? addressValue.addressAddition
        : '';

    this.latitude = addressValue?.latitude ? addressValue.latitude : 0;
    this.longitude = addressValue?.longitude ? addressValue.longitude : 0;
  }
}
