<div class="marketing-permission-delete-view">
  <div class="marketing-permission-delete-view__header">
    <div class="marketing-permission-delete-view__header-title">
      <h2 class="header-3-bold">
        {{ 'preference_center.delete.header' | translate }}
      </h2>
    </div>
    <div class="marketing-permission-delete-view__header-subtitle">
      <span>
        {{ 'preference_center.delete.text' | translate }}
        {{ data?.marketingPermission?.dealer_name }}
      </span>
    </div>

    <div *ngFor="let permission of data.marketingPermissions">
      <span
        *ngIf="permission.type === 'claas'"
        class="marketing-permission-delete-view__body label-1-style">
        {{ permission.type.toUpperCase() }}
      </span>
    </div>

    <div *ngFor="let permission of data.marketingPermissions">
      <span
        *ngIf="permission.type === 'dealer'"
        class="marketing-permission-delete-view__body label-1-style">
        {{ permission.dealer_name }}
      </span>
    </div>

    <div class="marketing-permission-delete-view__actions">
      <claas-button
        *ngIf="data?.buttonSave"
        [buttonClass]="errorButtonClass"
        [text]="'generic.delete_all' | translate"
        [disabled]="!!(actionInProgress$ | async)"
        (click)="onDeleteClicked()">
      </claas-button>

      <claas-button
        *ngIf="data?.buttonCancel"
        [buttonClass]="secondaryTextButtonClass"
        [text]="'generic.cancel' | translate"
        [disabled]="!!(actionInProgress$ | async)"
        (click)="onCancelClicked()">
      </claas-button>
    </div>
  </div>
</div>
