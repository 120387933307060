import { User } from '../../models/user.model';
import { MarketingPermission } from '../../models/marketing-permission.model';

export interface UserCoordinatesState {
  userDetails: User | null;
  marketingPermissions: MarketingPermission[] | null;
}

export const initialState: UserCoordinatesState = {
  userDetails: null,
  marketingPermissions: null
};
