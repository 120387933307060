import { Serializable } from './serializable.model';
export type RegistrationStatus =
  | 'PARTIALLY_REGISTERED'
  | 'ADDRESS_CHECK_PENDING'
  | 'REGISTRATION_COMPLETED';
export interface IAddress {
  street: string;
  house_number?: string;
  postal_code: string;
  city: string;
  country: string;
}
export interface IUser {
  sub: string;
  email: string;
  given_name: string;
  family_name: string;
  name: string;
  first_name: string;
  last_name: string;
  uuid: string;
  custom_image: string;
  user_metadata: IUserMetadata;
  app_metadata: IAppMetadata;
  [key: string]: any;
}
export interface IUserMetadata {
  family_name: string;
  given_name: string;
  country: string;
  language: string;
  addresses: IAddress[];
}
export interface IAppMetadata {
  status?: RegistrationStatus;
}
export interface User extends IUser {}
export class User extends Serializable implements User {
  constructor(params: any) {
    if (params['https://claas.com/user_metadata']) {
      params['user_metadata'] = params['https://claas.com/user_metadata'];
    }
    // Custom map names
    // App metadata can be null
    if (params['https://claas.com/app_metadata']) {
      params['app_metadata'] = params['https://claas.com/app_metadata'] || {};
    }
    if (params['app_metadata'] && !params['app_metadata'].status) {
      params['app_metadata'].status = 'PARTIALLY_REGISTERED';
    }
    params['uuid'] = params['sub'];
    delete params['https://claas.com/user_metadata'];
    delete params['https://claas.com/app_metadata'];
    super(params);
  }
  /**
   * The user has completed the full registration form, but is still potentially awaiting manual address check
   */
  get fullRegistrationFormCompleted() {
    return (
      this.app_metadata.status === 'ADDRESS_CHECK_PENDING' ||
      this.app_metadata.status === 'REGISTRATION_COMPLETED'
    );
  }

  /**
   * The user has completed full registration form and address check is completed; fully registered
   */
  get isFullyRegistered() {
    return this.app_metadata.status === 'REGISTRATION_COMPLETED';
  }
}
