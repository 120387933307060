import { Action, createReducer, on } from '@ngrx/store';
import * as UserActions from './user.actions';
import { UserState, initialState } from './user.state';

const userReducers = createReducer(
  initialState,
  on(UserActions.userChangedFromAuth0SDK, (state, { userDetails }) => ({
    ...state,
    userDetails: userDetails
  })),
  on(UserActions.allAuthActions.loginFlowInitiated, (state, {}) => ({
    ...state,
    pending: true
  })),
  on(
    UserActions.allAuthActions.loginComplete,
    (state, { profile, isLoggedIn }) => {
      return {
        ...state,
        userDetails: profile,
        isLoggedIn
      };
    }
  ),
  on(UserActions.allAuthActions.logoutComplete, (state, {}) => {
    return {
      ...state,
      userDetails: null,
      isLoggedIn: false
    };
  }),
  on(UserActions.allAuthActions.setOrganisationSuccess, (state, {}) => {
    return {
      ...state
    };
  }),
  on(UserActions.allAuthActions.loadUserAvatarUrl, (state, {}) => {
    return {
      ...state
    };
  }),
  on(UserActions.allAuthActions.userAvatarUrlLoaded, (state, { url }) => {
    return {
      ...state,
      userAvatarUrl: url
    };
  })
);

export function reducer(state: UserState | undefined, action: Action) {
  return userReducers(state, action);
}
