import { Action, createReducer, on } from '@ngrx/store';
import {
  getFeatureToggles,
  getFeatureTogglesComplete,
  getFeatureTogglesFailure,
  getFeatureTogglesSuccess
} from './feature-toggle.actions';
import { FeatureToggleState, initialState } from './feature-toggle.state';

export const featureToggleReducer = createReducer(
  initialState,
  on(getFeatureToggles, state => ({
    ...state
  })),
  on(getFeatureTogglesSuccess, (state, { featureToggles }) => ({
    ...state,
    featureToggles
  })),
  on(getFeatureTogglesFailure, state => ({
    ...state
  })),
  on(getFeatureTogglesComplete, state => ({
    ...state
  }))
);

export function reducer(state: FeatureToggleState | undefined, action: Action) {
  return featureToggleReducer(state, action);
}
