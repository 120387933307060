import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { PhoneNumber } from '../../models/phone-number.model';

@Component({
  selector: 'app-international-phone',
  templateUrl: './international-phone.component.html',
  styleUrls: ['./international-phone.component.scss']
})
export class InternationalPhoneComponent implements OnInit, OnDestroy {
  @Input() preferredCountries?: string[] = [];
  @Input() hintMessage?: string;
  @Input() isEditMode?: boolean;
  @Input() isAddingMode?: boolean;
  @Input() currentPhoneIndex = 0;
  @Input() phoneNumbers: PhoneNumber[] = [];
  @Input() onlyCountries: string[] = [];

  @Output() invalidStateEmitter = new EventEmitter<boolean>(false);
  @Output() currentPhonenumber = new EventEmitter();
  alreadyExistsSubject = new BehaviorSubject<boolean>(false);

  subscriptions = new Subscription();
  phoneNumberValue = '';
  myForm: FormGroup | undefined;
  otherPhoneNumber: string | undefined;
  tempCountry = '';
  tempPhoneNumber: string | undefined;
  defaultPhoneNumber = '';
  numberFieldTouched = false;

  ngOnInit() {
    if (!this.isAddingMode) {
      this.phoneNumberValue = this.setPhoneNumber();
      this.tempPhoneNumber = this.phoneNumberValue;
    }

    this.otherPhoneNumber = this.getOtherPhoneNumber(this.currentPhoneIndex);
    let phoneAlreadyExists = false;
    this.myForm = this.setForm();

    this.subscriptions.add(
      this.myForm.valueChanges.subscribe(currentValue => {
        this.numberFieldTouched = true;
        if (this.otherPhoneNumber) {
          phoneAlreadyExists = this.otherPhoneNumber === currentValue.phone;
        }

        const invalidValue =
          phoneAlreadyExists || !this.myForm?.get('phone')?.value;

        this.invalidStateEmitter.emit(invalidValue);
        this.tempPhoneNumber = currentValue.phone;

        const number = {
          country: this.tempCountry,
          phone_number_value: this.tempPhoneNumber
        };

        this.currentPhonenumber.emit(number);
      })
    );

    if (!this.isEditMode && !this.isAddingMode) {
      this.myForm.get('phone')?.disable();
    }
  }

  updateCountryCode(input: any) {
    this.tempCountry = input.iso2;
  }

  setPhoneNumber(): string {
    const activeNumber = this.phoneNumbers[this.currentPhoneIndex];

    return activeNumber?.phone_number_value
      ? activeNumber.phone_number_value
      : '';
  }

  getOtherPhoneNumber(currentIndex: number): string | undefined {
    if (currentIndex === undefined) {
      return undefined;
    } else {
      const index = (currentIndex + 1) % 2;
      const otherPhone = this.phoneNumbers[index];

      if (this.isAddingMode && this.phoneNumbers.length) {
        return this.phoneNumbers[currentIndex].phone_number_value;
      } else {
        return otherPhone ? otherPhone.phone_number_value : undefined;
      }
    }
  }

  setForm(): FormGroup {
    return new FormGroup({
      phone: new FormControl(
        this.phoneNumberValue ? this.phoneNumberValue : this.defaultPhoneNumber
      )
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
