<div class="organisation-phonenumber-view">
  <div class="organisation-phonenumber-view__header">
    <div class="organisation-phonenumber-view__header-main">
      <h2 *ngIf="data.subContext === 'add'" class="organisation-phonenumber-view__header-title subtitle-2">
        {{ 'personal_data.phonenumber.add_header' | translate }}
      </h2>
      <h2 *ngIf="data.subContext === 'edit'" class="organisation-phonenumber-view__header-title subtitle-2">
        {{ 'personal_data.phonenumber.edit_header' | translate }}
      </h2>
    </div>
  </div>

  <div class="organisation-phonenumber-view__body">
    <div class="organisation-phonenumber-view__body-content">
      <app-international-phone
        [preferredCountries]="preferredCountries"
        [hintMessage]="'personal_data.phonenumber.invalid_data' | translate"
        [isAddingMode]="data.subContext === 'add'"
        [isEditMode]="data.subContext === 'edit'"
        [currentPhoneIndex]="currentPhoneIndex"
        [phoneNumbers]="phoneNumbers"
        [onlyCountries]="countriesIds"
        (invalidStateEmitter)="updateNumberInvalidState($event)"
        (currentPhonenumber)="updatePhonenumber($event)">
      </app-international-phone>
    </div>
  </div>

  <div class="organisation-phonenumber-view__dialog-actions">
    <div class="left-side"></div>
    <div class="right-side">
      <claas-button
        *ngIf="data && data.buttonCancel"
        class="btn-left"
        [buttonClass]="secondaryTextButtonClass"
        [text]="'generic.cancel' | translate"
        (onClick)="onCancelClicked()">
      </claas-button>

      <claas-button
        *ngIf="data && data.buttonSave"
        class="btn-right save-btn"
        [text]="'generic.save' | translate"
        [disabled]="!!(invalidSaveButtonState$ | async)"
        (onClick)="onSaveClicked()">
      </claas-button>
    </div>
  </div>
</div>
