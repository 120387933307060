import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import {
  ButtonClass,
  ClaasFormComponentsModule
} from '@claas/claas-form-components';
import {
  CircularProgressDiameter,
  ClaasLayoutComponentsModule,
  ProgressColor
} from '@claas/claas-layout-components';
import { TranslateModule } from '@ngx-translate/core';
import { passedDataToDialog } from '../dialog-window/dialog-window.component';
import { BehaviorSubject, Subscription, combineLatestWith, filter } from 'rxjs';
import { ApiService } from '../../services/api.service';
import { UserAddress } from '../../models/user-address.model';
import { UserUpdateRequest } from '../../models/user-update-request.model';
import { PhoneNumber } from '../../models/phone-number.model';
import { countriesArr } from '../language-view/countries-source';

@Component({
  selector: 'app-user-update-view',
  standalone: true,
  imports: [
    AsyncPipe,
    ClaasFormComponentsModule,
    ClaasLayoutComponentsModule,
    NgIf,
    TranslateModule,
    NgForOf
  ],
  templateUrl: './user-update-view.component.html',
  styleUrl: './user-update-view.component.scss'
})
export class UserUpdateViewComponent implements OnInit, OnDestroy {
  @Input() secondaryTextButtonClass?: ButtonClass;
  @Input() data: passedDataToDialog = {};
  @Output() closeDialogWindow = new EventEmitter();
  @Output() displayPopup = new EventEmitter();
  countriesArr = countriesArr;
  salutationsArr = [
    { id: 'mr', label: 'registration.formfield.select.MR' },
    { id: 'mrs', label: 'registration.formfield.select.MRS' }
  ];
  showSpinner$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  secondaryOutlineButtonClass: ButtonClass = ButtonClass.SECONDARY_OUTLINE;
  secondaryColor: ProgressColor = ProgressColor.SECONDARY;
  largeSize: CircularProgressDiameter = CircularProgressDiameter.LARGE;
  userUpdateRequest: UserUpdateRequest | undefined;
  userData: {
    key: string;
    existingValue: any;
    updatedValue: any;
    label: string;
  }[] = [];
  userGivenName: string = '';
  userFamilyName: string = '';
  userSalutation: string = '';
  userTitle: string = '';
  userEmail: string = '';
  userPhoneNumber: string = '';
  userMobileNumber: string = '';
  userAddress: UserAddress | null = null;
  userAddresses: UserAddress[] = [];

  addressUpdated$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  phoneUpdated$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  mobileUpdated$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  userUpdated$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private subscriptions = new Subscription();

  constructor(private apiService: ApiService) {}

  ngOnInit() {
    if (this.data?.userUpdateRequest) {
      this.userUpdateRequest = this.data.userUpdateRequest;
    }

    this.getUserProfile();
  }

  getUserProfile() {
    this.subscriptions.add(
      this.apiService.getUserProfile().subscribe((res: any) => {
        if (res['first_name']) {
          this.userGivenName = res['first_name'];
        }
        if (res['last_name']) {
          this.userFamilyName = res['last_name'];
        }
        if (res['salutation']) {
          this.userSalutation = res['salutation'];
        }
        if (res['title']) {
          this.userTitle = res['title'];
        }
        if (res['email']) {
          this.userEmail = res['email'];
        }

        if (res['phone_number_value']) {
          this.userPhoneNumber = res['phone_number_value'];
        }

        if (res['mobile_number_value']) {
          this.userMobileNumber = res['mobile_number_value'];
        }

        if (res['addresses']) {
          this.userAddresses = res['addresses'];
          for (const addr of this.userAddresses) {
            if (addr.is_primary || addr.type === 'home') {
              this.userAddress = addr;
            }
          }
        } else {
          this.userAddress = null;
        }

        if (this.userUpdateRequest) {
          this.checkUpdatedUserFields(this.userUpdateRequest);
        } else {
          console.log('No user update request');
        }
      })
    );
  }

  setUserUpdateRequestStatus(payload: UserUpdateRequest, passedObject: any) {
    // Set status in update request to accepted or denied
    this.subscriptions.add(
      this.apiService.setUserUpdateRequestStatus(payload).subscribe({
        next: () => {
          this.showSpinner$.next(false);
          passedObject.refreshIsRequired = true;
          this.closeDialogWindow.emit(passedObject);
        },
        error: (error: Error) => {
          console.log('ERROR: ', error);
        }
      })
    );
  }

  updateUserFields() {
    const userBaseProfile: any = {};
    let userBaseProfileChanged = false;

    // Update user infos fields
    if (this.userData.find(data => data.key === 'salutation')?.updatedValue) {
      // @ts-ignore
      userBaseProfile.salutation = this.userData.find(
        data => data.key === 'salutation'
      ).updatedValue;
      userBaseProfileChanged = true;
    }

    if (this.userData.find(data => data.key === 'title')?.updatedValue) {
      // @ts-ignore
      userBaseProfile.title = this.userData.find(
        data => data.key === 'title'
      ).updatedValue;
      userBaseProfileChanged = true;
    }

    if (this.userData.find(data => data.key === 'given_name')?.updatedValue) {
      // @ts-ignore
      userBaseProfile.given_name = this.userData.find(
        data => data.key === 'given_name'
      ).updatedValue;
      // Update given name property for address.name property in case new address gets created
      this.userGivenName = userBaseProfile.given_name;
      userBaseProfileChanged = true;
    }

    if (this.userData.find(data => data.key === 'family_name')?.updatedValue) {
      // @ts-ignore
      userBaseProfile.family_name = this.userData.find(
        data => data.key === 'family_name'
      ).updatedValue;
      // Update family name property for address.name property in case new address gets created
      this.userFamilyName = userBaseProfile.family_name;
      userBaseProfileChanged = true;
    }

    if (userBaseProfileChanged) {
      this.updateUserBaseProfile(userBaseProfile);
    } else {
      this.userUpdated$.next(true);
    }

    // Update address fields
    let updatedAddress: UserAddress;
    const addressChanged = this.userData.find(data =>
      data.key.startsWith('address')
    );

    if (this.userAddress) {
      updatedAddress = this.userAddress;
    } else {
      updatedAddress = new UserAddress();
    }

    updatedAddress.name = this.userGivenName + ' ' + this.userFamilyName;

    if (this.userData.find(data => data.key === 'address.city')?.updatedValue) {
      // @ts-ignore
      updatedAddress.city = this.userData.find(
        data => data.key === 'address.city'
      ).updatedValue;
    }

    if (
      this.userData.find(data => data.key === 'address.country')?.updatedValue
    ) {
      // @ts-ignore
      updatedAddress.country = this.userData.find(
        data => data.key === 'address.country'
      ).updatedValue;
    }

    if (
      this.userData.find(data => data.key === 'address.street')?.updatedValue
    ) {
      // @ts-ignore
      updatedAddress.street = this.userData.find(
        data => data.key === 'address.street'
      ).updatedValue;
    }

    if (
      this.userData.find(data => data.key === 'address.house_number')
        ?.updatedValue
    ) {
      // @ts-ignore
      updatedAddress.house_number = this.userData.find(
        data => data.key === 'address.house_number'
      ).updatedValue;
    }

    if (
      this.userData.find(data => data.key === 'address.postal_code')
        ?.updatedValue
    ) {
      // @ts-ignore
      updatedAddress.postal_code = this.userData.find(
        data => data.key === 'address.postal_code'
      ).updatedValue;
    }

    if (
      this.userData.find(data => data.key === 'address.suburb')?.updatedValue
    ) {
      // @ts-ignore
      updatedAddress.suburb = this.userData.find(
        data => data.key === 'address.suburb'
      ).updatedValue;
    }

    if (
      this.userData.find(data => data.key === 'address.state')?.updatedValue
    ) {
      // @ts-ignore
      updatedAddress.state = this.userData.find(
        data => data.key === 'address.state'
      ).updatedValue;
    }

    if (
      this.userData.find(data => data.key === 'address.territory')?.updatedValue
    ) {
      // @ts-ignore
      updatedAddress.territory = this.userData.find(
        data => data.key === 'address.territory'
      ).updatedValue;
    }

    if (
      this.userData.find(data => data.key === 'address.addressAddition')
        ?.updatedValue
    ) {
      // @ts-ignore
      updatedAddress.addressAddition = this.userData.find(
        data => data.key === 'address.addressAddition'
      ).updatedValue;
    }

    this.userUpdated$.subscribe(finished => {
      if (finished) {
        if (addressChanged) {
          if (this.userAddress) {
            this.updateUserAddress(updatedAddress);
          } else {
            this.createUserAddress(updatedAddress);
          }
        } else {
          this.addressUpdated$.next(true);
        }
      }
    });

    // Update mobile number fields
    let mobileChanged: boolean = false;
    const updatedMobileNumber = new PhoneNumber();
    if (
      this.userData.find(data => data.key === 'mobile_number_value')
        ?.updatedValue
    ) {
      mobileChanged = true;
      updatedMobileNumber.type = 'mobile';
      updatedMobileNumber.phone_number_value = this.userData.find(
        data => data.key === 'mobile_number_value'
      )?.updatedValue;
    }

    this.addressUpdated$.subscribe(finished => {
      if (finished) {
        if (mobileChanged) {
          if (this.userMobileNumber) {
            this.updateUserPhoneNumber(updatedMobileNumber);
          } else if (
            updatedMobileNumber.phone_number_value &&
            updatedMobileNumber.phone_number_value !== ''
          ) {
            updatedMobileNumber.type = 'mobile';
            this.createUserPhoneNumber(updatedMobileNumber);
          }
        } else {
          this.mobileUpdated$.next(true);
        }
      }
    });

    // Update phone number fields
    let phoneChanged: boolean = false;
    const updatedPhoneNumber = new PhoneNumber();
    if (
      this.userData.find(data => data.key === 'phone_number_value')
        ?.updatedValue
    ) {
      phoneChanged = true;
      updatedPhoneNumber.type = 'phone';
      updatedPhoneNumber.phone_number_value = this.userData.find(
        data => data.key === 'phone_number_value'
      )?.updatedValue;
    }

    this.mobileUpdated$.subscribe(finished => {
      if (finished) {
        if (phoneChanged) {
          if (this.userPhoneNumber) {
            this.updateUserPhoneNumber(updatedPhoneNumber);
          } else if (
            updatedPhoneNumber.phone_number_value &&
            updatedPhoneNumber.phone_number_value !== ''
          ) {
            updatedPhoneNumber.type = 'phone';
            this.createUserPhoneNumber(updatedPhoneNumber);
          }
        } else {
          this.phoneUpdated$.next(true);
        }
      }
    });
  }

  updateUserBaseProfile(payload: any) {
    this.subscriptions.add(
      this.apiService.setUserProfile(payload).subscribe({
        next: () => {
          this.userUpdated$.next(true);
        },
        error: (error: Error) => {
          console.log('ERROR PATCH: ', error);
        }
      })
    );
  }

  createUserPhoneNumber(payload: PhoneNumber): void {
    this.subscriptions.add(
      this.apiService.createUserPhoneNumber(payload).subscribe({
        next: () => {
          if (payload.type === 'mobile') {
            this.mobileUpdated$.next(true);
          } else if (payload.type === 'phone') {
            this.phoneUpdated$.next(true);
          }
        },
        error: (error: Error) => {
          console.log('ERROR NUMBER: ', error);
        }
      })
    );
  }

  updateUserPhoneNumber(payload: PhoneNumber): void {
    this.subscriptions.add(
      this.apiService.updateUserPhoneNumber(payload).subscribe({
        next: () => {
          if (payload.type === 'mobile') {
            this.mobileUpdated$.next(true);
          } else if (payload.type === 'phone') {
            this.phoneUpdated$.next(true);
          }
        },
        error: (error: Error) => {
          console.log('ERROR NUMBER: ', error);
        }
      })
    );
  }

  createUserAddress(payload: UserAddress): void {
    this.subscriptions.add(
      this.apiService.saveNewAddress(payload).subscribe({
        next: () => {
          this.addressUpdated$.next(true);
        },
        error: (error: Error) => {
          console.log('ERROR ADDRESS: ', error);
        }
      })
    );
  }

  updateUserAddress(payload: UserAddress): void {
    this.subscriptions.add(
      this.apiService.updateAddress(payload).subscribe({
        next: () => {
          this.addressUpdated$.next(true);
        },
        error: (error: Error) => {
          console.log('ERROR ADDRESS: ', error);
        }
      })
    );
  }

  checkUpdatedUserFields(request: UserUpdateRequest, parentKey?: string) {
    // check in request which fields were updated
    for (const entry in request) {
      if (Object.prototype.hasOwnProperty.call(request, entry)) {
        // @ts-ignore
        const value = request[entry];

        // if its an object (address, phone number, mobile number) call function recursively
        if (typeof value === 'object' && value !== null) {
          this.checkUpdatedUserFields(value, entry);
          // the entries userId, type, id and is_primary (address flag), name (address), email, longitude and latitude are not relevant for displaying data
        } else if (
          value !== null &&
          entry !== 'user_id' &&
          entry !== 'type' &&
          entry !== 'id' &&
          entry !== 'is_primary' &&
          entry !== 'name' &&
          entry !== 'email' &&
          entry !== 'longitude' &&
          entry !== 'latitude'
        ) {
          // if the field is not null (nothing changed), add it to userDataChanges
          const dataField: {
            key: string;
            existingValue: any;
            updatedValue: any;
            label: string;
          } = { key: entry, existingValue: '', updatedValue: value, label: '' };

          // Get label without parent key for correct translation
          if (parentKey) {
            dataField.label = <string>entry.split('.').pop();
          } else {
            dataField.label = entry;
          }

          // Add parent key if it was an object (address, phone number, mobile number)
          if (parentKey) {
            dataField.key = parentKey + '.' + dataField.key;
          }

          if (dataField.key === 'salutation') {
            // set label for correct translation
            dataField.label = 'registration.formfield.salutation';
            // set updated & existing value
            dataField.updatedValue = value.toLowerCase();
            dataField.existingValue = this.userSalutation;
          }
          if (dataField.key === 'title') {
            // set label for correct translation
            dataField.label = 'registration.formfield.title';
            // set updated & existing value
            dataField.updatedValue = value;
            dataField.existingValue = this.userTitle;
          }
          if (dataField.key === 'given_name') {
            // set label for correct translation
            dataField.label = 'overview.given_name';
            // set updated & existing value
            dataField.updatedValue = value;
            dataField.existingValue = this.userGivenName;
          }
          if (dataField.key === 'family_name') {
            // set label for correct translation
            dataField.label = 'overview.family_name';
            // set updated & existing value
            dataField.updatedValue = value;
            dataField.existingValue = this.userFamilyName;
          }

          if (dataField.key === 'phone_number_value') {
            // set label for correct translation
            dataField.label = 'type.phone';
            // set updated & existing value
            dataField.updatedValue = value;
            if (this.userPhoneNumber) {
              dataField.existingValue = this.userPhoneNumber;
            }
          }

          if (dataField.key === 'mobile_number_value') {
            // set label for correct translation
            dataField.label = 'type.mobile';
            // set updated & existing value
            dataField.updatedValue = value;
            if (this.userMobileNumber) {
              dataField.existingValue = this.userMobileNumber;
            }
          }

          if (dataField.key === 'address.country') {
            // set label for correct translation
            dataField.label = 'overview.country';
            // set updated & existing value
            dataField.updatedValue = value.toLowerCase();
            if (this.userAddress && this.userAddress.country) {
              dataField.existingValue = this.userAddress.country;
            }
          }

          if (dataField.key === 'address.street') {
            // set label for correct translation
            dataField.label = 'registration.formfield.street';
            // set updated & existing value
            dataField.updatedValue = value;
            if (this.userAddress && this.userAddress.street) {
              dataField.existingValue = this.userAddress.street;
            }
          }

          if (dataField.key === 'address.house_number') {
            // set label for correct translation
            dataField.label = 'overview.house_number';
            // set updated & existing value
            dataField.updatedValue = value;
            if (this.userAddress && this.userAddress.house_number) {
              dataField.existingValue = this.userAddress.house_number;
            }
          }

          if (dataField.key === 'address.postal_code') {
            // set label for correct translation
            dataField.label = 'registration.formfield.postalCode';
            // set updated & existing value
            dataField.updatedValue = value;
            if (this.userAddress && this.userAddress.postal_code) {
              dataField.existingValue = this.userAddress.postal_code;
            }
          }

          if (dataField.key === 'address.city') {
            // set label for correct translation
            dataField.label = 'overview.city';
            // set updated & existing value
            dataField.updatedValue = value;
            if (this.userAddress && this.userAddress.city) {
              dataField.existingValue = this.userAddress.city;
            }
          }

          if (dataField.key === 'address.state') {
            // set label for correct translation
            dataField.label = 'registration.formfield.state';
            // set updated & existing value
            dataField.updatedValue = value.toLowerCase();
            if (this.userAddress && this.userAddress.state) {
              dataField.existingValue = this.userAddress.state;
            }
          }

          if (dataField.key === 'address.suburb') {
            // set label for correct translation
            dataField.label = 'registration.formfield.suburb';
            // set updated & existing value
            dataField.updatedValue = value;
            if (this.userAddress && this.userAddress.suburb) {
              dataField.existingValue = this.userAddress.suburb;
            }
          }

          if (dataField.key === 'address.territory') {
            // set label for correct translation
            dataField.label = 'overview.territory';
            // set updated & existing value
            dataField.updatedValue = value;
            if (this.userAddress && this.userAddress.territory) {
              dataField.existingValue = this.userAddress.territory;
            }
          }

          if (dataField.key === 'address.addressAddition') {
            // set label for correct translation
            dataField.label = 'overview.address_addition';
            // set updated & existing value
            dataField.updatedValue = value;
            if (this.userAddress && this.userAddress.addressAddition) {
              dataField.existingValue = this.userAddress.addressAddition;
            }
          }
          if (dataField.updatedValue !== dataField.existingValue) {
            this.userData.push(dataField);
          }
        }
      }
    }

    return this.userData;
  }

  getSalutation(salutation: string) {
    const c = this.salutationsArr.find(function (element) {
      return element.id === salutation.toLowerCase();
    });
    return c === undefined ? '' : c.label;
  }

  getCountry(country: string) {
    const c = this.countriesArr.find(function (element) {
      return element.id === country;
    });
    return c === undefined ? '' : c.label;
  }

  onClose(accepted: boolean): void {
    this.showSpinner$.next(true);

    const passedObject = {
      context: this.data.context,
      action: 'save'
    };

    if (accepted) {
      this.updateUserFields();
    }

    this.userUpdated$
      .pipe(
        combineLatestWith(
          this.addressUpdated$,
          this.phoneUpdated$,
          this.mobileUpdated$
        ),
        filter(
          ([user, address, phone, mobile]) => user && address && phone && mobile
        )
      )
      .subscribe(() => {
        if (this.userUpdateRequest) {
          this.userUpdateRequest.status = accepted ? 'accepted' : 'denied';
          this.setUserUpdateRequestStatus(this.userUpdateRequest, passedObject);
        }
      });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
