export class Address {
  type: string = '';
  name: string = '';
  country: string = '';
  state: string = '';
  suburb: string = '';
  territory: string = '';
  street: string = '';
  house_number: string = '';
  postal_code: string = '';
  city: string = '';
  addressAddition: string = '';
  is_primary = false;
  uuid: string = '';

  getAddress() {
    return {
      type: this.type,
      name: this.name,
      country: this.country,
      street: this.street,
      house_number: this.house_number,
      addressAddition: this.addressAddition,
      city: this.city,
      postal_code: this.postal_code,
      state: this.state,
      suburb: this.suburb,
      territory: this.territory,
      is_primary: this.is_primary,
      uuid: this.uuid
    };
  }
}
