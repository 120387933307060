export class Dealer {
  id: string;
  name: string;
  email: string;
  phone: string;
  streetName: string;
  houseNumber: string;
  postalCode: string;
  city: string;
  country: string;
  url: string;

  constructor() {
    this.id = '';
    this.name = '';
    this.email = '';
    this.phone = '';
    this.streetName = '';
    this.houseNumber = '';
    this.postalCode = '';
    this.city = '';
    this.country = '';
    this.url = '';
  }
}
