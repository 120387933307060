import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output
} from '@angular/core';
import {
  ButtonClass,
  Icon,
  IconFontSize,
  IconSize
} from '@claas/claas-form-components';
import {
  ResponseDataFromDialog,
  passedDataToDialog
} from '../dialog-window/dialog-window.component';
import { BehaviorSubject, Subscription, finalize, switchMap } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { ApiService } from '../../services/api.service';
import {
  CircularProgressDiameter,
  ProgressColor
} from '@claas/claas-layout-components';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-avatar-view',
  templateUrl: './avatar-view.component.html',
  styleUrls: ['./avatar-view.component.scss']
})
export class AvatarViewComponent implements OnDestroy {
  private subscriptions = new Subscription();
  tempAvatarValue: Blob | null = null;
  editIcon: Icon = {
    iconId: '001326',
    iconStyle: 'bold',
    namespace: 'claas-id',
    size: 24
  };
  largeSize = CircularProgressDiameter.LARGE;
  secondaryColor = ProgressColor.SECONDARY;
  mediumIconSize = IconSize.MEDIUM;
  mediumIconFontSize = IconFontSize.MEDIUM;
  confirmationDialogIsActive$ = new BehaviorSubject(false);

  @Input() avatarUrl: string | ArrayBuffer | null = '';
  @Input() data: passedDataToDialog = {};
  @Input() errorButtonClass?: ButtonClass;
  @Input() secondaryTextButtonClass?: ButtonClass;

  @Output() avatarChanged = new EventEmitter();
  @Output() displayPopup = new EventEmitter();
  @Output() closeDialogWindow = new EventEmitter();

  constructor(
    private authService: AuthService,
    private apiService: ApiService,
    private translateService: TranslateService
  ) {}

  onFileChanged(input: any): void {
    const selectedFile = input.target.files[0];

    if (selectedFile) {
      this.previewSelection(selectedFile);
    }
  }

  previewSelection(file: File): void {
    const render = new FileReader();

    render.readAsDataURL(file);
    render.onload = () => {
      this.avatarUrl = render.result;
      this.tempAvatarValue = file;
    };
  }

  showConfirmationDialog(): void {
    this.confirmationDialogIsActive$.next(true);
  }

  deletionIsCanceled(): void {
    this.confirmationDialogIsActive$.next(false);
  }

  deletionIsConfirmed(): void {
    this.confirmationDialogIsActive$.next(false);
    this.onDeleteClicked();
  }

  onDeleteClicked(): void {
    const passedObject = {
      context: this.data.context,
      action: 'delete'
    };

    this.deleteAvatarValue(passedObject);
  }

  onCancelClicked(): any {
    const passedObject = {
      action: 'cancel'
    };

    this.closeDialogWindow.emit(passedObject);
  }

  onSaveClicked(): void {
    const passedObject = {
      context: this.data.context,
      action: 'save'
    };

    if (this.tempAvatarValue) {
      this.saveNewAvatarValue(this.tempAvatarValue, passedObject);
    } else this.closeDialogWindow.emit(passedObject);
  }

  deleteAvatarValue(passedObject: ResponseDataFromDialog): void {
    this.subscriptions.add(
      this.authService
        .getTokenSilently$()
        .pipe(
          switchMap(accessToken =>
            this.apiService.deleteProfilePicture(accessToken)
          ),
          finalize(() => this.closeDialogWindow.emit(passedObject))
        )
        .subscribe({
          next: () => {
            this.translateService
              .get('personal_data.avatar.delete.success')
              .subscribe((text: string) => {
                passedObject.refreshIsRequired = true;
                this.displayPopup.emit({ text, className: '' });
              });
          },
          error: err => {
            this.translateService
                .get('personal_data.avatar.delete.fail')
                .subscribe((text: string) => {
                  this.displayPopup.emit({ text, className: 'error' });
                });
          }
        })
    );
  }

  saveNewAvatarValue(selectedFile: Blob, passedObject: any): void {
    // Create FormData Object for HTTP Request
    const formData = new FormData();
    formData.append('file', selectedFile);

    this.subscriptions.add(
      this.authService
        .getTokenSilently$()
        .pipe(
          switchMap(accessToken =>
            this.apiService.setProfilePicture(formData, accessToken)
          )
        )
        .subscribe({
          next: (response: any) => {
            this.subscriptions.add(
              this.translateService
                .get('personal_data.avatar.update.success')
                .subscribe((text: string) => {
                  // Get new profile picture
                  passedObject.refreshIsRequired = true;
                  this.displayPopup.emit({ text, className: '' });
                  this.closeDialogWindow.emit(passedObject);
                })
            );
          },
          error: err => {
            // File exceeds 5MB
            this.subscriptions.add(
              this.translateService
                .get('personal_data.avatar.update.fail.size')
                .subscribe((text: string) => {
                  this.displayPopup.emit({ text, className: 'error' });
                  this.closeDialogWindow.emit(passedObject);
                })
            );
          }
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
