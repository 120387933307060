(function (root, factory) {
  if (typeof define === 'function' && define.amd) define(['exports', './kotlin-kotlin-stdlib.js'], factory);else if (typeof exports === 'object') factory(module.exports, require('./kotlin-kotlin-stdlib.js'));else {
    if (typeof this['kotlin-kotlin-stdlib'] === 'undefined') {
      throw new Error("Error loading module 'com.claas.cde:shared'. Its dependency 'kotlin-kotlin-stdlib' was not found. Please, check whether 'kotlin-kotlin-stdlib' is loaded prior to 'com.claas.cde:shared'.");
    }
    root['com.claas.cde:shared'] = factory(typeof this['com.claas.cde:shared'] === 'undefined' ? {} : this['com.claas.cde:shared'], this['kotlin-kotlin-stdlib']);
  }
})(this, function (_, kotlin_kotlin) {
  'use strict';

  //region block: imports
  var imul = Math.imul;
  var protoOf = kotlin_kotlin.$_$.u;
  var objectMeta = kotlin_kotlin.$_$.t;
  var setMetadataFor = kotlin_kotlin.$_$.v;
  var classMeta = kotlin_kotlin.$_$.m;
  var VOID = kotlin_kotlin.$_$.a;
  var Unit_instance = kotlin_kotlin.$_$.k;
  var Enum = kotlin_kotlin.$_$.z;
  var noWhenBranchMatchedException = kotlin_kotlin.$_$.e1;
  var THROW_IAE = kotlin_kotlin.$_$.c1;
  var defineProp = kotlin_kotlin.$_$.n;
  var interfaceMeta = kotlin_kotlin.$_$.s;
  var IllegalArgumentException_init_$Create$ = kotlin_kotlin.$_$.f;
  var Exception_init_$Create$ = kotlin_kotlin.$_$.e;
  var isFinite = kotlin_kotlin.$_$.d1;
  var toString = kotlin_kotlin.$_$.x;
  var Companion_getInstance = kotlin_kotlin.$_$.j;
  var DurationUnit_HOURS_getInstance = kotlin_kotlin.$_$.b;
  var toDuration = kotlin_kotlin.$_$.y;
  var _Duration___get_inWholeHours__impl__kb9f3j = kotlin_kotlin.$_$.g;
  var _Duration___get_inWholeMinutes__impl__dognoh = kotlin_kotlin.$_$.h;
  var toLong = kotlin_kotlin.$_$.w;
  var Long = kotlin_kotlin.$_$.a1;
  var _Duration___get_inWholeSeconds__impl__hpy7b3 = kotlin_kotlin.$_$.i;
  var DurationUnit_MINUTES_getInstance = kotlin_kotlin.$_$.c;
  var equals = kotlin_kotlin.$_$.o;
  var charSequenceLength = kotlin_kotlin.$_$.l;
  var getNumberHashCode = kotlin_kotlin.$_$.p;
  var hashCode = kotlin_kotlin.$_$.r;
  var THROW_CCE = kotlin_kotlin.$_$.b1;
  var LinkedHashMap_init_$Create$ = kotlin_kotlin.$_$.d;
  var getStringHashCode = kotlin_kotlin.$_$.q;
  //endregion
  //region block: pre-declaration
  setMetadataFor(Companion, 'Companion', objectMeta);
  setMetadataFor(Unit, 'Unit', classMeta);
  setMetadataFor(Dimension, 'Dimension', classMeta, Unit);
  setMetadataFor(UnitDensity, 'UnitDensity', classMeta, Dimension);
  setMetadataFor(UnitConverter, 'UnitConverter', classMeta);
  setMetadataFor(UnitConverterLinear, 'UnitConverterLinear', classMeta, UnitConverter);
  setMetadataFor(Companion_0, 'Companion', objectMeta);
  setMetadataFor(UnitTemperature, 'UnitTemperature', classMeta, Dimension);
  setMetadataFor(UnitDimension, 'UnitDimension', classMeta, Enum);
  setMetadataFor(CDEUnitConverter, 'CDEUnitConverter', classMeta);
  setMetadataFor(CDEUnitSystems, 'CDEUnitSystems', classMeta, Enum);
  setMetadataFor(Scale, 'Scale', classMeta, Enum);
  function getLiquid(scale, $super) {
    scale = scale === VOID ? Scale_DEFAULT_getInstance() : scale;
    return this.e6(scale);
  }
  function getMass(scale, cropFactor, $super) {
    scale = scale === VOID ? Scale_DEFAULT_getInstance() : scale;
    cropFactor = cropFactor === VOID ? null : cropFactor;
    return this.f6(scale, cropFactor);
  }
  function getPressure(scale, $super) {
    scale = scale === VOID ? Scale_DEFAULT_getInstance() : scale;
    return this.g6(scale);
  }
  function getDensity(scale, cropFactor, $super) {
    scale = scale === VOID ? Scale_DEFAULT_getInstance() : scale;
    cropFactor = cropFactor === VOID ? null : cropFactor;
    return this.h6(scale, cropFactor);
  }
  function getLength(scale, $super) {
    scale = scale === VOID ? Scale_DEFAULT_getInstance() : scale;
    return this.i6(scale);
  }
  function getArea(scale, $super) {
    scale = scale === VOID ? Scale_DEFAULT_getInstance() : scale;
    return this.j6(scale);
  }
  function getTemperature(scale, $super) {
    scale = scale === VOID ? Scale_DEFAULT_getInstance() : scale;
    return this.k6(scale);
  }
  function getTime(scale, $super) {
    scale = scale === VOID ? Scale_DEFAULT_getInstance() : scale;
    return this.l6(scale);
  }
  function getSpeed(scale, $super) {
    scale = scale === VOID ? Scale_DEFAULT_getInstance() : scale;
    return this.m6(scale);
  }
  function getVolume(scale, $super) {
    scale = scale === VOID ? Scale_DEFAULT_getInstance() : scale;
    return this.n6(scale);
  }
  function getVolumeFlowRate(scale, $super) {
    scale = scale === VOID ? Scale_DEFAULT_getInstance() : scale;
    return this.o6(scale);
  }
  function getQuotient(scale, $super) {
    scale = scale === VOID ? Scale_DEFAULT_getInstance() : scale;
    return this.p6(scale);
  }
  function getFrequency(scale, $super) {
    scale = scale === VOID ? Scale_DEFAULT_getInstance() : scale;
    return this.q6(scale);
  }
  function getThroughput(scale, cropFactor, $super) {
    scale = scale === VOID ? Scale_DEFAULT_getInstance() : scale;
    cropFactor = cropFactor === VOID ? null : cropFactor;
    return this.r6(scale, cropFactor);
  }
  function getAreaPerformance(scale, $super) {
    scale = scale === VOID ? Scale_DEFAULT_getInstance() : scale;
    return this.s6(scale);
  }
  function getYieldPerArea(scale, cropFactor, $super) {
    scale = scale === VOID ? Scale_DEFAULT_getInstance() : scale;
    cropFactor = cropFactor === VOID ? null : cropFactor;
    return this.t6(scale, cropFactor);
  }
  function getFuelPerYield(scale, cropFactor, $super) {
    scale = scale === VOID ? Scale_DEFAULT_getInstance() : scale;
    cropFactor = cropFactor === VOID ? null : cropFactor;
    return this.u6(scale, cropFactor);
  }
  function getFuelPerArea(scale, $super) {
    scale = scale === VOID ? Scale_DEFAULT_getInstance() : scale;
    return this.v6(scale);
  }
  function getQuantity(scale, $super) {
    scale = scale === VOID ? Scale_DEFAULT_getInstance() : scale;
    return this.w6(scale);
  }
  function getUnknownDimension(scale, $super) {
    scale = scale === VOID ? Scale_DEFAULT_getInstance() : scale;
    return this.x6(scale);
  }
  setMetadataFor(CDEUnitSystemDimensions, 'CDEUnitSystemDimensions', interfaceMeta);
  setMetadataFor(CDEUnits, 'CDEUnits', objectMeta);
  setMetadataFor(Dimension$Companion$none$1, VOID, classMeta, Dimension);
  setMetadataFor(Companion_1, 'Companion', objectMeta);
  setMetadataFor(Companion_2, 'Companion', objectMeta);
  setMetadataFor(Measurement, 'Measurement', classMeta);
  setMetadataFor(Companion_3, 'Companion', objectMeta);
  setMetadataFor(UnknownDimension, 'UnknownDimension', classMeta, Dimension);
  setMetadataFor(Companion_4, 'Companion', objectMeta);
  setMetadataFor(UnitFuelPerArea, 'UnitFuelPerArea', classMeta, Dimension);
  setMetadataFor(Companion_5, 'Companion', objectMeta);
  setMetadataFor(UnitFuelPerYield, 'UnitFuelPerYield', classMeta, Dimension);
  setMetadataFor(Companion_6, 'Companion', objectMeta);
  setMetadataFor(UnitAreaPerformance, 'UnitAreaPerformance', classMeta, Dimension);
  setMetadataFor(Companion_7, 'Companion', objectMeta);
  setMetadataFor(UnitThroughput, 'UnitThroughput', classMeta, Dimension);
  setMetadataFor(Companion_8, 'Companion', objectMeta);
  setMetadataFor(UnitYieldPerArea, 'UnitYieldPerArea', classMeta, Dimension);
  setMetadataFor(Companion_9, 'Companion', objectMeta);
  setMetadataFor(UnitMass, 'UnitMass', classMeta, Dimension);
  setMetadataFor(Companion_10, 'Companion', objectMeta);
  setMetadataFor(UnitPressure, 'UnitPressure', classMeta, Dimension);
  setMetadataFor(Companion_11, 'Companion', objectMeta);
  setMetadataFor(UnitQuotient, 'UnitQuotient', classMeta, Dimension);
  setMetadataFor(Companion_12, 'Companion', objectMeta);
  setMetadataFor(UnitArea, 'UnitArea', classMeta, Dimension);
  setMetadataFor(Companion_13, 'Companion', objectMeta);
  setMetadataFor(UnitLength, 'UnitLength', classMeta, Dimension);
  setMetadataFor(Companion_14, 'Companion', objectMeta);
  setMetadataFor(UnitVolume, 'UnitVolume', classMeta, Dimension);
  setMetadataFor(Companion_15, 'Companion', objectMeta);
  setMetadataFor(UnitQuantity, 'UnitQuantity', classMeta, Dimension);
  setMetadataFor(Companion_16, 'Companion', objectMeta);
  setMetadataFor(UnitDuration, 'UnitDuration', classMeta, Dimension);
  setMetadataFor(Companion_17, 'Companion', objectMeta);
  setMetadataFor(UnitFrequency, 'UnitFrequency', classMeta, Dimension);
  setMetadataFor(Companion_18, 'Companion', objectMeta);
  setMetadataFor(UnitSpeed, 'UnitSpeed', classMeta, Dimension);
  setMetadataFor(CustomaryUnitSystem, 'CustomaryUnitSystem', objectMeta, VOID, [CDEUnitSystemDimensions]);
  setMetadataFor(ImperialUnitSystem, 'ImperialUnitSystem', objectMeta, VOID, [CDEUnitSystemDimensions]);
  setMetadataFor(MetricUnitSystem, 'MetricUnitSystem', objectMeta, VOID, [CDEUnitSystemDimensions]);
  setMetadataFor(SIUnitSystem, 'SIUnitSystem', objectMeta, VOID, [CDEUnitSystemDimensions]);
  setMetadataFor(Companion_19, 'Companion', objectMeta);
  setMetadataFor(UnitVolumeFlowRate, 'UnitVolumeFlowRate', classMeta, Dimension);
  //endregion
  function Companion() {
    Companion_instance = this;
    this.k4_1 = new UnitDensity('kg/m\xB3', new UnitConverterLinear(1.0));
    this.l4_1 = new UnitDensity('g/l', new UnitConverterLinear(1.0));
    this.m4_1 = new UnitDensity('oz/gal', new UnitConverterLinear(7.489151707));
    this.n4_1 = new UnitDensity('oz/gal', new UnitConverterLinear(6.236023291));
  }
  protoOf(Companion).o4 = function (cropFactor) {
    var bushelsPerGallonCoefficient = 1.0 / (0.00378541 / (0.453592 * cropFactor));
    return new UnitDensity('bu/gal', new UnitConverterLinear(bushelsPerGallonCoefficient));
  };
  var Companion_instance;
  function Companion_getInstance_0() {
    if (Companion_instance == null) new Companion();
    return Companion_instance;
  }
  function UnitDensity(symbol, converter) {
    Companion_getInstance_0();
    Dimension.call(this, symbol, converter);
  }
  protoOf(UnitDensity).baseUnit = function () {
    return Companion_getInstance_0().k4_1;
  };
  function UnitConverter() {}
  function UnitConverterLinear(coefficient, constant) {
    constant = constant === VOID ? 0.0 : constant;
    UnitConverter.call(this);
    this.s4_1 = coefficient;
    this.t4_1 = constant;
  }
  protoOf(UnitConverterLinear).baseUnitValue = function (fromValue) {
    return fromValue * this.s4_1 + this.t4_1;
  };
  protoOf(UnitConverterLinear).value = function (fromBaseUnitValue) {
    return (fromBaseUnitValue - this.t4_1) / this.s4_1;
  };
  function Companion_0() {
    Companion_instance_0 = this;
    this.u4_1 = new UnitTemperature('K', new UnitConverterLinear(1.0, 0.0));
    this.v4_1 = new UnitTemperature('\xB0C', new UnitConverterLinear(1.0, 273.15));
    this.w4_1 = new UnitTemperature('\xB0F', new UnitConverterLinear(0.55555555555556, 255.37222222222428));
  }
  var Companion_instance_0;
  function Companion_getInstance_1() {
    if (Companion_instance_0 == null) new Companion_0();
    return Companion_instance_0;
  }
  function UnitTemperature(symbol, converter) {
    Companion_getInstance_1();
    Dimension.call(this, symbol, converter);
  }
  protoOf(UnitTemperature).baseUnit = function () {
    return Companion_getInstance_1().u4_1;
  };
  var UnitDimension_LIQUID_instance;
  var UnitDimension_MASS_instance;
  var UnitDimension_PRESSURE_instance;
  var UnitDimension_DENSITY_instance;
  var UnitDimension_LENGTH_instance;
  var UnitDimension_AREA_instance;
  var UnitDimension_TEMPERATURE_instance;
  var UnitDimension_TIME_instance;
  var UnitDimension_SPEED_instance;
  var UnitDimension_VOLUME_instance;
  var UnitDimension_VOLUME_FLOW_RATE_instance;
  var UnitDimension_QUOTIENT_instance;
  var UnitDimension_FREQUENCY_instance;
  var UnitDimension_THROUGHPUT_instance;
  var UnitDimension_AREAPERFORMANCE_instance;
  var UnitDimension_FUELPERYIELD_instance;
  var UnitDimension_YIELDPERAREA_instance;
  var UnitDimension_FUELPERAREA_instance;
  var UnitDimension_QUANTITY_instance;
  var UnitDimension_UNKNOWN_instance;
  var UnitDimension_entriesInitialized;
  function UnitDimension_initEntries() {
    if (UnitDimension_entriesInitialized) return Unit_instance;
    UnitDimension_entriesInitialized = true;
    UnitDimension_LIQUID_instance = new UnitDimension('LIQUID', 0);
    UnitDimension_MASS_instance = new UnitDimension('MASS', 1);
    UnitDimension_PRESSURE_instance = new UnitDimension('PRESSURE', 2);
    UnitDimension_DENSITY_instance = new UnitDimension('DENSITY', 3);
    UnitDimension_LENGTH_instance = new UnitDimension('LENGTH', 4);
    UnitDimension_AREA_instance = new UnitDimension('AREA', 5);
    UnitDimension_TEMPERATURE_instance = new UnitDimension('TEMPERATURE', 6);
    UnitDimension_TIME_instance = new UnitDimension('TIME', 7);
    UnitDimension_SPEED_instance = new UnitDimension('SPEED', 8);
    UnitDimension_VOLUME_instance = new UnitDimension('VOLUME', 9);
    UnitDimension_VOLUME_FLOW_RATE_instance = new UnitDimension('VOLUME_FLOW_RATE', 10);
    UnitDimension_QUOTIENT_instance = new UnitDimension('QUOTIENT', 11);
    UnitDimension_FREQUENCY_instance = new UnitDimension('FREQUENCY', 12);
    UnitDimension_THROUGHPUT_instance = new UnitDimension('THROUGHPUT', 13);
    UnitDimension_AREAPERFORMANCE_instance = new UnitDimension('AREAPERFORMANCE', 14);
    UnitDimension_FUELPERYIELD_instance = new UnitDimension('FUELPERYIELD', 15);
    UnitDimension_YIELDPERAREA_instance = new UnitDimension('YIELDPERAREA', 16);
    UnitDimension_FUELPERAREA_instance = new UnitDimension('FUELPERAREA', 17);
    UnitDimension_QUANTITY_instance = new UnitDimension('QUANTITY', 18);
    UnitDimension_UNKNOWN_instance = new UnitDimension('UNKNOWN', 19);
  }
  function UnitDimension(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  protoOf(UnitDimension).z4 = function (unitSystem, scale, cropFactor) {
    var tmp;
    switch (this.j3_1) {
      case 0:
        tmp = unitSystem.getLiquid(scale);
        break;
      case 1:
        tmp = unitSystem.getMass(scale, cropFactor);
        break;
      case 2:
        tmp = unitSystem.getPressure(scale);
        break;
      case 3:
        tmp = unitSystem.getDensity(scale, cropFactor);
        break;
      case 4:
        tmp = unitSystem.getLength(scale);
        break;
      case 5:
        tmp = unitSystem.getArea(scale);
        break;
      case 6:
        tmp = unitSystem.getTemperature(scale);
        break;
      case 7:
        tmp = unitSystem.getTime(scale);
        break;
      case 8:
        tmp = unitSystem.getSpeed(scale);
        break;
      case 9:
        tmp = unitSystem.getVolume(scale);
        break;
      case 10:
        tmp = unitSystem.getVolumeFlowRate(scale);
        break;
      case 11:
        tmp = unitSystem.getQuotient(scale);
        break;
      case 12:
        tmp = unitSystem.getFrequency(scale);
        break;
      case 13:
        tmp = unitSystem.getThroughput(scale, cropFactor);
        break;
      case 14:
        tmp = unitSystem.getAreaPerformance(scale);
        break;
      case 15:
        tmp = unitSystem.getFuelPerYield(scale, cropFactor);
        break;
      case 16:
        tmp = unitSystem.getYieldPerArea(scale, cropFactor);
        break;
      case 17:
        tmp = unitSystem.getFuelPerArea(scale);
        break;
      case 18:
        tmp = unitSystem.getQuantity(scale);
        break;
      case 19:
        tmp = unitSystem.getUnknownDimension(scale);
        break;
      default:
        noWhenBranchMatchedException();
        break;
    }
    return tmp;
  };
  protoOf(UnitDimension).a5 = function (unitSystem, scale, cropFactor, $super) {
    scale = scale === VOID ? Scale_DEFAULT_getInstance() : scale;
    cropFactor = cropFactor === VOID ? null : cropFactor;
    return $super === VOID ? this.z4(unitSystem, scale, cropFactor) : $super.z4.call(this, unitSystem, scale, cropFactor);
  };
  function CDEUnitConverter(targetUnitSystem, scale, cropFactor) {
    this.b5_1 = targetUnitSystem;
    this.c5_1 = scale;
    this.d5_1 = cropFactor;
  }
  protoOf(CDEUnitConverter).e5 = function (unit) {
    switch (unit) {
      case 'l':
        return UnitDimension_LIQUID_getInstance();
      case 'kg':
        return UnitDimension_MASS_getInstance();
      case 'N/m\xB2':
        return UnitDimension_PRESSURE_getInstance();
      case 's':
        return UnitDimension_TIME_getInstance();
      case 'm\xB2':
      case 'm2':
        return UnitDimension_AREA_getInstance();
      case 'm\xB3':
      case 'm3':
        return UnitDimension_VOLUME_getInstance();
      case 'm':
        return UnitDimension_LENGTH_getInstance();
      case 'K':
      case 'k':
        return UnitDimension_TEMPERATURE_getInstance();
      case 'm/s':
        return UnitDimension_SPEED_getInstance();
      case 'm3/s':
      case 'm\xB3/s':
        return UnitDimension_VOLUME_FLOW_RATE_getInstance();
      case '%':
        return UnitDimension_QUOTIENT_getInstance();
      case '1/s':
      case 'Hz':
        return UnitDimension_FREQUENCY_getInstance();
      case 'm\xB2/s':
      case 'm2/s':
        return UnitDimension_AREAPERFORMANCE_getInstance();
      case 'kg/s':
        return UnitDimension_THROUGHPUT_getInstance();
      case 'm\xB3/kg':
        return UnitDimension_FUELPERYIELD_getInstance();
      case 'm\xB3/m\xB2':
        return UnitDimension_FUELPERAREA_getInstance();
      case 'kg/m\xB2':
        return UnitDimension_YIELDPERAREA_getInstance();
      case 'kg/m\xB3':
      case 'kg/m3':
        return UnitDimension_DENSITY_getInstance();
      case 'number':
      case '':
        return UnitDimension_QUANTITY_getInstance();
      default:
        return UnitDimension_UNKNOWN_getInstance();
    }
  };
  protoOf(CDEUnitConverter).f5 = function (value, unit) {
    var dimension = this.e5(unit);
    if (dimension.equals(UnitDimension_UNKNOWN_getInstance())) {
      return new Measurement(value, new UnknownDimension(unit, new UnitConverterLinear(1.0)));
    }
    var targetDimension = dimension.z4(this.b5_1.units, this.c5_1, this.d5_1);
    var siUnit = dimension.a5(CDEUnitSystems_SI_getInstance().units);
    return new Measurement(value, siUnit).convert(targetDimension);
  };
  function UnitDimension_LIQUID_getInstance() {
    UnitDimension_initEntries();
    return UnitDimension_LIQUID_instance;
  }
  function UnitDimension_MASS_getInstance() {
    UnitDimension_initEntries();
    return UnitDimension_MASS_instance;
  }
  function UnitDimension_PRESSURE_getInstance() {
    UnitDimension_initEntries();
    return UnitDimension_PRESSURE_instance;
  }
  function UnitDimension_DENSITY_getInstance() {
    UnitDimension_initEntries();
    return UnitDimension_DENSITY_instance;
  }
  function UnitDimension_LENGTH_getInstance() {
    UnitDimension_initEntries();
    return UnitDimension_LENGTH_instance;
  }
  function UnitDimension_AREA_getInstance() {
    UnitDimension_initEntries();
    return UnitDimension_AREA_instance;
  }
  function UnitDimension_TEMPERATURE_getInstance() {
    UnitDimension_initEntries();
    return UnitDimension_TEMPERATURE_instance;
  }
  function UnitDimension_TIME_getInstance() {
    UnitDimension_initEntries();
    return UnitDimension_TIME_instance;
  }
  function UnitDimension_SPEED_getInstance() {
    UnitDimension_initEntries();
    return UnitDimension_SPEED_instance;
  }
  function UnitDimension_VOLUME_getInstance() {
    UnitDimension_initEntries();
    return UnitDimension_VOLUME_instance;
  }
  function UnitDimension_VOLUME_FLOW_RATE_getInstance() {
    UnitDimension_initEntries();
    return UnitDimension_VOLUME_FLOW_RATE_instance;
  }
  function UnitDimension_QUOTIENT_getInstance() {
    UnitDimension_initEntries();
    return UnitDimension_QUOTIENT_instance;
  }
  function UnitDimension_FREQUENCY_getInstance() {
    UnitDimension_initEntries();
    return UnitDimension_FREQUENCY_instance;
  }
  function UnitDimension_THROUGHPUT_getInstance() {
    UnitDimension_initEntries();
    return UnitDimension_THROUGHPUT_instance;
  }
  function UnitDimension_AREAPERFORMANCE_getInstance() {
    UnitDimension_initEntries();
    return UnitDimension_AREAPERFORMANCE_instance;
  }
  function UnitDimension_FUELPERYIELD_getInstance() {
    UnitDimension_initEntries();
    return UnitDimension_FUELPERYIELD_instance;
  }
  function UnitDimension_YIELDPERAREA_getInstance() {
    UnitDimension_initEntries();
    return UnitDimension_YIELDPERAREA_instance;
  }
  function UnitDimension_FUELPERAREA_getInstance() {
    UnitDimension_initEntries();
    return UnitDimension_FUELPERAREA_instance;
  }
  function UnitDimension_QUANTITY_getInstance() {
    UnitDimension_initEntries();
    return UnitDimension_QUANTITY_instance;
  }
  function UnitDimension_UNKNOWN_getInstance() {
    UnitDimension_initEntries();
    return UnitDimension_UNKNOWN_instance;
  }
  var CDEUnitSystems_SI_instance;
  var CDEUnitSystems_METRIC_instance;
  var CDEUnitSystems_IMPERIAL_instance;
  var CDEUnitSystems_CUSTOMARY_instance;
  function values() {
    return [CDEUnitSystems_SI_getInstance(), CDEUnitSystems_METRIC_getInstance(), CDEUnitSystems_IMPERIAL_getInstance(), CDEUnitSystems_CUSTOMARY_getInstance()];
  }
  function valueOf(value) {
    switch (value) {
      case 'SI':
        return CDEUnitSystems_SI_getInstance();
      case 'METRIC':
        return CDEUnitSystems_METRIC_getInstance();
      case 'IMPERIAL':
        return CDEUnitSystems_IMPERIAL_getInstance();
      case 'CUSTOMARY':
        return CDEUnitSystems_CUSTOMARY_getInstance();
      default:
        CDEUnitSystems_initEntries();
        THROW_IAE('No enum constant value.');
        break;
    }
  }
  var CDEUnitSystems_entriesInitialized;
  function CDEUnitSystems_initEntries() {
    if (CDEUnitSystems_entriesInitialized) return Unit_instance;
    CDEUnitSystems_entriesInitialized = true;
    CDEUnitSystems_SI_instance = new CDEUnitSystems('SI', 0);
    CDEUnitSystems_METRIC_instance = new CDEUnitSystems('METRIC', 1);
    CDEUnitSystems_IMPERIAL_instance = new CDEUnitSystems('IMPERIAL', 2);
    CDEUnitSystems_CUSTOMARY_instance = new CDEUnitSystems('CUSTOMARY', 3);
  }
  function CDEUnitSystems(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  protoOf(CDEUnitSystems).i5 = function () {
    var tmp;
    switch (this.j3_1) {
      case 0:
        tmp = SIUnitSystem_getInstance();
        break;
      case 1:
        tmp = MetricUnitSystem_getInstance();
        break;
      case 2:
        tmp = ImperialUnitSystem_getInstance();
        break;
      case 3:
        tmp = CustomaryUnitSystem_getInstance();
        break;
      default:
        noWhenBranchMatchedException();
        break;
    }
    return tmp;
  };
  var Scale_DEFAULT_instance;
  var Scale_SMALL_instance;
  function values_0() {
    return [Scale_DEFAULT_getInstance(), Scale_SMALL_getInstance()];
  }
  function valueOf_0(value) {
    switch (value) {
      case 'DEFAULT':
        return Scale_DEFAULT_getInstance();
      case 'SMALL':
        return Scale_SMALL_getInstance();
      default:
        Scale_initEntries();
        THROW_IAE('No enum constant value.');
        break;
    }
  }
  var Scale_entriesInitialized;
  function Scale_initEntries() {
    if (Scale_entriesInitialized) return Unit_instance;
    Scale_entriesInitialized = true;
    Scale_DEFAULT_instance = new Scale('DEFAULT', 0);
    Scale_SMALL_instance = new Scale('SMALL', 1);
  }
  function Scale(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  function CDEUnitSystemDimensions() {}
  function CDEUnitSystems_SI_getInstance() {
    CDEUnitSystems_initEntries();
    return CDEUnitSystems_SI_instance;
  }
  function CDEUnitSystems_METRIC_getInstance() {
    CDEUnitSystems_initEntries();
    return CDEUnitSystems_METRIC_instance;
  }
  function CDEUnitSystems_IMPERIAL_getInstance() {
    CDEUnitSystems_initEntries();
    return CDEUnitSystems_IMPERIAL_instance;
  }
  function CDEUnitSystems_CUSTOMARY_getInstance() {
    CDEUnitSystems_initEntries();
    return CDEUnitSystems_CUSTOMARY_instance;
  }
  function Scale_DEFAULT_getInstance() {
    Scale_initEntries();
    return Scale_DEFAULT_instance;
  }
  function Scale_SMALL_getInstance() {
    Scale_initEntries();
    return Scale_SMALL_instance;
  }
  function CDEUnits() {}
  protoOf(CDEUnits).y6 = function (value, unit, unitSystem, scale, cropFactor) {
    var tmp;
    if (unitSystem instanceof CDEUnitSystems) {
      tmp = unitSystem;
    } else {
      if (!(unitSystem == null) ? typeof unitSystem === 'string' : false) {
        tmp = valueOf(unitSystem);
      } else {
        if (unitSystem == null) {
          tmp = CDEUnitSystems_SI_getInstance();
        } else {
          throw IllegalArgumentException_init_$Create$('Invalid unit system type');
        }
      }
    }
    var enumUnitSystem = tmp;
    var tmp_0;
    if (scale instanceof Scale) {
      tmp_0 = scale;
    } else {
      if (!(scale == null) ? typeof scale === 'string' : false) {
        // Inline function 'kotlin.text.uppercase' call
        // Inline function 'kotlin.js.asDynamic' call
        var tmp_1;
        switch (scale.toUpperCase()) {
          case 'DEFAULT':
            tmp_1 = Scale_DEFAULT_getInstance();
            break;
          case 'SMALL':
            tmp_1 = Scale_SMALL_getInstance();
            break;
          default:
            throw IllegalArgumentException_init_$Create$('Invalid scale value');
        }
        tmp_0 = tmp_1;
      } else {
        if (scale == null) {
          tmp_0 = Scale_DEFAULT_getInstance();
        } else {
          throw IllegalArgumentException_init_$Create$('Invalid scale type');
        }
      }
    }
    var enumScale = tmp_0;
    return new CDEUnitConverter(enumUnitSystem, enumScale, cropFactor).f5(value, unit);
  };
  protoOf(CDEUnits).convert = function (value, unit, unitSystem, scale, cropFactor, $super) {
    unitSystem = unitSystem === VOID ? CDEUnitSystems_SI_getInstance() : unitSystem;
    scale = scale === VOID ? Scale_DEFAULT_getInstance() : scale;
    cropFactor = cropFactor === VOID ? null : cropFactor;
    return this.y6(value, unit, unitSystem, scale, cropFactor);
  };
  protoOf(CDEUnits).z6 = function (unit, unitSystem, scale, cropFactor) {
    var tmp;
    if (unitSystem instanceof CDEUnitSystems) {
      tmp = unitSystem;
    } else {
      if (!(unitSystem == null) ? typeof unitSystem === 'string' : false) {
        tmp = valueOf(unitSystem);
      } else {
        if (unitSystem == null) {
          tmp = CDEUnitSystems_SI_getInstance();
        } else {
          throw IllegalArgumentException_init_$Create$('Invalid unit system type');
        }
      }
    }
    var enumUnitSystem = tmp;
    var tmp_0;
    if (scale instanceof Scale) {
      tmp_0 = scale;
    } else {
      if (!(scale == null) ? typeof scale === 'string' : false) {
        // Inline function 'kotlin.text.uppercase' call
        // Inline function 'kotlin.js.asDynamic' call
        var tmp_1;
        switch (scale.toUpperCase()) {
          case 'DEFAULT':
            tmp_1 = Scale_DEFAULT_getInstance();
            break;
          case 'SMALL':
            tmp_1 = Scale_SMALL_getInstance();
            break;
          default:
            throw IllegalArgumentException_init_$Create$('Invalid scale value');
        }
        tmp_0 = tmp_1;
      } else {
        if (scale == null) {
          tmp_0 = Scale_DEFAULT_getInstance();
        } else {
          throw IllegalArgumentException_init_$Create$('Invalid scale type');
        }
      }
    }
    var enumScale = tmp_0;
    var cdeUnitConverter = new CDEUnitConverter(enumUnitSystem, enumScale, cropFactor);
    var dimension = cdeUnitConverter.e5(unit);
    var tmp_2;
    if (!dimension.equals(UnitDimension_UNKNOWN_getInstance())) {
      var targetDimension = dimension.z4(enumUnitSystem.units, enumScale, cropFactor);
      tmp_2 = targetDimension.symbol;
    } else {
      tmp_2 = unit;
    }
    return tmp_2;
  };
  protoOf(CDEUnits).getTargetUnitSymbol = function (unit, unitSystem, scale, cropFactor, $super) {
    unitSystem = unitSystem === VOID ? CDEUnitSystems_SI_getInstance() : unitSystem;
    scale = scale === VOID ? Scale_DEFAULT_getInstance() : scale;
    cropFactor = cropFactor === VOID ? null : cropFactor;
    return this.z6(unit, unitSystem, scale, cropFactor);
  };
  var CDEUnits_instance;
  function CDEUnits_getInstance() {
    return CDEUnits_instance;
  }
  function Dimension$Companion$none$1() {
    Dimension.call(this, 'number', new UnitConverterLinear(1.0));
  }
  protoOf(Dimension$Companion$none$1).baseUnit = function () {
    throw Exception_init_$Create$('No base unit defined');
  };
  function Companion_1() {}
  protoOf(Companion_1).a7 = function () {
    return new Dimension$Companion$none$1();
  };
  var Companion_instance_1;
  function Companion_getInstance_2() {
    return Companion_instance_1;
  }
  function Dimension(symbol, converter) {
    Unit.call(this, symbol);
    this.symbol = symbol;
    this.converter = converter;
  }
  protoOf(Dimension).q4 = function () {
    return this.symbol;
  };
  protoOf(Dimension).r4 = function () {
    return this.converter;
  };
  function Companion_2() {}
  protoOf(Companion_2).of = function (value, unit) {
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!isFinite(value)) {
      // Inline function 'com.claas.cde.unitsandmeasurement.essentials.Companion.of.<anonymous>' call
      var message = 'Value must be finite.';
      throw IllegalArgumentException_init_$Create$(toString(message));
    }
    return new Measurement(value, unit);
  };
  var Companion_instance_2;
  function Companion_getInstance_3() {
    return Companion_instance_2;
  }
  function formatHourDuration($this, durationHours, withMinutes, withSeconds) {
    // Inline function 'kotlin.time.Companion.hours' call
    Companion_getInstance();
    var duration = toDuration(durationHours, DurationUnit_HOURS_getInstance());
    var hours = _Duration___get_inWholeHours__impl__kb9f3j(duration);
    if (!withMinutes) {
      return hours.toString();
    }
    var tmp = _Duration___get_inWholeMinutes__impl__dognoh(duration);
    // Inline function 'kotlin.Long.times' call
    var tmp$ret$1 = _Duration___get_inWholeHours__impl__kb9f3j(duration).m3(toLong(60));
    var minutes = tmp.w3(tmp$ret$1);
    var formattedMinutes = minutes.n3(new Long(9, 0)) > 0 ? minutes.toString() : '0' + minutes.toString();
    if (!withSeconds) {
      return hours.toString() + ':' + formattedMinutes;
    }
    var tmp_0 = _Duration___get_inWholeSeconds__impl__hpy7b3(duration);
    // Inline function 'kotlin.Long.times' call
    var tmp$ret$2 = hours.m3(toLong(3600));
    var tmp_1 = tmp_0.w3(tmp$ret$2);
    // Inline function 'kotlin.Long.times' call
    var tmp$ret$3 = minutes.m3(toLong(60));
    var seconds = tmp_1.w3(tmp$ret$3);
    var formattedSeconds = seconds.n3(new Long(9, 0)) > 0 ? seconds.toString() : '0' + seconds.toString();
    return hours.toString() + ':' + formattedMinutes + ':' + formattedSeconds;
  }
  function formatMinuteDuration($this, durationMinutes) {
    // Inline function 'kotlin.time.Companion.minutes' call
    Companion_getInstance();
    var duration = toDuration(durationMinutes, DurationUnit_MINUTES_getInstance());
    var mins = _Duration___get_inWholeMinutes__impl__dognoh(duration);
    var tmp = _Duration___get_inWholeSeconds__impl__hpy7b3(duration);
    // Inline function 'kotlin.Long.times' call
    var tmp$ret$1 = mins.m3(toLong(60));
    var secs = tmp.w3(tmp$ret$1);
    var formattedMins = mins.n3(new Long(9, 0)) > 0 ? mins.toString() : '0' + mins.toString();
    var formattedSecs = secs.n3(new Long(9, 0)) > 0 ? secs.toString() : '0' + secs.toString();
    return formattedMins + ':' + formattedSecs;
  }
  function convertUnit($this, unit, localizationCallback) {
    switch (unit) {
      case 'mt':
        return 't';
      case 'C':
        return '\xB0C';
      case 'K':
        return '\xB0K';
      case 'R':
        return '\xB0R';
      case 'deg':
        return '\xB0';
      default:
        return localizationCallback(unit);
    }
  }
  function Measurement$formatted$lambda(it) {
    return it;
  }
  function Measurement$formatted$lambda_0(it) {
    return it;
  }
  function Measurement(value, unit) {
    this.value = value;
    this.unit = unit;
  }
  protoOf(Measurement).b7 = function (_set____db54di) {
    this.value = _set____db54di;
  };
  protoOf(Measurement).z2 = function () {
    return this.value;
  };
  protoOf(Measurement).c7 = function () {
    return this.unit;
  };
  protoOf(Measurement).convert = function (toUnit) {
    var baseValue = this.unit.converter.baseUnitValue(this.value);
    return new Measurement(toUnit.converter.value(baseValue), toUnit);
  };
  protoOf(Measurement).d7 = function (country, maxFractionDigits, withMinutes, withSeconds, localizationCallback, withUnitSymbol) {
    var effectiveCountry = country == null ? 'de' : country;
    var effectiveMaxFractionDigits = maxFractionDigits == null ? 0 : maxFractionDigits;
    var effectiveWithUnitSymbol = withUnitSymbol == null ? true : withUnitSymbol;
    var effectiveWithMinutes = withMinutes == null ? false : withMinutes;
    var effectiveWithSeconds = withSeconds == null ? false : withSeconds;
    var tmp;
    if (localizationCallback == null) {
      tmp = Measurement$formatted$lambda_0;
    } else {
      tmp = localizationCallback;
    }
    var effectiveLocalizationCallback = tmp;
    var valueRepresentation = localizedRepresentation(this.value, effectiveCountry, effectiveMaxFractionDigits, effectiveMaxFractionDigits);
    if (this.unit.symbol === '%') {
      return effectiveWithUnitSymbol ? valueRepresentation + ' %' : valueRepresentation;
    } else if (this.unit.symbol === 'number') {
      return valueRepresentation;
    }
    var tmp_0 = this.unit;
    if (tmp_0 instanceof UnitDuration) {
      var tmp6_subject = this.unit;
      var tmp_1;
      if (equals(tmp6_subject, Companion_getInstance_17().minutes)) {
        tmp_1 = formatMinuteDuration(this, this.value);
      } else if (equals(tmp6_subject, Companion_getInstance_17().hours)) {
        var formattedHours = formatHourDuration(this, this.value, effectiveWithMinutes, effectiveWithSeconds);
        tmp_1 = effectiveWithUnitSymbol ? formattedHours + ' h' : formattedHours;
      } else {
        tmp_1 = valueRepresentation;
      }
      return tmp_1;
    }
    var tmp_2 = this.unit;
    if (tmp_2 instanceof UnitTemperature) {
      var tmp_3;
      // Inline function 'kotlin.math.absoluteValue' call
      var this_0 = this.value;
      if (Math.abs(this_0) < 1.0E-4) {
        tmp_3 = 0.0;
      } else {
        tmp_3 = this.value;
      }
      var formattedValue = tmp_3;
      var formattedValueRepresentation = localizedRepresentation(formattedValue, effectiveCountry, effectiveMaxFractionDigits, effectiveMaxFractionDigits);
      var tmp_4;
      if (effectiveWithUnitSymbol) {
        tmp_4 = formattedValueRepresentation + ' ' + convertUnit(this, this.unit.symbol, effectiveLocalizationCallback);
      } else {
        tmp_4 = formattedValueRepresentation;
      }
      return tmp_4;
    }
    var tmp_5;
    var tmp_6 = this.unit;
    if (tmp_6 instanceof UnknownDimension) {
      // Inline function 'kotlin.text.isNotEmpty' call
      var this_1 = this.unit.symbol;
      tmp_5 = charSequenceLength(this_1) > 0;
    } else {
      tmp_5 = false;
    }
    if (tmp_5) {
      var tmp_7;
      if (effectiveWithUnitSymbol) {
        tmp_7 = valueRepresentation + ' ' + this.unit.symbol;
      } else {
        tmp_7 = valueRepresentation;
      }
      return tmp_7;
    }
    var tmp_8;
    if (effectiveWithUnitSymbol) {
      tmp_8 = valueRepresentation + ' ' + convertUnit(this, this.unit.symbol, effectiveLocalizationCallback);
    } else {
      tmp_8 = valueRepresentation;
    }
    return tmp_8;
  };
  protoOf(Measurement).formatted = function (country, maxFractionDigits, withMinutes, withSeconds, localizationCallback, withUnitSymbol, $super) {
    country = country === VOID ? 'de' : country;
    maxFractionDigits = maxFractionDigits === VOID ? 0 : maxFractionDigits;
    withMinutes = withMinutes === VOID ? true : withMinutes;
    withSeconds = withSeconds === VOID ? false : withSeconds;
    var tmp;
    if (localizationCallback === VOID) {
      tmp = Measurement$formatted$lambda;
    } else {
      tmp = localizationCallback;
    }
    localizationCallback = tmp;
    withUnitSymbol = withUnitSymbol === VOID ? true : withUnitSymbol;
    return this.d7(country, maxFractionDigits, withMinutes, withSeconds, localizationCallback, withUnitSymbol);
  };
  protoOf(Measurement).e7 = function () {
    return this.value;
  };
  protoOf(Measurement).f7 = function () {
    return this.unit;
  };
  protoOf(Measurement).g7 = function (value, unit) {
    return new Measurement(value, unit);
  };
  protoOf(Measurement).copy = function (value, unit, $super) {
    value = value === VOID ? this.value : value;
    unit = unit === VOID ? this.unit : unit;
    return this.g7(value, unit);
  };
  protoOf(Measurement).toString = function () {
    return 'Measurement(value=' + this.value + ', unit=' + this.unit + ')';
  };
  protoOf(Measurement).hashCode = function () {
    var result = getNumberHashCode(this.value);
    result = imul(result, 31) + hashCode(this.unit) | 0;
    return result;
  };
  protoOf(Measurement).equals = function (other) {
    if (this === other) return true;
    if (!(other instanceof Measurement)) return false;
    var tmp0_other_with_cast = other instanceof Measurement ? other : THROW_CCE();
    if (!equals(this.value, tmp0_other_with_cast.value)) return false;
    if (!equals(this.unit, tmp0_other_with_cast.unit)) return false;
    return true;
  };
  function Unit(symbol) {
    this.h7_1 = symbol;
  }
  protoOf(Unit).toString = function () {
    return this.h7_1;
  };
  function Companion_3() {
    Companion_instance_3 = this;
    this.i7_1 = new UnknownDimension('', new UnitConverterLinear(1.0));
  }
  var Companion_instance_3;
  function Companion_getInstance_4() {
    if (Companion_instance_3 == null) new Companion_3();
    return Companion_instance_3;
  }
  function UnknownDimension(symbol, converter) {
    Companion_getInstance_4();
    Dimension.call(this, symbol, converter);
  }
  protoOf(UnknownDimension).baseUnit = function () {
    return Companion_getInstance_4().i7_1;
  };
  function Companion_4() {
    Companion_instance_4 = this;
    this.litersPerSquareMeter = new UnitFuelPerArea('l/m\xB2', new UnitConverterLinear(0.001));
    this.cubicMetersPerSquareMeter = new UnitFuelPerArea('m\xB3/m\xB2', new UnitConverterLinear(1.0));
    this.gallonsPerAcre = new UnitFuelPerArea('gal/ac', new UnitConverterLinear(9.353956228890666E-7));
    this.imperialGallonsPerAcre = new UnitFuelPerArea('gal/ac', new UnitConverterLinear(1.1233632950586948E-6));
    this.litersPerHectare = new UnitFuelPerArea('l/ha', new UnitConverterLinear(1.0E-7));
  }
  protoOf(Companion_4).j7 = function () {
    return this.litersPerSquareMeter;
  };
  protoOf(Companion_4).k7 = function () {
    return this.cubicMetersPerSquareMeter;
  };
  protoOf(Companion_4).l7 = function () {
    return this.gallonsPerAcre;
  };
  protoOf(Companion_4).m7 = function () {
    return this.imperialGallonsPerAcre;
  };
  protoOf(Companion_4).n7 = function () {
    return this.litersPerHectare;
  };
  protoOf(Companion_4).a7 = function () {
    return Companion_instance_1.none;
  };
  var Companion_instance_4;
  function Companion_getInstance_5() {
    if (Companion_instance_4 == null) new Companion_4();
    return Companion_instance_4;
  }
  function UnitFuelPerArea(symbol, converter) {
    Companion_getInstance_5();
    Dimension.call(this, symbol, converter);
  }
  protoOf(UnitFuelPerArea).baseUnit = function () {
    return Companion_getInstance_5().cubicMetersPerSquareMeter;
  };
  function Companion_5() {
    Companion_instance_5 = this;
    this.cubicMetersPerKilogram = new UnitFuelPerYield('m\xB3/kg', new UnitConverterLinear(1.0));
    this.litersPerMetricTon = new UnitFuelPerYield('l/t', new UnitConverterLinear(1.0E-6));
    this.imperialGallonsPerMetricTon = new UnitFuelPerYield('gal/t', new UnitConverterLinear(4.545450000000001E-6));
    this.gallonsPerShortTon = new UnitFuelPerYield('gal/ton', new UnitConverterLinear(4.1727E-6));
  }
  protoOf(Companion_5).o7 = function () {
    return this.cubicMetersPerKilogram;
  };
  protoOf(Companion_5).p7 = function () {
    return this.litersPerMetricTon;
  };
  protoOf(Companion_5).q7 = function () {
    return this.imperialGallonsPerMetricTon;
  };
  protoOf(Companion_5).r7 = function () {
    return this.gallonsPerShortTon;
  };
  protoOf(Companion_5).a7 = function () {
    return Companion_instance_1.none;
  };
  protoOf(Companion_5).gallonsPerBushel = function (cropFactor) {
    var gallonsPerBushelCoefficient = 0.00378541 / (0.453592 * cropFactor);
    return new UnitFuelPerYield('gal/bu', new UnitConverterLinear(gallonsPerBushelCoefficient));
  };
  var Companion_instance_5;
  function Companion_getInstance_6() {
    if (Companion_instance_5 == null) new Companion_5();
    return Companion_instance_5;
  }
  function UnitFuelPerYield(symbol, converter) {
    Companion_getInstance_6();
    Dimension.call(this, symbol, converter);
  }
  protoOf(UnitFuelPerYield).baseUnit = function () {
    return Companion_getInstance_6().cubicMetersPerKilogram;
  };
  function Companion_6() {
    Companion_instance_6 = this;
    this.squareMegametersPerSecond = new UnitAreaPerformance('Mm\xB2/s', new UnitConverterLinear(1.0E12));
    this.squareKilometersPerSecond = new UnitAreaPerformance('km\xB2/s', new UnitConverterLinear(1000000.0));
    this.squareMetersPerSecond = new UnitAreaPerformance('m\xB2/s', new UnitConverterLinear(1.0));
    this.squareCentimetersPerSecond = new UnitAreaPerformance('cm\xB2/s', new UnitConverterLinear(1.0E-4));
    this.squareMillimetersPerSecond = new UnitAreaPerformance('mm\xB2/s', new UnitConverterLinear(1.0E-6));
    this.squareMicrometersPerSecond = new UnitAreaPerformance('\xB5m\xB2/s', new UnitConverterLinear(1.0E-12));
    this.squareNanometersPerSecond = new UnitAreaPerformance('nm\xB2/s', new UnitConverterLinear(1.0E-18));
    this.squareInchesPerSecond = new UnitAreaPerformance('in\xB2/s', new UnitConverterLinear(6.4516E-4));
    this.squareFeetPerSecond = new UnitAreaPerformance('ft\xB2/s', new UnitConverterLinear(0.092903));
    this.squareYardsPerSecond = new UnitAreaPerformance('yd\xB2/s', new UnitConverterLinear(0.836127));
    this.squareMilesPerSecond = new UnitAreaPerformance('mi\xB2/s', new UnitConverterLinear(2590000.0));
    this.acresPerSecond = new UnitAreaPerformance('ac/s', new UnitConverterLinear(4046.86));
    this.aresPerSecond = new UnitAreaPerformance('a/s', new UnitConverterLinear(100.0));
    this.hectaresPerSecond = new UnitAreaPerformance('ha/s', new UnitConverterLinear(10000.0));
    this.squareMegametersPerMinute = new UnitAreaPerformance('Mm\xB2/min', new UnitConverterLinear(1.6666666666666666E10));
    this.squareKilometersPerMinute = new UnitAreaPerformance('km\xB2/min', new UnitConverterLinear(16666.666666666668));
    this.squareMetersPerMinute = new UnitAreaPerformance('m\xB2/min', new UnitConverterLinear(0.016666666666666666));
    this.squareCentimetersPerMinute = new UnitAreaPerformance('cm\xB2/min', new UnitConverterLinear(1.6666666666666667E-6));
    this.squareMillimetersPerMinute = new UnitAreaPerformance('mm\xB2/min', new UnitConverterLinear(1.6666666666666667E-8));
    this.squareMicrometersPerMinute = new UnitAreaPerformance('\xB5m\xB2/min', new UnitConverterLinear(1.6666666666666667E-14));
    this.squareNanometersPerMinute = new UnitAreaPerformance('nm\xB2/min', new UnitConverterLinear(1.6666666666666668E-20));
    this.squareInchesPerMinute = new UnitAreaPerformance('in\xB2/min', new UnitConverterLinear(1.0752666666666666E-5));
    this.squareFeetPerMinute = new UnitAreaPerformance('ft\xB2/min', new UnitConverterLinear(0.0015483833333333333));
    this.squareYardsPerMinute = new UnitAreaPerformance('yd\xB2/min', new UnitConverterLinear(0.013935449999999999));
    this.squareMilesPerMinute = new UnitAreaPerformance('mi\xB2/min', new UnitConverterLinear(43166.666666666664));
    this.acresPerMinute = new UnitAreaPerformance('ac/min', new UnitConverterLinear(67.44766666666666));
    this.aresPerMinute = new UnitAreaPerformance('a/min', new UnitConverterLinear(1.6666666666666667));
    this.hectaresPerMinute = new UnitAreaPerformance('ha/min', new UnitConverterLinear(166.66666666666666));
    this.squareMegametersPerHour = new UnitAreaPerformance('Mm\xB2/h', new UnitConverterLinear(2.777777777777778E8));
    this.squareKilometersPerHour = new UnitAreaPerformance('km\xB2/h', new UnitConverterLinear(277.77777777777777));
    this.squareMetersPerHour = new UnitAreaPerformance('m\xB2/h', new UnitConverterLinear(2.777777777777778E-4));
    this.squareCentimetersPerHour = new UnitAreaPerformance('cm\xB2/h', new UnitConverterLinear(2.777777777777778E-8));
    this.squareMillimetersPerHour = new UnitAreaPerformance('mm\xB2/h', new UnitConverterLinear(2.7777777777777777E-10));
    this.squareMicrometersPerHour = new UnitAreaPerformance('\xB5m\xB2/h', new UnitConverterLinear(2.7777777777777775E-16));
    this.squareNanometersPerHour = new UnitAreaPerformance('nm\xB2/h', new UnitConverterLinear(2.777777777777778E-22));
    this.squareInchesPerHour = new UnitAreaPerformance('in\xB2/h', new UnitConverterLinear(1.7921111111111112E-7));
    this.squareFeetPerHour = new UnitAreaPerformance('ft\xB2/h', new UnitConverterLinear(2.580638888888889E-5));
    this.squareYardsPerHour = new UnitAreaPerformance('yd\xB2/h', new UnitConverterLinear(2.322575E-4));
    this.squareMilesPerHour = new UnitAreaPerformance('mi\xB2/h', new UnitConverterLinear(719.4444444444445));
    this.acresPerHour = new UnitAreaPerformance('ac/h', new UnitConverterLinear(1.1241277777777778));
    this.aresPerHour = new UnitAreaPerformance('a/h', new UnitConverterLinear(0.027777777777777776));
    this.hectaresPerHour = new UnitAreaPerformance('ha/h', new UnitConverterLinear(2.7777777777777777));
  }
  protoOf(Companion_6).s7 = function () {
    return this.squareMegametersPerSecond;
  };
  protoOf(Companion_6).t7 = function () {
    return this.squareKilometersPerSecond;
  };
  protoOf(Companion_6).u7 = function () {
    return this.squareMetersPerSecond;
  };
  protoOf(Companion_6).v7 = function () {
    return this.squareCentimetersPerSecond;
  };
  protoOf(Companion_6).w7 = function () {
    return this.squareMillimetersPerSecond;
  };
  protoOf(Companion_6).x7 = function () {
    return this.squareMicrometersPerSecond;
  };
  protoOf(Companion_6).y7 = function () {
    return this.squareNanometersPerSecond;
  };
  protoOf(Companion_6).z7 = function () {
    return this.squareInchesPerSecond;
  };
  protoOf(Companion_6).a8 = function () {
    return this.squareFeetPerSecond;
  };
  protoOf(Companion_6).b8 = function () {
    return this.squareYardsPerSecond;
  };
  protoOf(Companion_6).c8 = function () {
    return this.squareMilesPerSecond;
  };
  protoOf(Companion_6).d8 = function () {
    return this.acresPerSecond;
  };
  protoOf(Companion_6).e8 = function () {
    return this.aresPerSecond;
  };
  protoOf(Companion_6).f8 = function () {
    return this.hectaresPerSecond;
  };
  protoOf(Companion_6).g8 = function () {
    return this.squareMegametersPerMinute;
  };
  protoOf(Companion_6).h8 = function () {
    return this.squareKilometersPerMinute;
  };
  protoOf(Companion_6).i8 = function () {
    return this.squareMetersPerMinute;
  };
  protoOf(Companion_6).j8 = function () {
    return this.squareCentimetersPerMinute;
  };
  protoOf(Companion_6).k8 = function () {
    return this.squareMillimetersPerMinute;
  };
  protoOf(Companion_6).l8 = function () {
    return this.squareMicrometersPerMinute;
  };
  protoOf(Companion_6).m8 = function () {
    return this.squareNanometersPerMinute;
  };
  protoOf(Companion_6).n8 = function () {
    return this.squareInchesPerMinute;
  };
  protoOf(Companion_6).o8 = function () {
    return this.squareFeetPerMinute;
  };
  protoOf(Companion_6).p8 = function () {
    return this.squareYardsPerMinute;
  };
  protoOf(Companion_6).q8 = function () {
    return this.squareMilesPerMinute;
  };
  protoOf(Companion_6).r8 = function () {
    return this.acresPerMinute;
  };
  protoOf(Companion_6).s8 = function () {
    return this.aresPerMinute;
  };
  protoOf(Companion_6).t8 = function () {
    return this.hectaresPerMinute;
  };
  protoOf(Companion_6).u8 = function () {
    return this.squareMegametersPerHour;
  };
  protoOf(Companion_6).v8 = function () {
    return this.squareKilometersPerHour;
  };
  protoOf(Companion_6).w8 = function () {
    return this.squareMetersPerHour;
  };
  protoOf(Companion_6).x8 = function () {
    return this.squareCentimetersPerHour;
  };
  protoOf(Companion_6).y8 = function () {
    return this.squareMillimetersPerHour;
  };
  protoOf(Companion_6).z8 = function () {
    return this.squareMicrometersPerHour;
  };
  protoOf(Companion_6).a9 = function () {
    return this.squareNanometersPerHour;
  };
  protoOf(Companion_6).b9 = function () {
    return this.squareInchesPerHour;
  };
  protoOf(Companion_6).c9 = function () {
    return this.squareFeetPerHour;
  };
  protoOf(Companion_6).d9 = function () {
    return this.squareYardsPerHour;
  };
  protoOf(Companion_6).e9 = function () {
    return this.squareMilesPerHour;
  };
  protoOf(Companion_6).f9 = function () {
    return this.acresPerHour;
  };
  protoOf(Companion_6).g9 = function () {
    return this.aresPerHour;
  };
  protoOf(Companion_6).h9 = function () {
    return this.hectaresPerHour;
  };
  protoOf(Companion_6).a7 = function () {
    return Companion_instance_1.none;
  };
  var Companion_instance_6;
  function Companion_getInstance_7() {
    if (Companion_instance_6 == null) new Companion_6();
    return Companion_instance_6;
  }
  function UnitAreaPerformance(symbol, converter) {
    Companion_getInstance_7();
    Dimension.call(this, symbol, converter);
  }
  protoOf(UnitAreaPerformance).baseUnit = function () {
    return Companion_getInstance_7().squareMetersPerSecond;
  };
  function Companion_7() {
    Companion_instance_7 = this;
    this.kilogramsPerSecond = new UnitThroughput('kg/s', new UnitConverterLinear(1.0));
    this.kilogramsPerHour = new UnitThroughput('kg/h', new UnitConverterLinear(2.777777777777778E-4));
    this.metricTonsPerHour = new UnitThroughput('t/h', new UnitConverterLinear(0.2777777777777778));
    this.shortTonsPerHour = new UnitThroughput('ton/h', new UnitConverterLinear(0.2519958333333333));
  }
  protoOf(Companion_7).i9 = function () {
    return this.kilogramsPerSecond;
  };
  protoOf(Companion_7).j9 = function () {
    return this.kilogramsPerHour;
  };
  protoOf(Companion_7).k9 = function () {
    return this.metricTonsPerHour;
  };
  protoOf(Companion_7).l9 = function () {
    return this.shortTonsPerHour;
  };
  protoOf(Companion_7).a7 = function () {
    return Companion_instance_1.none;
  };
  protoOf(Companion_7).bushelsPerHour = function (cropFactor) {
    var bushelsPerHourCoefficient = 1.0 / (3600.0 / (0.453592 * cropFactor));
    return new UnitThroughput('bu/h', new UnitConverterLinear(bushelsPerHourCoefficient));
  };
  var Companion_instance_7;
  function Companion_getInstance_8() {
    if (Companion_instance_7 == null) new Companion_7();
    return Companion_instance_7;
  }
  function UnitThroughput(symbol, converter) {
    Companion_getInstance_8();
    Dimension.call(this, symbol, converter);
  }
  protoOf(UnitThroughput).baseUnit = function () {
    return Companion_getInstance_8().kilogramsPerSecond;
  };
  function Companion_8() {
    Companion_instance_8 = this;
    this.kilogramsPerSquareMeter = new UnitYieldPerArea('kg/m\xB2', new UnitConverterLinear(1.0));
    this.metricTonsPerAcre = new UnitYieldPerArea('t/ac', new UnitConverterLinear(0.24711));
    this.shortTonsPerAcre = new UnitYieldPerArea('ton/ac', new UnitConverterLinear(0.22417));
    this.metricTonsPerHectare = new UnitYieldPerArea('t/ha', new UnitConverterLinear(0.1));
  }
  protoOf(Companion_8).m9 = function () {
    return this.kilogramsPerSquareMeter;
  };
  protoOf(Companion_8).n9 = function () {
    return this.metricTonsPerAcre;
  };
  protoOf(Companion_8).o9 = function () {
    return this.shortTonsPerAcre;
  };
  protoOf(Companion_8).p9 = function () {
    return this.metricTonsPerHectare;
  };
  protoOf(Companion_8).a7 = function () {
    return Companion_instance_1.none;
  };
  protoOf(Companion_8).bushelsPerAcre = function (cropFactor) {
    var bushelsPerAcreCoefficient = 1.0 / (4046.86 / (0.453592 * cropFactor));
    return new UnitYieldPerArea('bu/ac', new UnitConverterLinear(bushelsPerAcreCoefficient));
  };
  var Companion_instance_8;
  function Companion_getInstance_9() {
    if (Companion_instance_8 == null) new Companion_8();
    return Companion_instance_8;
  }
  function UnitYieldPerArea(symbol, converter) {
    Companion_getInstance_9();
    Dimension.call(this, symbol, converter);
  }
  protoOf(UnitYieldPerArea).baseUnit = function () {
    return Companion_getInstance_9().kilogramsPerSquareMeter;
  };
  function Companion_9() {
    Companion_instance_9 = this;
    var tmp = this;
    // Inline function 'kotlin.collections.mutableMapOf' call
    tmp.q9_1 = LinkedHashMap_init_$Create$();
    this.r9_1 = new UnitMass('kg', new UnitConverterLinear(1.0));
    this.s9_1 = new UnitMass('g', new UnitConverterLinear(0.001));
    this.t9_1 = new UnitMass('mg', new UnitConverterLinear(1.0E-6));
    this.u9_1 = new UnitMass('oz', new UnitConverterLinear(0.0283495));
    this.v9_1 = new UnitMass('lb', new UnitConverterLinear(0.453592));
    this.w9_1 = new UnitMass('st', new UnitConverterLinear(0.157473));
    this.x9_1 = new UnitMass('dt', new UnitConverterLinear(100.0));
    this.y9_1 = new UnitMass('t', new UnitConverterLinear(1000.0));
    this.z9_1 = new UnitMass('ton', new UnitConverterLinear(907.185));
  }
  protoOf(Companion_9).aa = function (cropFactor) {
    // Inline function 'kotlin.collections.getOrPut' call
    var this_0 = this.q9_1;
    var value = this_0.w(cropFactor);
    var tmp;
    if (value == null) {
      // Inline function 'com.claas.cde.unitsandmeasurement.massweightforce.Companion.bushels.<anonymous>' call
      var bushelCoefficient = 0.453592 * cropFactor;
      var answer = new UnitMass('bu', new UnitConverterLinear(bushelCoefficient));
      this_0.r(cropFactor, answer);
      tmp = answer;
    } else {
      tmp = value;
    }
    return tmp;
  };
  var Companion_instance_9;
  function Companion_getInstance_10() {
    if (Companion_instance_9 == null) new Companion_9();
    return Companion_instance_9;
  }
  function UnitMass(symbol, converter) {
    Companion_getInstance_10();
    Dimension.call(this, symbol, converter);
  }
  protoOf(UnitMass).hashCode = function () {
    return imul(31, getStringHashCode(this.symbol)) + hashCode(this.converter) | 0;
  };
  protoOf(UnitMass).baseUnit = function () {
    return Companion_getInstance_10().r9_1;
  };
  function Companion_10() {
    Companion_instance_10 = this;
    this.ba_1 = new UnitPressure('N/m\xB2', new UnitConverterLinear(1.0));
    this.ca_1 = new UnitPressure('GPa', new UnitConverterLinear(1.0E9));
    this.da_1 = new UnitPressure('MPa', new UnitConverterLinear(1000000.0));
    this.ea_1 = new UnitPressure('kPa', new UnitConverterLinear(1000.0));
    this.fa_1 = new UnitPressure('hPa', new UnitConverterLinear(100.0));
    this.ga_1 = new UnitPressure('inHg', new UnitConverterLinear(3386.39));
    this.ha_1 = new UnitPressure('bar', new UnitConverterLinear(100000.0));
    this.ia_1 = new UnitPressure('mbar', new UnitConverterLinear(100.0));
    this.ja_1 = new UnitPressure('mmHg', new UnitConverterLinear(133.322));
    this.ka_1 = new UnitPressure('psi', new UnitConverterLinear(6894.76));
  }
  var Companion_instance_10;
  function Companion_getInstance_11() {
    if (Companion_instance_10 == null) new Companion_10();
    return Companion_instance_10;
  }
  function UnitPressure(symbol, converter) {
    Companion_getInstance_11();
    Dimension.call(this, symbol, converter);
  }
  protoOf(UnitPressure).baseUnit = function () {
    return Companion_getInstance_11().ba_1;
  };
  function Companion_11() {
    Companion_instance_11 = this;
    this.la_1 = new UnitQuotient('%', new UnitConverterLinear(1.0));
    this.ma_1 = new UnitQuotient('\u2030', new UnitConverterLinear(0.1));
  }
  var Companion_instance_11;
  function Companion_getInstance_12() {
    if (Companion_instance_11 == null) new Companion_11();
    return Companion_instance_11;
  }
  function UnitQuotient(symbol, converter) {
    Companion_getInstance_12();
    Dimension.call(this, symbol, converter);
  }
  protoOf(UnitQuotient).baseUnit = function () {
    return Companion_getInstance_12().la_1;
  };
  function Companion_12() {
    Companion_instance_12 = this;
    this.na_1 = new UnitArea('Mm\xB2', new UnitConverterLinear(1.0E12));
    this.oa_1 = new UnitArea('km\xB2', new UnitConverterLinear(1000000.0));
    this.pa_1 = new UnitArea('m\xB2', new UnitConverterLinear(1.0));
    this.qa_1 = new UnitArea('cm\xB2', new UnitConverterLinear(1.0E-4));
    this.ra_1 = new UnitArea('mm\xB2', new UnitConverterLinear(1.0E-6));
    this.sa_1 = new UnitArea('\xB5m\xB2', new UnitConverterLinear(1.0E-12));
    this.ta_1 = new UnitArea('nm\xB2', new UnitConverterLinear(1.0E-18));
    this.ua_1 = new UnitArea('in\xB2', new UnitConverterLinear(6.4516E-4));
    this.va_1 = new UnitArea('ft\xB2', new UnitConverterLinear(0.092903));
    this.wa_1 = new UnitArea('yd\xB2', new UnitConverterLinear(0.836127));
    this.xa_1 = new UnitArea('mi\xB2', new UnitConverterLinear(2590000.0));
    this.ya_1 = new UnitArea('ac', new UnitConverterLinear(4046.856422));
    this.za_1 = new UnitArea('a', new UnitConverterLinear(100.0));
    this.ab_1 = new UnitArea('ha', new UnitConverterLinear(10000.0));
  }
  var Companion_instance_12;
  function Companion_getInstance_13() {
    if (Companion_instance_12 == null) new Companion_12();
    return Companion_instance_12;
  }
  function UnitArea(symbol, converter) {
    Companion_getInstance_13();
    Dimension.call(this, symbol, converter);
  }
  protoOf(UnitArea).baseUnit = function () {
    return Companion_getInstance_13().pa_1;
  };
  function Companion_13() {
    Companion_instance_13 = this;
    this.bb_1 = new UnitLength('Mm', new UnitConverterLinear(1000000.0));
    this.cb_1 = new UnitLength('km', new UnitConverterLinear(1000.0));
    this.db_1 = new UnitLength('hm', new UnitConverterLinear(100.0));
    this.eb_1 = new UnitLength('dam', new UnitConverterLinear(10.0));
    this.fb_1 = new UnitLength('m', new UnitConverterLinear(1.0));
    this.gb_1 = new UnitLength('dm', new UnitConverterLinear(0.1));
    this.hb_1 = new UnitLength('cm', new UnitConverterLinear(0.01));
    this.ib_1 = new UnitLength('mm', new UnitConverterLinear(0.001));
    this.jb_1 = new UnitLength('\xB5m', new UnitConverterLinear(1.0E-6));
    this.kb_1 = new UnitLength('nm', new UnitConverterLinear(1.0E-9));
    this.lb_1 = new UnitLength('pm', new UnitConverterLinear(1.0E-12));
    this.mb_1 = new UnitLength('in', new UnitConverterLinear(0.0254));
    this.nb_1 = new UnitLength('ft', new UnitConverterLinear(0.3048));
    this.ob_1 = new UnitLength('yd', new UnitConverterLinear(0.9144));
    this.pb_1 = new UnitLength('mi', new UnitConverterLinear(1609.344000614692));
    this.qb_1 = new UnitLength('smi', new UnitConverterLinear(10000.0));
    this.rb_1 = new UnitLength('ly', new UnitConverterLinear(9.461E15));
    this.sb_1 = new UnitLength('NM', new UnitConverterLinear(1852.0));
    this.tb_1 = new UnitLength('ftm', new UnitConverterLinear(1.8288));
    this.ub_1 = new UnitLength('fur', new UnitConverterLinear(201.168));
    this.vb_1 = new UnitLength('ua', new UnitConverterLinear(1.496E11));
    this.wb_1 = new UnitLength('pc', new UnitConverterLinear(3.086E16));
  }
  var Companion_instance_13;
  function Companion_getInstance_14() {
    if (Companion_instance_13 == null) new Companion_13();
    return Companion_instance_13;
  }
  function UnitLength(symbol, converter) {
    Companion_getInstance_14();
    Dimension.call(this, symbol, converter);
  }
  protoOf(UnitLength).baseUnit = function () {
    return Companion_getInstance_14().fb_1;
  };
  function Companion_14() {
    Companion_instance_14 = this;
    this.xb_1 = new UnitVolume('Ml', new UnitConverterLinear(1000000.0));
    this.yb_1 = new UnitVolume('kl', new UnitConverterLinear(1000.0));
    this.zb_1 = new UnitVolume('l', new UnitConverterLinear(1.0));
    this.ac_1 = new UnitVolume('dl', new UnitConverterLinear(0.1));
    this.bc_1 = new UnitVolume('cl', new UnitConverterLinear(0.01));
    this.cc_1 = new UnitVolume('ml', new UnitConverterLinear(0.001));
    this.dc_1 = new UnitVolume('km\xB3', new UnitConverterLinear(1.0E12));
    this.ec_1 = new UnitVolume('m\xB3', new UnitConverterLinear(1000.0));
    this.fc_1 = new UnitVolume('dm\xB3', new UnitConverterLinear(1.0));
    this.gc_1 = new UnitVolume('mm\xB3', new UnitConverterLinear(1.0E-6));
    this.hc_1 = new UnitVolume('in\xB3', new UnitConverterLinear(0.0163871));
    this.ic_1 = new UnitVolume('ft\xB3', new UnitConverterLinear(28.3168));
    this.jc_1 = new UnitVolume('yd\xB3', new UnitConverterLinear(764.555));
    this.kc_1 = new UnitVolume('mi\xB3', new UnitConverterLinear(4.168E12));
    this.lc_1 = new UnitVolume('af', new UnitConverterLinear(1233000.0));
    this.mc_1 = new UnitVolume('bsh', new UnitConverterLinear(35.2391));
    this.nc_1 = new UnitVolume('tsp', new UnitConverterLinear(0.00492892));
    this.oc_1 = new UnitVolume('tbsp', new UnitConverterLinear(0.0147868));
    this.pc_1 = new UnitVolume('fl oz', new UnitConverterLinear(0.0295735));
    this.qc_1 = new UnitVolume('cup', new UnitConverterLinear(0.24));
    this.rc_1 = new UnitVolume('pt', new UnitConverterLinear(0.473176));
    this.sc_1 = new UnitVolume('qt', new UnitConverterLinear(0.946353));
    this.tc_1 = new UnitVolume('gal', new UnitConverterLinear(3.78541));
    this.uc_1 = new UnitVolume('tsp', new UnitConverterLinear(0.00591939));
    this.vc_1 = new UnitVolume('tbsp', new UnitConverterLinear(0.0177582));
    this.wc_1 = new UnitVolume('fl oz', new UnitConverterLinear(0.0284131));
    this.xc_1 = new UnitVolume('pt', new UnitConverterLinear(0.568261));
    this.yc_1 = new UnitVolume('qt', new UnitConverterLinear(1.13652));
    this.zc_1 = new UnitVolume('gal', new UnitConverterLinear(4.54609));
    this.ad_1 = new UnitVolume('metric cup', new UnitConverterLinear(0.25));
  }
  var Companion_instance_14;
  function Companion_getInstance_15() {
    if (Companion_instance_14 == null) new Companion_14();
    return Companion_instance_14;
  }
  function UnitVolume(symbol, converter) {
    Companion_getInstance_15();
    Dimension.call(this, symbol, converter);
  }
  protoOf(UnitVolume).baseUnit = function () {
    return Companion_getInstance_15().zb_1;
  };
  function Companion_15() {
    Companion_instance_15 = this;
    this.bd_1 = new UnitQuantity('pcs', new UnitConverterLinear(1.0));
    this.cd_1 = new UnitQuantity('', new UnitConverterLinear(1.0));
  }
  var Companion_instance_15;
  function Companion_getInstance_16() {
    if (Companion_instance_15 == null) new Companion_15();
    return Companion_instance_15;
  }
  function UnitQuantity(symbol, converter) {
    Companion_getInstance_16();
    Dimension.call(this, symbol, converter);
  }
  protoOf(UnitQuantity).baseUnit = function () {
    return Companion_getInstance_16().cd_1;
  };
  function Companion_16() {
    Companion_instance_16 = this;
    this.seconds = new UnitDuration('s', new UnitConverterLinear(1.0));
    this.minutes = new UnitDuration('min', new UnitConverterLinear(60.0));
    this.hours = new UnitDuration('h', new UnitConverterLinear(3600.0));
  }
  protoOf(Companion_16).dd = function () {
    return this.seconds;
  };
  protoOf(Companion_16).ed = function () {
    return this.minutes;
  };
  protoOf(Companion_16).fd = function () {
    return this.hours;
  };
  protoOf(Companion_16).a7 = function () {
    return Companion_instance_1.none;
  };
  var Companion_instance_16;
  function Companion_getInstance_17() {
    if (Companion_instance_16 == null) new Companion_16();
    return Companion_instance_16;
  }
  function UnitDuration(symbol, converter) {
    Companion_getInstance_17();
    Dimension.call(this, symbol, converter);
  }
  protoOf(UnitDuration).baseUnit = function () {
    return Companion_getInstance_17().seconds;
  };
  function Companion_17() {
    Companion_instance_17 = this;
    this.gd_1 = new UnitFrequency('THz', new UnitConverterLinear(1.0E12));
    this.hd_1 = new UnitFrequency('GHz', new UnitConverterLinear(1.0E9));
    this.id_1 = new UnitFrequency('MHz', new UnitConverterLinear(1000000.0));
    this.jd_1 = new UnitFrequency('kHz', new UnitConverterLinear(1000.0));
    this.kd_1 = new UnitFrequency('Hz', new UnitConverterLinear(1.0));
    this.ld_1 = new UnitFrequency('mHz', new UnitConverterLinear(0.001));
    this.md_1 = new UnitFrequency('\xB5Hz', new UnitConverterLinear(1.0E-6));
    this.nd_1 = new UnitFrequency('nHz', new UnitConverterLinear(1.0E-9));
    this.od_1 = new UnitFrequency('rpm', new UnitConverterLinear(0.016666666666666666));
  }
  var Companion_instance_17;
  function Companion_getInstance_18() {
    if (Companion_instance_17 == null) new Companion_17();
    return Companion_instance_17;
  }
  function UnitFrequency(symbol, converter) {
    Companion_getInstance_18();
    Dimension.call(this, symbol, converter);
  }
  protoOf(UnitFrequency).baseUnit = function () {
    return Companion_getInstance_18().kd_1;
  };
  function Companion_18() {
    Companion_instance_18 = this;
    this.pd_1 = new UnitSpeed('m/s', new UnitConverterLinear(1.0));
    this.qd_1 = new UnitSpeed('km/h', new UnitConverterLinear(0.277778));
    this.rd_1 = new UnitSpeed('mph', new UnitConverterLinear(0.44704));
    this.sd_1 = new UnitSpeed('kn', new UnitConverterLinear(0.514444));
  }
  var Companion_instance_18;
  function Companion_getInstance_19() {
    if (Companion_instance_18 == null) new Companion_18();
    return Companion_instance_18;
  }
  function UnitSpeed(symbol, converter) {
    Companion_getInstance_19();
    Dimension.call(this, symbol, converter);
  }
  protoOf(UnitSpeed).baseUnit = function () {
    return Companion_getInstance_19().pd_1;
  };
  function CustomaryUnitSystem() {
    CustomaryUnitSystem_instance = this;
    this.td_1 = Companion_getInstance_15().tc_1;
    this.ud_1 = Companion_getInstance_10().z9_1;
    this.vd_1 = Companion_getInstance_11().ka_1;
    this.wd_1 = Companion_getInstance_0().m4_1;
    this.xd_1 = Companion_getInstance_14().pb_1;
    this.yd_1 = Companion_getInstance_13().ya_1;
    this.zd_1 = Companion_getInstance_1().w4_1;
    this.ae_1 = Companion_getInstance_17().hours;
    this.be_1 = Companion_getInstance_19().rd_1;
    this.ce_1 = Companion_getInstance_15().tc_1;
    this.de_1 = Companion_getInstance_20().re_1;
    this.ee_1 = Companion_getInstance_18().od_1;
    this.fe_1 = Companion_getInstance_8().shortTonsPerHour;
    this.ge_1 = Companion_getInstance_7().acresPerHour;
    this.he_1 = Companion_getInstance_9().shortTonsPerAcre;
    this.ie_1 = Companion_getInstance_6().gallonsPerShortTon;
    this.je_1 = Companion_getInstance_5().gallonsPerAcre;
    this.ke_1 = Companion_getInstance_4().i7_1;
    this.le_1 = Companion_getInstance_16().cd_1;
    this.me_1 = Companion_getInstance_12().la_1;
  }
  protoOf(CustomaryUnitSystem).l5 = function () {
    return this.td_1;
  };
  protoOf(CustomaryUnitSystem).m5 = function () {
    return this.ud_1;
  };
  protoOf(CustomaryUnitSystem).n5 = function () {
    return this.vd_1;
  };
  protoOf(CustomaryUnitSystem).o5 = function () {
    return this.wd_1;
  };
  protoOf(CustomaryUnitSystem).a = function () {
    return this.xd_1;
  };
  protoOf(CustomaryUnitSystem).p5 = function () {
    return this.yd_1;
  };
  protoOf(CustomaryUnitSystem).q5 = function () {
    return this.zd_1;
  };
  protoOf(CustomaryUnitSystem).r5 = function () {
    return this.ae_1;
  };
  protoOf(CustomaryUnitSystem).s5 = function () {
    return this.be_1;
  };
  protoOf(CustomaryUnitSystem).t5 = function () {
    return this.ce_1;
  };
  protoOf(CustomaryUnitSystem).u5 = function () {
    return this.de_1;
  };
  protoOf(CustomaryUnitSystem).w5 = function () {
    return this.ee_1;
  };
  protoOf(CustomaryUnitSystem).x5 = function () {
    return this.fe_1;
  };
  protoOf(CustomaryUnitSystem).y5 = function () {
    return this.ge_1;
  };
  protoOf(CustomaryUnitSystem).z5 = function () {
    return this.he_1;
  };
  protoOf(CustomaryUnitSystem).a6 = function () {
    return this.ie_1;
  };
  protoOf(CustomaryUnitSystem).b6 = function () {
    return this.je_1;
  };
  protoOf(CustomaryUnitSystem).d6 = function () {
    return this.ke_1;
  };
  protoOf(CustomaryUnitSystem).c6 = function () {
    return this.le_1;
  };
  protoOf(CustomaryUnitSystem).v5 = function () {
    return this.me_1;
  };
  protoOf(CustomaryUnitSystem).e6 = function (scale) {
    var tmp;
    switch (scale.j3_1) {
      case 1:
        tmp = Companion_getInstance_15().pc_1;
        break;
      case 0:
        tmp = this.td_1;
        break;
      default:
        noWhenBranchMatchedException();
        break;
    }
    return tmp;
  };
  protoOf(CustomaryUnitSystem).f6 = function (scale, cropFactor) {
    var tmp;
    if (!(cropFactor == null)) {
      tmp = Companion_getInstance_10().aa(cropFactor);
    } else {
      var tmp_0;
      switch (scale.j3_1) {
        case 1:
          tmp_0 = Companion_getInstance_10().u9_1;
          break;
        case 0:
          tmp_0 = this.ud_1;
          break;
        default:
          noWhenBranchMatchedException();
          break;
      }
      tmp = tmp_0;
    }
    return tmp;
  };
  protoOf(CustomaryUnitSystem).g6 = function (scale) {
    return this.vd_1;
  };
  protoOf(CustomaryUnitSystem).h6 = function (scale, cropFactor) {
    var tmp;
    if (!(cropFactor == null)) {
      tmp = Companion_getInstance_0().o4(cropFactor);
    } else {
      tmp = this.wd_1;
    }
    return tmp;
  };
  protoOf(CustomaryUnitSystem).i6 = function (scale) {
    var tmp;
    switch (scale.j3_1) {
      case 1:
        tmp = Companion_getInstance_14().ib_1;
        break;
      case 0:
        tmp = this.xd_1;
        break;
      default:
        noWhenBranchMatchedException();
        break;
    }
    return tmp;
  };
  protoOf(CustomaryUnitSystem).j6 = function (scale) {
    var tmp;
    switch (scale.j3_1) {
      case 1:
        tmp = Companion_getInstance_13().va_1;
        break;
      case 0:
        tmp = this.yd_1;
        break;
      default:
        noWhenBranchMatchedException();
        break;
    }
    return tmp;
  };
  protoOf(CustomaryUnitSystem).k6 = function (scale) {
    return this.zd_1;
  };
  protoOf(CustomaryUnitSystem).l6 = function (scale) {
    return this.ae_1;
  };
  protoOf(CustomaryUnitSystem).m6 = function (scale) {
    return this.be_1;
  };
  protoOf(CustomaryUnitSystem).n6 = function (scale) {
    return this.ce_1;
  };
  protoOf(CustomaryUnitSystem).o6 = function (scale) {
    return this.de_1;
  };
  protoOf(CustomaryUnitSystem).q6 = function (scale) {
    return this.ee_1;
  };
  protoOf(CustomaryUnitSystem).r6 = function (scale, cropFactor) {
    var tmp;
    if (!(cropFactor == null)) {
      tmp = Companion_getInstance_8().bushelsPerHour(cropFactor);
    } else {
      tmp = this.fe_1;
    }
    return tmp;
  };
  protoOf(CustomaryUnitSystem).s6 = function (scale) {
    return this.ge_1;
  };
  protoOf(CustomaryUnitSystem).t6 = function (scale, cropFactor) {
    var tmp;
    if (!(cropFactor == null)) {
      tmp = Companion_getInstance_9().bushelsPerAcre(cropFactor);
    } else {
      tmp = this.he_1;
    }
    return tmp;
  };
  protoOf(CustomaryUnitSystem).u6 = function (scale, cropFactor) {
    var tmp;
    if (!(cropFactor == null)) {
      tmp = Companion_getInstance_6().gallonsPerBushel(cropFactor);
    } else {
      tmp = this.ie_1;
    }
    return tmp;
  };
  protoOf(CustomaryUnitSystem).v6 = function (scale) {
    return this.je_1;
  };
  protoOf(CustomaryUnitSystem).x6 = function (scale) {
    return SIUnitSystem_getInstance().lf_1;
  };
  protoOf(CustomaryUnitSystem).w6 = function (scale) {
    return this.le_1;
  };
  protoOf(CustomaryUnitSystem).p6 = function (scale) {
    return this.me_1;
  };
  var CustomaryUnitSystem_instance;
  function CustomaryUnitSystem_getInstance() {
    if (CustomaryUnitSystem_instance == null) new CustomaryUnitSystem();
    return CustomaryUnitSystem_instance;
  }
  function ImperialUnitSystem() {
    ImperialUnitSystem_instance = this;
    this.mf_1 = Companion_getInstance_15().zc_1;
    this.nf_1 = Companion_getInstance_10().y9_1;
    this.of_1 = Companion_getInstance_11().ka_1;
    this.pf_1 = Companion_getInstance_0().n4_1;
    this.qf_1 = Companion_getInstance_14().pb_1;
    this.rf_1 = Companion_getInstance_13().ya_1;
    this.sf_1 = Companion_getInstance_1().v4_1;
    this.tf_1 = Companion_getInstance_17().hours;
    this.uf_1 = Companion_getInstance_19().rd_1;
    this.vf_1 = Companion_getInstance_15().zc_1;
    this.wf_1 = Companion_getInstance_20().qe_1;
    this.xf_1 = Companion_getInstance_18().od_1;
    this.yf_1 = Companion_getInstance_8().metricTonsPerHour;
    this.zf_1 = Companion_getInstance_7().acresPerHour;
    this.ag_1 = Companion_getInstance_9().metricTonsPerAcre;
    this.bg_1 = Companion_getInstance_6().imperialGallonsPerMetricTon;
    this.cg_1 = Companion_getInstance_5().imperialGallonsPerAcre;
    this.dg_1 = Companion_getInstance_4().i7_1;
    this.eg_1 = Companion_getInstance_16().cd_1;
    this.fg_1 = Companion_getInstance_12().la_1;
  }
  protoOf(ImperialUnitSystem).l5 = function () {
    return this.mf_1;
  };
  protoOf(ImperialUnitSystem).m5 = function () {
    return this.nf_1;
  };
  protoOf(ImperialUnitSystem).n5 = function () {
    return this.of_1;
  };
  protoOf(ImperialUnitSystem).o5 = function () {
    return this.pf_1;
  };
  protoOf(ImperialUnitSystem).a = function () {
    return this.qf_1;
  };
  protoOf(ImperialUnitSystem).p5 = function () {
    return this.rf_1;
  };
  protoOf(ImperialUnitSystem).q5 = function () {
    return this.sf_1;
  };
  protoOf(ImperialUnitSystem).r5 = function () {
    return this.tf_1;
  };
  protoOf(ImperialUnitSystem).s5 = function () {
    return this.uf_1;
  };
  protoOf(ImperialUnitSystem).t5 = function () {
    return this.vf_1;
  };
  protoOf(ImperialUnitSystem).u5 = function () {
    return this.wf_1;
  };
  protoOf(ImperialUnitSystem).w5 = function () {
    return this.xf_1;
  };
  protoOf(ImperialUnitSystem).x5 = function () {
    return this.yf_1;
  };
  protoOf(ImperialUnitSystem).y5 = function () {
    return this.zf_1;
  };
  protoOf(ImperialUnitSystem).z5 = function () {
    return this.ag_1;
  };
  protoOf(ImperialUnitSystem).a6 = function () {
    return this.bg_1;
  };
  protoOf(ImperialUnitSystem).b6 = function () {
    return this.cg_1;
  };
  protoOf(ImperialUnitSystem).d6 = function () {
    return this.dg_1;
  };
  protoOf(ImperialUnitSystem).c6 = function () {
    return this.eg_1;
  };
  protoOf(ImperialUnitSystem).v5 = function () {
    return this.fg_1;
  };
  protoOf(ImperialUnitSystem).e6 = function (scale) {
    var tmp;
    switch (scale.j3_1) {
      case 1:
        tmp = Companion_getInstance_15().wc_1;
        break;
      case 0:
        tmp = this.mf_1;
        break;
      default:
        noWhenBranchMatchedException();
        break;
    }
    return tmp;
  };
  protoOf(ImperialUnitSystem).f6 = function (scale, cropFactor) {
    var tmp;
    switch (scale.j3_1) {
      case 1:
        tmp = Companion_getInstance_10().u9_1;
        break;
      case 0:
        tmp = this.nf_1;
        break;
      default:
        noWhenBranchMatchedException();
        break;
    }
    return tmp;
  };
  protoOf(ImperialUnitSystem).g6 = function (scale) {
    return this.of_1;
  };
  protoOf(ImperialUnitSystem).h6 = function (scale, cropFactor) {
    return this.pf_1;
  };
  protoOf(ImperialUnitSystem).i6 = function (scale) {
    var tmp;
    switch (scale.j3_1) {
      case 1:
        tmp = Companion_getInstance_14().ib_1;
        break;
      case 0:
        tmp = this.qf_1;
        break;
      default:
        noWhenBranchMatchedException();
        break;
    }
    return tmp;
  };
  protoOf(ImperialUnitSystem).j6 = function (scale) {
    var tmp;
    switch (scale.j3_1) {
      case 1:
        tmp = Companion_getInstance_13().va_1;
        break;
      case 0:
        tmp = this.rf_1;
        break;
      default:
        noWhenBranchMatchedException();
        break;
    }
    return tmp;
  };
  protoOf(ImperialUnitSystem).k6 = function (scale) {
    return this.sf_1;
  };
  protoOf(ImperialUnitSystem).l6 = function (scale) {
    return this.tf_1;
  };
  protoOf(ImperialUnitSystem).m6 = function (scale) {
    return this.uf_1;
  };
  protoOf(ImperialUnitSystem).n6 = function (scale) {
    return this.vf_1;
  };
  protoOf(ImperialUnitSystem).o6 = function (scale) {
    return this.wf_1;
  };
  protoOf(ImperialUnitSystem).q6 = function (scale) {
    return this.xf_1;
  };
  protoOf(ImperialUnitSystem).r6 = function (scale, cropFactor) {
    return this.yf_1;
  };
  protoOf(ImperialUnitSystem).s6 = function (scale) {
    return this.zf_1;
  };
  protoOf(ImperialUnitSystem).t6 = function (scale, cropFactor) {
    return this.ag_1;
  };
  protoOf(ImperialUnitSystem).u6 = function (scale, cropFactor) {
    return this.bg_1;
  };
  protoOf(ImperialUnitSystem).v6 = function (scale) {
    return this.cg_1;
  };
  protoOf(ImperialUnitSystem).x6 = function (scale) {
    return SIUnitSystem_getInstance().lf_1;
  };
  protoOf(ImperialUnitSystem).w6 = function (scale) {
    return this.eg_1;
  };
  protoOf(ImperialUnitSystem).p6 = function (scale) {
    return this.fg_1;
  };
  var ImperialUnitSystem_instance;
  function ImperialUnitSystem_getInstance() {
    if (ImperialUnitSystem_instance == null) new ImperialUnitSystem();
    return ImperialUnitSystem_instance;
  }
  function MetricUnitSystem() {
    MetricUnitSystem_instance = this;
    this.gg_1 = Companion_getInstance_15().zb_1;
    this.hg_1 = Companion_getInstance_10().y9_1;
    this.ig_1 = Companion_getInstance_11().ha_1;
    this.jg_1 = Companion_getInstance_0().l4_1;
    this.kg_1 = Companion_getInstance_14().cb_1;
    this.lg_1 = Companion_getInstance_13().ab_1;
    this.mg_1 = Companion_getInstance_1().v4_1;
    this.ng_1 = Companion_getInstance_17().hours;
    this.og_1 = Companion_getInstance_19().qd_1;
    this.pg_1 = Companion_getInstance_15().zb_1;
    this.qg_1 = Companion_getInstance_20().pe_1;
    this.rg_1 = Companion_getInstance_18().od_1;
    this.sg_1 = Companion_getInstance_8().metricTonsPerHour;
    this.tg_1 = Companion_getInstance_7().hectaresPerHour;
    this.ug_1 = Companion_getInstance_9().metricTonsPerHectare;
    this.vg_1 = Companion_getInstance_6().litersPerMetricTon;
    this.wg_1 = Companion_getInstance_5().litersPerHectare;
    this.xg_1 = Companion_getInstance_16().cd_1;
    this.yg_1 = Companion_getInstance_4().i7_1;
    this.zg_1 = Companion_getInstance_12().la_1;
  }
  protoOf(MetricUnitSystem).l5 = function () {
    return this.gg_1;
  };
  protoOf(MetricUnitSystem).m5 = function () {
    return this.hg_1;
  };
  protoOf(MetricUnitSystem).n5 = function () {
    return this.ig_1;
  };
  protoOf(MetricUnitSystem).o5 = function () {
    return this.jg_1;
  };
  protoOf(MetricUnitSystem).a = function () {
    return this.kg_1;
  };
  protoOf(MetricUnitSystem).p5 = function () {
    return this.lg_1;
  };
  protoOf(MetricUnitSystem).q5 = function () {
    return this.mg_1;
  };
  protoOf(MetricUnitSystem).r5 = function () {
    return this.ng_1;
  };
  protoOf(MetricUnitSystem).s5 = function () {
    return this.og_1;
  };
  protoOf(MetricUnitSystem).t5 = function () {
    return this.pg_1;
  };
  protoOf(MetricUnitSystem).u5 = function () {
    return this.qg_1;
  };
  protoOf(MetricUnitSystem).w5 = function () {
    return this.rg_1;
  };
  protoOf(MetricUnitSystem).x5 = function () {
    return this.sg_1;
  };
  protoOf(MetricUnitSystem).y5 = function () {
    return this.tg_1;
  };
  protoOf(MetricUnitSystem).z5 = function () {
    return this.ug_1;
  };
  protoOf(MetricUnitSystem).a6 = function () {
    return this.vg_1;
  };
  protoOf(MetricUnitSystem).b6 = function () {
    return this.wg_1;
  };
  protoOf(MetricUnitSystem).c6 = function () {
    return this.xg_1;
  };
  protoOf(MetricUnitSystem).d6 = function () {
    return this.yg_1;
  };
  protoOf(MetricUnitSystem).v5 = function () {
    return this.zg_1;
  };
  protoOf(MetricUnitSystem).e6 = function (scale) {
    var tmp;
    switch (scale.j3_1) {
      case 1:
        tmp = Companion_getInstance_15().cc_1;
        break;
      case 0:
        tmp = this.gg_1;
        break;
      default:
        noWhenBranchMatchedException();
        break;
    }
    return tmp;
  };
  protoOf(MetricUnitSystem).f6 = function (scale, cropFactor) {
    var tmp;
    switch (scale.j3_1) {
      case 1:
        tmp = Companion_getInstance_10().s9_1;
        break;
      case 0:
        tmp = this.hg_1;
        break;
      default:
        noWhenBranchMatchedException();
        break;
    }
    return tmp;
  };
  protoOf(MetricUnitSystem).g6 = function (scale) {
    return this.ig_1;
  };
  protoOf(MetricUnitSystem).h6 = function (scale, cropFactor) {
    return this.jg_1;
  };
  protoOf(MetricUnitSystem).i6 = function (scale) {
    var tmp;
    switch (scale.j3_1) {
      case 1:
        tmp = Companion_getInstance_14().ib_1;
        break;
      case 0:
        tmp = this.kg_1;
        break;
      default:
        noWhenBranchMatchedException();
        break;
    }
    return tmp;
  };
  protoOf(MetricUnitSystem).j6 = function (scale) {
    var tmp;
    switch (scale.j3_1) {
      case 1:
        tmp = Companion_getInstance_13().pa_1;
        break;
      case 0:
        tmp = this.lg_1;
        break;
      default:
        noWhenBranchMatchedException();
        break;
    }
    return tmp;
  };
  protoOf(MetricUnitSystem).k6 = function (scale) {
    return this.mg_1;
  };
  protoOf(MetricUnitSystem).l6 = function (scale) {
    return this.ng_1;
  };
  protoOf(MetricUnitSystem).m6 = function (scale) {
    var tmp;
    switch (scale.j3_1) {
      case 1:
        tmp = Companion_getInstance_19().pd_1;
        break;
      case 0:
        tmp = this.og_1;
        break;
      default:
        noWhenBranchMatchedException();
        break;
    }
    return tmp;
  };
  protoOf(MetricUnitSystem).n6 = function (scale) {
    var tmp;
    switch (scale.j3_1) {
      case 1:
        tmp = Companion_getInstance_15().cc_1;
        break;
      case 0:
        tmp = this.pg_1;
        break;
      default:
        noWhenBranchMatchedException();
        break;
    }
    return tmp;
  };
  protoOf(MetricUnitSystem).o6 = function (scale) {
    return this.qg_1;
  };
  protoOf(MetricUnitSystem).q6 = function (scale) {
    return this.rg_1;
  };
  protoOf(MetricUnitSystem).r6 = function (scale, cropFactor) {
    var tmp;
    switch (scale.j3_1) {
      case 1:
        tmp = Companion_getInstance_8().kilogramsPerHour;
        break;
      case 0:
        tmp = this.sg_1;
        break;
      default:
        noWhenBranchMatchedException();
        break;
    }
    return tmp;
  };
  protoOf(MetricUnitSystem).s6 = function (scale) {
    var tmp;
    switch (scale.j3_1) {
      case 1:
        tmp = Companion_getInstance_7().squareMetersPerHour;
        break;
      case 0:
        tmp = this.tg_1;
        break;
      default:
        noWhenBranchMatchedException();
        break;
    }
    return tmp;
  };
  protoOf(MetricUnitSystem).t6 = function (scale, cropFactor) {
    return this.ug_1;
  };
  protoOf(MetricUnitSystem).u6 = function (scale, cropFactor) {
    return this.vg_1;
  };
  protoOf(MetricUnitSystem).v6 = function (scale) {
    return this.wg_1;
  };
  protoOf(MetricUnitSystem).p6 = function (scale) {
    return this.zg_1;
  };
  protoOf(MetricUnitSystem).w6 = function (scale) {
    return this.xg_1;
  };
  protoOf(MetricUnitSystem).x6 = function (scale) {
    return SIUnitSystem_getInstance().lf_1;
  };
  var MetricUnitSystem_instance;
  function MetricUnitSystem_getInstance() {
    if (MetricUnitSystem_instance == null) new MetricUnitSystem();
    return MetricUnitSystem_instance;
  }
  function SIUnitSystem() {
    SIUnitSystem_instance = this;
    this.se_1 = Companion_getInstance_15().zb_1;
    this.te_1 = Companion_getInstance_10().r9_1;
    this.ue_1 = Companion_getInstance_11().ba_1;
    this.ve_1 = Companion_getInstance_0().k4_1;
    this.we_1 = Companion_getInstance_14().fb_1;
    this.xe_1 = Companion_getInstance_13().pa_1;
    this.ye_1 = Companion_getInstance_1().u4_1;
    this.ze_1 = Companion_getInstance_17().seconds;
    this.af_1 = Companion_getInstance_19().pd_1;
    this.bf_1 = Companion_getInstance_15().ec_1;
    this.cf_1 = Companion_getInstance_20().ne_1;
    this.df_1 = Companion_getInstance_12().la_1;
    this.ef_1 = Companion_getInstance_18().kd_1;
    this.ff_1 = Companion_getInstance_8().kilogramsPerSecond;
    this.gf_1 = Companion_getInstance_7().squareMetersPerSecond;
    this.hf_1 = Companion_getInstance_9().kilogramsPerSquareMeter;
    this.if_1 = Companion_getInstance_6().cubicMetersPerKilogram;
    this.jf_1 = Companion_getInstance_5().cubicMetersPerSquareMeter;
    this.kf_1 = Companion_getInstance_16().cd_1;
    this.lf_1 = Companion_getInstance_4().i7_1;
  }
  protoOf(SIUnitSystem).l5 = function () {
    return this.se_1;
  };
  protoOf(SIUnitSystem).m5 = function () {
    return this.te_1;
  };
  protoOf(SIUnitSystem).n5 = function () {
    return this.ue_1;
  };
  protoOf(SIUnitSystem).o5 = function () {
    return this.ve_1;
  };
  protoOf(SIUnitSystem).a = function () {
    return this.we_1;
  };
  protoOf(SIUnitSystem).p5 = function () {
    return this.xe_1;
  };
  protoOf(SIUnitSystem).q5 = function () {
    return this.ye_1;
  };
  protoOf(SIUnitSystem).r5 = function () {
    return this.ze_1;
  };
  protoOf(SIUnitSystem).s5 = function () {
    return this.af_1;
  };
  protoOf(SIUnitSystem).t5 = function () {
    return this.bf_1;
  };
  protoOf(SIUnitSystem).u5 = function () {
    return this.cf_1;
  };
  protoOf(SIUnitSystem).v5 = function () {
    return this.df_1;
  };
  protoOf(SIUnitSystem).w5 = function () {
    return this.ef_1;
  };
  protoOf(SIUnitSystem).x5 = function () {
    return this.ff_1;
  };
  protoOf(SIUnitSystem).y5 = function () {
    return this.gf_1;
  };
  protoOf(SIUnitSystem).z5 = function () {
    return this.hf_1;
  };
  protoOf(SIUnitSystem).a6 = function () {
    return this.if_1;
  };
  protoOf(SIUnitSystem).b6 = function () {
    return this.jf_1;
  };
  protoOf(SIUnitSystem).c6 = function () {
    return this.kf_1;
  };
  protoOf(SIUnitSystem).d6 = function () {
    return this.lf_1;
  };
  protoOf(SIUnitSystem).e6 = function (scale) {
    return this.se_1;
  };
  protoOf(SIUnitSystem).f6 = function (scale, cropFactor) {
    return this.te_1;
  };
  protoOf(SIUnitSystem).g6 = function (scale) {
    return this.ue_1;
  };
  protoOf(SIUnitSystem).h6 = function (scale, cropFactor) {
    return this.ve_1;
  };
  protoOf(SIUnitSystem).i6 = function (scale) {
    return this.we_1;
  };
  protoOf(SIUnitSystem).j6 = function (scale) {
    return this.xe_1;
  };
  protoOf(SIUnitSystem).k6 = function (scale) {
    return this.ye_1;
  };
  protoOf(SIUnitSystem).l6 = function (scale) {
    return this.ze_1;
  };
  protoOf(SIUnitSystem).m6 = function (scale) {
    return this.af_1;
  };
  protoOf(SIUnitSystem).n6 = function (scale) {
    return this.bf_1;
  };
  protoOf(SIUnitSystem).o6 = function (scale) {
    return this.cf_1;
  };
  protoOf(SIUnitSystem).p6 = function (scale) {
    return this.df_1;
  };
  protoOf(SIUnitSystem).q6 = function (scale) {
    return this.ef_1;
  };
  protoOf(SIUnitSystem).r6 = function (scale, cropFactor) {
    return this.ff_1;
  };
  protoOf(SIUnitSystem).s6 = function (scale) {
    return this.gf_1;
  };
  protoOf(SIUnitSystem).t6 = function (scale, cropFactor) {
    return this.hf_1;
  };
  protoOf(SIUnitSystem).u6 = function (scale, cropFactor) {
    return this.if_1;
  };
  protoOf(SIUnitSystem).v6 = function (scale) {
    return this.jf_1;
  };
  protoOf(SIUnitSystem).w6 = function (scale) {
    return this.kf_1;
  };
  protoOf(SIUnitSystem).x6 = function (scale) {
    return this.lf_1;
  };
  var SIUnitSystem_instance;
  function SIUnitSystem_getInstance() {
    if (SIUnitSystem_instance == null) new SIUnitSystem();
    return SIUnitSystem_instance;
  }
  function Companion_19() {
    Companion_instance_19 = this;
    this.ne_1 = new UnitVolumeFlowRate('m\xB3/s', new UnitConverterLinear(1.0));
    this.oe_1 = new UnitVolumeFlowRate('l/s', new UnitConverterLinear(1000.0));
    this.pe_1 = new UnitVolumeFlowRate('l/h', new UnitConverterLinear(2.7777777777777776E-7));
    this.qe_1 = new UnitVolumeFlowRate('gal/h', new UnitConverterLinear(1.2628027777777776E-6));
    this.re_1 = new UnitVolumeFlowRate('gal/h', new UnitConverterLinear(1.0515032733333334E-6));
  }
  var Companion_instance_19;
  function Companion_getInstance_20() {
    if (Companion_instance_19 == null) new Companion_19();
    return Companion_instance_19;
  }
  function UnitVolumeFlowRate(symbol, converter) {
    Companion_getInstance_20();
    Dimension.call(this, symbol, converter);
  }
  protoOf(UnitVolumeFlowRate).baseUnit = function () {
    return Companion_getInstance_20().ne_1;
  };
  function localizedRepresentation(_this__u8e3s4, country, minimumFractionDigits, maximumFractionDigits) {
    var json = JSON.parse('{"minimumFractionDigits": ' + minimumFractionDigits + ', "maximumFractionDigits": ' + maximumFractionDigits + '}');
    var dyn = _this__u8e3s4;
    var tmp = dyn.toLocaleString(country, json);
    return (!(tmp == null) ? typeof tmp === 'string' : false) ? tmp : THROW_CCE();
  }
  //region block: post-declaration
  defineProp(protoOf(CDEUnitSystems), 'units', protoOf(CDEUnitSystems).i5);
  defineProp(protoOf(CDEUnitSystems), 'name', protoOf(CDEUnitSystems).o3);
  defineProp(protoOf(CDEUnitSystems), 'ordinal', protoOf(CDEUnitSystems).p3);
  defineProp(protoOf(Scale), 'name', protoOf(Scale).o3);
  defineProp(protoOf(Scale), 'ordinal', protoOf(Scale).p3);
  defineProp(protoOf(Companion_1), 'none', protoOf(Companion_1).a7);
  defineProp(protoOf(Companion_4), 'none', protoOf(Companion_4).a7);
  defineProp(protoOf(Companion_5), 'none', protoOf(Companion_5).a7);
  defineProp(protoOf(Companion_6), 'none', protoOf(Companion_6).a7);
  defineProp(protoOf(Companion_7), 'none', protoOf(Companion_7).a7);
  defineProp(protoOf(Companion_8), 'none', protoOf(Companion_8).a7);
  defineProp(protoOf(Companion_16), 'none', protoOf(Companion_16).a7);
  protoOf(CustomaryUnitSystem).getLiquid = getLiquid;
  protoOf(CustomaryUnitSystem).getMass = getMass;
  protoOf(CustomaryUnitSystem).getPressure = getPressure;
  protoOf(CustomaryUnitSystem).getDensity = getDensity;
  protoOf(CustomaryUnitSystem).getLength = getLength;
  protoOf(CustomaryUnitSystem).getArea = getArea;
  protoOf(CustomaryUnitSystem).getTemperature = getTemperature;
  protoOf(CustomaryUnitSystem).getTime = getTime;
  protoOf(CustomaryUnitSystem).getSpeed = getSpeed;
  protoOf(CustomaryUnitSystem).getVolume = getVolume;
  protoOf(CustomaryUnitSystem).getVolumeFlowRate = getVolumeFlowRate;
  protoOf(CustomaryUnitSystem).getFrequency = getFrequency;
  protoOf(CustomaryUnitSystem).getThroughput = getThroughput;
  protoOf(CustomaryUnitSystem).getAreaPerformance = getAreaPerformance;
  protoOf(CustomaryUnitSystem).getYieldPerArea = getYieldPerArea;
  protoOf(CustomaryUnitSystem).getFuelPerYield = getFuelPerYield;
  protoOf(CustomaryUnitSystem).getFuelPerArea = getFuelPerArea;
  protoOf(CustomaryUnitSystem).getUnknownDimension = getUnknownDimension;
  protoOf(CustomaryUnitSystem).getQuantity = getQuantity;
  protoOf(CustomaryUnitSystem).getQuotient = getQuotient;
  defineProp(protoOf(CustomaryUnitSystem), 'liquid', function () {
    return this.l5();
  });
  defineProp(protoOf(CustomaryUnitSystem), 'mass', function () {
    return this.m5();
  });
  defineProp(protoOf(CustomaryUnitSystem), 'pressure', function () {
    return this.n5();
  });
  defineProp(protoOf(CustomaryUnitSystem), 'density', function () {
    return this.o5();
  });
  defineProp(protoOf(CustomaryUnitSystem), 'length', function () {
    return this.a();
  });
  defineProp(protoOf(CustomaryUnitSystem), 'area', function () {
    return this.p5();
  });
  defineProp(protoOf(CustomaryUnitSystem), 'temperature', function () {
    return this.q5();
  });
  defineProp(protoOf(CustomaryUnitSystem), 'time', function () {
    return this.r5();
  });
  defineProp(protoOf(CustomaryUnitSystem), 'speed', function () {
    return this.s5();
  });
  defineProp(protoOf(CustomaryUnitSystem), 'volume', function () {
    return this.t5();
  });
  defineProp(protoOf(CustomaryUnitSystem), 'volumeFlowRate', function () {
    return this.u5();
  });
  defineProp(protoOf(CustomaryUnitSystem), 'frequency', function () {
    return this.w5();
  });
  defineProp(protoOf(CustomaryUnitSystem), 'throughput', function () {
    return this.x5();
  });
  defineProp(protoOf(CustomaryUnitSystem), 'areaPerformance', function () {
    return this.y5();
  });
  defineProp(protoOf(CustomaryUnitSystem), 'yieldPerArea', function () {
    return this.z5();
  });
  defineProp(protoOf(CustomaryUnitSystem), 'fuelPerYield', function () {
    return this.a6();
  });
  defineProp(protoOf(CustomaryUnitSystem), 'fuelPerArea', function () {
    return this.b6();
  });
  defineProp(protoOf(CustomaryUnitSystem), 'unknownDimension', function () {
    return this.d6();
  });
  defineProp(protoOf(CustomaryUnitSystem), 'quantity', function () {
    return this.c6();
  });
  defineProp(protoOf(CustomaryUnitSystem), 'quotient', function () {
    return this.v5();
  });
  protoOf(ImperialUnitSystem).getLiquid = getLiquid;
  protoOf(ImperialUnitSystem).getMass = getMass;
  protoOf(ImperialUnitSystem).getPressure = getPressure;
  protoOf(ImperialUnitSystem).getDensity = getDensity;
  protoOf(ImperialUnitSystem).getLength = getLength;
  protoOf(ImperialUnitSystem).getArea = getArea;
  protoOf(ImperialUnitSystem).getTemperature = getTemperature;
  protoOf(ImperialUnitSystem).getTime = getTime;
  protoOf(ImperialUnitSystem).getSpeed = getSpeed;
  protoOf(ImperialUnitSystem).getVolume = getVolume;
  protoOf(ImperialUnitSystem).getVolumeFlowRate = getVolumeFlowRate;
  protoOf(ImperialUnitSystem).getFrequency = getFrequency;
  protoOf(ImperialUnitSystem).getThroughput = getThroughput;
  protoOf(ImperialUnitSystem).getAreaPerformance = getAreaPerformance;
  protoOf(ImperialUnitSystem).getYieldPerArea = getYieldPerArea;
  protoOf(ImperialUnitSystem).getFuelPerYield = getFuelPerYield;
  protoOf(ImperialUnitSystem).getFuelPerArea = getFuelPerArea;
  protoOf(ImperialUnitSystem).getUnknownDimension = getUnknownDimension;
  protoOf(ImperialUnitSystem).getQuantity = getQuantity;
  protoOf(ImperialUnitSystem).getQuotient = getQuotient;
  defineProp(protoOf(ImperialUnitSystem), 'liquid', function () {
    return this.l5();
  });
  defineProp(protoOf(ImperialUnitSystem), 'mass', function () {
    return this.m5();
  });
  defineProp(protoOf(ImperialUnitSystem), 'pressure', function () {
    return this.n5();
  });
  defineProp(protoOf(ImperialUnitSystem), 'density', function () {
    return this.o5();
  });
  defineProp(protoOf(ImperialUnitSystem), 'length', function () {
    return this.a();
  });
  defineProp(protoOf(ImperialUnitSystem), 'area', function () {
    return this.p5();
  });
  defineProp(protoOf(ImperialUnitSystem), 'temperature', function () {
    return this.q5();
  });
  defineProp(protoOf(ImperialUnitSystem), 'time', function () {
    return this.r5();
  });
  defineProp(protoOf(ImperialUnitSystem), 'speed', function () {
    return this.s5();
  });
  defineProp(protoOf(ImperialUnitSystem), 'volume', function () {
    return this.t5();
  });
  defineProp(protoOf(ImperialUnitSystem), 'volumeFlowRate', function () {
    return this.u5();
  });
  defineProp(protoOf(ImperialUnitSystem), 'frequency', function () {
    return this.w5();
  });
  defineProp(protoOf(ImperialUnitSystem), 'throughput', function () {
    return this.x5();
  });
  defineProp(protoOf(ImperialUnitSystem), 'areaPerformance', function () {
    return this.y5();
  });
  defineProp(protoOf(ImperialUnitSystem), 'yieldPerArea', function () {
    return this.z5();
  });
  defineProp(protoOf(ImperialUnitSystem), 'fuelPerYield', function () {
    return this.a6();
  });
  defineProp(protoOf(ImperialUnitSystem), 'fuelPerArea', function () {
    return this.b6();
  });
  defineProp(protoOf(ImperialUnitSystem), 'unknownDimension', function () {
    return this.d6();
  });
  defineProp(protoOf(ImperialUnitSystem), 'quantity', function () {
    return this.c6();
  });
  defineProp(protoOf(ImperialUnitSystem), 'quotient', function () {
    return this.v5();
  });
  protoOf(MetricUnitSystem).getLiquid = getLiquid;
  protoOf(MetricUnitSystem).getMass = getMass;
  protoOf(MetricUnitSystem).getPressure = getPressure;
  protoOf(MetricUnitSystem).getDensity = getDensity;
  protoOf(MetricUnitSystem).getLength = getLength;
  protoOf(MetricUnitSystem).getArea = getArea;
  protoOf(MetricUnitSystem).getTemperature = getTemperature;
  protoOf(MetricUnitSystem).getTime = getTime;
  protoOf(MetricUnitSystem).getSpeed = getSpeed;
  protoOf(MetricUnitSystem).getVolume = getVolume;
  protoOf(MetricUnitSystem).getVolumeFlowRate = getVolumeFlowRate;
  protoOf(MetricUnitSystem).getFrequency = getFrequency;
  protoOf(MetricUnitSystem).getThroughput = getThroughput;
  protoOf(MetricUnitSystem).getAreaPerformance = getAreaPerformance;
  protoOf(MetricUnitSystem).getYieldPerArea = getYieldPerArea;
  protoOf(MetricUnitSystem).getFuelPerYield = getFuelPerYield;
  protoOf(MetricUnitSystem).getFuelPerArea = getFuelPerArea;
  protoOf(MetricUnitSystem).getQuotient = getQuotient;
  protoOf(MetricUnitSystem).getQuantity = getQuantity;
  protoOf(MetricUnitSystem).getUnknownDimension = getUnknownDimension;
  defineProp(protoOf(MetricUnitSystem), 'liquid', function () {
    return this.l5();
  });
  defineProp(protoOf(MetricUnitSystem), 'mass', function () {
    return this.m5();
  });
  defineProp(protoOf(MetricUnitSystem), 'pressure', function () {
    return this.n5();
  });
  defineProp(protoOf(MetricUnitSystem), 'density', function () {
    return this.o5();
  });
  defineProp(protoOf(MetricUnitSystem), 'length', function () {
    return this.a();
  });
  defineProp(protoOf(MetricUnitSystem), 'area', function () {
    return this.p5();
  });
  defineProp(protoOf(MetricUnitSystem), 'temperature', function () {
    return this.q5();
  });
  defineProp(protoOf(MetricUnitSystem), 'time', function () {
    return this.r5();
  });
  defineProp(protoOf(MetricUnitSystem), 'speed', function () {
    return this.s5();
  });
  defineProp(protoOf(MetricUnitSystem), 'volume', function () {
    return this.t5();
  });
  defineProp(protoOf(MetricUnitSystem), 'volumeFlowRate', function () {
    return this.u5();
  });
  defineProp(protoOf(MetricUnitSystem), 'frequency', function () {
    return this.w5();
  });
  defineProp(protoOf(MetricUnitSystem), 'throughput', function () {
    return this.x5();
  });
  defineProp(protoOf(MetricUnitSystem), 'areaPerformance', function () {
    return this.y5();
  });
  defineProp(protoOf(MetricUnitSystem), 'yieldPerArea', function () {
    return this.z5();
  });
  defineProp(protoOf(MetricUnitSystem), 'fuelPerYield', function () {
    return this.a6();
  });
  defineProp(protoOf(MetricUnitSystem), 'fuelPerArea', function () {
    return this.b6();
  });
  defineProp(protoOf(MetricUnitSystem), 'quantity', function () {
    return this.c6();
  });
  defineProp(protoOf(MetricUnitSystem), 'unknownDimension', function () {
    return this.d6();
  });
  defineProp(protoOf(MetricUnitSystem), 'quotient', function () {
    return this.v5();
  });
  protoOf(SIUnitSystem).getLiquid = getLiquid;
  protoOf(SIUnitSystem).getMass = getMass;
  protoOf(SIUnitSystem).getPressure = getPressure;
  protoOf(SIUnitSystem).getDensity = getDensity;
  protoOf(SIUnitSystem).getLength = getLength;
  protoOf(SIUnitSystem).getArea = getArea;
  protoOf(SIUnitSystem).getTemperature = getTemperature;
  protoOf(SIUnitSystem).getTime = getTime;
  protoOf(SIUnitSystem).getSpeed = getSpeed;
  protoOf(SIUnitSystem).getVolume = getVolume;
  protoOf(SIUnitSystem).getVolumeFlowRate = getVolumeFlowRate;
  protoOf(SIUnitSystem).getQuotient = getQuotient;
  protoOf(SIUnitSystem).getFrequency = getFrequency;
  protoOf(SIUnitSystem).getThroughput = getThroughput;
  protoOf(SIUnitSystem).getAreaPerformance = getAreaPerformance;
  protoOf(SIUnitSystem).getYieldPerArea = getYieldPerArea;
  protoOf(SIUnitSystem).getFuelPerYield = getFuelPerYield;
  protoOf(SIUnitSystem).getFuelPerArea = getFuelPerArea;
  protoOf(SIUnitSystem).getQuantity = getQuantity;
  protoOf(SIUnitSystem).getUnknownDimension = getUnknownDimension;
  defineProp(protoOf(SIUnitSystem), 'liquid', function () {
    return this.l5();
  });
  defineProp(protoOf(SIUnitSystem), 'mass', function () {
    return this.m5();
  });
  defineProp(protoOf(SIUnitSystem), 'pressure', function () {
    return this.n5();
  });
  defineProp(protoOf(SIUnitSystem), 'density', function () {
    return this.o5();
  });
  defineProp(protoOf(SIUnitSystem), 'length', function () {
    return this.a();
  });
  defineProp(protoOf(SIUnitSystem), 'area', function () {
    return this.p5();
  });
  defineProp(protoOf(SIUnitSystem), 'temperature', function () {
    return this.q5();
  });
  defineProp(protoOf(SIUnitSystem), 'time', function () {
    return this.r5();
  });
  defineProp(protoOf(SIUnitSystem), 'speed', function () {
    return this.s5();
  });
  defineProp(protoOf(SIUnitSystem), 'volume', function () {
    return this.t5();
  });
  defineProp(protoOf(SIUnitSystem), 'volumeFlowRate', function () {
    return this.u5();
  });
  defineProp(protoOf(SIUnitSystem), 'quotient', function () {
    return this.v5();
  });
  defineProp(protoOf(SIUnitSystem), 'frequency', function () {
    return this.w5();
  });
  defineProp(protoOf(SIUnitSystem), 'throughput', function () {
    return this.x5();
  });
  defineProp(protoOf(SIUnitSystem), 'areaPerformance', function () {
    return this.y5();
  });
  defineProp(protoOf(SIUnitSystem), 'yieldPerArea', function () {
    return this.z5();
  });
  defineProp(protoOf(SIUnitSystem), 'fuelPerYield', function () {
    return this.a6();
  });
  defineProp(protoOf(SIUnitSystem), 'fuelPerArea', function () {
    return this.b6();
  });
  defineProp(protoOf(SIUnitSystem), 'quantity', function () {
    return this.c6();
  });
  defineProp(protoOf(SIUnitSystem), 'unknownDimension', function () {
    return this.d6();
  });
  //endregion
  //region block: init
  CDEUnits_instance = new CDEUnits();
  Companion_instance_1 = new Companion_1();
  Companion_instance_2 = new Companion_2();
  //endregion
  //region block: exports
  function $jsExportAll$(_) {
    var $com = _.com || (_.com = {});
    var $com$claas = $com.claas || ($com.claas = {});
    var $com$claas$cde = $com$claas.cde || ($com$claas.cde = {});
    var $com$claas$cde$unitsandmeasurement = $com$claas$cde.unitsandmeasurement || ($com$claas$cde.unitsandmeasurement = {});
    var $com$claas$cde$unitsandmeasurement$conversion = $com$claas$cde$unitsandmeasurement.conversion || ($com$claas$cde$unitsandmeasurement.conversion = {});
    $com$claas$cde$unitsandmeasurement$conversion.UnitConverter = UnitConverter;
    var $com = _.com || (_.com = {});
    var $com$claas = $com.claas || ($com.claas = {});
    var $com$claas$cde = $com$claas.cde || ($com$claas.cde = {});
    var $com$claas$cde$unitsandmeasurement = $com$claas$cde.unitsandmeasurement || ($com$claas$cde.unitsandmeasurement = {});
    var $com$claas$cde$unitsandmeasurement$essentials = $com$claas$cde$unitsandmeasurement.essentials || ($com$claas$cde$unitsandmeasurement.essentials = {});
    $com$claas$cde$unitsandmeasurement$essentials.CDEUnitSystems = CDEUnitSystems;
    $com$claas$cde$unitsandmeasurement$essentials.CDEUnitSystems.values = values;
    $com$claas$cde$unitsandmeasurement$essentials.CDEUnitSystems.valueOf = valueOf;
    defineProp($com$claas$cde$unitsandmeasurement$essentials.CDEUnitSystems, 'SI', CDEUnitSystems_SI_getInstance);
    defineProp($com$claas$cde$unitsandmeasurement$essentials.CDEUnitSystems, 'METRIC', CDEUnitSystems_METRIC_getInstance);
    defineProp($com$claas$cde$unitsandmeasurement$essentials.CDEUnitSystems, 'IMPERIAL', CDEUnitSystems_IMPERIAL_getInstance);
    defineProp($com$claas$cde$unitsandmeasurement$essentials.CDEUnitSystems, 'CUSTOMARY', CDEUnitSystems_CUSTOMARY_getInstance);
    $com$claas$cde$unitsandmeasurement$essentials.Scale = Scale;
    $com$claas$cde$unitsandmeasurement$essentials.Scale.values = values_0;
    $com$claas$cde$unitsandmeasurement$essentials.Scale.valueOf = valueOf_0;
    defineProp($com$claas$cde$unitsandmeasurement$essentials.Scale, 'DEFAULT', Scale_DEFAULT_getInstance);
    defineProp($com$claas$cde$unitsandmeasurement$essentials.Scale, 'SMALL', Scale_SMALL_getInstance);
    var $com = _.com || (_.com = {});
    var $com$claas = $com.claas || ($com.claas = {});
    var $com$claas$cde = $com$claas.cde || ($com$claas.cde = {});
    var $com$claas$cde$unitsandmeasurement = $com$claas$cde.unitsandmeasurement || ($com$claas$cde.unitsandmeasurement = {});
    var $com$claas$cde$unitsandmeasurement$essentials = $com$claas$cde$unitsandmeasurement.essentials || ($com$claas$cde$unitsandmeasurement.essentials = {});
    defineProp($com$claas$cde$unitsandmeasurement$essentials, 'CDEUnits', CDEUnits_getInstance);
    var $com = _.com || (_.com = {});
    var $com$claas = $com.claas || ($com.claas = {});
    var $com$claas$cde = $com$claas.cde || ($com$claas.cde = {});
    var $com$claas$cde$unitsandmeasurement = $com$claas$cde.unitsandmeasurement || ($com$claas$cde.unitsandmeasurement = {});
    var $com$claas$cde$unitsandmeasurement$essentials = $com$claas$cde$unitsandmeasurement.essentials || ($com$claas$cde$unitsandmeasurement.essentials = {});
    $com$claas$cde$unitsandmeasurement$essentials.Dimension = Dimension;
    defineProp($com$claas$cde$unitsandmeasurement$essentials.Dimension, 'Companion', Companion_getInstance_2);
    var $com = _.com || (_.com = {});
    var $com$claas = $com.claas || ($com.claas = {});
    var $com$claas$cde = $com$claas.cde || ($com$claas.cde = {});
    var $com$claas$cde$unitsandmeasurement = $com$claas$cde.unitsandmeasurement || ($com$claas$cde.unitsandmeasurement = {});
    var $com$claas$cde$unitsandmeasurement$essentials = $com$claas$cde$unitsandmeasurement.essentials || ($com$claas$cde$unitsandmeasurement.essentials = {});
    $com$claas$cde$unitsandmeasurement$essentials.Measurement = Measurement;
    defineProp($com$claas$cde$unitsandmeasurement$essentials.Measurement, 'Companion', Companion_getInstance_3);
    var $com = _.com || (_.com = {});
    var $com$claas = $com.claas || ($com.claas = {});
    var $com$claas$cde = $com$claas.cde || ($com$claas.cde = {});
    var $com$claas$cde$unitsandmeasurement = $com$claas$cde.unitsandmeasurement || ($com$claas$cde.unitsandmeasurement = {});
    var $com$claas$cde$unitsandmeasurement$essentials = $com$claas$cde$unitsandmeasurement.essentials || ($com$claas$cde$unitsandmeasurement.essentials = {});
    $com$claas$cde$unitsandmeasurement$essentials.Unit = Unit;
    var $com = _.com || (_.com = {});
    var $com$claas = $com.claas || ($com.claas = {});
    var $com$claas$cde = $com$claas.cde || ($com$claas.cde = {});
    var $com$claas$cde$unitsandmeasurement = $com$claas$cde.unitsandmeasurement || ($com$claas$cde.unitsandmeasurement = {});
    var $com$claas$cde$unitsandmeasurement$fuelefficiency = $com$claas$cde$unitsandmeasurement.fuelefficiency || ($com$claas$cde$unitsandmeasurement.fuelefficiency = {});
    $com$claas$cde$unitsandmeasurement$fuelefficiency.UnitFuelPerArea = UnitFuelPerArea;
    defineProp($com$claas$cde$unitsandmeasurement$fuelefficiency.UnitFuelPerArea, 'Companion', Companion_getInstance_5);
    var $com = _.com || (_.com = {});
    var $com$claas = $com.claas || ($com.claas = {});
    var $com$claas$cde = $com$claas.cde || ($com$claas.cde = {});
    var $com$claas$cde$unitsandmeasurement = $com$claas$cde.unitsandmeasurement || ($com$claas$cde.unitsandmeasurement = {});
    var $com$claas$cde$unitsandmeasurement$fuelefficiency = $com$claas$cde$unitsandmeasurement.fuelefficiency || ($com$claas$cde$unitsandmeasurement.fuelefficiency = {});
    $com$claas$cde$unitsandmeasurement$fuelefficiency.UnitFuelPerYield = UnitFuelPerYield;
    defineProp($com$claas$cde$unitsandmeasurement$fuelefficiency.UnitFuelPerYield, 'Companion', Companion_getInstance_6);
    var $com = _.com || (_.com = {});
    var $com$claas = $com.claas || ($com.claas = {});
    var $com$claas$cde = $com$claas.cde || ($com$claas.cde = {});
    var $com$claas$cde$unitsandmeasurement = $com$claas$cde.unitsandmeasurement || ($com$claas$cde.unitsandmeasurement = {});
    var $com$claas$cde$unitsandmeasurement$harvestperformance = $com$claas$cde$unitsandmeasurement.harvestperformance || ($com$claas$cde$unitsandmeasurement.harvestperformance = {});
    $com$claas$cde$unitsandmeasurement$harvestperformance.UnitAreaPerformance = UnitAreaPerformance;
    defineProp($com$claas$cde$unitsandmeasurement$harvestperformance.UnitAreaPerformance, 'Companion', Companion_getInstance_7);
    var $com = _.com || (_.com = {});
    var $com$claas = $com.claas || ($com.claas = {});
    var $com$claas$cde = $com$claas.cde || ($com$claas.cde = {});
    var $com$claas$cde$unitsandmeasurement = $com$claas$cde.unitsandmeasurement || ($com$claas$cde.unitsandmeasurement = {});
    var $com$claas$cde$unitsandmeasurement$harvestperformance = $com$claas$cde$unitsandmeasurement.harvestperformance || ($com$claas$cde$unitsandmeasurement.harvestperformance = {});
    $com$claas$cde$unitsandmeasurement$harvestperformance.UnitThroughput = UnitThroughput;
    defineProp($com$claas$cde$unitsandmeasurement$harvestperformance.UnitThroughput, 'Companion', Companion_getInstance_8);
    var $com = _.com || (_.com = {});
    var $com$claas = $com.claas || ($com.claas = {});
    var $com$claas$cde = $com$claas.cde || ($com$claas.cde = {});
    var $com$claas$cde$unitsandmeasurement = $com$claas$cde.unitsandmeasurement || ($com$claas$cde.unitsandmeasurement = {});
    var $com$claas$cde$unitsandmeasurement$harvestperformance = $com$claas$cde$unitsandmeasurement.harvestperformance || ($com$claas$cde$unitsandmeasurement.harvestperformance = {});
    $com$claas$cde$unitsandmeasurement$harvestperformance.UnitYieldPerArea = UnitYieldPerArea;
    defineProp($com$claas$cde$unitsandmeasurement$harvestperformance.UnitYieldPerArea, 'Companion', Companion_getInstance_9);
    var $com = _.com || (_.com = {});
    var $com$claas = $com.claas || ($com.claas = {});
    var $com$claas$cde = $com$claas.cde || ($com$claas.cde = {});
    var $com$claas$cde$unitsandmeasurement = $com$claas$cde.unitsandmeasurement || ($com$claas$cde.unitsandmeasurement = {});
    var $com$claas$cde$unitsandmeasurement$timemotion = $com$claas$cde$unitsandmeasurement.timemotion || ($com$claas$cde$unitsandmeasurement.timemotion = {});
    $com$claas$cde$unitsandmeasurement$timemotion.UnitDuration = UnitDuration;
    defineProp($com$claas$cde$unitsandmeasurement$timemotion.UnitDuration, 'Companion', Companion_getInstance_17);
  }
  $jsExportAll$(_);
  //endregion
  return _;
});

