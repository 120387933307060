import { Action, createReducer, on } from '@ngrx/store';
import { UserOrganisationsState, initialState } from './organisations.state';
import {
  addressDetailsLoaded,
  addressDetailsNotLoaded,
  currentOrganisationLoaded,
  loadAddressDetails,
  loadOrganisationRegistrationSteps,
  loadRegistrationDefaultOrganisation,
  loadUserDefaultOrganisation,
  loadUserOrganisations,
  organisationDataNotLoaded,
  organisationMembersNotLoaded,
  organisationRegistrationStepsLoaded,
  organisationRegistrationStepsNotLoaded,
  registeredOrganisationLoaded,
  registeredOrganisationNotLoaded,
  registerOrganisation,
  registrationDefaultOrganisationLoaded,
  registrationDefaultOrganisationNotLoaded,
  setUserDefaultOrganisation,
  userDefaultOrganisationLoaded,
  userOrganisationsLoaded
} from './organisations.actions';
import {
  addressAutocompleteSuggestionsDataNotLoaded,
  clearRegistrationData,
  registrationDealerDataNotLoaded,
  registrationPersonalDataNotLoaded,
  registrationPersonalDataNotUpdated,
  registrationShopDataNotLoaded
} from '../global';

import {
  dealerByNameSearchDataNotLoaded,
  nearestDealersSearchDataNotLoaded
} from '../dealers';

export const organisationsReducer = createReducer(
  initialState,
  on(loadUserOrganisations, state => ({
    ...state
  })),
  on(userOrganisationsLoaded, (state, { userOrganisations }) => ({
    ...state,
    userOrganisations
  })),
  on(loadUserDefaultOrganisation, state => ({ ...state })),
  on(userDefaultOrganisationLoaded, (state, { defaultOrganisationId }) => ({
    ...state,
    defaultOrganisationId
  })),
  on(setUserDefaultOrganisation, state => ({ ...state })),
  on(loadOrganisationRegistrationSteps, state => ({ ...state })),
  on(organisationRegistrationStepsLoaded, (state, { registrationSteps }) => ({
    ...state,
    registrationSteps
  })),
  on(
    organisationRegistrationStepsNotLoaded,
    (state, { organisationError }) => ({
      ...state,
      organisationError
    })
  ),
  on(loadRegistrationDefaultOrganisation, state => ({ ...state })),
  on(
    registrationDefaultOrganisationLoaded,
    (state, { registrationDefaultOrganisation }) => ({
      ...state,
      registrationDefaultOrganisation
    })
  ),
  on(
    registrationDefaultOrganisationNotLoaded,
    (state, { organisationError }) => ({
      ...state,
      organisationError
    })
  ),
  on(registerOrganisation, state => ({
    ...state
  })),
  on(registeredOrganisationLoaded, (state, { registeredOrganisation }) => ({
    ...state,
    registeredOrganisation
  })),
  on(
    registeredOrganisationNotLoaded,
    (state, { registrationError, organisationError }) => ({
      ...state,
      registeredOrganisation: null,
      organisationError
    })
  ),
  on(currentOrganisationLoaded, (state, { currentOrganisation }) => ({
    ...state,
    currentOrganisation
  })),
  on(loadAddressDetails, state => ({
    ...state,
    addressDetails: null
  })),
  on(addressDetailsLoaded, (state, { addressDetails }) => ({
    ...state,
    addressDetails
  })),
  on(addressDetailsNotLoaded, (state, { organisationError }) => ({
    ...state,
    organisationError
  })),
  on(clearRegistrationData, state => ({
    ...state,
    registrationDefaultOrganisation: null
  })),
  on(organisationMembersNotLoaded, (state, { organisationError }) => ({
    ...state,
    organisationError
  })),
  on(organisationDataNotLoaded, (state, { organisationError }) => ({
    ...state,
    organisationError
  })),
  on(
    addressAutocompleteSuggestionsDataNotLoaded,
    (state, { organisationError }) => ({
      ...state,
      organisationError
    })
  ),
  on(registrationPersonalDataNotLoaded, (state, { organisationError }) => ({
    ...state,
    organisationError
  })),
  on(registrationPersonalDataNotUpdated, (state, { organisationError }) => ({
    ...state,
    organisationError
  })),
  on(
    registrationDealerDataNotLoaded,
    (state, { registrationDealerData, organisationError }) => ({
      ...state,
      organisationError
    })
  ),
  on(registrationShopDataNotLoaded, (state, { shopDataLoadError }) => ({
    ...state,
    organisationError: shopDataLoadError
  })),
  on(
    nearestDealersSearchDataNotLoaded,
    (state, { nearestDealersSearchData, organisationError }) => ({
      ...state,
      organisationError
    })
  ),
  on(dealerByNameSearchDataNotLoaded, (state, { organisationError }) => ({
    ...state,
    organisationError
  }))
);

export function reducer(
  state: UserOrganisationsState | undefined,
  action: Action
) {
  return organisationsReducer(state, action);
}
